/*
  Components
  - h2, h4
  - figure
  - div > span
*/
.slide h4 {
	transition: all 0.3s ease;
	-webkit-transform: translateX(20px);
    transform: translateX(20px);
	opacity: 0;
}

.slide figure {
    transition: all 1s ease;
	-webkit-transform: translateX(200px);
    transform: translateX(200px);
	opacity: 0;
}

.slide article {
    transition: all 0.3s ease-out;
	-webkit-transform: translateX(80px);
    transform: translateX(80px);
	opacity: 0;
}

.slide.animateIn.previous h4,
.slide.current h4,
.slide.animateIn.next h4,
.slide.animateIn.previous figure,
.slide.current figure,
.slide.animateIn.next figure,
.slide.animateIn.previous article,
.slide.current article,
.slide.animateIn.next article {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	-webkit-transition-delay: .7s;
					transition-delay: .7s;
					opacity: 1;
}

.slide.animateOut.previous figure,
.slide.animateOut.next figure {
	-webkit-transform: translateX(-200);
	        transform: translateX(-200);
  opacity: 0;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.slide.animateIn.previous h4,
.slide.animateIn.next h4 {
	-webkit-transition-delay: 1.1s;
					transition-delay: 1.1s;
}

.slide.animateIn.previous article,
.slide.animateIn.next section span {
	-webkit-transition-delay: 1.4s;
					transition-delay: 1.4s;
}

.slide.animateOut h4 {
	-webkit-transition-delay: .2s;
					transition-delay: .2s;
}

.slide.animateOut figure {
	-webkit-transition-delay: 0s;
					transition-delay: 0s;
}

.slide.animateOut article {
	-webkit-transition-delay: .2s;
					transition-delay: .2s;
}