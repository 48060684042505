/*----------------------------------------------------
@File: Default Styles
@Author: Md. Shahadat Hussain
@URL: https://themeforest.net/user/droitthemes

This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.
---------------------------------------------------- */
/*=====================================================================
@Template Name:
@Author: Md. Shahadat Hussain
@Developed By: Rony
@Developer URL: https://themeforest.net/user/droitthemes

@Default Styles

Table of Content:
01/ variables
02/ predefine
03/ button
04/ preloader
05/ header
06/ banner
07/ breadcrumb
08/ features
09/ service
10/ price
11/ about
12/ testimonial
13/ screenshot
14/ joblist
15/ faq
16/ portfolio
17/ contact
18/ error
19/ shop
20/ blog
21/ footer

=====================================================================*/
/*====================================================*/
/*=== fonts ====*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900");
/*===== color =====*/
/*====================================================*/
/*====================================================*/

.ion-icon {
  font-size: 128px;
}
ion-icon {
  font-size: 128px;
}

a {
  text-decoration: none !important;
}

a:hover, a:focus {
  text-decoration: none;
}

.row.m0 {
  margin: 0px;
}

.p0 {
  padding: 0px;
}

i:before {
  margin-left: 0px !important;
}

body {
  font-size: 15px;
  font-weight: 300;
  line-height: 28px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #677294;
  padding: 0px;
  overflow-x: hidden;
  z-index: 0;
}

.body_wrapper {
  z-index: 20;
  overflow: hidden;
}

a, .btn, button {
  text-decoration: none;
  outline: none;
}

a:hover, a:focus, .btn:hover, .btn:focus, button:hover, button:focus {
  text-decoration: none;
  outline: none;
}

.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.seo_sec_title h2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  color: #263b5e;
  margin-bottom: 15px;
}

.seo_sec_title p {
  font-size: 16px;
  font-weight: 300;
  color: #6a7695;
  margin-bottom: 0;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}
.seo_sec_title p {
  white-space: pre-line;
}

@media (min-width: 1250px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

.container.custom_container {
  max-width: 1520px;
}

.f_size_50 {
  font-size: 50px;
}

.f_size_40 {
  font-size: 40px;
}

.f_size_30 {
  font-size: 30px;
}

.f_size_28 {
  font-size: 28px;
}

.f_size_22 {
  font-size: 22px;
}

.f_size_24 {
  font-size: 24px;
}

.f_size_20 {
  font-size: 20px;
}

.f_size_18 {
  font-size: 18px;
}

.f_size_16 {
  font-size: 16px;
}

.f_size_15 {
  font-size: 15px;
}

.l_height60 {
  line-height: 60px;
}

.l_height50 {
  line-height: 50px;
}

.l_height45 {
  line-height: 45px;
}

.l_height40 {
  line-height: 40px;
}

.l_height30 {
  line-height: 30px;
}

.l_height34 {
  line-height: 34px;
}

.l_height28 {
  line-height: 28px;
}

.f_p {
  font-family: "Poppins", sans-serif;
}

/*=========== font-weight ==============*/
.f_300 {
  font-weight: 300;
}

.f_400 {
  font-weight: 400;
}

.f_500 {
  font-weight: 500;
}

.f_600 {
  font-weight: 600;
}

.f_700 {
  font-weight: 700;
}

.f_900 {
  font-weight: 900;
}

.t_color {
  color: #051441;
}

.w_color {
  color: #fff;
}

.t_color2 {
  color: #3f4451;
}

.t_color3 {
  color: #222d39;
}

.d_p_color {
  color: #bdbed6;
}

.bg_color {
  background: #fbfbfd;
}

.dk_bg_one {
  background: #090a21;
}

.dk_bg_two {
  background: #0f1029;
}

.seo_title_color {
  color: #263b5e;
}

.mt_60 {
  margin-top: 60px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_75 {
  margin-top: 75px;
}

.mt_100 {
  margin-top: 100px;
}

.mt_130 {
  margin-top: 130px;
}

.mb_70 {
  margin-bottom: 70px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_30 {
  margin-bottom: -30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mb-50 {
  margin-bottom: -50px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_90 {
  margin-bottom: 90px;
}

.pl_20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl_40 {
  padding-left: 40px;
}

.pl_50 {
  padding-left: 50px;
}

.pl_100 {
  padding-left: 100px;
}

.pl_70 {
  padding-left: 70px;
}

.pl_120 {
  padding-left: 120px;
}

.pr_100 {
  padding-right: 100px;
}

.pr_120 {
  padding-right: 120px;
}

.pr_70 {
  padding-right: 70px;
}

.pr_50 {
  padding-right: 50px;
}

.pr_20 {
  padding-right: 20px;
}

.pt_150 {
  padding-top: 150px;
}

.pt_120 {
  padding-top: 40px;
}

.sec_pad {
  padding: 120px 0px;
}

/*====================================================*/
/*====================================================*/
.btn_hover {
  overflow: hidden;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  z-index: 1;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.btn_hover:hover {
  color: #fff;
}

.btn_get {
  font: 500 14px/47px "Poppins", sans-serif;
  color: #6754e2;
  border: 2px solid #ccc5fa;
  border-radius: 4px;
  -webkit-box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);
  padding: 0px 23px;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-property: color;
  -o-transition-property: color;
  transition-property: color;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  min-width: 120px;
  text-align: center;
}
.btn_get.new_btn{
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn_get:hover {
  color: #fff;
  background: #5e2ced;
  border-color: #5e2ced;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn_get.new_btn:hover{
  box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);
}
.btn_get_two {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.btn_get_two:hover {
  background: transparent;
  color: #5e2ced;
}

.price_btn {
  font: 500 15px/53px "Poppins", sans-serif;
  color: #6754e2;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 0px 25px;
  display: inline-block;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  transition: background 0.2s linear;
}

.price_btn:hover {
  color: #fff;
}

.slider_btn {
  font: 500 14px/20px "Poppins", sans-serif;
  border-radius: 45px;
  padding: 15px 35px;
  background: #fff;
}

.slider_btn:hover {
  color: #fff;
}

.btn_three {
  font: 500 14px "Poppins", sans-serif;
  color: #fff;
  background: #7444fd;
  border-radius: 3px;
  padding: 15px 30px;
  border: 1px solid #7444fd;
  margin-top: 50px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  display: inline-block;
}

.btn_three:hover {
  color: #7444fd;
  background: transparent;
}

.agency_banner_btn {
  font: 500 14px "Poppins", sans-serif;
  color: #fff;
  background: #5e2ced;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  border-radius: 3px;
  padding: 16px 38px;
  border: 1px solid #5e2ced;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
}

.agency_banner_btn:hover {
  color: #5e2ced;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

.agency_banner_btn_two {
  font: 500 14px "Poppins", sans-serif;
  color: #222d39;
  position: relative;
  margin-left: 50px;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.agency_banner_btn_two:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #60656d;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  transition: background 0.2s linear;
}

.agency_banner_btn_two:hover {
  color: #5e2ced;
}

.agency_banner_btn_two:hover:before {
  background: #5e2ced;
}

.software_banner_btn {
  font: 500 14px "Poppins", sans-serif;
  color: #fff;
  background: #3d64f4;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  display: inline-block;
  padding: 16px 42px;
  border-radius: 45px;
  border: 0px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.software_banner_btn:hover {
  color: #3d64f4;
  background: #fff;
}

.video_btn {
  font: 500 14px "Poppins", sans-serif;
  color: #fff;
  margin-left: 30px;
  cursor: pointer;
}

.video_btn .icon {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #fff;
  display: inline-block;
  color: #3d64f4;
  text-align: center;
  line-height: 46px;
  font-size: 18px;
  margin-right: 18px;
  vertical-align: middle;
  -webkit-box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.19);
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.19);
}

.video_btn span {
  display: inline-block;
  position: relative;
}

.video_btn span:before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  background: #fff;
}

.video_btn:hover {
  color: #fff;
}

.btn_four {
  font: 500 14px "Poppins", sans-serif;
  padding: 16px 40px;
  border-radius: 45px;
  color: #fff;
  border: 1px solid #3d64f4;
  background: #3d64f4;
  cursor: pointer;
}

.btn_four:hover {
  color: #3d64f4;
  background: transparent;
}

.saas_banner_btn {
  padding: 16px 40px;
  background: #3d57f4;
  border-radius: 4px;
  color: #fff;
}

.saas_banner_btn:hover {
  color: #3d57f4;
  background: #fff;
}

.btn_five {
  border: 1px solid #fff;
  color: #fff;
  border-radius: 3px;
  padding: 12px 28px;
}

.btn_five:hover {
  background: #3d57f4;
  border-color: #3d57f4;
}

.app_btn {
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  background-color: #4069eb;
  display: inline-block;
  padding: 14px 40px;
  color: #fff;
  border-radius: 45px;
  border: 1px solid #4069eb;
}

.app_btn:hover {
  color: #4069eb;
  background: #fbfbfd;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.gr_btn {
  font: 500 14px "Poppins", sans-serif;
  color: #23b1fe;
  display: inline-block;
  padding: 15px 31px;
  position: relative;
  min-width: 140px;
  border-radius: 4px;
  background-image: -moz-linear-gradient(-48deg, #237efd 0%, #24b0fe 46%, #24e2ff 100%);
  background-image: -webkit-linear-gradient(-48deg, #237efd 0%, #24b0fe 46%, #24e2ff 100%);
  background-image: -ms-linear-gradient(-48deg, #237efd 0%, #24b0fe 46%, #24e2ff 100%);
  z-index: 1;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.gr_btn:before {
  content: "";
  position: absolute;
  top: 1px;
  left: 50%;
  width: calc(100% - 2px);
  bottom: 1px;
  border-radius: 4px;
  background: #fff;
  z-index: 0;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  -webkit-transform: translatex(-50%);
  -ms-transform: translatex(-50%);
  transform: translatex(-50%);
}

.gr_btn .text {
  position: relative;
  z-index: 1;
}

.gr_btn:hover {
  color: #fff;
}

.gr_btn:hover:before {
  opacity: 0;
}

.btn_six {
  background: #17c7bd !important;
  font-size: 15px;
  border: 1px solid #17c7bd !important;
  padding: 13px 35px;
  color: #fff !important;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
}

.btn_six:hover {
  background: #ebfaf9 !important;
  color: #17c7bd !important;
}

.btn_six + .btn_six {
  background: #ebfaf9 !important;
  color: #17c7bd !important;
  margin-left: 20px;
}

.btn_six + .btn_six i {
  padding-right: 5px;
}

.btn_six + .btn_six:hover {
  background: #17c7bd !important;
  color: #fff !important;
}

.seo_btn {
  display: inline-block;
  padding: 11px 40px;
  border-radius: 4px;
}

.seo_btn_one {
  color: #5e2ced;
  background: #e8e1fc;
}

.seo_btn_one:hover {
  background: #5e2ced;
  color: #fff;
}

.seo_btn_two {
  color: #00c99c;
  background: #d4f6ee;
}

.seo_btn_two:hover {
  background: #00c99c;
  color: #fff;
}

/*====================================================*/
/*====================================================*/

/* .ctn-preloader .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 1;
  pointer-events: none;
}

.ctn-preloader .loader .row {
  height: 100%;
}

.ctn-preloader .loader .loader-section {
  padding: 0px;
}

.ctn-preloader .loader .loader-section .bg {
  background-color: #ffffff;
  height: 100%;
  left: 0;
  width: 100%;
  -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}

.ctn-preloader .loader.dark_bg .loader-section .bg {
  background: #111339;
}

.ctn-preloader.loaded .animation-preloader {
  opacity: 0;
  -webkit-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.ctn-preloader.loaded .loader-section .bg {
  width: 0;
  -webkit-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
  -o-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
  transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
} */

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@-webkit-keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}

@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
  .ctn-preloader .animation-preloader .txt-loading {
    font: bold 3.5em "Poppins", sans-serif;
  }
}

@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }
  .ctn-preloader .animation-preloader .txt-loading {
    font: bold 2em "Poppins", sans-serif;
  }
}

/*====================================================*/
/*====================================================*/
/*============== header_area css ==============*/
.header_area {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 20;
  top: 0;
}

.menu_one {
  padding: 0px;
}

.menu_one .btn_get {
  margin-left: 40px;
}
.navbar-brand img{
  display: none;
}
.navbar-brand img + img {
  display: block;
}

.sticky_logo img{
  display: block;
}
.sticky_logo img + img{
  display: none;
}

@media (min-width: 992px) {
  .mobile_btn {
    visibility: hidden;
    display: none;
  }
}

.menu > .nav-item {
  position: relative;
  padding-bottom: 35px;
  padding-top: 35px;
}

.menu > .nav-item > .nav-link {
  font: 500 15px "Poppins", sans-serif;
  color: #051441;
  padding: 0px;
  -webkit-transition: color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s;
  transition: color 0.3s ease 0s;
  position: relative;
  cursor: pointer;
}

.menu > .nav-item > .nav-link:before {
  content: "";
  width: 0;
  height: 1px;
  background: #6754e2;
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.menu > .nav-item > .nav-link:after {
  display: none;
}

.menu > .nav-item.submenu .dropdown-menu {
  margin: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  left: -40px;
  min-width: 200px;
  padding: 0px;
  background: #fff;
  -webkit-box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, 0.05);
  box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, 0.05);
}

@media (min-width: 992px) {
  .menu > .nav-item.submenu .dropdown-menu {
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    opacity: 0;
    visibility: hidden;
    display: block;
  }
}

.menu > .nav-item.submenu .dropdown-menu .nav-item {
  display: block;
  width: 100%;
  margin-right: 0px;
  padding: 0px 40px;
  position: relative;
}

.menu > .nav-item.submenu .dropdown-menu .nav-item .nav-link {
  padding: 0px;
  white-space: nowrap;
  font: 400 14px/34px "Poppins", sans-serif;
  color: #051441;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.menu > .nav-item.submenu .dropdown-menu .nav-item .nav-link:after {
  display: none;
}

.menu > .nav-item.submenu .dropdown-menu .nav-item .nav-link span.arrow_carrot-right {
  right: 20px;
  position: absolute;
  line-height: 30px;
  font-size: 18px;
}

.menu > .nav-item.submenu .dropdown-menu .nav-item:first-child {
  padding-top: 25px;
}

@media (min-width: 992px) {
  .menu > .nav-item.submenu .dropdown-menu .nav-item:first-child > .dropdown-menu {
    top: 0;
  }
}

.menu > .nav-item.submenu .dropdown-menu .nav-item:last-child {
  padding-bottom: 30px;
  margin-bottom: -8px;
}

.menu > .nav-item.submenu .dropdown-menu .nav-item > .dropdown-menu {
  -webkit-transition: -webkit-transform 0.3s ease-in;
  transition: -webkit-transform 0.3s ease-in;
  -o-transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
  padding: 0px;
}

.menu > .nav-item.submenu .dropdown-menu .nav-item > .dropdown-menu:before {
  display: none;
}

@media (min-width: 992px) {
  .menu > .nav-item.submenu .dropdown-menu .nav-item > .dropdown-menu {
    position: absolute;
    left: 100%;
    top: -25px;
    opacity: 0;
    display: block;
    visibility: hidden;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
}

.menu > .nav-item.submenu .dropdown-menu.mega_menu_three > .nav-item {
  padding: 0px;
}

.menu > .nav-item.submenu .dropdown-menu.mega_menu_three > .nav-item > .dropdown-menu {
  left: 0;
  position: relative;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.menu > .nav-item.submenu .dropdown-menu.mega_menu_three > .nav-item > .dropdown-menu .nav-item {
  padding: 20px 45px;
  border: 1px solid transparent;
  -webkit-transition: all .15s linear;
  -o-transition: all .15s linear;
  transition: all .15s linear;
}

.menu > .nav-item.submenu .dropdown-menu.mega_menu_three > .nav-item > .dropdown-menu .nav-item .nav-link .navdropdown_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.menu > .nav-item.submenu .dropdown-menu.mega_menu_three > .nav-item > .dropdown-menu .nav-item .nav-link .navdropdown_link .navdropdown_icon {
  margin-right: 25px;
}

.menu > .nav-item.submenu .dropdown-menu.mega_menu_three > .nav-item > .dropdown-menu .nav-item .nav-link .navdropdown_link .navdropdown_icon img {
  max-width: 30px;
}

.menu > .nav-item.submenu .dropdown-menu.mega_menu_three > .nav-item > .dropdown-menu .nav-item .nav-link .navdropdown_link .navdropdown_content h5 {
  font: 500 16px "Poppins", sans-serif;
  color: #13112d;
  margin-bottom: 0px;
}

.menu > .nav-item.submenu .dropdown-menu.mega_menu_three > .nav-item > .dropdown-menu .nav-item .nav-link .navdropdown_link .navdropdown_content p {
  margin-bottom: 0;
  padding-top: 5px;
  font-size: 15px;
  line-height: 20px;
  color: #677294;
}

.menu > .nav-item.submenu .dropdown-menu.mega_menu_three > .nav-item > .dropdown-menu .nav-item.nav_download_btn {
  border-top-color: #f2f0f7;
  padding: 15px 45px;
}

.menu > .nav-item.submenu .dropdown-menu.mega_menu_three > .nav-item > .dropdown-menu .nav-item.nav_download_btn .nav-link .navdropdown_link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.menu > .nav-item.submenu .dropdown-menu.mega_menu_three > .nav-item > .dropdown-menu .nav-item.nav_download_btn .nav-link .navdropdown_link .navdropdown_icon {
  margin-right: 10px;
}

.menu > .nav-item.submenu .dropdown-menu.mega_menu_three > .nav-item > .dropdown-menu .nav-item:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  border-color: #f2f0f7;
  background-color: white;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1), 0px -1px 0px 0px rgba(243, 241, 248, 0.004);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1), 0px -1px 0px 0px rgba(243, 241, 248, 0.004);
}

.menu > .nav-item.submenu.mega_menu {
  position: static;
}

.menu > .nav-item.submenu.mega_menu .mega_menu_inner {
  min-width: 200px;
  list-style: none;
  position: absolute;
  -webkit-box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, 0.05);
  box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, 0.05);
  left: 0px;
  right: 0px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  background: #fff;
}

@media (min-width: 992px) {
  .menu > .nav-item.submenu.mega_menu .mega_menu_inner {
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    display: block;
  }
}

.menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu {
  position: relative;
  max-width: 1170px;
  margin: 0 auto;
  left: 0;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px;
}

@media (min-width: 992px) {
  .menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu > .nav-item {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 0px;
  width: 25%;
}

.menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu > .nav-item > .nav-link {
  font: 500 18px "Poppins", sans-serif;
  color: #13112d;
}

.menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu > .nav-item .dropdown-menu {
  left: 0;
  position: relative;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px;
  background: transparent;
  top: 0;
}

.menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu > .nav-item .dropdown-menu > .nav-item {
  padding-left: 0px;
  padding-right: 0px;
  white-space: normal;
}

.menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu > .nav-item .dropdown-menu > .nav-item:first-child {
  padding-top: 20px;
}

.menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu > .nav-item .dropdown-menu > .nav-item:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu > .nav-item:hover > .nav-link {
  color: inherit !important;
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu {
  left: 0;
}

@media (min-width: 992px) {
  .menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu {
    padding: 20px 0px;
  }
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .mCSB_scrollTools {
  width: 0px;
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .mCSB_container {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .mCustomScrollBox {
  width: 100%;
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item {
  padding: 0px;
  margin-bottom: 0;
  width: 100%;
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .dropdown-menu {
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

@media (max-height: 980px) and (min-width: 992px) {
  .menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .dropdown-menu {
    max-height: 720px;
    overflow-y: scroll;
  }
  .dropdown-menu::-webkit-scrollbar{
    display: none;
  }
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .dropdown-menu .nav-item {
  width: 25%;
  padding: 15px 20px;
  margin-bottom: 0;
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item {
  text-align: center;
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .rebon_tap {
  font: 500 12px "Poppins", sans-serif;
  color: #fff;
  background: #f91f43;
  display: block;
  position: absolute;
  top: -7px;
  left: -25px;
  padding: 15px 22px 5px;
  text-transform: uppercase;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .img {
  -webkit-box-shadow: 0px 4px 8px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 4px 8px 0px rgba(12, 0, 46, 0.06);
  position: relative;
  margin-top: 0;
  display: block;
  overflow: hidden;
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .img:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: #13112d;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .img img {
  width: 100%;
  max-width: 100%;
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .img.box_none {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .text {
  font: 500 16px/22px "Poppins", sans-serif;
  color: #13112d;
  display: block;
  margin-top: 20px;
}

.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item:hover .img:before {
  opacity: 0.50;
}

@media (min-width: 992px) {
  .menu > .nav-item.submenu.mega_menu:hover .dropdown-menu > .nav-item > .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }
  .menu > .nav-item.submenu.mega_menu:hover .mega_menu_inner {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}
/*
.menu > .nav-item:nth-last-child(-n+2).submenu .dropdown-menu {
  left: auto;
  right: 0;
}

.menu > .nav-item:nth-last-child(-n+2).submenu .dropdown-menu .nav-item > .dropdown-menu {
  left: auto;
  right: 100%;
}

.menu > .nav-item:nth-last-child(-n+2).submenu.mega_menu .dropdown-menu {
  left: 0;
  right: auto;
}

.menu > .nav-item:nth-last-child(-n+2).submenu.mega_menu .dropdown-menu .nav-item > .dropdown-menu {
  left: 0;
  right: auto;
} */

.menu > .nav-item + .nav-item {
  margin-left: 45px;
}

.menu > .nav-item .nav-link.active:before {
  width: 100%;
  left: 0;
  right: auto;
}

.menu > .nav-item:hover .nav-link:before {
  width: 100%;
  left: 0;
  right: auto;
}

@media (min-width: 992px) {
  .menu > .nav-item:hover .dropdown-menu {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
  }
  .menu > .nav-item:hover .dropdown-menu.mega_menu_three .nav-item .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .menu > .nav-item:hover .dropdown-menu > .nav-item:hover .dropdown-menu {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }
}

.navbar .search_cart .search a, .navbar .search_cart .shpping-cart a {
  font-size: 16px;
  line-height: 18px;
  color: #282835;
  margin-left: 16px;
}

.navbar .search_cart.w_menu .search a, .navbar .search_cart.w_menu .shpping-cart a {
  color: #fff;
}

.navbar .search_cart.w_menu .search a.nav-link {
  margin-left: 0;
}

.navbar .search_cart.w_menu .search a.nav-link:before {
  background: #fff;
}

.navbar .search_cart .search {
  display: inline-block;
}

.navbar .search_cart .search a.nav-link {
  font-size: 13px;
  line-height: 19px;
  position: relative;
  margin-left: 32px;
}

.navbar .search_cart .search a.nav-link:before {
  width: 1px !important;
  height: 12px;
  left: -14px;
  top: 3px;
  background: #13112d;
}

.navbar .search_cart .shpping-cart {
  display: inline-block;
  margin-left: 0px;
  margin-right: 0px;
}

.navbar .search_cart .shpping-cart .num {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #6754e2;
  color: #fff;
  font-size: 11px;
  display: block;
  position: absolute;
  top: -8px;
  right: -9px;
  line-height: 16px;
  text-align: center;
}

.navbar .search_cart .shpping-cart .nav-link:before {
  display: none;
}

.navbar .search_cart .shpping-cart .dropdown-menu {
  padding: 0px;
  min-width: 350px;
  width: 100%;
  position: absolute;
}

.navbar .search_cart .shpping-cart .dropdown-menu li {
  background: #fff;
}

.navbar .search_cart .shpping-cart .dropdown-menu li:first-child {
  padding-bottom: 0px;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item a {
  color: #333;
  margin-left: 0;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item .cart-img {
  display: block;
  width: 30%;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item .cart-content {
  width: 60%;
  padding-left: 15px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item .cart-content .cart-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
  -webkit-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item .cart-content p {
  font-size: 14px;
  line-height: 22px;
  font-family: "Poppins", sans-serif;
  color: #333;
  font-weight: 300;
  margin-bottom: 0px;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item .cart-content p del {
  color: #919191;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item .cart-remove {
  width: 10%;
  text-align: right;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item .cart-remove a {
  font-size: 14px;
  color: #333;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item .cart-remove a:hover {
  color: #6754e2;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item:hover .cart-img {
  opacity: 0.5;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item:hover .cart-title a {
  color: #6754e2;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart_f {
  padding: 25px 30px 30px;
  background: #f9f9f9;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart_f .total {
  font-size: 14px;
  color: #676b75;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart_f .total .p-total {
  float: right;
  font-size: 20px;
  color: #333333;
  font-weight: 500;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart_f .cart-button .get_btn {
  font-size: 14px;
  text-transform: capitalize;
  border-radius: 4px;
  margin: 0px;
  line-height: 40px;
  padding: 0px 32px;
  background: #6754e2;
  border: 1px solid #6754e2;
  color: #fff;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart_f .cart-button .get_btn:hover {
  background: transparent;
  color: #6754e2;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart_f .cart-button .get_btn + .get_btn {
  margin-left: 10px;
  background: transparent;
  color: #6754e2;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart_f .cart-button .get_btn + .get_btn:hover {
  background: #6754e2;
  color: #fff;
}

.menu_two {
  padding: 0px;
}

.menu_two .w_menu > .nav-item .nav-link:before {
  background: #a5d5f5;
}

.menu_two .w_menu > .nav-item.submenu.mega_menu > .dropdown-menu > .nav-item > .nav-link {
  color: #13112d;
}

.menu_two .menu_toggle .hamburger-cross span, .menu_two .menu_toggle .hamburger span {
  background: #fff;
}

.w_menu .nav-item .nav-link {
  color: #fff;
}

.w_menu .nav-item .nav-link:before {
  background: #fff;
}

.nav_right_btn .login_btn {
  font: 500 15px "Poppins", sans-serif;
  padding: 13px 32px;
  min-width: 120px;
  display: inline-block;
  text-align: center;
  color: #fff;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border-top-left-radius: 45px;
  border-bottom-left-radius: 45px;
  border-top-right-radius: 45px;
  border: 1px solid transparent;
}

.nav_right_btn .login_btn:hover, .nav_right_btn .login_btn.active {
  background: #fff;
  -webkit-box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);
}

@media (max-width: 991px) {
  .nav_right_btn {
    display: none;
  }
}

.menu_four .btn_get {
  border-color: #fff;
  padding: 0px 30px;
  background: #fff;
  min-width: 120px;
  text-align: center;
}

.menu_four .btn_get:hover {
  background: #5e2ced;
  border-color: #5e2ced;
}

.menu_four .btn_get_radious {
  background: transparent;
}

.header_area .menu_four .btn_get.btn_get_radious:hover {
  background: #fff;
}

.menu_four .menu_toggle .hamburger span, .menu_four .menu_toggle .hamburger-cross span {
  background: #fff;
}

.menu_five {
  border-radius: 0px;
}

.menu_five .menu > .nav-item .nav-link {
  color: #fff;
}

.menu_five .menu > .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link, .menu_five .menu > .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link, .menu_five .menu > .nav-item.submenu .dropdown-menu .nav-item > .nav-link.active {
  color: #3d64f4;
}

.menu_five .menu > .nav-item.submenu.mega_menu > .dropdown-menu > .nav-item > .nav-link {
  color: #051441;
}

.menu_five .btn_get {
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  line-height: 44px;
  padding: 0px 28px;
  min-width: 120px;
  text-align: center;
}

.menu_five .btn_get:hover {
  background: #fff;
  color: #3d64f4;
  border-color: #fff;
}

.menu_five .menu_toggle .hamburger span, .menu_five .menu_toggle .hamburger-cross span {
  background: #fff;
}

.menu_six {
  padding: 0px;
}

.menu_six .menu > .nav-item .nav-link {
  color: #fff;
}

.menu_six .menu > .nav-item:hover .nav-link, .menu_six .menu > .nav-item.active .nav-link {
  color: #3d57f4;
}

.menu_six .menu > .nav-item:hover .nav-link:before, .menu_six .menu > .nav-item.active .nav-link:before {
  background: #3d57f4;
}

.menu_six .menu > .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link, .menu_six .menu > .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link, .menu_six .menu > .nav-item.submenu .dropdown-menu .nav-item > .nav-link.active {
  color: #3d57f4;
}

.menu_six .menu > .nav-item.submenu.mega_menu > .dropdown-menu > .nav-item > .nav-link {
  color: #051441;
}

.menu_six .menu > .nav-item:nth-last-child(-n+2).submenu .dropdown-menu {
  left: -40px;
  right: auto;
}

.menu_six .menu_toggle .hamburger-cross span, .menu_six .menu_toggle .hamburger span {
  background: #fff;
}

.menu_six .btn_get {
  border: 1px solid #fff;
  line-height: 43px;
  color: #fff;
}

.menu_six .btn_get:hover {
  background: #3d57f4;
  border-color: #3d57f4;
}

.menu_seven {
  padding: 0px;
}

.menu_seven .menu .nav-item .nav-link {
  color: #fff;
}

.menu_seven .menu .nav-item.active .nav-link:before, .menu_seven .menu .nav-item:hover .nav-link:before {
  background: #fff;
}

.menu_seven .menu .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link, .menu_seven .menu .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link, .menu_seven .menu .nav-item.submenu .dropdown-menu .nav-item.active > .nav-link {
  color: #4069eb;
}

.menu_seven .menu .nav-item.submenu.mega_menu > .dropdown-menu > .nav-item > .nav-link {
  color: #051441;
}

.menu_seven .menu_toggle .hamburger-cross span, .menu_seven .menu_toggle .hamburger span {
  background: #fff;
}

.menu_seven .btn_get {
  background: transparent;
}

.menu_seven .btn_get:hover {
  background: #fff;
  color: #4069eb;
}

.menu_eight .menu .nav-item.active .nav-link:before, .menu_eight .menu .nav-item:hover .nav-link:before {
  background: #fff;
}

.menu_eight .menu .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link, .menu_eight .menu .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link, .menu_eight .menu .nav-item.submenu .dropdown-menu .nav-item.active > .nav-link {
  color: #23b1fe;
}

.menu_eight .menu .nav-item.submenu.mega_menu > .dropdown-menu > .nav-item > .nav-link {
  color: #051441;
}

.menu_eight .saas_btn {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #fff;
  color: #23b1fe;
}

.menu_eight .menu_toggle .hamburger-cross span, .menu_eight .menu_toggle .hamburger span {
  background: #fff;
}
.navbar_fixed .menu_eight .menu_toggle .hamburger-cross span,.navbar_fixed .menu_eight .menu_toggle .hamburger span{
  background: #23b1fe;
}

.menu_nine .menu > .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link, .menu_nine .menu > .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link, .menu_nine .menu > .nav-item.submenu .dropdown-menu .nav-item.active > .nav-link {
  color: #5f51fb;
}

.menu_nine .menu_toggle .hamburger-cross span, .menu_nine .menu_toggle .hamburger span {
  background: #fff;
}

.menu_nine .btn_get {
  border-radius: 45px;
  line-height: 40px;
  font-size: 15px;
  margin-left: 35px;
}

.btn_get_radious {
  border-radius: 45px;
  border-width: 1px;
  color: #fff;
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px 28px;
  line-height: 45px;
}

.btn_get_radious:before {
  background: #5846ed;
}

.btn_get_radious:hover {
  color: #5846ed;
}

.menu_tracking .container{
  width: 100%;
  max-width: 100%;
  padding-left: 65px;
  padding-right: 65px;
}
.menu_one .tracking_btn{
  font-size: 16px;
  color: #fff;
  background: #6754e2;
  border: 0px;
  padding: 0px 50px;
  line-height: 42px;
  margin-top: 0;
  margin-left: 0px;
  border-radius: 45px;
  display: block;
  position: relative;
  overflow: visible;
}
.menu_four.hosting_menu .event_btn.btn_get{
  border-color: #af8a90;
  background: transparent;
  padding: 0px 41px;
  margin-left: 0;
}
.navbar_fixed .menu_four.hosting_menu .event_btn.btn_get{
  color: #2c2c51;
  border-color: #2c2c51;
  background: transparent;
}
.navbar_fixed .menu_four.hosting_menu .event_btn.btn_get:hover{
  color: #fff;
}

/*============== header_area css ==============*/
/*========= hamburger menu css ========*/
.navbar-toggler {
  padding: 0px;
  margin-left: 20px;
}

.navbar-toggler:focus {
  outline: none;
}

.menu_toggle {
  width: 22px;
  height: 22px;
  position: relative;
  cursor: pointer;
  display: block;
}

.menu_toggle .hamburger {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
}

.menu_toggle .hamburger span {
  width: 0%;
  height: 2px;
  position: relative;
  top: 0;
  left: 0;
  margin: 4px 0;
  display: block;
  background: #5e2ced;
  border-radius: 3px;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.menu_toggle .hamburger span:nth-child(1) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.menu_toggle .hamburger span:nth-child(2) {
  -webkit-transition-delay: .125s;
  -o-transition-delay: .125s;
  transition-delay: .125s;
}

.menu_toggle .hamburger span:nth-child(3) {
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

.menu_toggle .hamburger-cross {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
}

.menu_toggle .hamburger-cross span {
  display: block;
  background: #5e2ced;
  border-radius: 3px;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.menu_toggle .hamburger-cross span:nth-child(1) {
  height: 100%;
  width: 2px;
  position: absolute;
  top: 0;
  left: 10px;
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
}

.menu_toggle .hamburger-cross span:nth-child(2) {
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 10px;
  -webkit-transition-delay: .4s;
  -o-transition-delay: .4s;
  transition-delay: .4s;
}

.collapsed .menu_toggle .hamburger span {
  width: 100%;
}

.collapsed .menu_toggle .hamburger span:nth-child(1) {
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
}

.collapsed .menu_toggle .hamburger span:nth-child(2) {
  -webkit-transition-delay: .4s;
  -o-transition-delay: .4s;
  transition-delay: .4s;
}

.collapsed .menu_toggle .hamburger span:nth-child(3) {
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.collapsed .menu_toggle .hamburger-cross span:nth-child(1) {
  height: 0%;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.collapsed .menu_toggle .hamburger-cross span:nth-child(2) {
  width: 0%;
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

.header_area_two + section, .header_area_three + section {
  margin-top: 93px;
}

.h_dark {
  background: #13112d;
}

.header_area_three {
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.1);
}

.header_area_three .menu_four .pl_30 {
  padding-left: 30px;
}

.header_area_three .menu_four .btn_get {
  border-color: #5e2ced;
  background: #5e2ced;
  color: #fff;
}

.header_area_three .menu_four .btn_get:hover {
  background: transparent;
  color: #5e2ced;
}

.header_area_three.navbar_fixed .menu_four .btn_get {
  border-color: #5e2ced !important;
}

.header_area_four .header_top {
  background: #13112d;
}

.header_area_four .header_top a {
  -webkit-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
}

.header_area_four .header_top a:hover {
  color: #5e2ced;
}

.header_area_four + section {
  margin-top: 144px;
}

.header_top {
  padding: 12px 0px;
}

.header_contact_info {
  margin-bottom: 0;
}

.header_contact_info li {
  display: inline-block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
}

.header_contact_info li:before {
  content: "";
  width: 1px;
  height: 12px;
  top: 1px;
  background: #5e5d76;
  display: inline-block;
  position: relative;
  margin: 0px 22px 0px 20px;
}

.header_contact_info li:first-child:before {
  display: none;
}

.header_contact_info li a {
  color: #c3c1da;
  font-weight: 300;
  padding-left: 5px;
}

.header_social_icon {
  text-align: right;
  margin-bottom: 0;
}

.header_social_icon li {
  display: inline-block;
  margin: 0px 0px 0px 15px;
}

.header_social_icon li a {
  font-size: 12px;
  color: #fff;
}

.header_area_five .menu_one {
  padding: 0px 50px;
}

.header_area_six .header_top {
  border-bottom: 1px solid #e5ddfc;
}

.header_area_six .header_top a:hover {
  color: #5e2ced !important;
}

.header_area_six .header_top .header_contact_info li {
  color: #282835;
}

.header_area_six .header_top .header_contact_info li a {
  color: #677294;
}

.header_area_six .header_top .header_social_icon li a {
  color: #282835;
}

.header_area_six + section {
  margin-top: 144px;
}

.header_area_seven .header_top {
  background: #5e2ced;
  padding: 0px;
}

.header_area_seven .header_top a {
  line-height: 50px;
  opacity: 0.7;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.header_area_seven .header_top a:hover {
  opacity: 1;
}

.header_area_seven .header_top .header_contact_info li i {
  vertical-align: middle;
}

.header_area_seven .header_top .header_contact_info li a {
  color: #fff;
}

.header_area_seven .header_top .header_social_icon {
  border-left: 1px solid #7444ff;
  padding-left: 22px;
  margin-left: 40px;
}

.header_area_seven .header_top .header_top_menu {
  text-align: right;
  margin-bottom: 0px;
}

.header_area_seven .header_top .header_top_menu li {
  display: inline-block;
}

.header_area_seven .header_top .header_top_menu li:before {
  content: "";
  width: 1px;
  height: 11px;
  background: #8e6bf7;
  display: inline-block;
  vertical-align: middle;
  margin: 0px 17px 0px 15px;
}

.header_area_seven .header_top .header_top_menu li a {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  color: #fff;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.header_area_seven .header_top .header_top_menu li:first-child:before {
  display: none;
}

.header_area_seven + section {
  margin-top: 118px;
}

.menu_poss .w_menu.search_cart .search a.nav-link {
  margin-left: 32px;
}

/*============ sticky menu css ==============*/
.header_area {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.navbar_fixed .header_area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
}

.navbar_fixed .header_area .menu_one {
  padding: 0px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.navbar_fixed .header_area .menu_one .btn_get {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navbar_fixed .header_area .menu_one .btn_get.new_btn{
  color: #6754e2;
  border: 2px solid #ccc5fa;
  background: transparent;
}

.navbar_fixed .header_area .menu_one .btn_get.new_btn:hover{
  color: #fff;
  background: #5e2ced;
  border-color: #5e2ced;
}

.navbar_fixed .header_area .sticky_logo img {
  display: none;
}

.navbar_fixed .header_area .sticky_logo img + img {
  display: block;
}

.navbar_fixed .header_area .w_menu .nav-item .nav-link {
  color: #051441;
}

.navbar_fixed .header_area .w_menu .nav-item .nav-link:before {
  background: #051441;
}

.navbar_fixed .header_area .menu_poss .w_menu .nav-item .nav-link:hover {
  color: #5e53fb;
}

.navbar_fixed .header_area .menu_poss .w_menu .nav-item .nav-link:hover:before {
  background: #5e53fb;
}

.navbar_fixed .header_area .menu_poss .w_menu .nav-item .nav-link.active {
  color: #5e53fb;
}

.navbar_fixed .header_area .menu_poss .w_menu .nav-item .nav-link.active:before {
  background: #5e53fb;
}

.navbar_fixed .header_area .menu_poss .w_menu.search_cart .search a.nav-link:before {
  background: #5e53fb;
}

.navbar_fixed .header_area .menu_two .menu_toggle .hamburger-cross span,.navbar_fixed .header_area .menu_two .menu_toggle .hamburger span {
  background: #051441;
}

.navbar_fixed .header_area .menu_two .nav_right_btn .login_btn.active,.navbar_fixed .header_area .menu_two .nav_right_btn .login_btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar_fixed .header_area .menu_two .nav_right_btn .login_btn.active:hover {
  color: #fff;
}

.menu_four .btn_get {
  border: 2px solid #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navbar_fixed .menu_four .btn_get {
  border-color: #ccc5fa;
}
.menu_four .btn_get:hover {
  background: #5e2ced;
  border-color: #5e2ced;
}
.navbar_fixed .header_area .menu_four .btn_get:hover {
  border-color: #6754e2;
}
.menu_one.erp_menu .er_btn{
  box-shadow: none;
}
.navbar_fixed .header_area .menu_four .btn_get_radious {
  border-color: #563bd1;
  color: #563bd1;
}

.navbar_fixed .header_area .menu_four .btn_get_radious:hover {
  background: #563bd1;
  color: #fff;
}

.navbar_fixed .header_area .menu_four .menu_toggle .hamburger span,.navbar_fixed .header_area .menu_four .menu_toggle .hamburger-cross span {
  background: #6754e2;
}

.navbar_fixed .header_area .nav_right_btn .login_btn {
  color: #051441;
}

.navbar_fixed .header_area .menu_five .btn_get {
  border-color: #3d64f4;
  color: #3d64f4;
  display: inline-block;
}

.navbar_fixed .header_area .menu_five .btn_get:hover {
  background: #3d64f4;
  color: #fff;
}

.navbar_fixed .header_area .menu_five .menu_toggle .hamburger-cross span, .navbar_fixed .header_area .menu_five .menu_toggle .hamburger span {
  background: #3d64f4;
}

.navbar_fixed .header_area .menu_six .menu > .nav-item > .nav-link {
  color: #051441;
}

.navbar_fixed .header_area .menu_six .menu > .nav-item:hover > .nav-link, .navbar_fixed .header_area .menu_six .menu > .nav-item.active > .nav-link {
  color: #3d57f4;
}

.navbar_fixed .header_area .menu_six .menu > .nav-item:hover > .nav-link:before, .navbar_fixed .header_area .menu_six .menu > .nav-item.active > .nav-link:before {
  background: #3d57f4;
}

.navbar_fixed .header_area .menu_six .menu_toggle .hamburger-cross span, .navbar_fixed .header_area .menu_six .menu_toggle .hamburger span {
  background: #3d57f4;
}

.navbar_fixed .header_area .menu_six .btn_get {
  border-color: #3d57f4;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #3d57f4;
}

.navbar_fixed .header_area .menu_six .btn_get:hover {
  color: #fff;
}

.navbar_fixed .header_area .menu_seven .menu > .nav-item > .nav-link {
  color: #051441;
}

.navbar_fixed .header_area .menu_seven .menu > .nav-item:hover > .nav-link, .navbar_fixed .header_area .menu_seven .menu > .nav-item.active > .nav-link {
  color: #4069eb;
}

.navbar_fixed .header_area .menu_seven .menu > .nav-item:hover > .nav-link:before, .navbar_fixed .header_area .menu_seven .menu > .nav-item.active > .nav-link:before {
  background: #4069eb;
}

.header_area.navbar_fixed .menu_seven .btn_get {
  border-color: #4069eb;
  color: #4069eb;
}

.header_area.navbar_fixed .menu_seven .btn_get:hover {
  background: #4069eb;
  color: #fff;
}

.header_area.navbar_fixed .menu_seven .menu_toggle .hamburger-cross span, .header_area.navbar_fixed .menu_seven .menu_toggle .hamburger span {
  background: #4069eb;
}

.header_area.navbar_fixed .menu_eight .saas_btn {
  border-color: #23b1fe;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.header_area.navbar_fixed .menu_eight .saas_btn:hover {
  background: #23b1fe;
  color: #fff;
}

.header_area.navbar_fixed .menu_eight .menu_toggle .hamburger span, .header_area.navbar_fixed .menu_eight .menu_toggle .hamburger-cross span {
  background: #23b1fe;
}

.header_area.navbar_fixed .menu_nine .menu > .nav-item:hover > .nav-link, .header_area.navbar_fixed .menu_nine .menu > .nav-item.active > .nav-link {
  color: #5f51fb;
}

.header_area.navbar_fixed .menu_nine .menu > .nav-item:hover > .nav-link:before, .header_area.navbar_fixed .menu_nine .menu > .nav-item.active > .nav-link:before {
  background: #5f51fb;
}

.header_area.navbar_fixed .menu_nine .btn_get {
  border-color: #5f51fb;
  color: #5f51fb;
}

.header_area.navbar_fixed .menu_nine .btn_get:hover {
  background: #5f51fb;
  color: #fff;
}

.header_area.navbar_fixed .menu_nine .menu_toggle .hamburger-cross span, .header_area.navbar_fixed .menu_nine .menu_toggle .hamburger span {
  background: #5f51fb;
}

.header_area.navbar_fixed.header_area_five .menu_one {
  padding: 0px 50px;
}

.header_area.navbar_fixed.header_area_six .header_top {
  display: none;
}

.full_header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px;
}

.full_header .logo img {
  display: none;
}

.full_header .logo img + img {
  display: inline-block;
}

.full_header .bar_menu {
  cursor: pointer;
}

.full_header .bar_menu span {
  width: 36px;
  height: 2px;
  background: #fff;
  display: block;
}

.full_header .bar_menu span:not(:last-child) {
  margin-bottom: 7px;
}

.full_header .bar_menu span:nth-child(2) {
  width: 24px;
}

.full_header .bar_menu span:nth-child(3) {
  width: 30px;
}

.full_header.content-white .logo img {
  display: inline-block;
}

.full_header.content-white .logo img + img {
  display: none;
}

.hamburger-menu-wrepper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 108;
  padding: 55px 80px;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: -webkit-transform 0.8s ease .4s;
  transition: -webkit-transform 0.8s ease .4s;
  -o-transition: transform 0.8s ease .4s;
  transition: transform 0.8s ease .4s;
  transition: transform 0.8s ease .4s, -webkit-transform 0.8s ease .4s;
  -webkit-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  background: rgba(5, 20, 65, 0.98);
}

.hamburger-menu-wrepper .nav {
  position: relative;
  z-index: 108;
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
  opacity: 0;
  visibility: hidden;
  -o-transition: all ease .3s .0s;
  -webkit-transition: all ease .3s .0s;
  transition: all ease .3s .0s;
}

.hamburger-menu-wrepper .nav .nav_logo img {
  opacity: 1 !important;
}

.hamburger-menu-wrepper .nav .close-menu {
  color: #fff;
}

.hamburger-menu-wrepper .animation-box {
  position: absolute;
  top: 5%;
  height: 100%;
  width: 100%;
  left: 0;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger-menu-wrepper .animation-box .menu-box {
  display: table;
  height: 100%;
  width: 62%;
  margin: 0 auto;
  opacity: 0;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger-menu-wrepper.show-menu {
  z-index: 108;
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  transition: -webkit-transform .7s ease .2s;
  -webkit-transition: -webkit-transform .7s ease .2s;
  -o-transition: transform .7s ease .2s;
  transition: transform .7s ease .2s;
  transition: transform .7s ease .2s, -webkit-transform .7s ease .2s;
}

.hamburger-menu-wrepper.show-menu .nav {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: opacity ease 1.4s 0.7s, -webkit-transform ease 1.4s 0.7s;
  transition: opacity ease 1.4s 0.7s, -webkit-transform ease 1.4s 0.7s;
  -o-transition: transform ease 1.4s 0.7s, opacity ease 1.4s 0.7s;
  transition: transform ease 1.4s 0.7s, opacity ease 1.4s 0.7s;
  transition: transform ease 1.4s 0.7s, opacity ease 1.4s 0.7s, -webkit-transform ease 1.4s 0.7s;
  opacity: 1;
  visibility: visible;
}

.hamburger-menu-wrepper.show-menu .animation-box {
  top: 0;
  opacity: 1;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: .7s;
  -o-transition-duration: .7s;
  transition-duration: .7s;
}

.hamburger-menu-wrepper.show-menu .animation-box .menu-box {
  opacity: 1;
}

.hamburger-menu-wrepper.show-menu .animation-box .menu-box .offcanfas_menu > li a {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: opacity ease 1.4s 0.6s, -webkit-transform ease 1.4s 0.6s;
  transition: opacity ease 1.4s 0.6s, -webkit-transform ease 1.4s 0.6s;
  -o-transition: transform ease 1.4s 0.6s, opacity ease 1.4s 0.6s;
  transition: transform ease 1.4s 0.6s, opacity ease 1.4s 0.6s;
  transition: transform ease 1.4s 0.6s, opacity ease 1.4s 0.6s, -webkit-transform ease 1.4s 0.6s;
  opacity: 1;
  visibility: visible;
}

.navbar .offcanfas_menu {
  margin: 0px;
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}

.navbar .offcanfas_menu > .nav-item {
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}

.navbar .offcanfas_menu > .nav-item:not(:last-child) {
  margin-bottom: 18px;
}

.navbar .offcanfas_menu > .nav-item .nav-link {
  font-size: 2.1rem;
  line-height: 46px;
  display: inline-block;
  color: #fff;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  -webkit-transform: translateY(45px);
  -ms-transform: translateY(45px);
  transform: translateY(45px);
  opacity: 0;
  visibility: hidden;
  -o-transition: all ease .3s .0s;
  -webkit-transition: all ease .3s .0s;
  transition: all ease .3s .0s;
  display: inline-block;
}

.navbar .offcanfas_menu > .nav-item .nav-link:before {
  background: #fff;
}

.navbar .offcanfas_menu > .nav-item.submenu .nav-link {
  padding-right: 20px;
}

.navbar .offcanfas_menu > .nav-item.submenu .nav-link:after {
  content: "\33";
  font-family: eleganticons;
  position: absolute;
  right: 13px;
  top: 0;
  display: block;
  border: 0;
  line-height: 50px;
  margin-left: 20px;
}

.navbar .offcanfas_menu > .nav-item.submenu .dropdown-menu {
  position: relative !important;
  -webkit-transform: translate3d(0px, 0px, 0px) !important;
  transform: translate3d(0px, 0px, 0px) !important;
  display: none;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0s linear;
  -o-transition: all 0s linear;
  transition: all 0s linear;
  z-index: 0;
  background: transparent;
  padding-left: 25px;
  padding-top: 20px;
  padding-bottom: 0;
  max-height: none !important;
  left: 0;
}

.navbar .offcanfas_menu > .nav-item.submenu .dropdown-menu > .nav-item {
  background: transparent;
  padding: 0px;
}

.navbar .offcanfas_menu > .nav-item.submenu .dropdown-menu > .nav-item > .nav-link {
  color: #fff;
  font-size: 16px;
  line-height: 31px;
}

.navbar .offcanfas_menu > .nav-item.submenu .dropdown-menu > .nav-item:hover .nav-link, .navbar .offcanfas_menu > .nav-item.submenu .dropdown-menu > .nav-item.active .nav-link {
  color: #5f28fb;
}

.navbar .offcanfas_menu > .nav-item.submenu.active .nav-link {
  color: #5f28fb;
}

.navbar .offcanfas_menu > .nav-item.submenu.active .nav-link:before {
  background: #5f28fb;
}

.navbar .offcanfas_menu > .nav-item.submenu.show .nav-link {
  color: #5f28fb;
}

.navbar .offcanfas_menu > .nav-item.submenu.show .nav-link:before {
  width: 100%;
  background: #5f28fb;
}

.navbar .offcanfas_menu > .nav-item.submenu.show .dropdown-menu {
  position: relative !important;
  -webkit-transform: translate3d(0px, 0px, 0px) !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.header_footer {
  display: table-footer-group;
  padding-bottom: 80px;
}

.header_footer h5 {
  font-size: 16px;
  color: #fff;
}

.header_footer ul {
  margin-bottom: 40px;
}

.header_footer ul li {
  display: inline-block;
  margin-right: 6px;
}

.header_footer ul li a {
  width: 44px;
  height: 44px;
  border: 1px solid #656c82;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  line-height: 44px;
  color: #fff;
  display: block;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.header_footer ul li a:hover {
  background: #fff;
  border-color: #fff;
  color: #5f28fb;
}

.header_footer p {
  font-size: 16px;
  color: #858faf;
  margin-bottom: 100px;
}

.close_icon {
  position: absolute;
  right: 60px;
  top: 40px;
  color: #7b84a2;
  font-size: 30px;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.close_icon:hover {
  color: #fff;
}

.burger_menu {
  width: 40px;
  height: 24px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  font-size: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #051441;
  margin-left: 25px;
}

.side_menu_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(5, 20, 65, 0.9);
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.8s ease .4s;
  transition: -webkit-transform 0.8s ease .4s;
  -o-transition: transform 0.8s ease .4s;
  transition: transform 0.8s ease .4s;
  transition: transform 0.8s ease .4s, -webkit-transform 0.8s ease .4s;
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.side_menu_wrapper .side_menu {
  max-width: 545px;
  height: 100%;
  margin-left: auto;
  background: #fff;
  position: relative;
  -webkit-transition-duration: .4s;
  -o-transition-duration: .4s;
  transition-duration: .4s;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  opacity: 0;
}

.side_menu_wrapper .side_menu ul {
  margin-bottom: 0;
}

.side_menu_wrapper .side_menu .menu_top {
  padding: 60px 70px 0px;
}

.side_menu_wrapper .side_menu .menu_top .close {
  font-size: 20px;
}

.side_menu_wrapper .side_menu .m_content {
  position: absolute;
  left: 0px;
  top: 10vh;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 55vh;
  -webkit-overflow-scrolling: touch;
  font-weight: 600;
  width: 100%;
  display: block;
  letter-spacing: -.025rem;
  list-style: none;
  margin: 0;
  -webkit-transform: translateY(3rem);
  -ms-transform: translateY(3rem);
  transform: translateY(3rem);
  -webkit-transition: opacity .5s .6s ease,
 -webkit-transform .5s .6s ease;
  transition: opacity .5s .6s ease,
 -webkit-transform .5s .6s ease;
  -o-transition: transform .5s .6s ease,
 opacity .5s .6s ease;
  transition: transform .5s .6s ease,
 opacity .5s .6s ease;
  transition: transform .5s .6s ease,
 opacity .5s .6s ease,
 -webkit-transform .5s .6s ease;
  list-style: none;
  padding-left: 18%;
  padding-right: 100px;
  margin-top: 5vh;
}

.side_menu_wrapper .side_menu .m_content h2 {
  font-size: 3.5vh;
  line-height: 4vh;
  font-weight: 700;
  color: #051441;
  margin-bottom: 1vh;
}

.side_menu_wrapper .side_menu .m_content a {
  font-size: 2.5vh;
  font-weight: 500;
}

.side_menu_wrapper .side_menu .m_content p {
  font-size: 1.9vh;
  line-height: 2.9vh;
  color: #7e86a1;
  font-weight: 300;
  margin-top: 4vh;
  margin-bottom: 8.2vh;
}

.side_menu_wrapper .side_menu .get_info li .media img {
  margin-right: 20px;
  height: 5vh;
}

.side_menu_wrapper .side_menu .get_info li .media .media-body h4 {
  font-size: 2vh;
  font-weight: 600;
  margin-bottom: 0.5vh;
}

.side_menu_wrapper .side_menu .get_info li .media .media-body p {
  margin-bottom: 0vh;
  margin-top: 0.5vh;
  font-size: 1.8vh;
}

.side_menu_wrapper .side_menu .get_info li + li {
  margin-top: 2.9vh;
}

.side_menu_wrapper .side_menu .m_footer {
  position: absolute;
  left: 18%;
  bottom: 10%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.side_menu_wrapper .side_menu .m_footer li a {
  font-size: 20px;
  color: #959ebb;
  font-size: 16px;
  width: 50px;
  height: 50px;
  text-align: center;
  border: 1px solid #959ebb;
  display: inline-block;
  margin: 0px 5px;
  line-height: 50px;
  border-radius: 50%;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.side_menu_wrapper .side_menu .m_footer li a:hover {
  background: #5f28fb;
  border-color: #5f28fb;
  color: #fff;
  -webkit-box-shadow: 0px 20px 20px 0px rgba(95, 40, 251, 0.3);
  box-shadow: 0px 20px 20px 0px rgba(95, 40, 251, 0.3);
}

.menu-is-opened .side_menu_wrapper {
  z-index: 100;
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  transition: -webkit-transform .7s ease .2s;
  -webkit-transition: -webkit-transform .7s ease .2s;
  -o-transition: transform .7s ease .2s;
  transition: transform .7s ease .2s;
  transition: transform .7s ease .2s, -webkit-transform .7s ease .2s;
}

.menu-is-opened .side_menu_wrapper .side_menu {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 0.5s 0.6s linear;
  transition: -webkit-transform 0.5s 0.6s linear;
  -o-transition: transform 0.5s 0.6s linear;
  transition: transform 0.5s 0.6s linear;
  transition: transform 0.5s 0.6s linear, -webkit-transform 0.5s 0.6s linear;
}

/*================= menu_scroll css ================*/
.menu_scroll .container-fluid {
  padding-left: 100px;
  padding-right: 100px;
}

/*====================================================*/
/*====================================================*/
/*================= saas_home_area css ===============*/
.saas_home_area {
  position: relative;
  background: #fafafe;
  height: 1100px;
}

.banner_top {
  padding-top: 210px;
}

.banner_top h2 {
  color: #051441;
  margin-bottom: 25px;
}

.banner_top p {
  margin-bottom: 42px;
}

.banner_top .subcribes {
  max-width: 630px;
  margin: 0 auto;
}

.banner_top .subcribes .form-control {
  border: 1px solid #e9ebf4;
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 30px 30px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 30px 30px 0px rgba(0, 11, 40, 0.1);
  font: 400 15px/46px "Poppins", sans-serif;
  padding-left: 30px;
  color: #051441;
  z-index: 0;
}

.banner_top .subcribes .form-control.placeholder {
  color: #b4b9c8;
}

.banner_top .subcribes .form-control:-moz-placeholder {
  color: #b4b9c8;
}

.banner_top .subcribes .form-control::-moz-placeholder {
  color: #b4b9c8;
}

.banner_top .subcribes .form-control::-webkit-input-placeholder {
  color: #b4b9c8;
}

.banner_top .subcribes .btn_submit {
  position: absolute;
  right: 8px;
  color: #fff;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 11px 32px;
}

.saas_home_img {
  text-align: center;
  margin-top: 120px;
  position: relative;
}

.saas_home_img img {
  -webkit-box-shadow: 0px 40px 60px 0px rgba(0, 11, 40, 0.16);
  box-shadow: 0px 40px 60px 0px rgba(0, 11, 40, 0.16);
  border-radius: 8px;
}

/*============== slider_area css ==============*/
.slider_area {
  background-image: -moz-linear-gradient(40deg, #1786d8 0%, #00aff0 100%);
  background-image: -webkit-linear-gradient(40deg, #1786d8 0%, #00aff0 100%);
  background-image: -ms-linear-gradient(40deg, #1786d8 0%, #00aff0 100%);
  height: 930px;
  background-size: cover;
  background-position: 50%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.slider_area .bottom_shoape {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: -1;
  width: 100%;
}

.slider_area .middle_shape {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.leaf {
  position: absolute;
}

.leaf.l_left {
  top: 200px;
  left: 260px;
}

.leaf.l_right {
  right: 0;
  bottom: 100px;
}

.mobile_img {
  position: relative;
}

.mobile_img .img {
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: animateUpDown;
  animation-name: animateUpDown;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.mobile_img .mobile {
  z-index: -1;
  position: relative;
}

.mobile_img .women_img {
  top: 245px;
  right: 160px;
  z-index: 1;
}

.slider_content {
  padding-top: 200px;
}

.slider_content h2, .slider_content p {
  color: #fff;
}

@-webkit-keyframes animateUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes animateUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

/*=============== prototype_banner_area css ==============*/
.prototype_banner_area {
  background: #fbfbfd;
  padding-top: 220px;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.prototype_content .banner_subscribe .subcribes {
  display: block;
}

.prototype_content .banner_subscribe .subcribes .form-control {
  max-width: 370px;
  width: 100%;
  border-radius: 3px;
  background-color: white;
  border: 0px;
  height: 50px;
  color: #222d39;
  padding-left: 30px;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 2px 3px 0px rgba(12, 0, 46, 0.06);
  font: 300 14px/28px "Poppins", sans-serif;
  border: 1px solid transparent;
}

.prototype_content .banner_subscribe .subcribes .form-control.placeholder {
  color: #aeb4ba;
}

.prototype_content .banner_subscribe .subcribes .form-control:-moz-placeholder {
  color: #aeb4ba;
}

.prototype_content .banner_subscribe .subcribes .form-control::-moz-placeholder {
  color: #aeb4ba;
}

.prototype_content .banner_subscribe .subcribes .form-control::-webkit-input-placeholder {
  color: #aeb4ba;
}

.prototype_content .banner_subscribe .subcribes .form-control:focus {
  border-color: #6754e2;
}

.partner_logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 100px;
  border-bottom: 1px solid #eeeef5;
  padding-bottom: 35px;
  position: relative;
  z-index: 4;
}

.partner_logo .p_logo_item {
  width: calc(100% / 5);
  text-align: center;
}

.partner_logo .p_logo_item img {
  max-width: 100%;
  -webkit-filter: contrast(0.3%);
  filter: contrast(0.3%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.partner_logo .p_logo_item:hover img {
  -webkit-filter: contrast(100%);
  filter: contrast(100%);
}

/*============== agency_banner_area css =============*/
.agency_banner_area {
  padding-top: 150px;
  position: relative;
  z-index: 1;
}

.agency_banner_area .banner_shap {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.agency_content h2 {
  font-size: 50px;
  line-height: 66px;
}

/*================ software_banner_area css ================*/
.software_banner_area {
  min-height: 100vh;
  background-image: linear-gradient(140deg, #23026d 0%, #3d64f4 100%);
  position: relative;
  z-index: 0;
}

.software_banner_area:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  background: url("../img/home5/shap_tecture.png") no-repeat scroll center 0/cover;
}

/*=============== saas banner area ================*/
.saas_banner_area {
  background-image: linear-gradient(140deg, #030207 0%, #111339 100%);
  padding-top: 230px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.saas_shap {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.dasboard_img img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

/*============= app_banner_area css ===========*/
.app_banner_area {
  background: url(../img/home7/banner.png) no-repeat scroll center 100%;
  padding-top: 230px;
  padding-bottom: 210px;
  overflow: hidden;
  background-size: cover;
}

.app_banner_area .app_img .app_screen {
  position: absolute;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: animateUpDown;
  animation-name: animateUpDown;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.app_banner_area .app_img .app_screen.one {
  top: 135px;
  left: 110px;
  -webkit-animation-duration: 1.9s;
  animation-duration: 1.9s;
}

.app_banner_area .app_img .app_screen.two {
  top: 46px;
  left: 220px;
  -webkit-animation-duration: 1.8s;
  animation-duration: 1.8s;
}

.app_banner_area .app_img .app_screen.three {
  top: 6px;
  left: 375px;
  -webkit-animation-duration: 1.6s;
  animation-duration: 1.6s;
}

.app_banner_contentmt h2 {
  line-height: 64px;
}

.app_banner_contentmt .app_btn {
  border: 0px;
  background: #fff;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #4069eb;
  padding: 14px 40px;
}

.app_banner_contentmt .app_btn:hover {
  border-color: #4069eb;
  background: #4069eb;
  color: #fff;
}

/*================ agency_banner_area_two css ===============*/
.agency_banner_area_two {
  background-image: -moz-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  padding: 230px 0px 140px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.dot_shap {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 50%;
  z-index: -1;
}

.dot_shap.one {
  width: 740px;
  height: 740px;
  top: -280px;
}

.dot_shap.two {
  width: 640px;
  height: 640px;
  top: -150px;
  right: -80px;
}

.dot_shap.three {
  width: 640px;
  height: 640px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -450px;
}

.agency_banner_img {
  margin-left: -100px;
}

.agency_content_two .agency_banner_btn {
  color: #5e2ced;
  border-color: #fff;
  background: #fff;
}

/*=============== saas_banner_area_two css ===============*/
.saas_banner_area_two {
  position: relative;
  z-index: 1;
}

.saas_banner_area_two .shap_img {
  position: absolute;
  width: 101%;
  height: 540px;
  bottom: 120px;
  z-index: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
  -webkit-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  transform: rotate(2deg);
  right: 0;
}

.saas_banner_area_two .section_intro {
  background-image: -moz-linear-gradient(-50deg, #237efd 0%, #24b0fe 46%, #24e2ff 100%);
  background-image: -webkit-linear-gradient(-50deg, #237efd 0%, #24b0fe 46%, #24e2ff 100%);
  background-image: -ms-linear-gradient(-50deg, #237efd 0%, #24b0fe 46%, #24e2ff 100%);
}

.section_container {
  max-width: 830px;
  margin: 0 auto;
  text-align: center;
  height: 100vh;
}

.section_container .intro {
  height: 53%;
  width: 100%;
  position: relative;
}

.section_container .intro_content {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 0px 15px;
}

.section_container .intro_content h1 {
  font-size: 50px;
  letter-spacing: -0.20px;
  line-height: 70px;
  margin-bottom: 16px;
}

.section_container .intro_content p {
  line-height: 30px;
  margin-bottom: 60px;
}

.section_container .intro_content .subcribes {
  max-width: 570px;
  margin: 0 auto;
}

.section_container .intro_content .subcribes .form-control {
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 8px 16px 0px rgba(0, 11, 40, 0.1);
  font: 300 16px/36px "Poppins", sans-serif;
  border: 0px;
  color: #a1a8be;
  height: 60px;
  padding-left: 30px;
  position: relative;
  z-index: 1;
}

.section_container .intro_content .subcribes .form-control.placeholder {
  color: #a1a8be;
}

.section_container .intro_content .subcribes .form-control:-moz-placeholder {
  color: #a1a8be;
}

.section_container .intro_content .subcribes .form-control::-moz-placeholder {
  color: #a1a8be;
}

.section_container .intro_content .subcribes .form-control::-webkit-input-placeholder {
  color: #a1a8be;
}

.section_container .intro_content .subcribes .btn_submit {
  position: absolute;
  top: 0;
  right: 0;
  font: 400 14px/60px "Poppins", sans-serif;
  color: #fff;
  background: #1c7ce7;
  border-radius: 4px;
  padding: 0px 25px;
  border: 0px;
  z-index: 2;
}

.animation_img {
  text-align: center;
  margin-top: -320px;
  z-index: 2;
  position: relative;
}

/*=============== saas_banner_area_two css ===============*/
.payment_banner_area {
  position: relative;
  min-height: 100vh;
  background-image: -moz-linear-gradient(-86deg, #7121ff 0%, #21d4fd 100%);
  background-image: -webkit-linear-gradient(-86deg, #7121ff 0%, #21d4fd 100%);
  background-image: -ms-linear-gradient(-86deg, #7121ff 0%, #21d4fd 100%);
  background-image: linear-gradient(-86deg, #7121ff 0%, #21d4fd 100%);
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.payment_banner_area .shape {
  background-image: -moz-linear-gradient(-57deg, #7121ff 0%, #21d4fd 100%);
  background-image: -webkit-linear-gradient(-57deg, #7121ff 0%, #21d4fd 100%);
  background-image: -ms-linear-gradient(-57deg, #7121ff 0%, #21d4fd 100%);
  background-image: linear-gradient(-57deg, #7121ff 0%, #21d4fd 100%);
  position: absolute;
  left: -256px;
  top: -178px;
  width: 1095px;
  height: 602px;
  border-radius: 90px;
  -webkit-transform: rotate(-38deg);
  -ms-transform: rotate(-38deg);
  transform: rotate(-38deg);
  z-index: -1;
  opacity: 0.20;
}

.payment_banner_area .shape.two {
  background-image: -moz-linear-gradient(-75deg, #7121ff 0%, #21d4fd 100%);
  background-image: -webkit-linear-gradient(-75deg, #7121ff 0%, #21d4fd 100%);
  background-image: -ms-linear-gradient(-75deg, #7121ff 0%, #21d4fd 100%);
  background-image: linear-gradient(-75deg, #7121ff 0%, #21d4fd 100%);
  position: absolute;
  left: 689px;
  top: 458px;
  border-radius: 150px;
  width: 816px;
  height: 702px;
}

.animation_img_two {
  width: 50%;
  height: 60%;
  float: right;
  text-align: right;
  position: absolute;
  bottom: 188px;
  right: 0;
  z-index: 0;
}

.animation_img_two img {
  max-width: 100%;
}

.svg_intro_bottom {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.payment_banner_content {
  max-width: 670px;
}

.payment_banner_content h1 {
  line-height: 70px;
  margin-bottom: 25px;
}

.payment_banner_content p {
  line-height: 30px;
}

.payment_banner_content .agency_banner_btn {
  background: #fff;
  color: #5956fe;
  border-color: #fff;
}

.payment_banner_content .agency_banner_btn_two {
  color: #ffffff;
  margin-left: 30px;
}

.payment_banner_content .agency_banner_btn_two:before {
  display: none;
}

.payment_banner_content .agency_banner_btn_two i {
  vertical-align: middle;
  padding-left: 10px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.payment_banner_content .agency_banner_btn_two:hover i {
  padding-left: 15px;
}

/*=============== payment_banner_area_two css ==============*/
.payment_banner_area_two {
  background-image: -moz-linear-gradient(125deg, #5f51fb 0%, #767cfd 64%, #8ca7ff 100%);
  background-image: -webkit-linear-gradient(125deg, #5f51fb 0%, #767cfd 64%, #8ca7ff 100%);
  background-image: -ms-linear-gradient(125deg, #5f51fb 0%, #767cfd 64%, #8ca7ff 100%);
  height: 100vh;
  min-height: 860px;
  padding-top: 200px;
  position: relative;
  z-index: 1;
}

.payment_banner_area_two .symbols-pulse > div {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.04)), color-stop(65%, rgba(255, 255, 255, 0.04)), to(rgba(255, 255, 255, 0.04)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 65%, rgba(255, 255, 255, 0.04) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 65%, rgba(255, 255, 255, 0.04) 100%);
  background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 65%, rgba(255, 255, 255, 0.04) 100%);
  width: 28rem;
  height: 28rem;
}

.payment_content_two {
  max-width: 800px;
  margin: 0 auto;
}

.payment_content_two h2 {
  font-size: 50px;
  font-family: "Poppins", sans-serif;
  line-height: 66px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 33px;
}

.payment_content_two h2 span {
  font-weight: 700;
}

.payment_content_two .slider_btn {
  color: #5f51fb;
  font-size: 15px;
  padding: 15px 41px;
  -webkit-box-shadow: 0px 30px 30px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 30px 30px 0px rgba(0, 11, 40, 0.1);
}

.payment_content_two .slider_btn i {
  padding-right: 10px;
}

.payment_content_two .slider_btn:hover {
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.payment_content_two .video_btn {
  position: relative;
  z-index: 1;
}

.payment_content_two .video_btn:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
}

.payment_content_two .payment_img {
  margin-top: 60px;
  position: relative;
  z-index: 1;
}

.payment_content_two .payment_img .payment_icon {
  position: absolute;
  z-index: 0;
}

.payment_content_two .payment_img .payment_icon.icon_one {
  top: -82px;
  left: -34px;
}

.payment_content_two .payment_img .payment_icon.icon_two {
  left: 20px;
  top: 33%;
}

.payment_content_two .payment_img .payment_icon.icon_three {
  top: 38%;
  left: -20px;
  z-index: 1;
}

.payment_content_two .payment_img .payment_icon.icon_four {
  right: 0;
  top: -89px;
}

.payment_content_two .payment_img .payment_icon.icon_five {
  right: -40px;
  top: 80px;
}

.payment_content_two .payment_img .payment_icon.icon_six {
  right: -20px;
  top: 160px;
}

.company_banner_area {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: -moz-linear-gradient(125deg, #5f51fb 0%, #ae59cb 64%, #fd609b 100%);
  background-image: -webkit-linear-gradient(125deg, #5f51fb 0%, #ae59cb 64%, #fd609b 100%);
  background-image: -ms-linear-gradient(125deg, #5f51fb 0%, #ae59cb 64%, #fd609b 100%);
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #fff;
}

.company_banner_area .parallax-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 105%;
  z-index: -1;
  opacity: 0.1;
}

.company_banner_area .company_banner_content h6 {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 28px;
  color: #fff;
}

.company_banner_area .company_banner_content h2 {
  font-size: 50px;
  line-height: 66px;
  font-weight: 700;
  color: #fff;
}

.company_banner_area .company_banner_content .about_btn {
  color: #5f51fb;
  font-size: 15px;
  padding: 14px 35px;
  margin-top: 28px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.company_banner_area .company_banner_content .about_btn:hover {
  background: #fff;
  color: #5f51fb;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.new_startup_banner_area {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #563bd1;
  position: relative;
  z-index: 0;
}

.new_startup_banner_area:before {
  content: "";
  background: url("../img/new/startup_banner_bg.png") no-repeat scroll center bottom;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  top: 0;
}

.new_startup_banner_area .new_startup_img {
  padding-right: 70px;
  position: relative;
  z-index: 1;
}

.new_startup_banner_area .new_startup_img .line {
  position: absolute;
  z-index: -1;
  height: 100%;
}

.new_startup_banner_area .new_startup_img .line.line_one {
  left: -40px;
  top: -95px;
}

.new_startup_banner_area .new_startup_img .line.line_two {
  left: 80px;
  bottom: -138px;
  opacity: 0.4;
}

.new_startup_banner_area .new_startup_img .line.line_three {
  right: 93px;
  top: -160px;
  opacity: 0.4;
}

.new_startup_content h2 {
  font: 300 50px "Poppins", sans-serif;
  line-height: 58px;
}

.new_startup_content h2 span {
  font-weight: 700;
}

.new_startup_content .app_btn {
  background: #fff;
  border-color: #fff;
  color: #563bd1;
  -webkit-box-shadow: 0px 20px 20px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 20px 0px rgba(0, 11, 40, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
}

.new_startup_content .app_btn i {
  padding-left: 8px;
}

.startup_banner_area_three {
  min-height: 100vh;
  background: #17c7bd;
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.startup_banner_area_three:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: url("../img/new/startup_shap.png") no-repeat scroll center bottom/cover;
  z-index: -1;
}

.startup_content_three h2 {
  font-size: 50px;
  color: #051441;
  font-weight: 300;
  line-height: 60px;
  margin-bottom: 30px;
}

.startup_content_three h2 span {
  font-weight: 700;
}

.startup_content_three p {
  font-size: 18px;
  color: #3c4043;
  line-height: 30px;
  font-style: italic;
  margin-bottom: 50px;
}

.stratup_app_screen {
  position: absolute;
  width: 48%;
  height: 100%;
  right: 0;
  z-index: -1;
}

.stratup_app_screen .phone, .stratup_app_screen .laptop {
  position: absolute;
  right: 0;
}

.stratup_app_screen .phone {
  bottom: -220px;
  left: 0;
}

.stratup_app_screen .laptop {
  right: -200px;
  bottom: -200px;
}

/*================= digital_banner_area css ============*/
.digital_banner_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  background-image: -moz-linear-gradient(40deg, #5f51fb 0%, #767cfd 64%, #8ca7ff 100%);
  background-image: -webkit-linear-gradient(40deg, #5f51fb 0%, #767cfd 64%, #8ca7ff 100%);
  background-image: -ms-linear-gradient(40deg, #5f51fb 0%, #767cfd 64%, #8ca7ff 100%);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.digital_banner_area .digital_banner_shap {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  background: url("../img/new/digital_img.png") no-repeat scroll right 0;
  top: 0;
  z-index: -1;
}

.digital_banner_area .round_shap {
  position: absolute;
  border-radius: 50%;
  background-image: -moz-linear-gradient(50deg, #40dcfd 0%, #5286fd 36%, #632ffd 100%);
  background-image: -webkit-linear-gradient(50deg, #40dcfd 0%, #5286fd 36%, #632ffd 100%);
  background-image: -ms-linear-gradient(50deg, #40dcfd 0%, #5286fd 36%, #632ffd 100%);
  border-radius: 50%;
  z-index: -1;
}

.digital_banner_area .round_shap.one {
  width: 200px;
  height: 200px;
  left: -100px;
}

.digital_banner_area .round_shap.two {
  width: 520px;
  height: 520px;
  left: 38%;
  bottom: -100px;
}

.digital_banner_area .round_shap.three {
  width: 100px;
  height: 100px;
  left: 34%;
  top: 200px;
}

.digital_banner_area #demo {
  position: absolute;
  z-index: -1;
}

.digital_content {
  padding-right: 85px;
}

.digital_content h6 {
  font-size: 15px;
  font-weight: 300;
  line-height: 28px;
  border-bottom: 1px solid #fff;
  display: inline-block;
  color: #fff;
}

.digital_content h2 {
  font-size: 50px;
  line-height: 66px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #fff;
}

.digital_content p {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 44px;
  color: #fff;
}

.digital_content .btn_six {
  -webkit-box-shadow: 0px 30px 30px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 30px 30px 0px rgba(0, 11, 40, 0.1);
  background: #26c4e5;
  border-color: #26c4e5;
  color: #fff;
}

.digital_content .btn_six:hover {
  background: #26c4e5;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.digital_video_slider {
  width: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.digital_video_slider .owl-stage {
  padding-left: 0px !important;
  padding-bottom: 30px;
}

.digital_video_slider .video_item {
  border-radius: 6px;
  overflow: hidden;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(0, 11, 40, 0.12);
  box-shadow: 0px 20px 30px 0px rgba(0, 11, 40, 0.12);
}

.digital_video_slider .video_item .video_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.digital_video_slider .video_item .video_icon:before {
  -webkit-transform: scale(1.32);
  -ms-transform: scale(1.32);
  transform: scale(1.32);
}

.digital_video_slider .video_item .video_icon:after {
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(255, 255, 255, 0.3);
  box-shadow: 0px 1px 15px 1px rgba(255, 255, 255, 0.3);
  content: "";
  -webkit-animation: pulse 2s infinite;
  -webkit-transform: scale(8);
  -ms-transform: scale(8);
  transform: scale(8);
  animation: pulse 2s infinite;
}

.digital_video_slider .video_item .video_icon i:after {
  display: none;
}

/*================= digital_banner_area css ============*/
.saas_banner_area_three .owl-stage {
  padding-bottom: 138px;
}

.saas_banner_area_three .owl-dots {
  position: absolute;
  right: 50px;
  top: 43%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: 0;
}

.saas_banner_area_three .owl-dots button {
  display: block;
  margin: 15px 0px;
}

.saas_banner_area_three .owl-dots button span {
  background: #9886f7;
  border-color: #9886f7;
}

.saas_banner_area_three .owl-dots button.active span {
  background: #fff;
  border-color: #fff;
}

.saas_banner_area_three .slider_item {
  min-height: 900px;
  background-image: -moz-linear-gradient(125deg, #5f51fb 0%, #ae59cb 64%, #fd609b 100%);
  background-image: -webkit-linear-gradient(125deg, #5f51fb 0%, #ae59cb 64%, #fd609b 100%);
  background-image: -ms-linear-gradient(125deg, #5f51fb 0%, #ae59cb 64%, #fd609b 100%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.saas_banner_area_three .slider_item.slider_item_two {
  background-image: -moz-linear-gradient(-120deg, #8647f9 0%, #4e91c1 64%, #15da88 100%);
  background-image: -webkit-linear-gradient(-120deg, #8647f9 0%, #4e91c1 64%, #15da88 100%);
  background-image: -ms-linear-gradient(-120deg, #8647f9 0%, #4e91c1 64%, #15da88 100%);
}

.saas_banner_area_three .slider_item .slidet_content {
  text-align: center;
  color: #fff;
  padding-top: 210px;
}

.saas_banner_area_three .slider_item .slidet_content h2 {
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #fff;
}

.saas_banner_area_three .slider_item .slidet_content h2 span {
  font-weight: 300;
}

.saas_banner_area_three .slider_item .slidet_content p {
  margin-bottom: 38px;
  font-size: 20px;
  color: #fff;
}

.saas_banner_area_three .slider_item .slidet_content .slider_btn {
  padding: 14px 51px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.saas_banner_area_three .slider_item .slidet_content .slider_btn:hover {
  background: #fff;
  color: #5f51fb;
}

.saas_banner_area_three .slider_item .slidet_content .slider_btn + .slider_btn {
  background: #fff;
  color: #5f51fb;
  margin-left: 15px;
}

.saas_banner_area_three .slider_item .slidet_content .slider_btn + .slider_btn:hover {
  background: transparent;
  color: #fff;
}

.saas_banner_area_three .slider_item .slidet_content_two {
  color: #fff;
  padding-top: 190px;
}

.saas_banner_area_three .slider_item .slidet_content_two h2 {
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #fff;
}

.saas_banner_area_three .slider_item .slidet_content_two .slider_btn {
  border: 1px solid #fff;
  color: #5f51fb;
}

.saas_banner_area_three .slider_item .slidet_content_two .slider_btn:hover {
  background: transparent;
  color: #fff;
}

.saas_banner_area_three .slider_item .image_mockup {
  position: absolute;
  bottom: -140px;
  width: 1030px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
}

.saas_banner_area_three .slider_item .image_mockup img {
  max-width: 100%;
  width: auto;
  display: inline-block;
}

.saas_banner_area_three .slider_item .image_mockup .laptop {
  opacity: 0;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: all 0.7s linear;
  -o-transition: all 0.7s linear;
  transition: all 0.7s linear;
}

.saas_banner_area_three .slider_item .image_mockup .phone {
  right: 0;
  position: absolute;
  bottom: 46px;
}

.saas_banner_area_three .slider_item .image_mockup .watch {
  left: 0;
  position: absolute;
  bottom: 0px;
}

.saas_banner_area_three .active .image_mockup .laptop {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.seo_home_area {
  background: #f7fdfc;
  position: relative;
  margin-bottom: 135px;
}

.seo_home_area .saas_home_img {
  margin-top: 30px;
}

.seo_home_area .saas_home_img img {
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: -140px;
}

.seo_banner_content h2 {
  font-size: 60px;
  line-height: 80px;
  color: #263b5e;
  font-weight: 600;
}

.seo_banner_content p {
  font-size: 18px;
  line-height: 30px;
  color: #6a7695;
  margin-bottom: 53px;
}

.seo_banner_content .seo_btn {
  margin: 0px 8px;
}

.home_bubble .bubble {
  position: absolute;
  border-radius: 50%;
}

.home_bubble .bubble.b_one, .home_bubble .bubble.b_four {
  width: 10px;
  height: 10px;
}

.home_bubble .bubble.b_two, .home_bubble .bubble.b_five {
  width: 30px;
  height: 30px;
}

.home_bubble .bubble.b_three, .home_bubble .bubble.b_six {
  width: 14px;
  height: 14px;
}

.home_bubble .bubble.b_one {
  background: #00c99c;
  top: 40%;
  left: 85px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: spin2 2s infinite alternate;
  animation: spin2 2s infinite alternate;
}

.home_bubble .bubble.b_two {
  background: #fca249;
  top: 50%;
  left: 155px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: spin1 2s infinite alternate;
  animation: spin1 2s infinite alternate;
}

.home_bubble .bubble.b_three {
  background: #7d95fa;
  top: 85%;
  left: 255px;
  -webkit-animation: spin1 1s infinite alternate;
  animation: spin1 1s infinite alternate;
}

.home_bubble .bubble.b_four {
  background: #d27dfa;
  top: 40%;
  right: 85px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: spin1 2s infinite alternate;
  animation: spin1 2s infinite alternate;
}

.home_bubble .bubble.b_five {
  background: #ff9398;
  top: 50%;
  right: 155px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: spin1 3s infinite alternate;
  animation: spin1 3s infinite alternate;
}

.home_bubble .bubble.b_six {
  background: #f1d53b;
  top: 85%;
  right: 55px;
  -webkit-animation: spin2 2s infinite alternate;
  animation: spin2 2s infinite alternate;
}

.home_bubble .triangle {
  position: absolute;
}

.home_bubble .triangle.b_seven {
  left: 25%;
  top: 400px;
}

.home_bubble .triangle.b_eight {
  left: -25px;
  bottom: -45px;
}

.home_bubble .triangle.b_nine {
  right: 28%;
  bottom: 250px;
  -webkit-animation: spin2 2s infinite alternate;
  animation: spin2 2s infinite alternate;
}

/*============ keyframes animation  =============*/
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

/*====================================================*/
/*====================================================*/
/*================= Start breadcrumb_area css =================*/
.breadcrumb_area {
  background-image: -moz-linear-gradient(180deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(180deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(180deg, #5e2ced 0%, #a485fd 100%);
  background-image:  linear-gradient(180deg, #5e2ced 0%, #a485fd 100%);
  position: relative;
  z-index: 1;
  padding: 235px 0px 125px;
  overflow: hidden;
}

.breadcrumb_area .breadcrumb_shap {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.breadcrumb_area p{
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
}

/*================= End breadcrumb_area css =================*/
/*================= Start breadcrumb_area_two css =================*/
.breadcrumb_area_two {
  background: #f8f6fe;
  position: relative;
  padding: 235px 0px 125px;
  z-index: 1;
}

.breadcrumb_area_two .breadcrumb_shap {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  height: 100%;
}

.breadcrumb_area_two .bubble li {
  background: #5e2ced;
  opacity: 0.1;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
}

.breadcrumb_area_two .bubble li:nth-child(1) {
  left: 220px;
  bottom: 50px;
  -webkit-animation: spin3 2s infinite alternate;
  animation: spin3 2s infinite alternate;
}

.breadcrumb_area_two .bubble li:nth-child(2) {
  left: 35%;
  top: 100px;
  -webkit-animation: spin 2s infinite alternate;
  animation: spin 2s infinite alternate;
}

.breadcrumb_area_two .bubble li:nth-child(3) {
  left: 40%;
  width: 51px;
  height: 51px;
  bottom: 50px;
  -webkit-animation: spin1 3s infinite alternate;
  animation: spin1 3s infinite alternate;
}

.breadcrumb_area_two .bubble li:nth-child(4) {
  left: 42%;
  bottom: 50px;
  width: 20px;
  height: 20px;
}

.breadcrumb_area_two .bubble li:nth-child(5) {
  left: 76%;
  width: 51px;
  height: 51px;
  top: 160px;
  -webkit-animation: spin1 3s infinite alternate;
  animation: spin1 3s infinite alternate;
}

.breadcrumb_area_two .bubble li:nth-child(6) {
  left: 75%;
  width: 30px;
  height: 30px;
  top: 186px;
}

.breadcrumb_area_two .bubble li:nth-child(7) {
  left: 52%;
  top: 150px;
  width: 20px;
  height: 20px;
  -webkit-animation: spin2 2s infinite alternate;
  animation: spin2 2s infinite alternate;
}

.breadcrumb_area_two .bubble li:nth-child(8) {
  left: 90%;
  top: 250px;
  width: 20px;
  height: 20px;
  -webkit-animation: spin2 2s infinite alternate;
  animation: spin2 2s infinite alternate;
}

.breadcrumb_content_two h1 {
  color: #222d39;
  margin-bottom: 12px;
}

.breadcrumb_content_two .breadcrumb {
  background: transparent;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.breadcrumb_content_two .breadcrumb li {
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  position: relative;
  color: #525c7c;
}

.breadcrumb_content_two .breadcrumb li a {
  color: #051441;
}

.breadcrumb_content_two .breadcrumb li + li {
  padding-left: 32px;
}

.breadcrumb_content_two .breadcrumb li + li:after {
  content: "\e649";
  font-family: 'themify';
  position: absolute;
  left: 8px;
  top: 0;
  font-size: 14px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: translateX(-10px) scale(0.9);
    transform: translateX(-10px) scale(0.9);
  }
  100% {
    -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
    transform: translateX(30px) scale(1.3) translateY(10px);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: translateX(-10px) scale(0.9);
    transform: translateX(-10px) scale(0.9);
  }
  100% {
    -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
    transform: translateX(30px) scale(1.3) translateY(10px);
  }
}

@-webkit-keyframes spin1 {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes spin1 {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@-webkit-keyframes spin2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

@keyframes spin2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

@-webkit-keyframes spin3 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  25% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}

@keyframes spin3 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  25% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}

/*================= End breadcrumb_area_two css =================*/
/*========= blog_breadcrumb_area css ========*/
.blog_breadcrumb_area {
  position: relative;
  height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
}

.blog_breadcrumb_area .background_overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: -moz-linear-gradient(-140deg, #5e2ced 0%, #8037da 100%);
  background-image: -webkit-linear-gradient(-140deg, #5e2ced 0%, #8037da 100%);
  background-image: -ms-linear-gradient(-140deg, #5e2ced 0%, #8037da 100%);
  opacity: 0.90;
  z-index: -1;
}

.blog_breadcrumb_area .breadcrumb_content_two {
  color: #fff;
  padding-top: 80px;
}

.blog_breadcrumb_area .breadcrumb_content_two h5 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 18px;
  color: #fff;
}

.blog_breadcrumb_area .breadcrumb_content_two h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.25em;
  color: #fff;
  margin-bottom: 25px;
}

.blog_breadcrumb_area .breadcrumb_content_two ol {
  margin-bottom: 0;
}

.blog_breadcrumb_area .breadcrumb_content_two ol li {
  display: inline-block;
  font-size: 16px;
}

.blog_breadcrumb_area .breadcrumb_content_two ol li + li:before {
  content: "\e649";
  display: inline-block;
  font-family: 'themify';
  font-size: 10px;
  margin: 0px 5px 0px 3px;
}

/*========= blog_breadcrumb_area css ========*/
/*====================================================*/
/*====================================================*/
/*================= service_promo_area css =================*/
.service_promo_area {
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.service_promo_area .shape {
  position: absolute;
  width: 100%;
  height: 1320px;
  z-index: -1;
}

.service_promo_area .shape.shape_one {
  opacity: 0.01;
  left: -25%;
  top: 350px;
}

.service_promo_area .shape.shape_two {
  opacity: 0.02;
  left: -15%;
  top: 400px;
}

.service_promo_area .shape.shape_three {
  right: -52%;
  bottom: -9%;
  opacity: 0.01;
}

.service_promo_area .shape.shape_four {
  right: -44%;
  bottom: -12%;
  opacity: 0.02;
}

.s_service_section {
  margin-top: 360px;
}

.s_service_item {
  background-image: -moz-linear-gradient(40deg, #6754e2 0%, #19cce6 100%);
  background-image: -webkit-linear-gradient(40deg, #6754e2 0%, #19cce6 100%);
  background-image: -ms-linear-gradient(40deg, #6754e2 0%, #19cce6 100%);
  -webkit-box-shadow: 0px 20px 60px 0px rgba(0, 11, 40, 0.06);
  box-shadow: 0px 20px 60px 0px rgba(0, 11, 40, 0.06);
  padding: 50px 40px 40px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.s_service_item .icon {
  width: 82px;
  height: 82px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  line-height: 82px;
  font-size: 30px;
  text-align: center;
  margin-bottom: 34px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.s_service_item .icon.icon_1 {
  background-color: rgba(10, 188, 123, 0.059);
  border-color: rgba(10, 188, 123, 0.3);
  color: #0abc7b;
}

.s_service_item .icon.icon_2 {
  border-color: rgba(231, 178, 18, 0.3);
  background-color: rgba(214, 167, 25, 0.059);
  color: #d6a719;
}

.s_service_item .icon.icon_3 {
  color: #6754e2;
  background-color: rgba(103, 84, 226, 0.059);
  border-color: rgba(103, 84, 226, 0.3);
}

.s_service_item .icon.icon_4 {
  color: #f91c60;
  background-color: rgba(248, 27, 95, 0.059);
  border-color: rgba(248, 27, 95, 0.3);
}

.s_service_item .solid_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: -1;
  opacity: 1;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.s_service_item h5 {
  margin-bottom: 18px;
  -webkit-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.s_service_item:hover .solid_overlay {
  opacity: 0;
}

.s_service_item:hover .learn_btn_two, .s_service_item:hover h5, .s_service_item:hover p {
  color: #fff;
}

.s_service_item:hover .icon {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.059);
  color: #fff;
}

.learn_btn_two {
  color: #051441;
  font-size: 15px;
  display: inline-block;
  margin-top: 7px;
  position: relative;
  -webkit-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.learn_btn_two:before {
  content: "";
  height: 1px;
  width: 0;
  position: absolute;
  left: 0;
  background: #fff;
  bottom: 0;
  -webkit-transition: width 0.2s ease;
  -o-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.learn_btn_two i {
  font-size: 13px;
  padding-left: 5px;
  vertical-align: middle;
}

.learn_btn_two:hover {
  color: #fff;
}

.learn_btn_two:hover:before {
  width: 100%;
}

.learn_btn_two:hover i {
  padding-left: 10px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.service_promo_area_two {
  margin-top: -140px;
  padding-bottom: 120px;
}

.service_promo_area_two .s_service_section {
  margin-top: 200px;
}

.service_promo_area_two .shape.shape_one {
  top: 150px;
}

.service_promo_area_two .shape.shape_two {
  top: 250px;
}

.service_promo_area_two .s_service_item {
  background: #5f51fb;
}

/*=============== s_features_section css ================*/
.s_features_section {
  margin-top: 190px;
  padding-bottom: 70px;
}

.s_features_item .s_features_content {
  max-width: 590px;
}

.s_features_item .s_features_content .icon_square {
  width: 72px;
  height: 72px;
  border-radius: 4px;
  border: 1px solid rgba(127, 203, 17, 0.3);
  background-color: rgba(127, 203, 17, 0.059);
  text-align: center;
  font-size: 30px;
  line-height: 72px;
  color: #7fcb11;
}

.s_features_item .s_features_content h2 {
  margin: 32px 0px 20px;
}

.s_features_item .ml_50 {
  margin-left: -50px;
}

.s_features_item .ml_25 {
  margin-left: -25px;
}

.s_features_item.s_features_item_two .s_features_content .icon_square {
  background-color: rgba(94, 44, 237, 0.059);
  border-color: rgba(94, 44, 237, 0.3);
  color: #6754e2;
}

.learn_btn {
  font: 500 16px/25px "Poppins", sans-serif;
  color: #051441;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  margin-top: 30px;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.learn_btn i {
  padding-left: 8px;
  font-size: 14px;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.learn_btn:before {
  content: "";
  width: 100%;
  left: 0;
  bottom: 0;
  height: 1px;
  background: #051441;
  position: absolute;
  -webkit-transition: background 0.3s linear;
  -o-transition: background 0.3s linear;
  transition: background 0.3s linear;
}

.learn_btn:hover {
  color: #7fcb11;
}

.learn_btn:hover:before {
  background: #7fcb11;
}

.learn_btn:hover i {
  padding-left: 15px;
}

/*============= software_promo_area css ============*/
.software_promo_area {
  background: #f6f7fa;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.software_promo_area .round_shape {
  border-radius: 50%;
  max-width: 650px;
  min-height: 650px;
  margin: 120px auto 0px;
  position: relative;
}

.software_promo_area .round_shape .symbols-pulse {
  top: 50%;
}

.software_promo_area .round_shape .r_shape {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid #e9ebf0;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.software_promo_area .round_shape .r_shape.r_shape_five {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.software_promo_area .round_shape .r_shape.r_shape_five a {
  display: block;
  color: #fff;
  font-size: 80px;
  font-weight: 700;
}

.software_promo_area .round_shape .r_shape.r_shape_five .round {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
  background: #399be4;
  border: 5px solid #fff;
  right: 10px;
  position: absolute;
  bottom: 12px;
}

.s_promo_info .promo_item {
  position: absolute;
  border-style: solid;
  border: 0px solid rgba(93, 88, 247, 0.14);
  border-radius: 50%;
  background-color: white;
  -webkit-box-shadow: 0px 30px 60px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 30px 60px 0px rgba(0, 11, 40, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 1;
  width: 95px;
  height: 95px;
}

.s_promo_info .promo_item .text {
  padding: 10px;
}

.s_promo_info .promo_item .text img {
  max-width: 100%;
  height: auto;
}

.s_promo_info .promo_item .text p {
  display: block;
  font-size: 14px;
  color: #677294;
  margin-bottom: 0px;
  margin-top: 5px;
  font-weight: 400;
}

.s_promo_info .promo_item.item_one {
  left: 50%;
  top: 0px;
}

.s_promo_info .promo_item.item_two {
  left: 0px;
  top: 228px;
}

.s_promo_info .promo_item.item_three {
  left: 133px;
  top: 95px;
}

.s_promo_info .promo_item.item_four {
  left: 72%;
  top: 23%;
}

.s_promo_info .promo_item.item_five {
  left: 84%;
  top: 50%;
}

.s_promo_info .promo_item.item_six {
  top: 61%;
  left: 24%;
}

.s_promo_info .promo_item.item_seven {
  top: 70%;
  left: 67%;
}

.s_promo_info .promo_item.item_eight {
  top: 84%;
  left: 41%;
}

.s_promo_info .promo_item.item_nine {
  left: 0px;
  top: 65%;
}

.s_promo_info .promo_item.scroll_animation.in-view {
  opacity: 1;
}

.s_promo_info .promo_item.scroll_animation.in-view.item_one {
  -webkit-animation: rollIncustom3 0.6s linear;
  animation: rollIncustom3 0.6s linear;
}

.s_promo_info .promo_item.scroll_animation.in-view.item_two {
  -webkit-animation: rollIncustom 0.6s linear;
  animation: rollIncustom 0.6s linear;
}

.s_promo_info .promo_item.scroll_animation.in-view.item_three {
  -webkit-animation: rollIncustom1 0.9s linear;
  animation: rollIncustom1 0.9s linear;
}

.s_promo_info .promo_item.scroll_animation.in-view.item_four {
  -webkit-animation: rollIncustom4 1.2s linear;
  animation: rollIncustom4 1.2s linear;
}

.s_promo_info .promo_item.scroll_animation.in-view.item_five {
  -webkit-animation: rollIncustom5 0.9s linear;
  animation: rollIncustom5 0.9s linear;
}

.s_promo_info .promo_item.scroll_animation.in-view.item_six {
  -webkit-animation: rollIncustom6 0.6s linear;
  animation: rollIncustom6 0.6s linear;
}

.s_promo_info .promo_item.scroll_animation.in-view.item_seven {
  -webkit-animation: rollIncustom7 0.9s linear;
  animation: rollIncustom7 0.9s linear;
}

.s_promo_info .promo_item.scroll_animation.in-view.item_eight {
  -webkit-animation: rollIncustom8 0.6s linear;
  animation: rollIncustom8 0.6s linear;
}

.s_promo_info .promo_item.scroll_animation.in-view.item_nine {
  -webkit-animation: rollIncustom9 0.9s linear;
  animation: rollIncustom9 0.9s linear;
}

.process_area .features_info {
  padding-bottom: 185px;
}

/*============== features_area css ==============*/
.features_area {
  background: #fbfbfd;
  overflow: hidden;
}

.feature_info .feature_img img {
  max-width: 100%;
}

.feature_info .feature_img.f_img_one {
  margin-left: -50px;
}

.feature_info .feature_img.f_img_one .one {
  bottom: 10px;
  left: 50px;
}

.feature_info .feature_img.f_img_one .three {
  bottom: 60px;
  left: 50px;
}

.feature_info .feature_img.f_img_two .one {
  right: 120px;
  bottom: 10px;
}

.feature_info .feature_img.f_img_two .three {
  right: 70px;
  bottom: 30px;
}

.feature_info .feature_img.f_img_two .four {
  top: 80px;
  left: 100px;
}

.feature_info .f_content .icon {
  position: relative;
  width: 105px;
  height: 105px;
  text-align: center;
  line-height: 105px;
  font-size: 20px;
  color: #fff;
  margin-left: -24px;
  z-index: 1;
}

.feature_info .f_content .icon img {
  position: absolute;
  left: 0;
  top: 10px;
  z-index: -1;
}

.feature_info .f_content h2 {
  color: #3f4451;
  line-height: 44px;
  padding-right: 50px;
  margin: 8px 0px 30px;
}

.feature_info .f_content p {
  color: #677294;
  line-height: 30px;
  margin-bottom: 0px;
}

/*============= prototype_featured_area css ===============*/
.prototype_featured_area {
  background: #f4f4f9;
  position: relative;
  z-index: 1;
}

.p_feature_item + .p_feature_item {
  margin-top: 170px;
}

.p_feature_item img {
  max-width: 100%;
}

.p_feature_item .p_feture_img_one {
  margin-right: -160px;
}

.p_feature_item .p_feture_img_two {
  margin-left: -160px;
}

.p_feature_item .prototype_content h2 {
  font-size: 26px;
  line-height: 36px;
  padding-right: 70px;
}

.p_feature_item .prototype_content .prototype_logo {
  background-color: #fbfbfd;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 3px 0px rgba(12, 0, 46, 0.04);
  display: inline-block;
  padding: 7px 16px;
  margin: 35px 0px 45px;
}

.p_feature_item .prototype_content .prototype_logo a {
  display: inline-block;
  padding: 10px 12px;
}

.p_feature_item .prototype_content p {
  font-weight: 300;
}

/*============== agency_featured_area css =============*/
.agency_featured_area {
  padding-top: 160px;
}

.agency_featured_area_two {
  padding-bottom: 150px;
}

.features_info {
  position: relative;
  padding-bottom: 170px;
}

.features_info .dot_img {
  position: absolute;
  left: 0;
  top: 28px;
}

.features_info.feature_info_two {
  padding-bottom: 70px;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(255, 161, 39, 0.161);
  display: block;
  position: absolute;
  left: -9px;
  top: 15px;
  z-index: 1;
}

.dot .dot1 {
  position: absolute;
  left: 50%;
  margin-top: -4px;
  margin-left: -4px;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #feb85d;
}

.dot .dot2 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(254, 184, 93, 0.8);
  -webkit-animation: pulsate 3s infinite;
  animation: pulsate 3s infinite;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  will-change: transform;
}

.dot.middle_dot {
  left: 54.8%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  top: auto;
}

.agency_featured_item {
  margin-top: 130px;
}

.agency_featured_item .agency_featured_content {
  position: relative;
}

.agency_featured_item .agency_featured_content h3 {
  font: 500 26px/36px "Poppins", sans-serif;
  color: #222d39;
  margin: 32px 0px 25px;
}

.agency_featured_item .agency_featured_content p {
  font-size: 15px;
}

.agency_featured_item .agency_featured_content .icon {
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 50%;
  background-image: -moz-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(94, 44, 237, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(94, 44, 237, 0.2);
  font-size: 20px;
  color: #fff;
  display: inline-block;
  text-align: center;
}

.agency_featured_item.agency_featured_item_two .agency_featured_content .dot {
  left: 30px;
}

/*=============== software_featured_area css ===============*/
.software_featured_area {
  padding-top: 120px;
}

.software_featured_item .s_icon {
  display: inline-block;
  position: relative;
}

.software_featured_item .s_icon .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: -22px;
}

.software_featured_item h3 {
  font-size: 17px;
  margin: 30px 0px 25px;
}

.software_featured_item .learn_btn {
  font: 400 14px/26px "Poppins", sans-serif;
  color: #222d39;
  position: relative;
  margin-top: 0px;
}

.software_featured_item .learn_btn:before {
  content: "";
  width: 0;
  height: 1px;
  left: auto;
  right: 0;
  bottom: 0;
  background: #3d64f4;
  position: absolute;
  -webkit-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  transition: width 0.2s linear;
}

.software_featured_item .learn_btn:hover {
  color: #3d64f4;
}

.software_featured_item .learn_btn:hover:before {
  width: 100%;
  right: auto;
  left: 0;
}

/*=============== software_featured_area_two css ==================*/
.software_featured_area_two {
  background-image: linear-gradient(40deg, #23026d 0%, #3d64f4 100%);
}

.software_featured_img {
  margin-left: -30px;
}

.software_featured_content .btn_four {
  border: 0px;
  color: #3d64f4;
  background: #fff;
}

.software_featured_content .btn_four:hover {
  color: #fff;
  background: #3d64f4;
}

/*=============== saas_featured_area css ==============*/
.saas_featured_area {
  position: relative;
  overflow: hidden;
}

.square_box {
  position: absolute;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top-left-radius: 45px;
  opacity: 0.302;
}

.square_box.box_one {
  background-image: linear-gradient(140deg, #290a59 0%, #3d57f4 100%);
  width: 382px;
  height: 382px;
  bottom: -191px;
  left: -50px;
}

.square_box.box_two {
  background-image: linear-gradient(140deg, #3c0f73 0%, #bc2280 100%);
  width: 235px;
  height: 235px;
  bottom: -116px;
  left: 250px;
}

.square_box.box_three {
  background-image: -moz-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  background-image: -webkit-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  background-image: -ms-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  opacity: 0.059;
  left: -80px;
  top: -60px;
  width: 500px;
  height: 500px;
  border-radius: 45px;
}

.square_box.box_four {
  background-image: -moz-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  background-image: -webkit-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  background-image: -ms-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  opacity: 0.059;
  left: 150px;
  top: -25px;
  width: 550px;
  height: 550px;
  border-radius: 45px;
}

.saas_featured_info .saas_featured_item {
  color: #fff;
  border-radius: 4px;
  padding: 50px 30px 50px 40px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}

.saas_featured_info .saas_featured_item.s_featured_one {
  background-image: linear-gradient(40deg, #3c0f73 0%, #bc2280 100%);
}

.saas_featured_info .saas_featured_item.s_featured_two {
  background-image: linear-gradient(40deg, #290a59 0%, #3d57f4 100%);
}

.saas_featured_info .saas_featured_item.s_featured_three {
  background-image: linear-gradient(40deg, #411881 0%, #1183ad 100%);
}

.saas_featured_info .saas_featured_item.s_featured_four {
  background-image: linear-gradient(40deg, #411881 0%, #9b2bdc 100%);
}

.saas_featured_info .saas_featured_item h6 {
  font-size: 18px;
  color: #fff;
}

.saas_featured_info .saas_featured_item p {
  font-size: 15px;
  font-weight: 300;
  color: #fff;
}

.saas_featured_info .saas_featured_item:hover {
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
}

.saas_featured_info .saas_featured_content p {
  color: #bdbed6;
}

.saas_featured_info .f_img_one {
  margin-left: -100px;
}

.saas_featured_info .img_border img {
  border: 4px solid #2a3656;
  border-radius: 6px;
}

.saas_featured_info .f_img_two {
  margin-right: -120px;
  position: relative;
  z-index: 0;
}

.saas_featured_info .f_img_two:before {
  content: "";
  background-color: #0f1029;
  position: absolute;
  width: 420px;
  height: 100%;
  right: -50px;
  top: -55px;
  z-index: -1;
}

.saas_featured_info .f_img_two img {
  border-color: #49436f;
}

.saas_featured_info .saas_featured_content .saas_banner_btn {
  padding: 12px 30px;
}

.saas_featured_info + .saas_featured_info {
  margin-top: 250px;
}

/*=============== app_featured_area css ===============*/
.app_featured_area {
  padding: 140px 0px;
  position: relative;
  z-index: 1;
}

.triangle_shape {
  position: absolute;
  z-index: -2;
  height: 161%;
  width: 149%;
  background: #fbfbfd;
  bottom: -290px;
  right: -47%;
  -webkit-transform: rotate(-36deg);
  -ms-transform: rotate(-36deg);
  transform: rotate(-36deg);
}

.app_featured_content {
  padding: 50px 100px 90px 0;
}

.app_featured_content .learn_btn_two {
  margin-top: 20px;
  overflow: hidden;
}

.app_featured_content .learn_btn_two:before {
  background: #4069eb;
}

.app_featured_content .learn_btn_two:hover {
  color: #4069eb;
}

.app_fetured_item {
  position: relative;
  height: 100%;
  margin-left: 70px;
}

.app_item {
  text-align: center;
  border-radius: 6px;
  width: 200px;
  padding: 45px 0px 35px;
  position: absolute;
  z-index: 0;
}

.app_item h6 {
  margin-bottom: 0px;
  margin-top: 10px;
}

.app_item.item_one {
  background-image: -moz-linear-gradient(40deg, #e87e16 0%, #f0de14 100%);
  background-image: -webkit-linear-gradient(40deg, #e87e16 0%, #f0de14 100%);
  background-image: -ms-linear-gradient(40deg, #e87e16 0%, #f0de14 100%);
  background-image: linear-gradient(40deg, #e87e16 0%, #f0de14 100%);
  -webkit-box-shadow: 0px 20px 40px 0px rgba(224, 149, 32, 0.4);
  box-shadow: 0px 20px 40px 0px rgba(224, 149, 32, 0.4);
  right: 0;
  top: 0;
  z-index: 1;
}

.app_item.item_two {
  background-image: -moz-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  -webkit-box-shadow: 0px 20px 40px 0px rgba(94, 44, 237, 0.4);
  box-shadow: 0px 20px 40px 0px rgba(94, 44, 237, 0.4);
  left: 140px;
  top: 90px;
}

.app_item.item_three {
  background-image: -moz-linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  background-image: -webkit-linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  background-image: -ms-linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  background-image: linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  -webkit-box-shadow: 0px 20px 40px 0px rgba(224, 56, 39, 0.4);
  box-shadow: 0px 20px 40px 0px rgba(224, 56, 39, 0.4);
  left: 0;
  bottom: 70px;
  z-index: -1;
}

.app_item.item_four {
  background-image: -moz-linear-gradient(40deg, #2171d4 0%, #2cc4f0 100%);
  background-image: -webkit-linear-gradient(40deg, #2171d4 0%, #2cc4f0 100%);
  background-image: -ms-linear-gradient(40deg, #2171d4 0%, #2cc4f0 100%);
  background-image: linear-gradient(40deg, #2171d4 0%, #2cc4f0 100%);
  -webkit-box-shadow: 0px 20px 40px 0px rgba(44, 130, 237, 0.4);
  box-shadow: 0px 20px 40px 0px rgba(44, 130, 237, 0.4);
  right: 0;
  bottom: 10px;
}

.app_img {
  position: relative;
  display: inline-block;
  z-index: 0;
}

.app_img .dot {
  background: rgba(64, 105, 235, 0.161);
}

.app_img .dot .dot1 {
  background: #6c8bed;
}

.app_img .dot .dot2 {
  background: rgba(64, 105, 235, 0.8);
}

.app_img .dot_one {
  right: 75px;
  top: 35px;
  left: auto;
}

.app_img .dot_two {
  right: 100px;
  top: 67%;
  left: auto;
}

.app_img .dot_three {
  top: 38%;
  left: 105px;
}

.app_img .text_bg {
  position: absolute;
  z-index: 1;
}

.app_img .text_bg.one {
  left: -50%;
  top: 190px;
}

.app_img .text_bg.two {
  right: -34%;
  top: -50px;
}

.app_img .text_bg.three {
  right: -50%;
  bottom: 100px;
}

.app_featured_area_two {
  position: relative;
  padding-bottom: 120px;
}

.app_featured_area_two .app_feature_info {
  margin-top: 230px;
}

/*=============== saas_features_area_two css ==============*/
.saas_features_area_two {
  padding-top: 90px;
}

.saas_features_item {
  padding: 0px 40px;
  margin-bottom: 30px;
}

.saas_features_item h4 {
  margin: 25px 0px 20px;
}

.new_service {
  position: relative;
}

.new_service .col-lg-4:first-child .separator {
  display: none;
}

.new_service .number {
  width: 33px;
  height: 33px;
  text-align: center;
  font-size: 16px;
  color: #5f51fb;
  border: 1px solid #5f51fb;
  border-radius: 50%;
  margin: 0 auto;
  line-height: 32px;
  background: #fff;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 1;
  position: relative;
}

.new_service .separator {
  position: absolute;
  top: 15px;
  width: 100%;
  background: #ededed;
  height: 1px;
  left: -51%;
  z-index: 0;
}

.new_service .separator:before {
  content: "";
  position: absolute;
  top: 0px;
  width: 0;
  background: #5f51fb;
  height: 1px;
  left: 0;
  z-index: 0;
  -webkit-transition: width 0.3s linear;
  -o-transition: width 0.3s linear;
  transition: width 0.3s linear;
}

.new_service .new_service_content {
  margin-top: 50px;
  border-radius: 4px;
  padding: 50px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.new_service .saas_features_item {
  padding: 0px;
  cursor: pointer;
}

.new_service .saas_features_item:hover .number {
  background: #5f51fb;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(95, 81, 251, 0.5);
  box-shadow: 0px 10px 30px 0px rgba(95, 81, 251, 0.5);
  color: #fff;
}

.new_service .saas_features_item:hover .separator:before {
  width: 100%;
}

.new_service .saas_features_item:hover .new_service_content {
  background-color: white;
  -webkit-box-shadow: 0px 50px 80px 0px rgba(0, 11, 40, 0.08);
  box-shadow: 0px 50px 80px 0px rgba(0, 11, 40, 0.08);
}

/*=============== saas_features_area_two css ==============*/
/*=============== payment_features_area css =============*/
.payment_features_area {
  position: relative;
  padding-top: 100px;
  padding-bottom: 50px;
}

.payment_features_area .featured_item + .featured_item {
  margin-top: 100px;
}

.payment_features_area .payment_featured_img {
  margin-left: -190px;
}

.payment_features_area .payment_featured_img.img_two {
  margin-right: -190px;
  margin-left: 0px;
}

.payment_features_area .bg_shape {
  position: absolute;
  background-color: #fafbff;
  width: 1100px;
  height: 1775px;
  display: block;
  border-radius: 120px;
  z-index: -1;
}

.payment_features_area .shape_one {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: -669px;
  top: -80px;
}

.payment_features_area .shape_two {
  left: -669px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-43deg);
  top: 275px;
  width: 870px;
}

.payment_features_area .shape_three {
  bottom: -29%;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: -100px;
  width: 988px;
  height: 1022px;
}

.payment_features_content .icon {
  display: inline-block;
  position: relative;
  height: 100px;
  width: 100px;
  margin-bottom: 40px;
}

.payment_features_content .icon .icon_img {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -23px;
  margin-left: -11px;
}

.payment_features_content h2 {
  font-weight: 700;
  font-size: 26px;
  color: #051441;
  margin-bottom: 15px;
}

.payment_features_content p {
  font-size: 18px;
  color: #677294;
  line-height: 30px;
  margin-bottom: 50px;
}

.pay_btn {
  color: #5956fe;
  position: relative;
  border: 0px;
  z-index: 1;
  border-radius: 4px;
  background: #fff;
  z-index: 1;
  min-width: 170px;
  padding: 15px 20px;
  text-align: center;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(63, 54, 132, 0.1);
  box-shadow: 0px 2px 5px 0px rgba(63, 54, 132, 0.1);
}

.pay_btn:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  border-radius: 3px;
  background-image: -moz-linear-gradient(0deg, #7121ff 0%, #21d4fd 100%);
  background-image: -webkit-linear-gradient(0deg, #7121ff 0%, #21d4fd 100%);
  background-image: -ms-linear-gradient(0deg, #7121ff 0%, #21d4fd 100%);
  background-image: linear-gradient(0deg, #7121ff 0%, #21d4fd 100%);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.pay_btn.pay_btn_two {
  color: #fff;
  margin-left: 18px;
}

.pay_btn.pay_btn_two:before {
  opacity: 1;
}

.pay_btn.pay_btn_two:hover {
  color: #5956fe;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(63, 54, 132, 0.1);
  box-shadow: 0px 2px 5px 0px rgba(63, 54, 132, 0.1);
}

.pay_btn.pay_btn_two:hover:before {
  opacity: 0;
}

.pay_btn:hover {
  color: #fff;
}

.pay_btn:hover:before {
  opacity: 1;
}

.payment_features_two {
  position: relative;
  z-index: 1;
}

.payment_features_two .sec_title h2 {
  font-size: 35px;
  line-height: 44px;
  font-weight: 500;
}

.payment_features_item {
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 20px 60px 0px rgba(0, 11, 40, 0.06);
  box-shadow: 0px 20px 60px 0px rgba(0, 11, 40, 0.06);
  padding: 50px 40px;
}

.payment_features_item h4 {
  font: 600 20px/26px "Poppins", sans-serif;
  color: #051441;
  margin-bottom: 22px;
}

.payment_features_item p {
  font-weight: 300;
  margin-bottom: 28px;
}

.payment_features_item .learn_btn_two:before {
  background: #5f51fb;
}

.payment_features_item .learn_btn_two:hover {
  color: #5f51fb;
}

/*======== payment_features_area css ==========*/
.payment_features_area_three {
  padding: 135px 0px 75px;
}

.payment_features_content_two h2 {
  font-size: 35px;
  line-height: 44px;
  margin-bottom: 18px;
  font-weight: 500;
}

.payment_features_content_two p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  margin-bottom: 60px;
}

.payment_features_content_two .item {
  margin-top: 30px;
}

.payment_features_content_two .item h3 {
  font-size: 20px;
  line-height: 26px;
  color: #051441;
  margin-bottom: 15px;
  margin-top: 0px;
}

.payment_features_content_two .item p {
  font-size: 16px;
  line-height: 26px;
}

.payment_features_content_two .app_btn {
  background: #5f51fb;
  border-color: #5f51fb;
  padding: 11px 38px;
}

.payment_features_content_two .app_btn i {
  font-size: 12px;
  padding-left: 10px;
}

.payment_features_content_two .app_btn:hover {
  color: #5f51fb;
  background: transparent;
}

.payment_features_img {
  position: relative;
  text-align: left;
  z-index: 1;
  float: left;
}

.payment_features_img:before {
  content: "";
  background: url(../img/new/shape_bg.png) no-repeat scroll center left;
  position: absolute;
  left: -65px;
  top: -20px;
  right: -32px;
  height: 100%;
  background-size: cover;
  z-index: -1;
}

.payment_features_img img {
  margin-left: -50px;
}

.payment_features_area_four .payment_features_content h2 {
  line-height: 44px;
  font-size: 30px;
}

.payment_features_area_four .pay_btn {
  border-radius: 45px;
  border: 1px solid #5f51fb;
  min-width: auto;
  padding: 12px 36px;
  color: #5f51fb;
}

.payment_features_area_four .pay_btn:before {
  background: #5f51fb;
}

.payment_features_area_four .pay_btn:hover {
  color: #fff;
}

.payment_features_area_four .pay_btn.pay_btn_two {
  color: #fff;
}

.payment_features_area_four .pay_btn.pay_btn_two:hover {
  color: #5f51fb;
}

/*===========perfect_solution_area css ==========*/
.perfect_solution_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.perfect_solution_area .perfect_solution_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 150px 0px;
}

.perfect_solution_area .perfect_solution_left .per_solution_content {
  max-width: 685px;
  margin-left: auto;
  margin-right: 0;
  padding-right: 170px;
}

.perfect_solution_area .perfect_solution_left .per_solution_content h2 {
  font-size: 40px;
  line-height: 56px;
  color: #051441;
  font-weight: 500;
  margin-bottom: 32px;
}

.perfect_solution_area .perfect_solution_left .per_solution_content p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 50px;
}

.perfect_solution_area .perfect_solution_left .per_solution_content .btn_three {
  margin-top: 0px;
  font-size: 15px;
  background: #5f51fb;
  padding: 15px 42px;
}

.perfect_solution_area .perfect_solution_left .per_solution_content .btn_three:hover {
  background: transparent;
}

.perfect_solution_area .perfect_solution_left .per_solution_content .btn_six {
  background: transparent;
  color: #677294;
  border-color: #dfe3ed;
  margin-left: 20px;
}

.perfect_solution_area .perfect_solution_left .per_solution_content .btn_six:hover {
  background: #5f51fb;
  border-color: #5f51fb;
  color: #fff;
}

.perfect_solution_area .perfect_solution_left .per_solution_content.per_solution_content_two {
  padding-right: 0px;
  margin-right: auto;
  margin-left: 0;
  padding-left: 110px;
}

.perfect_solution_area .perfect_solution_left .per_solution_content.per_solution_content_three {
  padding-right: 0px;
  margin-right: auto;
  margin-left: 0;
  padding-left: 110px;
}

.perfect_solution_area .perfect_solution_left .per_solution_content.per_solution_content_three h2 {
  font-weight: 600;
  line-height: 56px;
}

.perfect_solution_area .perfect_solution_left .per_solution_content.per_solution_content_three .btn_three {
  border-radius: 45px;
  padding: 13px 44px;
}

.perfect_solution_area .perfect_solution_right {
  padding: 0px;
  position: relative;
}

.perfect_solution_area .perfect_solution_right .bg_img {
  background: url("../img/new/solution_01.jpg") no-repeat scroll center 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 130%;
  background-size: cover;
}

.perfect_solution_area .perfect_solution_right .bg_img.bg_two {
  background: url("../img/new/solution_02.jpg") no-repeat scroll center 0;
}

/*=========== startup_fuatures_area css ==========*/
.startup_tab {
  border: 0px;
  margin-bottom: 50px;
}

.startup_tab .nav-item {
  width: calc(100% / 5);
  padding: 0px 15px;
  margin: 0px;
  text-align: center;
}

.startup_tab .nav-item .nav-link {
  border: 0px;
  background: #fbfbfd;
  padding: 32px 0px;
  border-radius: 4px;
  border: 2px solid transparent;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.startup_tab .nav-item .nav-link .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 30px;
  line-height: 72px;
  color: #fff;
  margin-bottom: 15px;
}

.startup_tab .nav-item .nav-link h3 {
  font-size: 18px;
  line-height: 26px;
  color: #051441;
  font-weight: 400;
  white-space: pre-line;
  margin-bottom: 0;
}

.startup_tab .nav-item .nav-link.active {
  border-color: #6fadfa;
  background: #fff;
  -webkit-box-shadow: 0px 30px 40px 0px rgba(29, 7, 90, 0.08);
  box-shadow: 0px 30px 40px 0px rgba(29, 7, 90, 0.08);
}

.startup_tab .nav-item:nth-child(1) .icon {
  background: #fa6fd1;
}

.startup_tab .nav-item:nth-child(1) .nav-link.active {
  border-color: #fa6fd1;
}

.startup_tab .nav-item:nth-child(2) .icon {
  background: #6fadfa;
}

.startup_tab .nav-item:nth-child(2) .nav-link.active {
  border-color: #6fadfa;
}

.startup_tab .nav-item:nth-child(3) .icon {
  background: #f3af4e;
}

.startup_tab .nav-item:nth-child(3) .nav-link.active {
  border-color: #f3af4e;
}

.startup_tab .nav-item:nth-child(4) .icon {
  background: #aa6ffa;
}

.startup_tab .nav-item:nth-child(4) .nav-link.active {
  border-color: #aa6ffa;
}

.startup_tab .nav-item:nth-child(5) .icon {
  background: #fa6666;
}

.startup_tab .nav-item:nth-child(5) .nav-link.active {
  border-color: #fa6666;
}

.startup_tab_content .show .startup_tab_img .phone_img, .startup_tab_content .show .startup_tab_img .web_img {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.startup_tab_img {
  position: relative;
  padding-top: 40px;
  padding-bottom: 64px;
  z-index: 1;
}

.startup_tab_img:before {
  content: "";
  height: 100%;
  background: url("../img/new/tab_shape_bg.png") no-repeat scroll center 0/contain;
  top: 0px;
  right: -55px;
  left: -55px;
  position: absolute;
  z-index: -1;
}

.startup_tab_img .web_img {
  display: inline-block;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 11, 40, 0.14);
  box-shadow: 0px 20px 40px 0px rgba(0, 11, 40, 0.14);
  -webkit-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transform: translateX(-30px);
  opacity: 0;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.startup_tab_img .phone_img {
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
  opacity: 0;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

/*============== intregration_area css =============*/
.intregration_logo {
  padding-right: 60px;
  margin-bottom: -30px;
}

.intregration_logo .intregration_item {
  margin-bottom: 30px;
}

.intregration_icon {
  width: 100%;
  display: block;
  background-color: white;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(29, 7, 90, 0.06);
  box-shadow: 0px 4px 5px 0px rgba(29, 7, 90, 0.06);
  height: 100%;
  padding: 30px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.intregration_icon:hover {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}

.intregration_content h2 {
  font-size: 30px;
  color: #051441;
  font-weight: 600;
  margin-bottom: 25px;
}

.intregration_content p {
  line-height: 30px;
  margin-bottom: 40px;
}

.startup_fuatures_area_two .startup_tab {
  margin-bottom: 0px;
}

.startup_fuatures_area_two .startup_tab .nav-item:hover:nth-child(1) .nav-link {
  border-color: #fa6fd1;
}

.startup_fuatures_area_two .startup_tab .nav-item:hover:nth-child(2) .nav-link {
  border-color: #6fadfa;
}

.startup_fuatures_area_two .startup_tab .nav-item:hover:nth-child(3) .nav-link {
  border-color: #f3af4e;
}

.startup_fuatures_area_two .startup_tab .nav-item:hover:nth-child(4) .nav-link {
  border-color: #aa6ffa;
}

.startup_fuatures_area_two .startup_tab .nav-item:hover:nth-child(5) .nav-link {
  border-color: #fa6666;
}

.seo_features_content h2 {
  font-size: 30px;
  line-height: 40px;
  color: #263b5e;
  font-weight: 600;
  margin-bottom: 25px;
}

.seo_features_content p {
  font-size: 16px;
  color: #6a7695;
  font-weight: 300;
}

.seo_features_content h6 {
  font-size: 18px;
  font-weight: 400;
  color: #263b5e;
  line-height: 30px;
  margin-bottom: 35px;
}

.seo_features_content .seo_btn {
  margin-top: 40px;
}

.seo_features_content .seo_features_item {
  padding-right: 150px;
  margin-top: 50px;
}

.seo_features_content .seo_features_item .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #f1ebfe;
  text-align: center;
  line-height: 70px;
  margin-right: 30px;
}

.seo_features_content .seo_features_item .icon.two {
  background: #fcf1e7;
}

.seo_features_content .seo_features_item h3 {
  font-size: 20px;
  color: #263b5e;
  font-weight: 500;
  margin-bottom: 15px;
}

.seo_features_content .seo_features_item p {
  font-size: 16px;
  line-height: 28px;
  color: #6a7695;
  margin-bottom: 0;
}

.seo_features_img {
  text-align: center;
  position: relative;
  z-index: 1;
}

.seo_features_img img {
  margin-right: -80px;
}

.seo_features_img .round_circle {
  position: absolute;
  width: 540px;
  height: 540px;
  border-radius: 50%;
  background: #5e2ced;
  right: -126px;
  z-index: -1;
  opacity: 0.06;
}

.seo_features_img .round_circle.two {
  width: 360px;
  height: 360px;
  left: 84px;
  right: auto;
  top: 74px;
  z-index: -2;
}

.seo_features_img.seo_features_img_two img {
  margin-left: -170px;
  margin-right: 0;
}

.seo_features_img.seo_features_img_two .round_circle {
  left: -86px;
  right: auto;
  width: 500px;
  height: 500px;
  background: #dfac0b;
}

.seo_features_img.seo_features_img_two .round_circle.two {
  right: 60px;
  width: 370px;
  height: 370px;
  left: auto;
}

/*====================================================*/
/*====================================================*/
/*=========== service_area css ============*/
.service_area {
  background: #f4f6fa;
}

.service_item {
  background: #fff;
  -webkit-box-shadow: 0px 30px 40px 0px rgba(3, 115, 156, 0.1);
  box-shadow: 0px 30px 40px 0px rgba(3, 115, 156, 0.1);
  padding: 47px 40px 0px;
  height: 100%;
  position: relative;
  border: 2px solid transparent;
  -webkit-transition: border 0.2s linear;
  -o-transition: border 0.2s linear;
  transition: border 0.2s linear;
  cursor: pointer;
  overflow: hidden;
}

.service_item p {
  line-height: 28px;
}

.service_item .icon {
  width: 54px;
  height: 54px;
  line-height: 54px;
  font-size: 20px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  background-image: -moz-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  box-shadow: 0px 14px 30px 0px rgba(94, 44, 237, 0.4);
  position: absolute;
  right: 30px;
  top: 30px;
}

.service_item .icon.s_icon_one {
  background-image: -moz-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  box-shadow: 0px 14px 30px 0px rgba(94, 44, 237, 0.4);
}

.service_item .icon.s_icon_two {
  background-image: -moz-linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  background-image: -webkit-linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  background-image: -ms-linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  background-image: linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  -webkit-box-shadow: 0px 14px 30px 0px rgba(224, 56, 39, 0.4);
  box-shadow: 0px 14px 30px 0px rgba(224, 56, 39, 0.4);
}

.service_item .icon.s_icon_three {
  background-image: -moz-linear-gradient(40deg, #2c82ed 0%, #38d0fc 100%);
  background-image: -webkit-linear-gradient(40deg, #2c82ed 0%, #38d0fc 100%);
  background-image: -ms-linear-gradient(40deg, #2c82ed 0%, #38d0fc 100%);
  background-image: linear-gradient(40deg, #2c82ed 0%, #38d0fc 100%);
  -webkit-box-shadow: 0px 14px 30px 0px rgba(44, 130, 237, 0.4);
  box-shadow: 0px 14px 30px 0px rgba(44, 130, 237, 0.4);
}

.service_item .icon.s_icon_four {
  background-image: -moz-linear-gradient(40deg, #e09520 0%, #fae926 100%);
  background-image: -webkit-linear-gradient(40deg, #e09520 0%, #fae926 100%);
  background-image: -ms-linear-gradient(40deg, #e09520 0%, #fae926 100%);
  background-image: linear-gradient(40deg, #e09520 0%, #fae926 100%);
  -webkit-box-shadow: 0px 14px 30px 0px rgba(224, 149, 32, 0.4);
  box-shadow: 0px 14px 30px 0px rgba(224, 149, 32, 0.4);
}

.service_item img {
  margin-right: -25px;
  width: auto;
}

.service_item:hover {
  border-color: #c4e7f7;
}

/*================ prototype_service_info css ==============*/
.prototype_service_area_two, .prototype_service_area_three {
  overflow: hidden;
}

.prototype_service_info {
  padding: 150px 0px 175px;
  position: relative;
  z-index: 0;
}

.p_service_info {
  margin-bottom: -90px;
}

.p_service_item {
  margin-bottom: 90px;
}

.p_service_item .icon {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 55px;
  height: 60px;
  position: relative;
  margin: 0px 0px 25px;
  font-size: 24px;
  -webkit-border-radius: 50% / 14%;
  border-radius: 50% / 14%;
  color: white;
  text-align: center;
  line-height: 64px;
  -o-text-overflow: clip;
  text-overflow: clip;
  z-index: 1;
}

.p_service_item .icon:before {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  content: "";
  top: 10%;
  right: -5.5%;
  bottom: 10%;
  left: -5%;
  border: none;
  -webkit-border-radius: 7% / 50%;
  border-radius: 7% / 50%;
  -o-text-overflow: clip;
  text-overflow: clip;
  text-shadow: none;
  z-index: -1;
}

.p_service_item .icon.icon_one {
  background: #7142f8;
}

.p_service_item .icon.icon_one:before {
  background: #7142f8;
}

.p_service_item .icon.icon_two {
  background: #5cbd2c;
}

.p_service_item .icon.icon_two:before {
  background: #5cbd2c;
}

.p_service_item .icon.icon_three {
  background: #f38650;
}

.p_service_item .icon.icon_three:before {
  background: #f38650;
}

.p_service_item .icon.icon_four {
  background: #ecbf24;
}

.p_service_item .icon.icon_four:before {
  background: #ecbf24;
}

.p_service_item .icon.icon_five {
  background: #fa4c9a;
}

.p_service_item .icon.icon_five:before {
  background: #fa4c9a;
}

.p_service_item .icon.icon_six {
  background: #35bfe7;
}

.p_service_item .icon.icon_six:before {
  background: #35bfe7;
}

.p_service_item h5 {
  font-size: 17px;
  line-height: 22px;
  margin-bottom: 15px;
}

.p_service_item p {
  font-size: 15px;
}

.symbols-pulse {
  position: absolute;
  top: 58%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.symbols-pulse > div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 23rem;
  height: 23rem;
  border-radius: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(227, 221, 246, 0.1)), color-stop(65%, rgba(227, 221, 246, 0.2)), to(rgba(227, 221, 246, 0.1)));
  background-image: -webkit-linear-gradient(top, rgba(227, 221, 246, 0.1) 0%, rgba(227, 221, 246, 0.2) 65%, rgba(227, 221, 246, 0.1) 100%);
  background-image: -o-linear-gradient(top, rgba(227, 221, 246, 0.1) 0%, rgba(227, 221, 246, 0.2) 65%, rgba(227, 221, 246, 0.1) 100%);
  background-image: linear-gradient(-180deg, rgba(227, 221, 246, 0.1) 0%, rgba(227, 221, 246, 0.2) 65%, rgba(227, 221, 246, 0.1) 100%);
  z-index: -1;
}

.symbols-pulse .pulse-1 {
  -webkit-transform: translate(-50%, -50%) scale(0.67);
  -ms-transform: translate(-50%, -50%) scale(0.67);
  transform: translate(-50%, -50%) scale(0.67);
}

.symbols-pulse .pulse-2 {
  -webkit-transform: translate(-50%, -50%) scale(1.05);
  -ms-transform: translate(-50%, -50%) scale(1.05);
  transform: translate(-50%, -50%) scale(1.05);
}

.symbols-pulse .pulse-3 {
  -webkit-transform: translate(-50%, -50%) scale(1.63);
  -ms-transform: translate(-50%, -50%) scale(1.63);
  transform: translate(-50%, -50%) scale(1.63);
}

.symbols-pulse .pulse-4 {
  -webkit-transform: translate(-50%, -50%) scale(2.1);
  -ms-transform: translate(-50%, -50%) scale(2.1);
  transform: translate(-50%, -50%) scale(2.1);
}

.symbols-pulse .pulse-x {
  will-change: transform;
  -webkit-animation: pulsate 3s infinite;
  animation: pulsate 3s infinite;
}

@-webkit-keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4);
  }
}

@keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4);
  }
}

/*================ prototype_service_area css =============*/
.prototype_service_area {
  background: #fbfbfd;
  padding-bottom: 130px;
  padding-top: 20px;
}

.service_carousel {
  padding-left: 155px;
  position: relative;
  z-index: 1;
}

.service_carousel:before {
  width: 100px;
  content: "";
  height: 100%;
  right: -25px;
  top: 0;
  position: absolute;
  background: #fbfbfb;
  -webkit-filter: blur(12px);
  filter: blur(12px);
  -webkit-box-shadow: 0px 100px 40px 0px rgba(251, 251, 251, 0.1);
  box-shadow: 0px 100px 40px 0px rgba(251, 251, 251, 0.1);
  z-index: 1;
}

.service_carousel .slick-list {
  padding-left: 0 !important;
}

.service_carousel .slick-slide > div {
  padding-left: 35px;
  padding-bottom: 55px;
  height: 100%;
}

.service_carousel .slick-slide .service_item {
  -webkit-box-shadow: 0px 30px 40px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 30px 40px 0px rgba(12, 0, 46, 0.1);
}

.service_carousel .slick-slide .service_item:hover {
  border-color: rgba(116, 68, 253, 0.2);
}

.service_carousel .slick-slide .service_item img {
  width: auto;
}
.service_carousel .slick-list .slick-track,.service_carousel .slick-list{
  display: flex;
}
.service_carousel .slick-prev{
  display: none !important;
}
.service_carousel .slick-next {
  position: absolute;
  top: 40%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: white;
  -webkit-box-shadow: 0px 2px 20px 2px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 2px 20px 2px rgba(12, 0, 46, 0.06);
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  text-indent: -999px;
}
.service_carousel .slick-next:before{
  content: "\e629";
  font-family: 'themify';
  text-indent: 999px;
  position: absolute;
  position: absolute;
  left: 0;
  top: 0;
  text-indent: 0;
  width: 100%;
  font-size: 16px;
  color: #222d39;
  line-height: 56px;
}

.service_carousel .slick-next:hover {
  background: #7444fd !important;

}
.service_carousel .slick-next:hover:before{
  color: #fff !important;
}

.prototype_service_area_two {
  background: #f8f8fc;
  padding: 120px 0px 60px;
  position: relative;
  z-index: 1;
}

/*============= agency_service_area css =============*/
.agency_service_area {
  padding: 135px 0px 140px;
}

.agency_service_item {
  margin-bottom: 30px;
}

.agency_service_item .icon {
  width: auto;
  height: auto;
  display: inline-block;
}

.agency_service_item .icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.agency_service_item a {
  font: 400 14px/26px "Poppins", sans-serif;
  color: #222d39;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-transition: color 0.3s linear 0s;
  -o-transition: color 0.3s linear 0s;
  transition: color 0.3s linear 0s;
}

.agency_service_item a:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #222d39;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
  -webkit-transition: width 0.3s linear 0s;
  -o-transition: width 0.3s linear 0s;
  transition: width 0.3s linear 0s;
}

.agency_service_item p {
  display: inline-block;
}

.agency_service_item p i {
  vertical-align: middle;
  padding-left: 8px;
  -webkit-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.agency_service_item p:hover a {
  color: #5e2ced;
}

.agency_service_item p:hover a:before {
  width: 132%;
  background: #5e2ced;
}

.agency_service_item p:hover i {
  color: #5e2ced;
  padding-left: 12px;
}

/*====================== design_developers_area css =================*/
.design_img img {
  border: 2px solid #e4ebf2;
  border-radius: 6px;
  background-color: #c3c9ce;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
}

.design_img_two {
  margin-left: -100px;
}

/*============== software_service_area css =============*/
.software_service_area {
  background: #f9f9fc;
}

.software_service_tab {
  margin: 0px;
  border: 0px;
  display: block;
}

.software_service_tab .nav-item {
  margin: 0px;
}

.software_service_tab .nav-item .nav-link {
  display: block;
  padding: 16px 32px 16px 40px;
  -webkit-transition: border-left-color .25s linear;
  -o-transition: border-left-color .25s linear;
  transition: border-left-color .25s linear;
  border-radius: 0px;
  border: 0px;
  border-left: 4px solid #e6e8ef;
  background: transparent;
  font: 400 16px "Poppins", sans-serif;
  color: #677294;
  position: relative;
}

.software_service_tab .nav-item .nav-link:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 8px;
  border-color: transparent transparent transparent #3d64f4;
  position: absolute;
  left: -2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
}

.software_service_tab .nav-item .nav-link.active {
  border-left-color: #3d64f4;
  color: #3d64f4;
}

.software_service_tab .nav-item .nav-link.active:before {
  opacity: 1;
}

.software_service_tab_content {
  margin-bottom: -70px;
}

.software_service_tab_content .software_service_item {
  padding-right: 30px;
}

.software_service_tab_content .software_service_item i {
  font-size: 30px;
  color: #3d64f4;
  line-height: 40px;
}

.software_service_tab_content .software_service_item h5 {
  font: 600 17px/25px "Poppins", sans-serif;
  color: #222d39;
}

.software_service_tab_content .software_service_item p {
  font-size: 15px;
}

/*============== developer_product_area css ================*/
.developer_product_area {
  background: #f9f9fc;
  padding: 160px 0px;
}

.developer_product_content {
  padding-right: 100px;
}

.developer_product_content .develor_tab {
  display: inline-block;
  border-bottom: 2px solid #e1e3ed;
  padding: 0px 40px 0px 10px;
}

.developer_product_content .develor_tab .nav-item {
  margin: 0px 0px 0px 50px;
  float: left;
}

.developer_product_content .develor_tab .nav-item .nav-link {
  font: 400 16px "Poppins", sans-serif;
  color: #677294;
  border: 0px;
  padding: 0px 0px 10px;
  position: relative;
}

.developer_product_content .develor_tab .nav-item .nav-link:before {
  width: 0;
  content: "";
  left: auto;
  right: 0;
  height: 2px;
  background: #3d64f4;
  position: absolute;
  bottom: -2px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.developer_product_content .develor_tab .nav-item .nav-link.active {
  color: #3d64f4;
  background: transparent;
}

.developer_product_content .develor_tab .nav-item .nav-link.active:before {
  right: auto;
  left: 0;
  width: 100%;
}

.developer_product_content .develor_tab .nav-item:first-child {
  margin-left: 0px;
}

.developer_product_content .developer_tab_content p {
  line-height: 28px;
  font-weight: 300;
}

.developer_product_content .developer_tab_content .details_btn {
  font: 400 14px "Poppins", sans-serif;
  color: #3d64f4;
  position: relative;
  display: inline-block;
}

.developer_product_content .developer_tab_content .details_btn:before {
  width: 100%;
  content: "";
  left: auto;
  right: 0;
  height: 1px;
  background: #3d64f4;
  position: absolute;
  bottom: 0px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.c_violet {
  color: #222d39;
}

.c_violet:before {
  background: #5e2ced;
}

.c_violet:hover {
  color: #5e2ced;
}

.d_product_content_two .develor_tab .nav-item .nav-link:before {
  background: #5e2ced;
}

.d_product_content_two .develor_tab .nav-item .nav-link.active {
  color: #5e2ced;
}

.tab_img_info {
  position: relative;
}

.tab_img_info .tab_img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 6px;
  overflow: hidden;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.tab_img_info .tab_img.active {
  position: relative;
  opacity: 1;
}

/*============= app_service_area css ============*/
.app_service_area {
  padding-top: 120px;
  background: #fff;
}

.app_service_info {
  margin-bottom: -90px;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.app_service_item {
  border: 1px solid #f4f4f9;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  padding: 50px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin-bottom: 30px;
  cursor: pointer;
}

.app_service_item .app_icon {
  font-size: 40px;
  line-height: 45px;
}

.app_service_item .app_icon.one {
  text-shadow: 0px 14px 30px rgba(44, 130, 237, 0.4);
  color: #2171d4;
}

.app_service_item .app_icon.two {
  text-shadow: 0px 14px 30px rgba(224, 56, 39, 0.4);
  color: #e03827;
}

.app_service_item .app_icon.three {
  text-shadow: 0px 14px 30px rgba(94, 44, 237, 0.4);
  color: #5e2ced;
}

.app_service_item .learn_btn_two {
  color: #222d39;
  font-size: 14px;
}

.app_service_item .learn_btn_two:before {
  background: #4069eb;
}

.app_service_item .learn_btn_two:hover {
  color: #4069eb;
}

.app_service_item .learn_btn_two.c_violet:hover {
  color: #5e2ced;
}

.app_service_item .learn_btn_two.c_violet:hover:before {
  background: #5e2ced;
}

.app_service_item:hover {
  -webkit-box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.08);
  box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.08);
}

.app_service_area_two {
  background: #f9f9fc;
  padding: 120px 0px;
}

.app_service_area_two .app_service_info {
  margin-bottom: -30px;
}

/*============== service_details_area css ==============*/
.service_details_img {
  margin-left: -80px;
}

.service_details ul {
  padding-left: 35px;
}

.service_details ul li {
  font: 400 16px "Poppins", sans-serif;
  color: #677294;
  position: relative;
  padding-left: 35px;
  margin-bottom: 13px;
}

.service_details ul li i {
  position: absolute;
  left: 0;
  top: 5px;
  color: #5e2ced;
}

.service_details ul li:last-child {
  margin-bottom: 0px;
}

.service_details_item + .service_details_item {
  margin-top: 200px;
}

/*============= saas_service_area css =============*/
.saas_service_item .saas_service_content .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 24px;
  line-height: 64px;
  color: #fff;
  text-align: center;
  margin-bottom: 35px;
}

.saas_service_item .saas_service_content .icon.icon_one {
  background-image: -moz-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(94, 44, 237, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(94, 44, 237, 0.2);
}

.saas_service_item .saas_service_content .icon.icon_two {
  background-image: -moz-linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  background-image: -webkit-linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  background-image: -ms-linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(227, 69, 49, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(227, 69, 49, 0.2);
}

.saas_service_item .saas_service_content .icon.icon_three {
  background-image: -moz-linear-gradient(40deg, #57b22a 0%, #77ea3d 100%);
  background-image: -webkit-linear-gradient(40deg, #57b22a 0%, #77ea3d 100%);
  background-image: -ms-linear-gradient(40deg, #57b22a 0%, #77ea3d 100%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(92, 186, 45, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(92, 186, 45, 0.2);
}

.saas_service_item .saas_service_content .icon.icon_four {
  background-image: -moz-linear-gradient(40deg, #2c82ed 0%, #38d0fc 100%);
  background-image: -webkit-linear-gradient(40deg, #2c82ed 0%, #38d0fc 100%);
  background-image: -ms-linear-gradient(40deg, #2c82ed 0%, #38d0fc 100%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(45, 139, 239, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(45, 139, 239, 0.2);
}

.saas_service_item .saas_service_content h4 {
  margin-bottom: 20px;
}

.saas_service_item .saas_service_content p {
  margin-bottom: 45px;
}

.saas_service_item + .saas_service_item {
  margin-top: 180px;
}

/*============ payment_service_area css ==============*/
.payment_service_area {
  padding-top: 225px;
  padding-bottom: 150px;
  position: relative;
  z-index: 2;
  background: url("../img/home9/shape_two.png") no-repeat scroll center 0;
  background-size: cover;
}

.payment_service_area .image_shape {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 882px;
  z-index: -1;
}

.payment_service_area .service-content h2 {
  font-size: 26px;
  margin-bottom: 25px;
}

.payment_service_area .service-content p {
  font-size: 18px;
}

.pay_icon {
  background: url("../img/home9/icon_shape_two.png") no-repeat scroll center 0;
  width: 110px;
  height: 103px;
  text-align: center;
  line-height: 95px;
  margin-bottom: 34px;
}

.payment_service_item {
  padding-right: 80px;
  margin-bottom: 75px;
}

.payment_service_item .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  text-align: center;
  line-height: 55px;
  margin-right: 30px;
}

.payment_service_item h3 {
  margin-bottom: 15px;
}

.payment_service_item p {
  margin-bottom: 0;
}

/*============= stratup_service_area css =============*/
.stratup_service_img {
  position: relative;
  padding-top: 20px;
  z-index: 1;
}

.stratup_service_img .shape {
  position: absolute;
  left: -37px;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(../img/new/new_shape.png) no-repeat scroll left 0;
  z-index: -1;
  background-size: contain;
}

.stratup_service_img .phone_img {
  position: absolute;
  left: 0;
  bottom: 0;
}

.stratup_service_info {
  margin-left: -15px;
}

.stratup_service_info .startup_service_item {
  position: relative;
  padding-left: 75px;
}

.stratup_service_info .startup_service_item .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  color: #fff;
  background: #6fadfa;
  line-height: 65px;
  position: absolute;
  left: 0;
  top: -5px;
}

.stratup_service_info .startup_service_item .icon.icon_two {
  background: #f3af4e;
}

.stratup_service_info .startup_service_item .icon.icon_three {
  background: #fa6fd1;
}

.stratup_service_info .startup_service_item .icon.icon_four {
  background: #fa6666;
}

.stratup_service_info .startup_service_item h3 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #051441;
  margin-bottom: 2px;
}

.stratup_service_info .startup_service_item p {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
}

.stratup_service_info .startup_service_item + .startup_service_item {
  margin-top: 40px;
}

/*============= stratup_service_area css =============*/
/*============= progress_bar_area css =============*/
.progress_bar_area {
  padding-top: 120px;
}

.progress_bar_area .br_bottom {
  width: 100%;
  height: 1px;
  background: #f0f0f8;
  margin-top: 100px;
}

.progress_bar_area .progress_item {
  text-align: center;
  padding: 0px 30px;
  margin-bottom: 30px;
}

.progress_bar_area .progress_item .circle {
  position: relative;
  margin-bottom: 22px;
}

.progress_bar_area .progress_item h4 {
  color: #051441;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
}

.progress_bar_area .progress_item p {
  margin-bottom: 0;
  line-height: 28px;
}

.progress_bar_area .number {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: #051441;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

/*============= progress_bar_area css =============*/
/*============= service_details_area css =============*/
.service_details_area .job_info .info_item {
  padding-left: 0px;
}

.service_details_area .details_content .sec_title p + p {
  margin-top: 40px;
  margin-bottom: 0;
}

/*=========== case_study_area css ==========*/
.case_study_area .row {
  margin-bottom: -40px;
}

.case_study_item {
  margin-bottom: 40px;
}

.case_study_item img {
  max-width: 100%;
}

.case_study_item .text {
  background: #f4f6f9;
  padding: 20px 40px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}

.case_study_item p {
  font-size: 15px;
  color: #677294;
  margin-bottom: 0;
}

.case_study_item h3 {
  font-size: 20px;
  line-height: 34px;
  font-weight: 500;
  color: #282835;
  margin-bottom: 0;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.case_study_item h3:hover {
  color: #5e2ced;
}

.case_study_item:hover .text {
  background: #fff;
  -webkit-box-shadow: 0px 20px 20px 0px rgba(9, 1, 34, 0.06);
  box-shadow: 0px 20px 20px 0px rgba(9, 1, 34, 0.06);
}

.study_details h2 {
  font-size: 30px;
  font-weight: 600;
  color: #051441;
  margin-bottom: 25px;
}

.study_details .btn_info {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
}

.study_details .tag {
  font-size: 20px;
  color: #3dc318;
  margin-left: 25px;
}

.study_details .tag i {
  padding-right: 15px;
}

.study_details ul li {
  font: 400 15px "Poppins", sans-serif;
  color: #677294;
  margin-bottom: 15px;
}

.study_details ul li span {
  font-weight: 600;
  color: #051441;
}

.study_details_content {
  padding-top: 58px;
}

.study_details_content h2 {
  font-size: 26px;
  color: #051441;
  font-weight: 600;
  margin-bottom: 25px;
}

.study_details_content p {
  color: #677294;
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.study_details_content p + p {
  margin-top: 30px;
}

.study_details_two .study_details_content {
  padding-top: 0px;
}

.study_details_two .study_details_content + .study_details_content {
  padding-top: 50px;
}

.study_details_three .details_img img {
  -webkit-box-shadow: -12.856px 15.321px 30px 0px rgba(9, 1, 34, 0.1);
  box-shadow: -12.856px 15.321px 30px 0px rgba(9, 1, 34, 0.1);
}

.study_details_three .study_details_content {
  padding-top: 0;
}

.study_details_three .study_details_content h2 {
  margin-bottom: 25px;
}

.study_details_three .study_details_content p {
  margin-bottom: 50px;
}

.study_details_three .study_details_content ul li {
  font: 400 15px/34px "Poppins", sans-serif;
  color: #282835;
  position: relative;
  padding-left: 25px;
}

.study_details_three .study_details_content ul li:before {
  content: "\e64c";
  position: absolute;
  left: 0;
  top: 0;
  font-family: 'themify';
  color: #5e2ced;
}

/*============= seo_service_area css =============*/
.seo_service_item {
  background-color: white;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
  box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
  padding: 60px 40px 38px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}

.seo_service_item h4 {
  font-size: 20px;
  line-height: 28px;
  color: #263b5e;
  font-weight: 500;
  margin-top: 35px;
  margin-bottom: 16px;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.seo_service_item h4:hover {
  color: #5e2ced;
}

.seo_service_item p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 30px;
  color: #6a7695;
  margin-bottom: 40px;
}

.seo_service_item i {
  font-size: 40px;
  color: #afb8ce;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.seo_service_item i:hover {
  color: #5e2ced;
}

.seo_service_item:hover {
  -webkit-box-shadow: 0px 30px 60px 0px rgba(38, 59, 94, 0.1);
  box-shadow: 0px 30px 60px 0px rgba(38, 59, 94, 0.1);
}

/*=========== case_studies_area css ==========*/
.case_studies_slider{
  margin-left: -15px;
  margin-right: -15px;
}
.case_studies_slider .slick-dots {
  margin-top: 40px;
  position: relative;
  bottom: 0;
}
.case_studies_slider .slick-dots li{
  margin: 0px;
}
.case_studies_slider .slick-dots li button {
  width: 6px;
  height: 6px;
  background: #b9c4d8;
  border: 0px;
}

.case_studies_slider .slick-dots li.slick-active button{
  background: #5e2ced;
}

.studies_item {
  background: #fff;
  -webkit-box-shadow: 0px 3px 4px 0px rgba(38, 59, 94, 0.1);
  box-shadow: 0px 3px 4px 0px rgba(38, 59, 94, 0.1);
  margin: 10px 15px;
}
.studies_item img{
  max-width: 100%;
}

.studies_item .text {
  padding: 18px 40px;
}

.studies_item .text h4 {
  font-size: 20px;
  color: #263b5e;
  font-weight: 500;
  margin-bottom: 3px;
}
.studies_item .text h4:hover{
  color: #5e2ced;
}

.studies_item .text p {
  margin-bottom: 0;
}

.studies_item .text p a {
  font-size: 16px;
  color: #6a7695;
}

/*====================================================*/
/*====================================================*/
/*================= s_pricing_area css ===============*/
.s_pricing-item {
  text-align: center;
  background: #f6f7fa;
  border: 1px solid #f6f7fa;
  padding: 55px;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin-bottom: 30px;
  position: relative;
}

.s_pricing-item .tag_label {
  font: 400 16px/22px "Poppins", sans-serif;
  color: #fff;
  background: #5f2eed;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  top: 36px;
  right: 0;
  padding: 9px 26px;
  border: 0px;
  display: block;
}

.s_pricing-item .tag_label:before {
  content: "";
  border-top: 20px solid #5f2eed;
  border-bottom: 20px solid #5f2eed;
  border-right: 11px solid transparent;
  border-left: 11px solid transparent;
  position: absolute;
  right: -11px;
  top: 0;
}

.s_pricing-item .tag_label.blue_bg {
  background: #19cce6;
}

.s_pricing-item .tag_label.blue_bg:before {
  border-top: 20px solid #19cce6;
  border-bottom: 20px solid #19cce6;
}

.s_pricing-item .shape_img {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.s_pricing-item .s_price_icon {
  width: 110px;
  height: 110px;
  text-align: center;
  line-height: 110px;
  background: #f0f2f8;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.s_pricing-item .s_price_icon i:before {
  font-size: 48px;
}

.s_pricing-item .s_price_icon.p_icon1 {
  color: #7fcb11;
}

.s_pricing-item .s_price_icon.p_icon2 {
  color: #6754e2;
}

.s_pricing-item .s_price_icon.p_icon3 {
  color: #e92460;
}

.s_pricing-item p {
  color: #677294;
}

.s_pricing-item .price {
  color: #051441;
  padding: 24px 0px 40px;
  border-bottom: 1px solid #e9e9f4;
}

.s_pricing-item .price sub {
  color: #677294;
  bottom: 0;
}

.s_pricing-item ul li {
  line-height: 40px;
  color: #505975;
  font-weight: 300;
}

.s_pricing-item:hover {
  border-color: #f2f2f8;
  background-color: white;
  -webkit-box-shadow: 0px 30px 60px 0px rgba(0, 11, 40, 0.14);
  box-shadow: 0px 30px 60px 0px rgba(0, 11, 40, 0.14);
}

.s_pricing-item:hover .s_price_icon {
  background: #faf9fa;
}

/*=========== s_subscribe_area css ============*/
.s_subscribe_area {
  position: relative;
  z-index: 1;
  background: #f9fafd;
  padding: 120px 0px 130px;
  overflow: hidden;
}

.s_subscribe_area:before {
  content: "";
  width: 100%;
  height: 100%;
  background: url("../img/saas/map.png") no-repeat scroll center 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.s_subscribe_area .mchimp-errmessage, .s_subscribe_area .mchimp-sucmessage {
  text-align: center;
}

.right_shape, .bottom_shape {
  position: absolute;
  width: 700px;
  height: 600px;
}

.right_shape path, .bottom_shape path {
  opacity: 0.03;
}

.right_shape {
  right: -450px;
  top: -102px;
}

.bottom_shape {
  left: 130px;
  top: 280px;
}

.s_subcribes {
  max-width: 970px;
  margin: 0 auto;
  position: relative;
}

.s_subcribes .form-control {
  font: 300 16px/100px "Poppins", sans-serif;
  color: #9ea4b7;
  height: 100px;
  padding: 0px 0px 0px 50px;
  background-color: white;
  -webkit-box-shadow: 0px 30px 60px 0px rgba(8, 0, 63, 0.14);
  box-shadow: 0px 30px 60px 0px rgba(8, 0, 63, 0.14);
  border: 0px;
  border-radius: 0px;
  border-left-width: 10px;
  border-style: solid;
  z-index: 0;
}

.s_subcribes .form-control.placeholder {
  color: #9ea4b7;
}

.s_subcribes .form-control:-moz-placeholder {
  color: #9ea4b7;
}

.s_subcribes .form-control::-moz-placeholder {
  color: #9ea4b7;
}

.s_subcribes .form-control::-webkit-input-placeholder {
  color: #9ea4b7;
}

.s_subcribes .btn-submit {
  position: absolute;
  background: transparent;
  color: #6754e2;
  right: 40px;
  line-height: 100px;
  padding: 0px;
  font-size: 30px;
  z-index: 3;
}

/*================= pricing_area css ==============*/
.pricing_area {
  background: #fbfbfd;
}

.price_tab {
  border-radius: 45px;
  max-width: 400px;
  background-color: white;
  -webkit-box-shadow: 0px 3px 13px 0px rgba(0, 11, 40, 0.08);
  box-shadow: 0px 3px 13px 0px rgba(0, 11, 40, 0.08);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  border: 0px;
  padding: 7px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.price_tab .nav-item {
  padding: 0px;
  margin: 0px;
}

.price_tab .nav-item .nav-link {
  margin: 0px;
  font: 400 16px "Poppins", sans-serif;
  color: #677294;
  padding: 12px 30px;
  display: inline-block;
  border: 0px;
  border-radius: 45px;
  min-width: 193px;
  text-align: center;
  -webkit-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
  position: relative;
  z-index: 1;
}

.price_tab .nav-item .nav-link.active {
  border: 0px;
  border-radius: 45px;
  background: transparent;
  color: #fff;
}

.price_tab .hover_bg {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  height: calc(100% - 14px);
  border-radius: 45px;
  z-index: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.price_tab_two .hover_bg {
  background: #5f2eed;
}

.price_content .fade {
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.price_content .fade.show {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.price_content .price_item {
  text-align: center;
  background-color: white;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 4px 6px 0px rgba(0, 11, 40, 0.1);
  padding: 50px 50px 40px;
  border: 1px solid #fff;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
}

.price_content .price_item .tag {
  position: absolute;
  font: 400 14px "Poppins", sans-serif;
  color: #fff;
  background: #00aff0;
  padding: 19px 32px;
  top: -10px;
  left: -39px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.price_content .price_item .tag span {
  position: relative;
  top: 11px;
}

.price_content .price_item p {
  font-size: 16px;
  line-height: 22px;
  color: #677294;
  font-weight: 300;
  margin-bottom: 0px;
}

.price_content .price_item .price {
  line-height: 40px;
  border-bottom: 1px solid #e9e9f4;
  padding: 33px 0px 30px;
}

.price_content .price_item .price sub {
  color: #677294;
  bottom: 0;
}

.price_content .price_item .p_list {
  padding: 30px 0px 20px;
}

.price_content .price_item .p_list li {
  font: 300 16px/40px "Poppins", sans-serif;
  color: #505975;
}

.price_content .price_item .p_list li i {
  padding-right: 10px;
}

.price_content .price_item .p_list li .ti-check {
  color: #00aff0;
}

.price_content .price_item .p_list li .ti-close {
  color: #f0002d;
}

.price_content .price_item .price_btn {
  border-radius: 45px;
  color: #00aff0;
  border-color: #00aff0;
  padding: 0px 36px;
}

.price_content .price_item .price_btn:hover {
  color: #fff;
  background: #00aff0;
}

.price_content .price_item:hover {
  border-color: #c9ebfa;
  -webkit-box-shadow: 0px 30px 60px 0px rgba(0, 11, 40, 0.14);
  box-shadow: 0px 30px 60px 0px rgba(0, 11, 40, 0.14);
}

/*=================== pricing_area_two css ===============*/
.pricing_area_two {
  background: #f6f7fa;
}

.price_content_two .price_item .tag {
  background: #5f2eed;
}

.price_content_two .price_item .p_list li .ti-check {
  color: #5f2eed;
}

.price_content_two .price_item .price_btn {
  border-color: #5f2eed;
  color: #5f2eed;
  background: transparent;
}

.price_content_two .price_item .price_btn:hover {
  background: #5f2eed;
  color: #fff;
}

.price_content_two .price_item:hover {
  border-color: #dad1f8;
}

.price_content_three .price_item .tag {
  background: #5f51fb;
}

.price_content_three .price_item .p_list li .ti-check {
  color: #5f51fb;
}

.price_content_three .price_item .price_btn {
  border-color: #5f51fb;
  color: #5f51fb;
  background: transparent;
}

.price_content_three .price_item .price_btn:hover {
  background: #5f51fb;
  color: #fff;
}

.price_content_three .price_item:hover {
  border-color: #dfdcfe;
}

/*================== call_action_area css ================*/
.call_action_area {
  min-height: 900px;
  position: relative;
  background-image: -moz-linear-gradient(180deg, #1786d8 0%, #00aff0 100%);
  background-image: -webkit-linear-gradient(180deg, #1786d8 0%, #00aff0 100%);
  background-image: -ms-linear-gradient(180deg, #1786d8 0%, #00aff0 100%);
  z-index: 1;
  padding-top: 250px;
  position: relative;
  overflow: hidden;
}

.call_action_area:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: url("../img/home2/action_bg.png") no-repeat scroll center 0;
}

.call_action_area .action_one {
  left: -240px;
  bottom: 0;
  height: 100%;
  z-index: -1;
}

.call_action_area .action_two {
  right: 0;
  background-position: 50% 50%;
  z-index: -1;
  top: 0;
}

.call_action_area .action_content {
  max-width: 570px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.call_action_area .action_content h2 {
  margin-bottom: 15px;
  color: #fff;
}

.call_action_area .action_content p {
  font-weight: 300;
  color: #fff;
}

.call_action_area .action_content .action_btn {
  min-width: 180px;
  line-height: 55px;
  border-radius: 45px;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 11, 40, 0.2);
  box-shadow: 0px 20px 40px 0px rgba(0, 11, 40, 0.2);
  background: #fff;
  color: #00aff0;
}

.call_action_area .action_content .action_btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*============= design_tab_area css ============*/
.design_tab_area {
  background: #fbfbfd;
}

.design_tab {
  border: 0px;
}

.design_tab .nav-item {
  width: 100%;
  margin-bottom: 30px;
}

.design_tab .nav-item .nav-link {
  background-color: white;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 3px 0px rgba(12, 0, 46, 0.04);
  border: 0px;
  border-radius: 0px;
  padding: 25px 50px;
  -webkit-transition: background 0.5s ease 0s;
  -o-transition: background 0.5s ease 0s;
  transition: background 0.5s ease 0s;
}

.design_tab .nav-item .nav-link h5 {
  font-size: 17px;
  line-height: 26px;
  font-weight: 500;
  color: #222d39;
  -webkit-transition: color 0.5s ease 0s;
  -o-transition: color 0.5s ease 0s;
  transition: color 0.5s ease 0s;
}

.design_tab .nav-item .nav-link p {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 300;
  color: #677294;
  -webkit-transition: color 0.5s ease 0s;
  -o-transition: color 0.5s ease 0s;
  transition: color 0.5s ease 0s;
}

.design_tab .nav-item .nav-link.active {
  background-color: #7444fd;
}

.design_tab .nav-item .nav-link.active h5, .design_tab .nav-item .nav-link.active p {
  color: #fff;
}

.tab-content .tab-pane .tab_img img {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  max-width: 100%;
}

.tab-content .tab-pane.show .tab_img img {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.tab-content .tab_img {
  margin-right: -160px;
  padding-left: 120px;
  max-width: 100%;
}

/*============= action_area_two css =============*/
.action_area_two {
  background: #f6f6fa;
  padding-bottom: 40px;
}

.action_content {
  margin-top: 25px;
}

.action_content .btn_three {
  margin-top: 40px;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
}

.action_content .btn_three:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.action_img {
  margin-right: -160px;
  margin-top: -50px;
}

.action_img img {
  max-width: 100%;
}

/*================= action area three css =================*/
.action_area_three {
  background-image: -moz-linear-gradient(0deg, #5e2ced 0%, #6c3cf4 100%);
  background-image: -webkit-linear-gradient(0deg, #5e2ced 0%, #6c3cf4 100%);
  background-image: -ms-linear-gradient(0deg, #5e2ced 0%, #6c3cf4 100%);
  background-image: linear-gradient(0deg, #5e2ced 0%, #6c3cf4 100%);
  position: relative;
  z-index: 1;
}

.action_area_three:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
}

.action_area_three .curved {
  position: absolute;
  width: 100%;
  height: 100px;
  left: 0;
  z-index: -1;
  background: url("../img/home4/action_shap.png") no-repeat scroll center top;
  top: 0;
  background-size: contain;
}

.action_area_three .action_content h2 {
  color: #fff;
}

.action_area_three .action_content .about_btn {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.action_area_three .action_content .about_btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #5e2ced;
  background: #fff;
}

.action_area_three .action_content .white_btn {
  color: #5e2ced;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fff;
  margin-right: 25px;
}

.action_area_three .action_content .white_btn:hover {
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  color: #fff;
  background: #5e2ced;
}

/*=========== payment_priceing_area css ===========*/
.payment_priceing_area {
  padding: 120px 0px 90px;
  position: relative;
  z-index: 1;
}

.payment_priceing_area:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url("../img/new/price_bg.png") no-repeat scroll center;
  position: absolute;
  z-index: -1;
}

.payment_priceing_area .payment_price_info {
  display: block;
  overflow: hidden;
  padding: 30px 0px;
}

.payment_priceing_area .sec_title h2 {
  font-size: 35px;
  font-weight: 500;
  line-height: 45px;
}

.payment_price_item {
  text-align: center;
  background: #fbfbfd;
  padding: 50px 40px 55px;
  border: 1px solid #f1f3f8;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  width: calc(100% / 3);
  float: left;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.payment_price_item:before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: -moz-linear-gradient(-140deg, #fdabe7 0%, #bd8ef2 36%, #7d71fd 100%);
  background-image: -webkit-linear-gradient(-140deg, #fdabe7 0%, #bd8ef2 36%, #7d71fd 100%);
  background-image: -ms-linear-gradient(-140deg, #fdabe7 0%, #bd8ef2 36%, #7d71fd 100%);
  -webkit-box-shadow: 0px 30px 60px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 30px 60px 0px rgba(0, 11, 40, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
  -webkit-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
}

.payment_price_item h2 {
  font-size: 40px;
  font-weight: 600;
  color: #051441;
}

.payment_price_item h6 {
  font-size: 18px;
  color: #051441;
  font-weight: 400;
  margin-bottom: 30px;
}

.payment_price_item p {
  line-height: 30px;
  margin-bottom: 50px;
}

.payment_price_item .payment_price_btn {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-radius: 45px;
  background: rgba(253, 83, 135, 0.1);
  display: inline-block;
  padding: 10px 42px;
  color: #fd5387;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.payment_price_item .payment_price_btn i {
  font-size: 12px;
  padding-left: 5px;
}

.payment_price_item:nth-child(3) .payment_price_btn {
  background: rgba(95, 81, 251, 0.1);
  color: #5f51fb;
}

.payment_price_item.center {
  border: 0px;
  border-radius: 4px;
  padding: 80px 40px 86px;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.payment_price_item.center:before {
  opacity: 1;
}

.payment_price_item.center h2, .payment_price_item.center h6, .payment_price_item.center p {
  color: #fff;
}

.payment_price_item.center .payment_price_btn {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.payment_price_item:hover {
  border-radius: 4px;
  border-color: #bd8ef2;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.payment_price_item:hover:before {
  opacity: 1;
}

.payment_price_item:hover h2, .payment_price_item:hover h6, .payment_price_item:hover p {
  color: #fff;
}

.payment_price_item:hover .payment_price_btn {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

/*============== priceing_area_four css ============*/
.priceing_area_four .sec_title {
  margin-bottom: 50px;
}

.priceing_area_four .sec_title h2 {
  margin-bottom: 0px;
}

.priceing_area_four .sec_title p {
  font-style: italic;
}

.price_info_two {
  -webkit-box-shadow: 0px 4px 5px 0px rgba(12, 0, 46, 0.05);
  box-shadow: 0px 4px 5px 0px rgba(12, 0, 46, 0.05);
}

.price_info_two .price_head {
  display: table;
  width: 100%;
}

.price_info_two .price_head .p_head {
  width: calc(100% / 4);
  text-align: center;
  color: #fff;
  vertical-align: middle;
  display: table-cell;
  padding: 31px 0px;
}

.price_info_two .price_head .p_head h4 {
  color: #222d39;
  font-size: 20px;
}

.price_info_two .price_head .p_head h4, .price_info_two .price_head .p_head h5 {
  margin-bottom: 0px;
}

.price_info_two .price_head .p_head p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}

.price_info_two .price_head .p_head:nth-child(2) {
  background: #0ed1b3;
}

.price_info_two .price_head .p_head:nth-child(3) {
  background: #6c84ee;
}

.price_info_two .price_head .p_head:nth-child(4) {
  background: #ee6cda;
}

.price_info_two .price_head .p_head h5 {
  font-size: 20px;
  font-weight: 600;
}

.price_info_two .price_item {
  width: calc(100% / 4);
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  cursor: pointer;
  padding: 15px 0px;
}

.price_info_two .price_item:nth-child(odd) {
  background: #f8f8fa;
}

.price_info_two .price_item:first-child {
  text-align: left;
  padding-left: 30px;
}

.price_info_two .price_item h5 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #222d39;
}

.price_info_two .price_item h5 i {
  font-size: 20px;
}

.price_info_two .price_item .pr_title {
  position: relative;
  display: inline-block;
  padding-left: 30px;
}

.price_info_two .price_item .pr_title:before {
  content: "\70";
  position: absolute;
  left: 0;
  top: 0;
  font-family: eleganticons;
  color: #afb5c7;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.price_info_two .price_item .pr_title:hover:before {
  color: #5e2ced;
}

.price_info_two .price_item .check {
  color: #0ed1b3;
}

.price_info_two .price_item .cros {
  color: #afb5c7;
}

.price_info_two .pr_list {
  display: table;
  width: 100%;
}

.price_info_two .price_btn {
  border-radius: 45px;
  color: #677294;
  border-color: #dfe3ed;
  padding: 0px 32px;
  line-height: 46px;
  margin: 40px 0px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.price_info_two .price_btn:hover {
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.tooltip .tooltip-inner {
  background-color: #fff;
  opacity: 1;
  color: #111;
  opacity: 1;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.14);
  box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.14);
  font-size: "Poppins", sans-serif;
  font-size: 16px;
  color: #677294;
  line-height: 26px;
  width: 100%;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #fff;
}

/*====================================================*/
/*====================================================*/
/*============= agency_about_area css ============*/
.agency_about_area .slick-dots {
  position: absolute;
  left: -48%;
  bottom: 10px;
  text-align: left;
}

.agency_about_area .slick-dots li button {
  width: 8px;
  height: 8px;
  display: block;
  background: #9c7bfb;
  border-radius: 50%;
  border: 1px solid #9c7bfb;
  padding: 0px;
  margin: 0px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.agency_about_area .slick-dots li.slick-active button {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  border-color: rgba(251, 251, 253, 0.8);
  background: transparent;
}

.about_content_left {
  padding: 0px 0px 0px 200px;
}

.about_content {
  background-image: -moz-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  height: 100%;
  color: #fff;
  padding: 100px;
  position: relative;
  bottom: -30px;
}

.about_content h2, .about_content p {
  color: #fff;
}

.about_btn {
  font: 500 14px "Poppins", sans-serif;
  padding: 17px 28px;
  background: #fbfbfd;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  border-radius: 3px;
  color: #5e2ced;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.about_btn:hover {
  color: #fff;
  background: #5e2ced;
}

.about_img {
  padding: 0px;
  position: relative;
}

.about_img .about_img_slider .about_item {
  float: left;
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
}

.about_img .about_img_slider .about_item:after {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(17, 22, 28, 0.7);
  position: absolute;
  opacity: 0;
  z-index: 0;
  -webkit-transition: opacity 0.4s linear 0s;
  -o-transition: opacity 0.4s linear 0s;
  transition: opacity 0.4s linear 0s;
}

.about_img .about_img_slider .about_item img {
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.about_img .about_img_slider .about_item.w55 {
  width: 53.3%;
}

.about_img .about_img_slider .about_item.w45 {
  width: 46.7%;
}

.about_img .about_img_slider .about_item img {
  width: 100%;
  max-width: 100%;
}

.about_img .about_img_slider .about_item .about_text {
  position: absolute;
  bottom: 80px;
  padding: 0px 80px 0px 50px;
  left: 0;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.about_img .about_img_slider .about_item .about_text h5 {
  color: #fff;
}

.about_img .about_img_slider .about_item .about_text .br {
  width: 50px;
  height: 5px;
  background: #fff;
  opacity: 0.30;
  margin-bottom: 25px;
  display: block;
}

.about_img .about_img_slider .about_item .about_text.text_two {
  bottom: 30px;
}

.about_img .about_img_slider .about_item:hover:after {
  opacity: 1;
}

.about_img .about_img_slider .about_item:hover img {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}

.about_img .about_img_slider .about_item:hover .about_text {
  bottom: 90px;
}

.about_img .about_img_slider .about_item:hover .about_text.text_two {
  bottom: 50px;
}

.about_img .pluse_icon {
  width: 100px;
  height: 100px;
  text-align: center;
  color: #5e2ced;
  line-height: 100px;
  font-size: 22px;
  background-color: #fbfbfd;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.14);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.14);
  display: inline-block;
  position: absolute;
  bottom: -60px;
  left: 0;
  z-index: 2;
}

/*====================================================*/
/*====================================================*/
/*=============== agency_testimonial_area css ==============*/
.agency_testimonial_info {
  position: relative;
}

.agency_testimonial_info .testimonial_slider {
  max-width: 770px;
  border-radius: 6px;
  margin: 0 auto;
  background-color: white;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.06);
  padding: 50px;
}

.agency_testimonial_info .testimonial_slider .testimonial_item {
  z-index: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.agency_testimonial_info .testimonial_slider .testimonial_item .author_img img {
  max-width: 100%;
  width: auto;
  display: inline-block;
  border-radius: 50%;
}

.agency_testimonial_info .testimonial_slider .testimonial_item .author_description {
  margin-top: 18px;
  margin-bottom: 40px;
}

.agency_testimonial_info .testimonial_slider .testimonial_item .author_description h4 {
  margin-bottom: 5px;
}

.agency_testimonial_info .testimonial_slider .testimonial_item .author_description h6 {
  font: 400 14px/26px "Poppins", sans-serif;
  color: #959cb1;
}

.agency_testimonial_info .testimonial_slider .testimonial_item p {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 50px;
}

.agency_testimonial_info .testimonial_slider .active {
  position: relative;
  z-index: 9;
}

.agency_testimonial_info .testimonial_slider .owl-dots {
  text-align: center;
  bottom: 0px;
  position: relative;
  margin-top: 0px;
}

.agency_testimonial_info .testimonial_slider .owl-dots .owl-dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid transparent;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin: 0px 2px;
  display: inline-flex;
  align-items: center;
}

.agency_testimonial_info .testimonial_slider .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  background: #cfcfe7;
  border-radius: 50%;
  margin: 0 auto;
  border: 0px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.agency_testimonial_info .testimonial_slider .owl-dots .owl-dot.active {
  border-color: #5e2ced;
}

.agency_testimonial_info .testimonial_slider .owl-dots .owl-dot.active span {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.agency_testimonial_info .slick-prev, .agency_testimonial_info .slick-next {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  border: 1px solid #f3f5f8;
  background-color: white;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.06);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  text-align: center;
}
.agency_testimonial_info .slick-prev{
  left: -150px;
}
.agency_testimonial_info .slick-next:before{
  content: "\e649";
  font-family: 'themify';
  color: #222d39;
  line-height: 50px;
  font-size: 15px;
}
.agency_testimonial_info .slick-prev:before{
  content: "\e64a";
  font-family: 'themify';
  color: #222d39;
  line-height: 50px;
  font-size: 15px;
}


.agency_testimonial_info .slick-prev:hover, .agency_testimonial_info .slick-next:hover {
  background: #5e2ced;
}
.agency_testimonial_info .slick-prev:hover:before, .agency_testimonial_info .slick-next:hover:before {
  color: #fff;
}

.agency_testimonial_info .slick-next {
  right: -150px;
}
.agency_testimonial_info .slick-dots{
  left: 0;
  bottom: 35px;
  margin: 0px;
}
.agency_testimonial_info .slick-dots li{
  margin: 0px;
}
.agency_testimonial_info .slick-dots li button{
  background: #cfcfe7;
  border-color: #cfcfe7;
}
/*============= partner_logo_area_two css ==============*/
.partner_logo_area_two {
  padding-top: 120px;
}

.partner_logo_area_two h4 {
  color: #8891aa;
}

.partner_info {
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.partner_info .logo_item {
  width: calc(100% / 5);
  margin-bottom: 60px;
}

.partner_info .logo_item img {
  max-width: 100%;
  -webkit-filter: contrast(0.3%);
  filter: contrast(0.3%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.partner_info .logo_item:hover img {
  -webkit-filter: contrast(100%);
  filter: contrast(100%);
}

.subscribe_form_info {
  border-style: solid;
  border-width: 1px;
  border-color: #f3f6f9;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 30px 50px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 30px 50px 0px rgba(12, 0, 46, 0.1);
  padding: 120px 0px;
  margin-bottom: -150px;
  z-index: 2;
  position: relative;
}

.subscribe_form_info .subscribe-form {
  max-width: 370px;
  margin: 0 auto;
}

.subscribe_form_info .subscribe-form .form-control {
  text-align: center;
  border: 0px;
  font: 400 18px/28px "Poppins", sans-serif;
  color: #b4bacc;
  border-radius: 0px;
  border-bottom: 1px solid #3d64f4;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.subscribe_form_info .subscribe-form .form-control.placeholder {
  color: #b4bacc;
}

.subscribe_form_info .subscribe-form .form-control:-moz-placeholder {
  color: #b4bacc;
}

.subscribe_form_info .subscribe-form .form-control::-moz-placeholder {
  color: #b4bacc;
}

.subscribe_form_info .subscribe-form .form-control::-webkit-input-placeholder {
  color: #b4bacc;
}

.subscribe_form_info .subscribe-form .btn_four {
  padding: 16px 44px;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
  box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
}

.subscribe_form_info .subscribe-form .btn_four:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.s_form_info_two .subscribe-form .form-control {
  border-color: #5e2ced;
}

.s_form_info_two .subscribe-form .btn_four {
  border-radius: 4px;
  border-color: #5e2ced;
  background: #5e2ced;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
  box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
}

.s_form_info_two .subscribe-form .btn_four:hover {
  color: #fff;
}

.saas_subscribe_area_two .subscribe_form_info {
  padding: 80px 0px;
}

.saas_subscribe_area_two .subscribe_form_info .form-control {
  border-color: #5f51fb;
}

.saas_subscribe_area_two .subscribe_form_info .btn_four {
  border-color: #5f51fb;
  background: #5f51fd;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
  box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
}

.saas_subscribe_area_two .subscribe_form_info .btn_four:hover {
  color: #fff;
}

/*=============== partner_logo_area_three css ================*/
.partner_logo_area_three {
  padding: 100px 0px;
}

.partner_logo_area_three .partner_info {
  margin-bottom: -20px;
}

.partner_logo_area_three .partner_info .logo_item {
  margin-bottom: 20px;
}

.partner_logo_area_three .partner_info .logo_item a {
  display: inline-block;
}

.partner_logo_area_three .partner_info .logo_item img {
  -webkit-filter: contrast(100%);
  filter: contrast(100%);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.partner_logo_area_three .partner_info .logo_item:hover img {
  -webkit-filter: brightness(200%);
  filter: brightness(200%);
}

.partner_logo_area_three .partner_info_two .logo_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.partner_logo_area_three .partner_info_two .logo_item:hover img {
  -webkit-filter: brightness(1);
  filter: brightness(1);
}

.partner_logo_area_four {
  padding-top: 80px;
  margin-bottom: -40px;
}

.partner_logo_area_four h4 {
  color: #8891aa;
}

.partner_logo_area_five {
  padding: 200px 0px 70px;
}

.payment_logo_area {
  padding: 200px 0px 120px;
}

.payment_logo_area .sec_title h2 {
  font-size: 35px;
  line-height: 44px;
}

.partner_logo_area_six {
  padding: 56px 0px;
}

.partner_logo_area_six .partner_info {
  margin-bottom: -60px;
}

.partner_logo_area_six .partner_info .logo_item:hover img {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

/*============== saas_signup_area css ==============*/
.saas_signup_form .input-group .form-control {
  background: transparent;
  font: 300 14px "Poppins", sans-serif;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 0px;
  border: 0px;
  border-bottom: 1px solid #3c3c5b;
  padding-left: 0px;
  padding-bottom: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 0;
  position: relative;
}

.saas_signup_form .input-group .form-control.placeholder {
  color: #535473;
}

.saas_signup_form .input-group .form-control:-moz-placeholder {
  color: #535473;
}

.saas_signup_form .input-group .form-control::-moz-placeholder {
  color: #535473;
}

.saas_signup_form .input-group .form-control::-webkit-input-placeholder {
  color: #535473;
}

.saas_signup_form .input-group .form-control:focus + label {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.saas_signup_form .input-group label {
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #3d57f4;
  z-index: 2;
  bottom: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  margin-bottom: 0;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.saas_signup_form .saas_banner_btn {
  border: 0px;
  padding: 12px 48px;
}

/*============= fun_fact_area  css ==============*/
.fun_fact_area {
  padding-top: 150px;
  padding-bottom: 110px;
  overflow: hidden;
}

.fun_fact_area_two {
  background: #f7f6fa;
}

.fun_fact_content h1 {
  font-size: 60px;
}
.fun_fact_content .fact_item + .fact_item{
  padding-left: 100px;
}
.fun_fact_content .fact_item h1 {
  font-size: 70px;
  font-weight: 400;
}

.fact_author_img {
  position: relative;
}

.fact_author_img .box_three {
  width: 630px;
  height: 630px;
  background-image: -moz-linear-gradient(90deg, #290a59 0%, #3d57f4 100%);
  background-image: -webkit-linear-gradient(90deg, #290a59 0%, #3d57f4 100%);
  background-image: -ms-linear-gradient(90deg, #290a59 0%, #3d57f4 100%);
  background-image: linear-gradient(90deg, #290a59 0%, #3d57f4 100%);
  opacity: 1;
  top: -460px;
}

.fact_author_img .box_four {
  background-image: -moz-linear-gradient(90deg, #411881 0%, #9b2bdc 100%);
  background-image: -webkit-linear-gradient(90deg, #411881 0%, #9b2bdc 100%);
  background-image: -ms-linear-gradient(90deg, #411881 0%, #9b2bdc 100%);
  background-image: linear-gradient(90deg, #411881 0%, #9b2bdc 100%);
  opacity: 1;
  width: 708px;
  height: 708px;
  top: -460px;
  left: 150px;
}

.fact_author_img img {
  margin-top: -70px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.fact_author_img_two .box_three {
  background-image: -moz-linear-gradient(-90deg, #fb862f 0%, #c640fd 100%);
  background-image: -webkit-linear-gradient(-90deg, #fb862f 0%, #c640fd 100%);
  background-image: -ms-linear-gradient(-90deg, #fb862f 0%, #c640fd 100%);
  background-image: linear-gradient(-90deg, #fb862f 0%, #c640fd 100%);
}

.fact_author_img_two .box_four {
  background-image: -moz-linear-gradient(90deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(90deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(90deg, #5e2ced 0%, #a485fd 100%);
  background-image: linear-gradient(90deg, #5e2ced 0%, #a485fd 100%);
}

/*============ feedback_area css ==============*/
.feedback_slider .owl-nav {
  display: none;
}

.feedback_slider .item {
  padding: 0px 15px;
}

.feedback_slider .shap_one, .feedback_slider .shap_two {
  position: absolute;
  bottom: 0;
  right: 20px;
  opacity: 0.302;
  border-radius: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 66px;
  height: 66px;
  bottom: -35px;
  opacity: 0.302;
}

.feedback_slider .shap_one {
  background-image: -moz-linear-gradient(40deg, #290a59 0%, #3d57f4 100%);
  background-image: -webkit-linear-gradient(40deg, #290a59 0%, #3d57f4 100%);
  background-image: -ms-linear-gradient(40deg, #290a59 0%, #3d57f4 100%);
}

.feedback_slider .shap_two {
  background-image: -moz-linear-gradient(40deg, #290a59 0%, #3d57f4 100%);
  background-image: -webkit-linear-gradient(40deg, #290a59 0%, #3d57f4 100%);
  background-image: -ms-linear-gradient(40deg, #290a59 0%, #3d57f4 100%);
  right: -10px;
  bottom: -45px;
}

.feedback_slider .slick-center .feedback_item {
  border-color: #3d57f4;
}

.feedback_slider .slick-center .feedback_item .shap_one, .feedback_slider .slick-center .feedback_item .shap_two {
  opacity: 1;
}

.feedback_item {
  border: 1px solid #131430;
  border-radius: 4px;
  background-color: #0f1029;
  -webkit-box-shadow: 0px 30px 50px 0px rgba(12, 0, 46, 0.3);
  box-shadow: 0px 30px 50px 0px rgba(12, 0, 46, 0.3);
  padding: 45px;
  position: relative;
  overflow: hidden;
}

.feedback_item .feed_back_author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.feedback_item .media .img {
  border: 1px solid #44467b;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-right: 18px;
}

.feedback_item .media .img img {
  max-width: 100%;
  width: auto;
}

.feedback_item .media .media-body {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.feedback_item .media .media-body h6 {
  margin-bottom: 0px;
  font-size: 14px;
  color: #58596e;
}

.feedback_item p {
  font-weight: 300;
  margin-top: 34px;
  margin-bottom: 25px;
}

.feedback_item .post_date {
  font-size: 14px;
  line-height: 24px;
  color: #58596e;
  font-style: italic;
  font-weight: 300;
  position: relative;
}

.feedback_item .post_date:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #58596e;
  position: absolute;
  left: 0;
  bottom: 0;
}

.feedback_item .ratting {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
}

.feedback_item .ratting a {
  font-size: 12px;
  color: #58596e;
}

.feedback_item .ratting a:nth-child(1), .feedback_item .ratting a:nth-child(2), .feedback_item .ratting a:nth-child(3) {
  color: #3d57f4;
}

.feedback_slider .slick-dots{
  position: relative;
  bottom: 0;
}

.slick-dots {
  text-align: center;
  margin-top: 75px;
}
.slick-dots li{
  width:auto;
  height: auto;
}
.slick-dots li button{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #3d3e65;
  margin: 0px 5px;
  padding: 4px;
  border: 1px solid #3d3e65;
  display: block;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.slick-dots li button:before{
  display: none;
}

.slick-dots li.slick-active button{
  background: transparent;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  border-color: #3d57f4;
}

.owl-dots .owl-dot:focus {
  outline: none;
}

.feedback_area_two {
  background-image: -moz-linear-gradient(30deg, #5f51fb 0%, #8ca7ff 100%);
  background-image: -webkit-linear-gradient(30deg, #5f51fb 0%, #8ca7ff 100%);
  background-image: -ms-linear-gradient(30deg, #5f51fb 0%, #8ca7ff 100%);
  height: 550px;
  position: relative;
  z-index: 1;
}

.feedback_area_two:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url("../img/new/feedback_shap.png") no-repeat scroll center 0;
  position: absolute;
  z-index: -1;
}

.feedback_area_two .sec_title {
  padding-left: 162px;
}

.feedback_area_two .sec_title h2 {
  font-size: 35px;
  line-height: 45px;
}

.feedback_area_two .sec_title p {
  color: #cfd0fa;
  font-style: italic;
}

.feedback_slider_two .item {
  padding-bottom: 50px;
}

.feedback_slider_two .shap_one, .feedback_slider_two .shap_two {
  opacity: 0.12;
  background-image: -moz-linear-gradient(40deg, #5f51fb 0%, #767cfd 64%, #8ca7ff 100%);
  background-image: -webkit-linear-gradient(40deg, #5f51fb 0%, #767cfd 64%, #8ca7ff 100%);
  background-image: -ms-linear-gradient(40deg, #5f51fb 0%, #767cfd 64%, #8ca7ff 100%);
}

.feedback_slider_two .center .feedback_item_two .shap_one, .feedback_slider_two .center .feedback_item_two .shap_two {
  opacity: 0.25;
}

.feedback_slider_two .owl-nav {
  display: block;
  position: absolute;
  right: 120px;
  top: -100px;
}

.feedback_slider_two .owl-nav i {
  width: 50px;
  height: 50px;
  text-align: center;
  border: 1px solid #fff;
  display: inline-block;
  color: #fff;
  line-height: 50px;
  font-size: 12px;
  border-radius: 50%;
  margin-left: 10px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.feedback_slider_two .owl-nav i:hover {
  background: #fff;
  color: #5f51fb;
}

.feedback_slider_two .feedback_item {
  background: #fff;
  -webkit-box-shadow: 0px 20px 60px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 60px 0px rgba(0, 11, 40, 0.1);
  border: 0px;
  margin: 0 20px;
}

.feedback_slider_two .feedback_item .media .img {
  border: 0px;
}

.feedback_slider_two .owl-dots {
  display: none;
}

.feedback_area_three {
  height: 540px;
}

.feedback_area_three .row {
  margin-left: -20px;
  margin-right: -20px;
}

.feedback_area_three .feedback_slider_two .feedback_item {
  margin: 0px 20px;
  -webkit-box-shadow: 0px 30px 40px 0px rgba(0, 11, 40, 0.08);
  box-shadow: 0px 30px 40px 0px rgba(0, 11, 40, 0.08);
}

.feedback_area_three .feedback_slider_two .feedback_item .ratting a:nth-child(1), .feedback_area_three .feedback_slider_two .feedback_item .ratting a:nth-child(2), .feedback_area_three .feedback_slider_two .feedback_item .ratting a:nth-child(3) {
  color: #fbc509;
}

.feedback_area_three .feedback_slider_two .feedback_item p {
  line-height: 28px;
}

.feedback_area_three .feedback_slider_two .owl-nav {
  right: 15px;
}

.feedback_area_three .feedback_slider_two .owl-nav i {
  border-color: #dfe3ed;
  color: #677294;
}

.feedback_area_three .feedback_slider_two .owl-nav i:hover {
  border-color: #5f51fb;
  background: #5f51fb;
  color: #fff;
}

/*=============== app_testimonial_area css =============*/
.app_testimonial_area {
  background: #fbfbfd;
  padding-top: 200px;
  padding-bottom: 140px;
  overflow: hidden;
  position: relative;
}

.app_testimonial_area .text_shadow {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.app_testimonial_area .text_shadow:before {
  content: attr(data-line);
  position: absolute;
  left: 0px;
  width: 100%;
  text-align: center;
  color: #f6f6fa;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  font-size: 200px;
  line-height: 200px;
  z-index: -1;
  text-transform: uppercase;
  top: 50%;
  opacity: 0.9;
}

.app_testimonial_slider {
  max-width: 690px;
  margin: 0 auto;
}

.nav_container {
  position: relative;
  z-index: 1;
}

.nav_container .prev, .nav_container .next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  -webkit-box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.06);
  font-size: 20px;
  color: #222d39;
  text-align: center;
  border: 0px;
  line-height: 50px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
  z-index: 1;
}

.nav_container .prev:hover, .nav_container .next:hover {
  background: #4069eb;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.nav_container .prev {
  left: 15px;
}

.nav_container .next {
  right: 15px;
}

.nav_container .slick-dots {
  margin-top: 30px;
}

.nav_container .slick-dots li button {
  background: #cfcfe7;
  border: 0px;
}

.nav_container .slick-dots li.slick-active button{
  background: #653cee;
}

.shap {
  position: absolute;
  opacity: 0.02;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 45px;
  left: 90px;
  z-index: -1;
}

.shap.one {
  background-image: -moz-linear-gradient(140deg, #7d0df0 0%, #0cb6e7 100%);
  background-image: -webkit-linear-gradient(140deg, #7d0df0 0%, #0cb6e7 100%);
  background-image: -ms-linear-gradient(140deg, #7d0df0 0%, #0cb6e7 100%);
  width: 650px;
  height: 510px;
  top: -155%;
}

.shap.two {
  background-image: -moz-linear-gradient(140deg, #7d0df0 0%, #0cb6e7 100%);
  background-image: -webkit-linear-gradient(140deg, #7d0df0 0%, #0cb6e7 100%);
  background-image: -ms-linear-gradient(140deg, #7d0df0 0%, #0cb6e7 100%);
  width: 666px;
  height: 330px;
  top: -145%;
  left: 290px;
}

.app_testimonial_item .author-img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto 15px;
}

.app_testimonial_item .author-img img {
  width: auto;
  border-radius: 100%;
  max-width: 100%;
}

.app_testimonial_item .author_info {
  margin-bottom: 35px;
}

.app_testimonial_item .author_info p {
  color: #959cb1;
  font-size: 14px;
  margin-bottom: 0px;
}

/*===========experts_team_area css ==========*/
.experts_team_area .learn_btn:hover {
  color: #4069eb;
}

.experts_team_area .learn_btn:hover:before {
  background: #4069eb;
}

.ex_team_item {
  position: relative;
  text-align: center;
  margin-bottom: 40px;
  overflow: hidden;
  cursor: pointer;
}

.ex_team_item img {
  max-width: 100%;
}

.ex_team_item .hover_content, .ex_team_item .team_content {
  position: absolute;
  width: 100%;
}

.ex_team_item h3 {
  margin-bottom: 3px;
}

.ex_team_item h5 {
  font: 300 14px/24px "Poppins", sans-serif;
  color: #677294;
  margin-bottom: 0px;
}

.ex_team_item .team_content {
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  padding: 17px 0px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.ex_team_item .hover_content {
  top: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 33px;
  background-image: -moz-linear-gradient(140deg, rgba(125, 13, 240, 0.9) 0%, rgba(58, 113, 235, 0.9) 100%);
  background-image: -webkit-linear-gradient(140deg, rgba(125, 13, 240, 0.9) 0%, rgba(58, 113, 235, 0.9) 100%);
  background-image: -ms-linear-gradient(140deg, rgba(125, 13, 240, 0.9) 0%, rgba(58, 113, 235, 0.9) 100%);
  background-image: linear-gradient(140deg, rgba(125, 13, 240, 0.9) 0%, rgba(58, 113, 235, 0.9) 100%);
  opacity: 0;
  visibility: visible;
  z-index: -1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.ex_team_item .hover_content .n_hover_content {
  width: 100%;
}

.ex_team_item .hover_content .n_hover_content ul {
  margin-bottom: 0px;
}

.ex_team_item .hover_content .n_hover_content ul li {
  display: inline-block;
}

.ex_team_item .hover_content .n_hover_content ul li a {
  font-size: 13px;
  color: #fff;
  margin: 0px 5px;
}

.ex_team_item .hover_content .n_hover_content ul li a:hover {
  color: #0cb6e7;
}

.ex_team_item .hover_content .n_hover_content .br {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  display: block;
  margin: 15px 0px 20px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
}

.ex_team_item .hover_content .n_hover_content h3 {
  -webkit-transform: translateY(12px);
  -ms-transform: translateY(12px);
  transform: translateY(12px);
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.ex_team_item .hover_content .n_hover_content h5 {
  color: #fff;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
}

.ex_team_item:hover .hover_content {
  opacity: 1;
  visibility: visible;
  z-index: 0;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.ex_team_item:hover .hover_content .br {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.ex_team_item:hover .hover_content h3, .ex_team_item:hover .hover_content h5 {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.ex_team_item:hover .team_content {
  opacity: 0;
  visibility: visible;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

/*=============== payment_clients_area  css =============*/
.payment_clients_area {
  background: #fafbff;
  padding: 130px 0px;
  position: relative;
}

.payment_clients_area .clients_bg_shape_top {
  position: absolute;
  width: 100%;
  height: 85px;
  top: -82px;
  left: 0;
  background: url("../img/home9/triangle_top.png") no-repeat;
}

.payment_clients_area .clients_bg_shape_right {
  width: 1600px;
  height: 1253px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 59px 0px rgba(91, 82, 254, 0.05);
  box-shadow: 0px 0px 59px 0px rgba(91, 82, 254, 0.05);
  position: absolute;
  right: -44.5%;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-radius: 150px;
  top: -375px;
}

.payment_clients_area .payment_clients_inner {
  position: relative;
}

.payment_clients_area .payment_clients_inner .clients_item {
  border-width: 5px;
  border-color: #f6f6fa;
  border-style: solid;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0px 1px 30px 0px rgba(55, 125, 162, 0.2);
  box-shadow: 0px 1px 30px 0px rgba(55, 125, 162, 0.2);
  position: absolute;
  text-align: center;
}

.payment_clients_area .payment_clients_inner .clients_item img {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.payment_clients_area .payment_clients_inner .clients_item.one {
  width: 120px;
  height: 120px;
  top: 170px;
}

.payment_clients_area .payment_clients_inner .clients_item.two {
  width: 180px;
  height: 180px;
  top: 325px;
  left: 150px;
}

.payment_clients_area .payment_clients_inner .clients_item.three {
  width: 128px;
  height: 128px;
  left: 245px;
}

.payment_clients_area .payment_clients_inner .clients_item.four {
  width: 142px;
  height: 142px;
  left: 450px;
  top: 400px;
}

.payment_clients_area .payment_clients_inner .clients_item.five {
  width: 110px;
  height: 110px;
  left: 621px;
  top: 260px;
}

.payment_clients_area .payment_clients_inner .clients_item.six {
  width: 100px;
  height: 100px;
  left: 425px;
  top: 180px;
}

.payment_clients_area .payment_clients_inner .clients_item.seven {
  width: 80px;
  height: 80px;
  left: 550px;
  top: 0px;
}

.payment_clients_area .payment_clients_inner .clients_item.eight {
  width: 95px;
  height: 95px;
  left: 745px;
  top: 35px;
}

/*============ payment_testimonial_area css =============*/
.payment_testimonial_area {
  background: #fafbff;
  padding-top: 150px;
}

.payment_testimonial_area .testimonial_img {
  margin-bottom: -82px;
  position: relative;
  z-index: 1;
}

.payment_testimonial_info .testimonial_content {
  padding-left: 80px;
  position: relative;
  margin-top: 100px;
}

.payment_testimonial_info .testimonial_content .icon {
  font-size: 300px;
  color: #ebeff9;
  position: absolute;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  left: -35px;
  top: 0;
  font-family: "Poppins", sans-serif;
  height: 133px;
}

.payment_testimonial_info .testimonial_content p {
  line-height: 34px;
  margin-bottom: 45px;
}

.payment_testimonial_info .testimonial_content .author_description {
  color: #6781a9;
  font-weight: 300;
  padding-top: 5px;
}

/*================ payment_action_area css ===================*/
.payment_action_area {
  padding: 225px 0px 145px;
  position: relative;
}

.payment_action_area .clients_bg_shape_bottom {
  position: absolute;
  width: 100%;
  height: 85px;
  top: 0px;
  left: 0;
  background: url("../img/home9/triangle_bottom.png") no-repeat;
  background-size: contain;
}

.payment_action_content .pay_icon {
  margin: 0 auto 50px;
}

.payment_action_content h2 {
  font-size: 26px;
}

.payment_action_content p {
  line-height: 30px;
  font-size: 18px;
  margin: 25px 0px 60px;
}

.payment_action_content .pay_btn {
  margin: 0px;
}

/*=========== sass_partner_logo_area css ==========*/
.partner_logo_area .partner_logo_area_four{
  margin-bottom: 0;
  padding-top: 0px;
  padding-bottom: 50px;
}
.sass_partner_logo_area, .saas_map_area, .saas_subscribe_area {
  background: #fbfcfe;
}

/*=========== saas_subscribe_area css ==========*/
.saas_subscribe_area .saas_action_content {
  max-width: 970px;
  margin: 0 auto;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 22px 95px 0px rgba(55, 168, 237, 0.1);
  box-shadow: 0px 22px 95px 0px rgba(55, 168, 237, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 60px;
  margin-bottom: -50px;
  position: relative;
  z-index: 2;
}

.saas_subscribe_area .saas_action_content .gr_btn {
  min-width: 200px;
  padding: 20px 0px;
  text-align: center;
  color: #fff;
}

.saas_subscribe_area .saas_action_content .gr_btn:before {
  opacity: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.saas_subscribe_area .saas_action_content .gr_btn:hover {
  color: #23b1fe;
}

.saas_subscribe_area .saas_action_content .gr_btn:hover:before {
  opacity: 1;
}

/*============ payment_subscribe_area css ===========*/
.payment_subscribe_info {
  border-radius: 6px;
  background-image: -moz-linear-gradient(50deg, #5f51fb 0%, #767cfd 64%, #8ca7ff 100%);
  background-image: -webkit-linear-gradient(50deg, #5f51fb 0%, #767cfd 64%, #8ca7ff 100%);
  background-image: -ms-linear-gradient(50deg, #5f51fb 0%, #767cfd 64%, #8ca7ff 100%);
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 60px;
  margin-bottom: -100px;
  position: relative;
}

.payment_subscribe_info .payment_subscribe_content {
  padding-top: 0px;
}

.payment_subscribe_info .payment_subscribe_content h2 {
  font: 600 26px "Poppins", sans-serif;
  color: #fff;
}

.payment_subscribe_info .payment_subscribe_content p {
  margin-bottom: 0;
  font-weight: 300;
  color: #fff;
}

.payment_subscribe_info .subscribe-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.payment_subscribe_info .subscribe-form .form-control {
  width: 430px;
  height: 60px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 45px;
  background: rgba(247, 248, 252, 0.059);
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  padding-left: 30px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.payment_subscribe_info .subscribe-form .form-control.placeholder {
  color: #d3d3f2;
}

.payment_subscribe_info .subscribe-form .form-control:-moz-placeholder {
  color: #d3d3f2;
}

.payment_subscribe_info .subscribe-form .form-control::-moz-placeholder {
  color: #d3d3f2;
}

.payment_subscribe_info .subscribe-form .form-control::-webkit-input-placeholder {
  color: #d3d3f2;
}

.payment_subscribe_info .subscribe-form .btn_four {
  background: #5f51fb;
  color: #fff;
  border: #5f51fd;
  margin-left: 20px;
}

.payment_subscribe_info .subscribe-form .btn_four:hover {
  background: #fff;
  border-color: #fff;
  color: #5f51fd;
}

/*========== testimonial_area_four css ==========*/
.testimonial_area_four {
  position: relative;
}

.testimonial_shap_img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.testimonial_title {
  position: relative;
  padding-bottom: 90px;
}

.testimonial_title h6 {
  font-size: 16px;
  color: #563bd1;
  font-family: "Poppins", sans-serif;
  font-style: italic;
}

.testimonial_title .prev, .testimonial_title .next {
  position: absolute;
  bottom: 0;
  width: 50px;
  height: 50px;
  border: 1px solid #e0e4ed;
  text-align: center;
  font-size: 14px;
  color: #677294;
  border-radius: 50%;
  line-height: 48px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  background: transparent;
}

.testimonial_title .prev:hover, .testimonial_title .next:hover {
  background: #563bd1;
  color: #fff;
}

.testimonial_title .next {
  left: 60px;
}

.stratup_testimonial_info {
  height: 576px;
  position: relative;
}

.stratup_testimonial_info:before {
  content: "";
  background: url("../img/new/testimonial_bg.png") no-repeat scroll center 0/contain;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.testimonial_slider_four {
  padding: 0px 70px;
  width: 100%;
}

.testimonial_slider_four .item {
  text-align: center;
  color: #fff;
}

.testimonial_slider_four .item .author_img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 35px;
}

.testimonial_slider_four .item img {
  width: auto;
}

.testimonial_slider_four .item p {
  font-size: 18px;
  line-height: 36px;
  font-style: italic;
  margin-bottom: 40px;
  color: #fff;
}

.testimonial_slider_four .item h5 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.testimonial_slider_four .item h6 {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;
  color: #fff;
}

.testimonial_area_five {
  position: relative;
}

.testimonial_area_five .stratup_testimonial_info:before {
  background: url("../img/new/testimonial_bg_two.png") no-repeat scroll center 0/contain;
}

.testimonial_area_five .testimonial_title h6 {
  color: #26c4e5;
}

.testimonial_area_five .testimonial_title .owl-next:hover, .testimonial_area_five .testimonial_title .owl-prev:hover {
  background: #26c4e5;
}

/*========== video_area css ============*/
.video_area {
  position: relative;
  z-index: 1;
}

.video_content {
  position: relative;
}

.video_leaf {
  position: absolute;
  left: 85px;
  top: -80px;
  background: url("../img/new/leaf.png") no-repeat scroll;
  z-index: -2;
}

.cup {
  position: absolute;
  right: 98px;
  bottom: 40px;
}

.video_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
  background: -webkit-linear-gradient(40deg, rgba(58, 43, 220, 0.9) 0%, rgba(23, 199, 189, 0.9) 100%), url("../img/new/video_bg.jpg") no-repeat;
  background: -o-linear-gradient(40deg, rgba(58, 43, 220, 0.9) 0%, rgba(23, 199, 189, 0.9) 100%), url("../img/new/video_bg.jpg") no-repeat;
  background: linear-gradient(50deg, rgba(58, 43, 220, 0.9) 0%, rgba(23, 199, 189, 0.9) 100%), url("../img/new/video_bg.jpg") no-repeat;
  background-size: cover;
  -webkit-box-shadow: 0px 50px 70px 0px rgba(0, 11, 40, 0.24);
  box-shadow: 0px 50px 70px 0px rgba(0, 11, 40, 0.24);
  height: 450px;
  max-width: 770px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.video_info h2 {
  position: absolute;
  top: 58%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 100px;
  opacity: 0.10;
  font-weight: 700;
  z-index: -1;
}

.video_icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: white;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(1, 16, 58, 0.14);
  box-shadow: 0px 20px 40px 0px rgba(1, 16, 58, 0.14);
  text-align: center;
  line-height: 85px;
  font-size: 30px;
  position: relative;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
}

.video_icon:before, .video_icon:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 0.2px solid #fff;
  border-radius: 50%;
  opacity: 0.10;
  position: absolute;
  left: 0;
}

.video_icon:before {
  -webkit-transform: scale(2.5);
  -ms-transform: scale(2.5);
  transform: scale(2.5);
}

.video_icon:after {
  -webkit-transform: scale(3.8);
  -ms-transform: scale(3.8);
  transform: scale(3.8);
}

.video_icon i {
  color: #17c7bd;
  text-shadow: 0px 6px 25px rgba(16, 147, 140, 0.9);
  position: relative;
}

.video_icon i:after {
  width: 300%;
  height: 300%;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0px 1px 15px 1px rgba(255, 255, 255, 0.5);
  content: "";
  -webkit-animation: pulse 2s infinite;
  -webkit-transform: scale(8);
  -ms-transform: scale(8);
  transform: scale(8);
  animation: pulse 2s infinite;
}

.video_icon:hover {
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
}

/*============ seo_fact_area css ===========*/
.seo_fact_area {
  background: #f9f7fd;
  position: relative;
}

.seo_fact_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.seo_fact_info:before {
  content: "";
  background: url("../img/seo/shap.png") no-repeat scroll center center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
}

.seo_fact_info .seo_fact_item {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(38, 59, 94, 0.1);
  box-shadow: 0px 20px 40px 0px rgba(38, 59, 94, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.seo_fact_info .seo_fact_item .counter {
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 15px;
}

.seo_fact_info .seo_fact_item .counter.one {
  color: #15b2ec;
}

.seo_fact_info .seo_fact_item .counter.two {
  color: #f5a416;
}

.seo_fact_info .seo_fact_item .counter.three {
  color: #00c99c;
}

.seo_fact_info .seo_fact_item .counter.four {
  color: #f12699;
}

.seo_fact_info .seo_fact_item p {
  margin-bottom: 0;
  color: #6a7695;
  font-size: 16px;
}

.seo_fact_info .seo_fact_item:nth-child(even) {
  margin-top: 135px;
}

.seo_fact_info .seo_fact_item:first-child {
  margin-top: 25px;
}

.seo_fact_info .seo_fact_item.last {
  margin-top: 120px;
}

.seo_call_to_action_area {
  background: #5e2ced;
}

.seo_call_action_text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.seo_call_action_text h2 {
  font-size: 40px;
  line-height: 54px;
  color: #fff;
  font-weight: 600;
}

.seo_call_action_text .about_btn {
  border: 1px solid #fff;
}

/*====================================================*/
/*====================================================*/
/*================= app_screenshot_area css =============*/
.app_screenshot_area {
  overflow: hidden;
}

.app_screenshot_area .app_screen_info {
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -10px;
}

.app_screenshot_area .app_screenshot_slider .item {
  padding: 10px 20px 20px;
}

.app_screenshot_area .app_screenshot_slider .item .screenshot_img {
  border: 1px solid #f4f4f9;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 16px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 6px 16px 0px rgba(12, 0, 46, 0.06);
}

.app_screenshot_area .app_screenshot_slider .item .screenshot_img img {
  max-width: 100%;
}

.app_screenshot_area .app_screenshot_slider .slick-dots {
  margin-top: 40px;
}

.app_screenshot_area .app_screenshot_slider .slick-dots li button {
  background: #cfcfe7;
  border: 0px;
  margin: 0px;
}

.app_screenshot_area .app_screenshot_slider .slick-dots li.slick-active button{
  background: #653cee;
}

.app_screenshot_area .app_screenshot_slider .owl-prev, .app_screenshot_area .app_screenshot_slider .owl-next {
  position: absolute;
  bottom: 0;
  width: 50px;
  height: 50px;
  border: 1px solid #d9e1ea;
  background: #fbfbfd;
  border-radius: 25px;
  font-size: 20px;
  line-height: 50px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.app_screenshot_area .app_screenshot_slider .owl-prev:hover, .app_screenshot_area .app_screenshot_slider .owl-next:hover {
  background: #4069eb;
  border-color: #4069eb;
  color: #fff;
}

.app_screenshot_area .app_screenshot_slider .owl-prev {
  left: calc(50% - 60px);
}

.app_screenshot_area .app_screenshot_slider .owl-next {
  right: calc(50% - 60px);
}

/*================= app_screenshot_area css =============*/
/*=============== get started area css ===============*/
.get_started_area {
  background-image: -moz-linear-gradient(140deg, #672dde 0%, #4069eb 100%);
  background-image: -webkit-linear-gradient(140deg, #672dde 0%, #4069eb 100%);
  background-image: -ms-linear-gradient(140deg, #672dde 0%, #4069eb 100%);
  background-image: linear-gradient(140deg, #672dde 0%, #4069eb 100%);
  padding: 120px 0px 80px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.get_started_area .shap {
  opacity: 0.03;
}

.get_started_area .shap.one {
  background: white;
  top: -290px;
  left: -50px;
}

.get_started_area .shap.two {
  background: white;
  top: -260px;
  left: 100px;
}

.get_started_area .shap.three {
  background: white;
  top: auto;
  left: 47%;
  bottom: -300px;
}

.get_started_area .shap.four {
  background: white;
  top: auto;
  left: 63%;
  bottom: -280px;
}

.get_content h3 {
  font-size: 24px;
  color: #fff;
}

.get_content h2, .get_content p {
  color: #fff;
}

.get_content .app_btn {
  font: 500 16px "Poppins", sans-serif;
  padding: 12px 34px;
  border-color: #fff;
  background: #fff;
  line-height: 27px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  min-width: 200px;
  text-align: center;
}

.get_content .app_btn.app_btn_one {
  color: #4069eb;
  vertical-align: middle;
}

.get_content .app_btn.app_btn_one img {
  padding-right: 12px;
}

.get_content .app_btn.app_btn_one:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.get_content .app_btn.app_btn_two {
  background: rgba(255, 255, 255, 0.1);
  vertical-align: middle;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-left: 20px;
}

.get_content .app_btn.app_btn_two i {
  font-size: 25px;
  padding-right: 10px;
  vertical-align: sub;
}

.get_content .app_btn.app_btn_two:hover {
  color: #4069eb;
  background: #fff;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
}

/*============ get_started_three css ============*/
.get_started_three .startup_tab_img .web_img, .get_started_three .startup_tab_img .phone_img {
  opacity: 1;
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
}

.get_started_three .get_btn_info {
  margin-top: 50px;
}

.get_started_three .get_btn_info .get_btn_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 110px;
  position: relative;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.get_started_three .get_btn_info .get_btn_item .get_icon {
  height: 100px;
  width: 100px;
  line-height: 100px;
  font-size: 45px;
  text-align: center;
  border-radius: 3px;
  background: #fff1f3;
  color: #ff738b;
  margin-right: 25px;
}

.get_started_three .get_btn_info .get_btn_item h3 {
  font-size: 20px;
  line-height: 34px;
  color: #051441;
  font-weight: 400;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.get_started_three .get_btn_info .get_btn_item .icon_two {
  background: #f1f5ff;
}

.get_started_three .get_btn_info .get_btn_item .icon_two .small {
  color: #46d779;
  font-size: 25px;
  position: relative;
  top: -25px;
  left: 6px;
  padding-top: 0;
}

.get_started_three .get_btn_info .get_btn_item .icon_two i {
  color: #73a1ff;
  padding-top: 30px;
  display: inline-block;
}

/*====================================================*/
/*====================================================*/
/*===================== job_listing_area css ======================*/
.job_listing_area .sec_title {
  max-width: 970px;
}

.job_listing_area .app_service_info {
  margin-bottom: -30px;
}

.job_listing {
  margin-top: 160px;
}

.job_listing .job_list_tab {
  border-bottom: 1px solid #e7e7f6;
}

.job_listing .job_list_tab .list_item_tab {
  font: 300 15px/26px "Poppins", sans-serif;
  color: #677294;
  padding: 0px 0px 10px;
  border: 0px;
  border-radius: 0px;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.job_listing .job_list_tab .list_item_tab:before {
  content: "";
  width: 0;
  height: 1px;
  background: #5f2eed;
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.job_listing .job_list_tab .list_item_tab.active, .job_listing .job_list_tab .list_item_tab:hover {
  background: transparent;
}

.job_listing .job_list_tab .list_item_tab.active:before {
  width: 100%;
  right: auto;
  left: 0;
}

.job_listing .job_list_tab .list_item_tab + .list_item_tab {
  margin-left: 55px;
}

.job_listing .listing_tab {
  margin-bottom: -30px;
  margin-top: 60px;
  overflow: hidden;
}

.job_listing .listing_tab .list_item {
  display: table;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 8px 0px rgba(12, 0, 46, 0.04);
  padding: 20px 45px 20px 20px;
  margin-bottom: 30px;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.job_listing .listing_tab .list_item figure {
  float: left;
  width: 70px;
  margin-bottom: 0px;
  margin-right: 20px;
}

.job_listing .listing_tab .list_item figure a, .job_listing .listing_tab .list_item figure img {
  float: left;
  width: 100%;
}

.job_listing .listing_tab .list_item .joblisting_text {
  display: table-cell;
  vertical-align: middle;
  border-left: 1px solid #eeebf7;
  width: 100%;
  padding-left: 25px;
}

.job_listing .listing_tab .list_item .joblisting_text .job_list_table {
  display: table;
  width: 100%;
}

.job_listing .listing_tab .list_item .joblisting_text .jobsearch-table-cell {
  display: table-cell;
  vertical-align: middle;
  width: 75%;
  line-height: 46px;
}

.job_listing .listing_tab .list_item .joblisting_text h4 a {
  font-size: 20px;
  margin-bottom: 5px;
  display: inline-block;
}

.job_listing .listing_tab .list_item .joblisting_text h4 a:hover {
  color: #5e2ced;
}

.job_listing .listing_tab .list_item .joblisting_text ul {
  margin-bottom: 0px;
}

.job_listing .listing_tab .list_item .joblisting_text ul li {
  float: left;
  font: 300 14px/15px "Poppins", sans-serif;
  color: #677294;
  padding: 0px 10px;
  border-left: 2px solid #aeb3c3;
}

.job_listing .listing_tab .list_item .joblisting_text ul li:first-child {
  padding-left: 0px;
  border: none;
}

.job_listing .listing_tab .list_item .joblisting_text ul li.p_color {
  color: #5e2ced;
}

.job_listing .listing_tab .list_item .joblisting_text ul li.g_color {
  color: #61bd17;
}

.job_listing .listing_tab .list_item .joblisting_text ul li.gold_color {
  color: #d7ad1e;
}

.job_listing .listing_tab .list_item .joblisting_text .jobsearch-job-userlist {
  float: right;
}

.job_listing .listing_tab .list_item .joblisting_text .jobsearch-job-userlist .apply_btn {
  font: 300 14px/36px "Poppins", sans-serif;
  color: #5c6789;
  border: 1px solid #e7e7f6;
  border-radius: 3px;
  display: inline-block;
  padding: 4px 22px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.job_listing .listing_tab .list_item .joblisting_text .jobsearch-job-userlist .apply_btn:hover {
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.job_listing .listing_tab .list_item .joblisting_text .jobsearch-job-userlist .like-btn {
  font-size: 18px;
  line-height: 46px;
  float: right;
  margin-left: 20px;
}

.job_listing .listing_tab .list_item .joblisting_text .jobsearch-job-userlist .like-btn a {
  display: inline-block;
  color: #d4d8e4;
}

.job_listing .listing_tab .list_item .joblisting_text .jobsearch-job-userlist .like-btn a:hover {
  color: #5e2ced;
}

.job_listing .listing_tab .list_item:hover {
  -webkit-box-shadow: 0px 25px 30px -8px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 25px 30px -8px rgba(12, 0, 46, 0.06);
}

.job_listing .pagination {
  display: block;
}

.job_listing .pagination .nav-links .page-numbers {
  width: 40px;
  height: 40px;
  border: 1px solid #e7e7f6;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  color: #677294;
  font-size: 16px;
  border-radius: 50%;
  background-color: #fbfbfd;
  margin: 0px 3px;
}

.job_listing .pagination .nav-links .page-numbers i {
  font-size: 14px;
}

.job_listing .pagination .nav-links .page-numbers.current {
  background: #5e2ced;
  color: #fff;
}

/*===================== job_listing_area css ======================*/
/*============== job_details_area css =============*/
.details_content .f_size_22 {
  font-size: 22px;
}

.details_content .btn_three {
  min-width: 150px;
  text-align: center;
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.details_content .btn_three:hover {
  color: #fff;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
  box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
}

.job_deatails_content {
  padding-bottom: 40px;
}

.job_deatails_content ul li {
  position: relative;
  padding-left: 36px;
  font-weight: 300;
  font-size: 15px;
  color: #677294;
  margin-bottom: 27px;
}

.job_deatails_content ul li i {
  position: absolute;
  left: 0;
  top: 5px;
  color: #5f2eed;
}

.job_deatails_content:nth-child(4) {
  border-bottom: 1px solid #eeebf6;
}

.job_info {
  border: 1px solid #f4f4f9;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  padding: 50px;
}

.job_info .info_head {
  border-bottom: 1px solid #eeebf6;
  padding-bottom: 18px;
}

.job_info .info_head i {
  font-size: 40px;
  color: #9979f7;
  text-shadow: 0px 14px 30px rgba(94, 44, 237, 0.4);
  margin-bottom: 35px;
  display: inline-block;
}

.job_info .info_item {
  position: relative;
  padding-left: 30px;
  margin-top: 25px;
}

.job_info .info_item i {
  color: #9979f7;
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 5px;
}

.job_info .info_item h6 {
  font-size: 18px;
  color: #222d39;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
}

.job_info .info_item p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 300;
  color: #677294;
}

/*========== job_apply_area css =========*/
.apply_form .form-group {
  margin-bottom: 30px;
}

.apply_form input[type="text"], .apply_form textarea, .apply_form .selectpickers {
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  font: 300 15px/60px "Poppins", sans-serif;
  color: #9ca3b9;
  width: 100%;
  height: 60px;
  padding-left: 30px;
  border: 1px solid #fff;
}

.apply_form input[type="text"]:focus, .apply_form textarea:focus, .apply_form .selectpickers:focus {
  -webkit-box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  border-color: #f2effc;
  outline: none;
}

.apply_form input[type="text"].placeholder, .apply_form textarea.placeholder, .apply_form .selectpickers.placeholder {
  color: #9ca3b9;
}

.apply_form input[type="text"]:-moz-placeholder, .apply_form textarea:-moz-placeholder, .apply_form .selectpickers:-moz-placeholder {
  color: #9ca3b9;
}

.apply_form input[type="text"]::-moz-placeholder, .apply_form textarea::-moz-placeholder, .apply_form .selectpickers::-moz-placeholder {
  color: #9ca3b9;
}

.apply_form input[type="text"]::-webkit-input-placeholder, .apply_form textarea::-webkit-input-placeholder, .apply_form .selectpickers::-webkit-input-placeholder {
  color: #9ca3b9;
}

.apply_form textarea {
  height: 180px;
}

.apply_form .selectpickers {
  width: 100%;
}

.apply_form .upload_box {
  width: 100%;
  border: 1px dotted #e7e3f2;
  font: 300 15px/26px "Poppins", sans-serif;
  color: #9ca3b9;
  padding: 20px 50px;
}

.apply_form .upload_box input[type="file"] {
  font: 300 15px/26px "Poppins", sans-serif;
  color: #9ca3b9;
  display: block;
  margin-top: 45px;
}

.apply_form .upload_box input[type="file"]:focus {
  outline: none;
}

.apply_form p {
  font: 300 15px/26px "Poppins", sans-serif;
  color: #677294;
}

.apply_form p a {
  color: #222d39;
}

.apply_form .btn_three {
  font-size: 14px;
  font-weight: 500;
  background: #5e2ced;
  padding: 14px 37px;
  border-radius: 3px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  margin-top: 20px;
}

.apply_form .btn_three:hover {
  color: #fff;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
  box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
}

/*====================================================*/
/*====================================================*/
/*============== faq area css =============*/
.faq_tab {
  border: 1px solid #f4f4f9;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  padding: 50px 40px;
}

.faq_tab .nav-tabs {
  border: 0px;
  margin-bottom: 0px;
  display: block;
}

.faq_tab .nav-item {
  margin: 0px;
}

.faq_tab .nav-item .nav-link {
  font: 400 16px/22px "Poppins", sans-serif;
  color: #677294;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
}

.faq_tab .nav-item .nav-link:before {
  content: "";
  width: 0;
  height: 1px;
  background: #5e2ced;
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  transition: width 0.2s linear;
}

.faq_tab .nav-item .nav-link.active {
  color: #5e2ced;
}

.faq_tab .nav-item .nav-link.active:before {
  width: 100%;
}

.faq_content .tab-pane .card {
  border: 0px;
  border-radius: 0px;
  background: transparent;
  border-bottom: 1px solid #e4e0ee;
}

.faq_content .tab-pane .card .card-header {
  padding: 0px;
  border: 0px;
  background: transparent;
}

.faq_content .tab-pane .card .card-header .btn {
  color: #5e2ced;
  display: block;
  width: 100%;
  text-align: left;
  font: 500 18px/26px "Poppins", sans-serif;
  padding: 20px 30px 20px 0px;
  position: relative;
  white-space: normal;
}

.faq_content .tab-pane .card .card-header .btn i {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
  font-size: 20px;
}

.faq_content .tab-pane .card .card-header .btn i + i {
  display: block;
}

.faq_content .tab-pane .card .card-header .btn.collapsed {
  color: #576370;
}

.faq_content .tab-pane .card .card-header .btn.collapsed i {
  display: block;
}

.faq_content .tab-pane .card .card-header .btn.collapsed i + i {
  display: none;
}

.faq_content .tab-pane .card .card-body {
  padding: 0px 0px 45px;
  font: 400 15px/26px "Poppins", sans-serif;
  color: #677294;
}

/*====================================================*/
/*====================================================*/
/*============= portfolio area css ============*/
.portfolio_area {
  overflow: hidden;
}

.portfolio_area .price_btn {
  border-color: #5f51fb;
  color: #5f51fb;
  background: transparent;
  margin-top: 50px;
  line-height: 48px;
  padding: 0px 30px;
}

.portfolio_area .price_btn:hover {
  background: #5f51fb;
  color: #fff;
}

.portfolio_filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.portfolio_filter .work_portfolio_item {
  font: 400 16px/22px "Poppins", sans-serif;
  color: #677294;
  display: inline-block;
  border-radius: 3px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  position: relative;
  cursor: pointer;
}

.portfolio_filter .work_portfolio_item:before {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  background: transparent;
  position: absolute;
  left: auto;
  right: 0;
  -webkit-transition: width 0.3s linear;
  -o-transition: width 0.3s linear;
  transition: width 0.3s linear;
}

.portfolio_filter .work_portfolio_item.active, .portfolio_filter .work_portfolio_item:hover {
  color: #5e2ced;
}

.portfolio_filter .work_portfolio_item.active:before, .portfolio_filter .work_portfolio_item:hover:before {
  width: 100%;
  background: #5e2ced;
  right: auto;
  left: 0;
}

.portfolio_filter .work_portfolio_item + .work_portfolio_item {
  margin-left: 60px;
}

.portfolio_filter_blue .work_portfolio_item.active, .portfolio_filter_blue .work_portfolio_item:hover {
  color: #26c4e5;
}

.portfolio_filter_blue .work_portfolio_item.active:before, .portfolio_filter_blue .work_portfolio_item:hover:before {
  width: 100%;
  background: #26c4e5;
}

.pr_btn {
  margin-top: 70px;
  background: transparent;
  color: #26c4e5;
  border-color: #26c4e5;
}

.pr_btn:hover {
  background: #26c4e5;
  color: #fff;
}

.portfolio_gallery .portfolio_item .portfolio_img {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.portfolio_gallery .portfolio_item .portfolio_img img {
  max-width: 100%;
  width: 100%;
}

.portfolio_gallery .portfolio_item .portfolio_img .img_rounded {
  border-radius: 6px;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(44, 44, 57, 0.7);
  opacity: 0;
  -webkit-transition: opacity 300ms linear;
  -o-transition: opacity 300ms linear;
  transition: opacity 300ms linear;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .img_popup {
  width: 50px;
  height: 50px;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  right: 50px;
  top: 50px;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .img_popup:hover {
  background: #fff;
  color: #282835;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .portfolio-description {
  bottom: 0;
  padding-left: 50px;
  padding-bottom: 48px;
  width: 100%;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .portfolio-description h3 {
  -webkit-transform: translateY(25px);
  -ms-transform: translateY(25px);
  transform: translateY(25px);
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  color: #fff;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .portfolio-description .links {
  overflow: hidden;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .portfolio-description .links a {
  -webkit-transform: translateY(25px);
  -ms-transform: translateY(25px);
  transform: translateY(25px);
  color: #fff;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .portfolio-description .links a:before {
  background: #fff;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content.h_content_two .img_popup {
  right: 30px;
  top: 30px;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content.h_content_two .portfolio-description {
  padding-left: 30px;
  padding-bottom: 30px;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content.h_content_two .portfolio-description .links a {
  font-size: 14px;
}

.portfolio_gallery .portfolio_item .portfolio_img:hover .hover_content {
  opacity: 1;
}

.portfolio_gallery .portfolio_item .portfolio_img:hover .hover_content .img_popup {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.portfolio_gallery .portfolio_item .portfolio_img:hover .hover_content .portfolio-description h3, .portfolio_gallery .portfolio_item .portfolio_img:hover .hover_content .portfolio-description a {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.portfolio-description .portfolio-title {
  overflow: hidden;
  display: inline-block;
}

.portfolio-description h3 {
  margin-top: 22px;
  margin-bottom: 0px;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  color: #282835;
}

.portfolio-description h3:hover {
  color: #5e2ced;
}

.portfolio-description .links {
  overflow: hidden;
}

.portfolio-description .links a {
  font-size: 15px;
  color: #677294;
  font-weight: 300;
  position: relative;
  display: inline-block;
  margin-right: 8px;
  -webkit-transition: color 0.01s linear;
  -o-transition: color 0.01s linear;
  transition: color 0.01s linear;
}

.portfolio-description .links a:before {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  background: #5e2ced;
  position: absolute;
  left: auto;
  right: 0;
  -webkit-transition: width 0.3s linear;
  -o-transition: width 0.3s linear;
  transition: width 0.3s linear;
}

.portfolio-description .links a:hover {
  color: #5e2ced;
}

.portfolio-description .links a:hover:before {
  width: 100%;
  right: auto;
  left: 0;
}

.portfolio_fullwidth_area {
  padding-top: 100px;
}

/*==============Start portfolio_details_area css ==============*/
.p_category_item .social_icon, .icon .social_icon {
  padding-top: 8px;
}

.p_category_item .social_icon a, .icon .social_icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  line-height: 43px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-right: 5px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #fff;
}

.p_category_item .social_icon a:nth-child(1), .icon .social_icon a:nth-child(1) {
  background: #4779de;
}

.p_category_item .social_icon a:nth-child(2), .icon .social_icon a:nth-child(2) {
  background: #e43e30;
}

.p_category_item .social_icon a:nth-child(3), .icon .social_icon a:nth-child(3) {
  background: #1da1f3;
}

.p_category_item .social_icon a:hover, .icon .social_icon a:hover {
  -webkit-box-shadow: 0px 5px 12px 2px rgba(0, 11, 40, 0.2);
  box-shadow: 0px 5px 12px 2px rgba(0, 11, 40, 0.2);
}

.portfolio_pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.portfolio_pagination .prev, .portfolio_pagination .next {
  font-size: 14px;
  font-weight: 300;
  color: #9ca3b9;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.portfolio_pagination .prev:hover, .portfolio_pagination .next:hover {
  color: #222d39;
}

.portfolio_pagination .prev i {
  padding-right: 8px;
}

.portfolio_pagination .next i {
  padding-left: 8px;
}

.pr_slider .owl-prev, .pr_slider .owl-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  background: transparent;
  border: 0px;
  background: transparent;
  padding: 0px;
  font-size: 24px;
  color: #677294;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  cursor: pointer;
}

.pr_slider .owl-prev:hover, .pr_slider .owl-next:hover {
  color: #5e2ced;
}

.pr_slider .owl-prev {
  left: 40px;
}

.pr_slider .owl-next {
  right: 40px;
}

.p_details_three .portfolio_category {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 50px;
}

.p_details_three .portfolio_category .p_category_item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.p_details_three .portfolio_pagination {
  margin-top: 190px;
}

.p_details_three .icon {
  margin-top: 120px;
}

/*==============End portfolio_details_area css ==============*/
/*====================================================*/
/*====================================================*/
.map_area {
  height: 552px;
  position: relative;
}

.map_area iframe {
  height: 100%;
  width: 100%;
  border: 0px;
  pointer-events: none;
  margin: 0px;
  padding: 0px;
}

.app_contact_info {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 600px;
  background-color: white;
  -webkit-box-shadow: 0px 40px 50px 0px rgba(12, 0, 46, 0.2);
  box-shadow: 0px 40px 50px 0px rgba(12, 0, 46, 0.2);
  padding: 45px 45px 15px;
}

.app_contact_info .triangle {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 55px;
  background: #fff;
  bottom: -7px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.app_contact_info .info_item {
  padding-left: 55px;
  position: relative;
  margin-bottom: 35px;
}

.app_contact_info .info_item i {
  position: absolute;
  left: 0;
  font-size: 30px;
  color: #5e2ced;
  background-image: -moz-linear-gradient(0deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(0deg, #5e2ced 0%, #a485fd 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  top: 0px;
  left: 0;
}

.app_contact_info .info_item h6 {
  color: #051441;
  margin-bottom: 5px;
}

.app_contact_info .info_item p, .app_contact_info .info_item a {
  color: #677294;
  line-height: 24px;
  margin-bottom: 0px;
}

.app_contact_info .info_item a:hover {
  color: #4069eb;
}

/*================== contact_info_area css ================*/
.contact_info_item {
  border-bottom: 1px solid #eeebf6;
  padding-bottom: 25px;
}

.contact_info_item p {
  color: #677294;
  margin-bottom: 8px;
}

.contact_info_item a {
  color: #677294;
  padding-left: 3px;
  display: inline-block;
}

.contact_info_item a:hover {
  color: #5e2ced;
}

.contact_info_item + .contact_info_item {
  border: 0px;
  margin-top: 33px;
}

.mapbox {
  height: 400px;
  -webkit-box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.05);
  box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.05);
  border: 10px solid #fff;
}

#mapBox {
  height: 100% !important;
}

.text_box {
  margin-bottom: 30px;
}

.text_box input[type="text"], .text_box textarea, .text_box input[type="password"], .text_box input[type="email"] {
  font: 400 15px/60px "Poppins", sans-serif;
  color: #222d39;
  height: 60px;
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  width: 100%;
  border: 1px solid #fff;
  padding-left: 30px;
}

.text_box input[type="text"].placeholder, .text_box textarea.placeholder, .text_box input[type="password"].placeholder, .text_box input[type="email"].placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]:-moz-placeholder, .text_box textarea:-moz-placeholder, .text_box input[type="password"]:-moz-placeholder, .text_box input[type="email"]:-moz-placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]::-moz-placeholder, .text_box textarea::-moz-placeholder, .text_box input[type="password"]::-moz-placeholder, .text_box input[type="email"]::-moz-placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]::-webkit-input-placeholder, .text_box textarea::-webkit-input-placeholder, .text_box input[type="password"]::-webkit-input-placeholder, .text_box input[type="email"]::-webkit-input-placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]:focus, .text_box textarea:focus, .text_box input[type="password"]:focus, .text_box input[type="email"]:focus {
  border-color: #f2effc;
  -webkit-box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  outline: none;
}

.text_box textarea {
  height: 220px;
}
.contact_form{
  padding-left: 40px;
}
.contact_form_box {
  overflow: hidden;
}
.contact_form_box .btn_three {
  min-width: 180px;
  text-align: center;
  background: #5e2ced;
  border-color: #5e2ced;
  margin-top: 0px;
  float: right;
}

.contact_form_box .btn_three:hover {
  background: transparent;
  color: #5e2ced;
}

.mapbox2 {
  height: 440px;
  border-top: 10px solid #fff;
}

/*============= login_area css =============*/
.login_area {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.login_area .login_info {
  padding: 160px 100px 160px 0px;
}

.login_area .login_info:before {
  content: "";
  position: absolute;
  width: 2500px;
  right: 0;
  height: 100%;
  background: #fbfbfb;
  top: 0;
  z-index: -1;
}

.login_area .login_info p a {
  color: #677294;
}

.login-form .text_box .text_c {
  color: #161341;
  font-size: 14px;
  margin-bottom: 15px;
}

.login-form .text_box input[type="password"] {
  padding-top: 8px;
  line-height: 50px;
}

.login-form .text_box input[type="password"]:focus {
  padding-top: 0px;
}

.login-form .extra {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font: 400 14px/30px "Poppins", sans-serif;
  margin-top: 45px;
}

.login-form .extra .checkbox label {
  margin-bottom: 0px;
  color: #161341;
}

.login-form .extra .checkbox input[type="checkbox"] {
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.login-form .extra .checkbox input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 0;
  border: 1px solid #7348ef;
  border-radius: 2px;
  background-color: white;
}

.login-form .extra .checkbox input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 4px;
  height: 8px;
  border: solid #7348ef;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0px;
  left: 3px;
}

.login-form .extra .forgotten-password a {
  color: #677294;
}

.login-form .btn_three {
  width: 100%;
  background: #5e2ced;
  color: #fff;
  margin-top: 40px;
}

.login-form .btn_three:hover {
  -webkit-box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
  box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
}

.login-form .alter-login {
  font: 400 15px/26px "Poppins", sans-serif;
  color: #9ca3b9;
}

.login-form .alter-login a {
  color: #5e2ced;
  display: inline-block;
  margin-left: 6px;
}

.login_img {
  padding-left: 100px;
  margin-right: -250px;
}

.login_img img {
  max-width: 100%;
}

/*==================== sign_in_area css ==================*/
.sign_info {
  border: 20px solid #fff;
  background: #fbfbfd;
  padding: 80px 50px;
  -webkit-box-shadow: 0px 50px 100px 0px rgba(12, 0, 46, 0.05);
  box-shadow: 0px 50px 100px 0px rgba(12, 0, 46, 0.05);
}

.sign_info .login_info {
  padding-left: 30px;
}

.sign_info .sign_info_content h2 {
  font-size: 30px;
  color: #677294;
}

.sign_info .sign_info_content ul li {
  font: 400 16px/34px "Poppins", sans-serif;
  color: #677294;
}

.sign_info .sign_info_content ul li i {
  color: #5e2ced;
  margin-right: 10px;
}

.sign_info .login-form .text_box {
  margin-bottom: 25px;
}

.sign_info .lead-text {
  font: 400 14px/40px "Poppins", sans-serif;
  color: #677294;
  padding-right: 20px;
}

.sign_info .social_tag li {
  display: inline-block;
}

.sign_info .social_tag li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  line-height: 44px;
  color: #fff;
  font-size: 13px;
  text-align: center;
  margin-left: 7px;
}

.sign_info .social_tag li:first-child a {
  background: #4779de;
}

.sign_info .social_tag li:nth-child(2) a {
  background: #e43e30;
}

.sign_info .social_tag li:nth-child(3) a {
  background: #1da1f3;
}

.sign_info .sign-in-form .btn_three {
  width: auto;
  margin-top: 0;
}

.sign_info .sign-in-form .extra .forgotten-password a {
  color: #161341;
  font-weight: 400;
}

.sign_info .sign-in-form .extra .forgotten-password a:hover {
  color: #5e2ced;
}

.sign_btn_transparent {
  display: inline-block;
  background: transparent;
  border-color: #5e2ced;
  color: #5e2ced;
}

.sign_btn_transparent:hover {
  background: #5e2ced;
  color: #fff;
}

/*============= get_quote_form css============*/
.get_quote_form .form-group {
  margin-bottom: 30px;
}

.get_quote_form .form-group .form-control {
  font: 300 15px "Poppins", sans-serif;
  color: #677294;
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  border: 1px solid transparent;
  height: 60px;
  padding: 0px 30px;
}

.get_quote_form .form-group .form-control.placeholder {
  color: #9ca3b9;
}

.get_quote_form .form-group .form-control:-moz-placeholder {
  color: #9ca3b9;
}

.get_quote_form .form-group .form-control::-moz-placeholder {
  color: #9ca3b9;
}

.get_quote_form .form-group .form-control::-webkit-input-placeholder {
  color: #9ca3b9;
}

.get_quote_form .form-group .form-control:focus {
  border-color: #f2effc;
  -webkit-box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
}

.get_quote_form .form-group textarea.form-control {
  height: 210px;
  padding-top: 15px;
}

.get_quote_form .btn_three {
  margin-top: 10px;
}

/*=========== comingsoon css ============*/
.saasland_container {
  height: 100vh;
  width: 100%;
  min-height: 600px;
  background: #fbfbfd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.saasland_container .saasland_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
}

.saasland_container .saasland_col_content {
  position: relative;
  width: 40%;
}

.saasland_container .saasland_col_content .container_wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 35px;
  z-index: 3;
  overflow: hidden;
  min-height: 600px;
}

.saasland_container .saasland_col_content .saasland_log {
  left: 21%;
  position: absolute;
  width: 70%;
  top: 100px;
}

.saasland_container .saasland_col_content .saasland_content {
  display: block;
  position: absolute;
  left: 21%;
  top: calc(50% - 150px);
  width: 70%;
}

.saasland_container .saasland_col_content .saasland_content h2 {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  color: #747d99;
  margin-bottom: 30px;
  font-weight: 400;
  font-style: italic;
}

.saasland_container .saasland_col_content .saasland_content h1 {
  color: #051441;
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 60px;
}

.saasland_container .saasland_col_content .saasland_content .car_theme_btn {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
}

.saasland_container .saasland_col_content .saasland_bottom {
  bottom: 25px;
  left: 21%;
  position: absolute;
  width: 70%;
  font: 400 12px/40px "Poppins", sans-serif;
  color: #7f88a6;
}

.saasland_container .saasland_col_content .saasland_bottom a {
  color: #6754e2;
}

.saasland_col_screen {
  background-image: -moz-linear-gradient(-120deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(-120deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(-120deg, #5e2ced 0%, #a485fd 100%);
  width: 60%;
  position: relative;
  z-index: 1;
}

.saasland_col_screen .saasland_bg {
  position: absolute;
  content: "";
  width: 500px;
  height: 100%;
  background: #fbfbfd;
  left: -1px;
  z-index: -1;
  -webkit-clip-path: polygon(0px 100%, 0px 0px, 95% 0px, 0% 100%);
  clip-path: polygon(0px 100%, 0px 0px, 95% 0px, 0% 100%);
}

.saasland_col_screen .clock {
  position: absolute;
  bottom: 190px;
  z-index: 1;
  text-align: center;
  width: 100%;
}

.saasland_col_screen .clock .timer {
  display: inline-block;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  padding: 20px 30px;
  margin: 0px 8px;
}

.saasland_col_screen .clock .timer span {
  font-size: 50px;
  font-weight: 500;
  line-height: 50px;
}

.saasland_col_screen .clock .timer .smalltext {
  font-size: 18px;
}

.saasland_col_screen .saasland_social {
  bottom: 25px;
  left: 70px;
  position: absolute;
  z-index: 1;
}

.saasland_col_screen .saasland_social p {
  margin-bottom: 0px;
  display: inline-block;
  color: #fff;
  font-size: 14px;
}

.saasland_col_screen .saasland_social ul {
  display: inline-block;
  padding-left: 0;
}

.saasland_col_screen .saasland_social ul li {
  display: inline-block;
}

.saasland_col_screen .saasland_social ul li a {
  font-size: 14px;
  color: #fff;
  padding: 0px 5px;
  -webkit-transition: color 0.4s linear;
  -o-transition: color 0.4s linear;
  transition: color 0.4s linear;
}

.saasland_col_screen .saasland_social ul li a:hover {
  color: #6754e2;
}

#success, #error {
  position: relative;
  top: 10px;
  display: block;
  right: 0;
  left: 0;
  bottom: -15px;
  display: none;
  font-weight: 600;
}

#success {
  color: lawngreen;
}

#error {
  color: orangered;
}

/*=============== seo_subscribe_area css ============*/
.seo_subscribe_area {
  background-color: #5e2ced;
  padding: 120px 0px 150px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.seo_subscribe_area .seo_sec_title h2, .seo_subscribe_area .seo_sec_title p {
  color: #fff;
}

.seo_subscribe_area .overlay_img {
  background: url("../img/seo/cloud_bg.png") no-repeat scroll center 0/cover;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.seo_subscribe_area .cloud_img {
  position: absolute;
  right: 0;
  bottom: -7px;
  width: 300px;
  height: 250px;
}

.seo_subscribe_form {
  max-width: 970px;
  margin: 0 auto;
}

.seo_subscribe_form .input-group {
  padding: 0px 10px;
}

.seo_subscribe_form .input-group input {
  font-size: 16px;
  line-height: 58px;
  height: 60px;
  font-weight: 300;
  border-radius: 4px;
  padding: 2px 30px 0px;
}

.seo_subscribe_form .input-group .form-control {
  max-width: 400px;
  color: #949db5;
  background: #fff;
  border: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.seo_subscribe_form .input-group .form-control.placeholder {
  color: #949db5;
}

.seo_subscribe_form .input-group .form-control:-moz-placeholder {
  color: #949db5;
}

.seo_subscribe_form .input-group .form-control::-moz-placeholder {
  color: #949db5;
}

.seo_subscribe_form .input-group .form-control::-webkit-input-placeholder {
  color: #949db5;
}

.seo_subscribe_form .input-group .check-btn {
  padding-left: 40px;
  padding-right: 40px;
  background-color: #00c99c;
  border: 0px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(18, 2, 86, 0.2);
  box-shadow: 0px 10px 30px 0px rgba(18, 2, 86, 0.2);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #fff;
  cursor: pointer;
}

.seo_subscribe_form .input-group .check-btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*=============== seo_subscribe_area css ============*/
/*====================================================*/
/*====================================================*/
/*================= error_area css ==================*/
.error_area {
  background: -moz-linear-gradient(180deg, rgba(94, 44, 237, 0.93) 0%, rgba(164, 133, 253, 0.9) 100%), url("../img/error_bg.png") no-repeat scroll center 100%;
  background: -webkit-linear-gradient(180deg, rgba(94, 44, 237, 0.93) 0%, rgba(164, 133, 253, 0.9) 100%), url("../img/error_bg.png") no-repeat scroll center 100%;
  background: -ms-linear-gradient(180deg, rgba(94, 44, 237, 0.93) 0%, rgba(164, 133, 253, 0.9) 100%), url("../img/error_bg.png") no-repeat scroll center 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
  height: 100vh;
  min-height: 650px;
}

.error_shap {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.error_contain h1 {
  font-size: 15vw;
  line-height: 14vw;
  margin-bottom: 0px;
}

.error_contain h2 {
  letter-spacing: 12px;
  margin-bottom: 25px;
}

.error_contain .about_btn {
  min-width: 230px;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(15, 0, 57, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(15, 0, 57, 0.1);
}

/*================= error_area css ==================*/
/*================= error_two_area css ==================*/
.error_two_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
  height: 100vh;
  min-height: 750px;
}

.error_content_two h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  color: #051441;
  margin-top: 40px;
}

.error_content_two p {
  font-size: 20px;
  line-height: 30px;
}

.error_content_two .search {
  margin: 45px 0px 40px;
}

.error_content_two .search .form-control {
  background-color: white;
  -webkit-box-shadow: 0px 4px 22px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 4px 22px 0px rgba(12, 0, 46, 0.06);
  border: 1px solid transparent;
  border-radius: 45px;
  height: 60px;
  padding: 0px 30px;
  color: #677294;
  font-size: 15px;
  font-weight: 300;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.error_content_two .search .form-control.placeholder {
  color: #677294;
}

.error_content_two .search .form-control:-moz-placeholder {
  color: #677294;
}

.error_content_two .search .form-control::-moz-placeholder {
  color: #677294;
}

.error_content_two .search .form-control::-webkit-input-placeholder {
  color: #677294;
}

.error_content_two .search .form-control:focus {
  border-color: #5e2ced;
}

.error_content_two .about_btn {
  background: #051441;
  color: #fff;
  border-radius: 3px;
  padding: 17px 35px;
  font-weight: 400;
}

.error_content_two .about_btn i {
  vertical-align: middle;
  padding-left: 5px;
}

.error_content_two .about_btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*================= error_two_area css ==================*/
/*================= download_area css ==================*/
.download_area {
  height: 100vh;
  min-height: 700px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  position: relative;
  z-index: 1;
}

.download_parallax_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../img/new/case/parallax.png") no-repeat scroll center center;
  z-index: -1;
}

.download_content .download_btn {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 1px solid #f6f5fc;
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 0;
  margin-bottom: 60px;
}

.download_content .download_btn:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background: #f7f4ff;
  border-radius: 50%;
  z-index: -2;
}

.download_content .download_btn:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 160px;
  height: 160px;
  background: #f1ecff;
  border-radius: 50%;
  z-index: -1;
}

.download_content .download_btn .pulse-x {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #f1ecff;
  width: 110px;
  height: 110px;
  border-radius: 100%;
  z-index: -2;
  -webkit-animation: pulsate 3s infinite;
  animation: pulsate 3s infinite;
}

.download_content .download_btn i {
  width: 120px;
  height: 120px;
  display: inline-block;
  background-image: -moz-linear-gradient(50deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(50deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(50deg, #5e2ced 0%, #a485fd 100%);
  -webkit-box-shadow: inset 2px -3.464px 6px 0px rgba(34, 10, 104, 0.2);
  box-shadow: inset 2px -3.464px 6px 0px rgba(34, 10, 104, 0.2);
  border-radius: 50%;
  line-height: 120px;
  font-size: 55px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.download_content h2 {
  font-size: 60px;
  color: #051441;
  font-weight: 400;
  margin-bottom: 35px;
}

.download_content p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
  color: #051441;
}

.download_content p a {
  color: #5e2ced;
  position: relative;
}

.download_content p a:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #5e2ced;
  position: absolute;
  left: 0;
  bottom: 0;
}

.thanks_content h2 {
  font-size: 50px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.thanks_content p {
  color: #677294;
}

.thanks_content a {
  font-size: 14px;
  font-weight: 500;
  color: #959bad;
  position: relative;
  text-transform: uppercase;
  padding: 0px 6px;
  display: inline-block;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin-top: 45px;
}

.thanks_content a:before {
  content: "";
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #959bad;
  left: 0;
  bottom: 0;
  position: absolute;
}

.thanks_content a i {
  vertical-align: middle;
  display: inline-block;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  padding-left: 5px;
}

.thanks_content a:hover {
  color: #5e2ced;
}

.thanks_content a:hover:before {
  border-color: #5e2ced;
}

.thanks_content a:hover i {
  -webkit-transform: translateX(8px);
  -ms-transform: translateX(8px);
  transform: translateX(8px);
}

/*================= download_area css ==================*/
/*====================================================*/
/*====================================================*/
.typography_breacrumb {
  background: #f9f9f9;
}

.typography_breacrumb h1 {
  color: #222d39;
  font-weight: 400;
}

.typography_breacrumb h1 span {
  font-weight: 700;
}

.typography_breacrumb p {
  color: #677294;
}

h1, h2, h3, h4, h5, h6 {
  color: #4b505e;
}

p {
  font-size: 16px;
  color: #677294;
}

p span {
  padding: 3px 8px;
}

.bg-dark {
  background: #222d39 !important;
}

.bg-purpple {
  background: #6e3ef8;
}

.bg-blue {
  background: #2cabed;
}

.h_title {
  font-size: 24px;
  color: #677294;
  font-weight: 300;
  margin-bottom: 50px;
}

.heading_style h1, .heading_style h2, .heading_style h3, .heading_style h4, .heading_style h5, .heading_style h6 {
  color: #4b505e;
  font-weight: 700;
}

.heading_style .h_title {
  font-size: 24px;
  color: #677294;
  font-weight: 300;
}

.heading_style h1 {
  font-size: 50px;
}

.heading_style h2 {
  font-size: 44px;
}

.heading_style h3 {
  font-size: 36px;
}

.heading_style h4 {
  font-size: 30px;
}

.heading_style h5 {
  font-size: 24px;
}

.heading_style h6 {
  font-size: 18px;
}

.heading_style.medium h1, .heading_style.medium h2, .heading_style.medium h3, .heading_style.medium h4, .heading_style.medium h5, .heading_style.medium h6 {
  font-weight: 500;
}

.heading_style.medium .h_title {
  font-weight: 300;
}

.heading_style.regular .h_title {
  font-weight: 300;
}

.heading_style.regular h1, .heading_style.regular h2, .heading_style.regular h3, .heading_style.regular h4, .heading_style.regular h5, .heading_style.regular h6 {
  font-weight: 400;
}

.tooltip_p .text-green {
  color: #49c10e;
}

.tooltip_p .text-red {
  color: #f0368d;
}

.tooltip > .tooltip-inner {
  background-color: #f00;
  color: #fff;
}

.tooltip > .tooltip-inner .arrow:before {
  border-bottom-color: red;
}

.drop_text span {
  float: left;
  font-size: 54px;
  color: #2cabed;
  font-weight: 700;
  line-height: 50px;
}

.nested {
  font-size: 16px;
  color: #627387;
  line-height: 36px;
  font-weight: 400;
}

.blockquote {
  border-left: 2px solid #4b505e;
  padding-left: 30px;
}

.blockquote p {
  font-size: 18px;
  color: #4b505e;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
}

.blockquote_two {
  background: #f9fafb;
  padding: 20px 20px 20px 40px;
  font-size: 22px;
  font-style: italic;
  color: #627387;
}

.blockquote_two img {
  margin-right: 40px;
}

.blockquote_three {
  background: #f9fafb;
  padding: 40px;
  font-size: 22px;
  color: #677294;
  font-style: italic;
  line-height: 40px;
}

.blockquote_three img {
  margin-right: 40px;
}

.typography_promo_area {
  position: relative;
  z-index: 1;
  height: 400px;
  background-color: #5e2ced;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.typography_promo_area .bg_overlay {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-attachment: fixed;
  opacity: 0.1;
}

.typography_promo_area h2 {
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
}

.typography_promo_area .btn_get {
  color: #fff;
  border: 1px solid #fff;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin-top: 30px;
}

.typography_promo_area .btn_get:hover {
  background: #fff;
  color: #051441;
}

.alert {
  font-size: 16px;
  line-height: 34px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-radius: 0px;
  margin-bottom: 30px;
}

.alert i {
  float: left;
  font-size: 20px;
  line-height: 32px;
  margin-right: 20px;
}

.alert .alert_close {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}

.alert .alert_close i {
  margin-right: 0;
}

.alert.notice {
  background: #f9f8fc;
  border-color: #e9e6f5;
  color: #677294;
}

.alert.error {
  background: #ffebeb;
  border-color: #f4bdbd;
  color: #eb4a4a;
}

.alert.warning {
  background: #fffaeb;
  border-color: #edd796;
  color: #e7b931;
}

.alert.info {
  background: #ebfcff;
  border-color: #aee9f3;
  color: #49cbe1;
}

.alert.success {
  background: #ecffeb;
  border-color: #b0efac;
  color: #4acc43;
}

.alert.message {
  background: #f5f1fd;
  border-color: #d7caf5;
  color: #8d62e3;
}

.big_alert {
  padding: 40px;
  font-size: 20px;
}

.big_alert i {
  font-size: 24px;
}

.box_alert {
  border: 1px solid #e9e6f5;
  padding: 40px;
  position: relative;
  margin-bottom: 30px;
}

.box_alert .icon {
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 70px;
  font-size: 20px;
  color: #677294;
  border: 1px solid #e9e6f5;
  background: #f9f8fc;
  border-radius: 50%;
  margin-right: 30px;
}

.box_alert .media-body h5 {
  font-size: 20px;
  line-height: 28px;
  color: #222d39;
  font-weight: 500;
  margin-bottom: 10px;
}

.box_alert .media-body p {
  font-size: 15px;
  line-height: 28px;
  color: #677294;
}

.box_alert .alert_close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #677294;
  font-size: 20px;
  cursor: pointer;
}

.box_alert.box_warning .icon {
  background: #fffaeb;
  border-color: #edd796;
  color: #e7b931;
}

.box_alert.box_success .icon {
  background: #ecffeb;
  border-color: #b0efac;
  color: #4acc43;
}

.box_alert.box_error .icon {
  background: #ffebeb;
  border-color: #f4bdbd;
  color: #eb4a4a;
}

.box_alert.box_info .icon {
  background: #ebfcff;
  border-color: #aee9f3;
  color: #49cbe1;
}

.box_alert.box_info_two .icon {
  background: #f5f1fd;
  border-color: #d7caf5;
  color: #8d62e3;
}

.button_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.button_item .btn_hover {
  margin-top: 0;
}

.button_item + .button_item {
  margin-top: 20px;
}

.btn_s {
  padding: 9px 44px;
}

.btn_m {
  padding: 16px 50px;
}

.btn_l {
  padding: 24px 86px;
}

.btn_c {
  padding: 16px 50px;
}

.btn_border {
  border-width: 1px;
  border-style: solid;
  border-color: #222d39;
  color: #222d39;
}

.btn_border:hover {
  background: #7444fd;
  border-color: #7444fd;
  color: #fff;
}

.border2 {
  border-width: 2px;
}

.border3 {
  border-width: 3px;
}

.border4 {
  border-width: 4px;
}

.btn_r {
  border-radius: 4px;
}

.btn_r_n {
  border-radius: 0px;
}

.btn_r_two {
  border-radius: 8px;
}

.btn_r_three {
  border-radius: 45px;
}

.box_s {
  background: #5f2eed;
  -webkit-box-shadow: 0 6px #8057f8;
  box-shadow: 0 6px #8057f8;
}

.box_s:hover {
  background: #5f2eed;
  color: #fff;
}

.box_s:hover:hover {
  -webkit-box-shadow: 0 4px #8057f8;
  box-shadow: 0 4px #8057f8;
}

.btn-style2 {
  position: relative;
  overflow: visible;
  z-index: auto;
  background: #5f2eed;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.btn-style2:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #dfd5fb;
  position: absolute;
  right: -5px;
  bottom: -5px;
  z-index: -2;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.btn-style2:hover {
  background: #5f2eed;
  margin-right: 0;
  margin-bottom: 0;
  color: #fff;
}

.btn-style2:hover:before {
  right: 0;
  bottom: 0;
}

.btn-style3 {
  position: relative;
  background: transparent;
}

.btn-style3:before {
  content: "";
  left: 5px;
  right: 5px;
  bottom: 5px;
  top: 5px;
  background: #5f2eed;
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.btn-style3:hover {
  color: #fff;
}

.btn-style3:hover:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.btn-style4 {
  position: relative;
  background: #dfd5fb;
  border: 0px;
}

.btn-style4:before {
  content: "";
  left: 2px;
  right: 2px;
  bottom: 2px;
  top: 2px;
  background: #5f2eed;
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.btn-style4:hover {
  color: #fff;
}

.btn-style4:hover:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.i_btn_one {
  padding: 16px 50px;
}

.i_btn_one i {
  margin-right: 10px;
}

.i_btn_two {
  padding: 16px 50px;
}

.i_btn_two i {
  margin-left: 14px;
}

.i_btn_three {
  padding: 0 50px 0px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.i_btn_three i {
  width: 50px;
  float: left;
  text-align: center;
  height: 100%;
  line-height: 53px;
  margin-right: 35px;
  border-right: 1.2px solid rgba(255, 255, 255, 0.2);
}

.i_btn_three:hover i {
  border-color: #7444fd;
}

.i_btn_four {
  padding: 0 0px 0px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.i_btn_four i {
  width: 50px;
  float: left;
  text-align: center;
  height: 100%;
  line-height: 53px;
  margin-left: 35px;
  border-left: 1.2px solid rgba(255, 255, 255, 0.2);
}

.i_btn_four:hover i {
  border-color: #7444fd;
}

.btn_blue {
  background: #2e95ed;
  border-color: #2e95ed;
}

.btn_blue:hover {
  background: #2e95ed;
  color: #fff;
  -webkit-box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.btn_red {
  background: #db247f;
  border-color: #db247f;
}

.btn_red:hover {
  background: #db247f;
  color: #fff;
  -webkit-box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.btn_green {
  background: #62be09;
  border-color: #62be09;
  padding: 16px 64px;
}

.btn_green:hover {
  background: #62be09;
  color: #fff;
  -webkit-box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.btn_yellow {
  background: #e4a817;
  border-color: #e4a817;
  padding: 16px 64px;
}

.btn_yellow:hover {
  background: #e4a817;
  color: #fff;
  -webkit-box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.btn_purple {
  background: #b715f3;
  border-color: #b715f3;
}

.btn_purple:hover {
  background: #b715f3;
  color: #fff;
  -webkit-box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.btn_violte {
  background: #5f2eed;
  border-color: #5f2eed;
}

.btn_violte:hover {
  background: #5f2eed;
  color: #fff;
  -webkit-box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.btn_orange {
  background: #f05b2d;
  border-color: #f05b2d;
  padding: 16px 64px;
}

.btn_orange:hover {
  background: #f05b2d;
  color: #fff;
  -webkit-box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.btn_nill {
  background: #1bbbce;
  border-color: #1bbbce;
  padding: 16px 64px;
}

.btn_nill:hover {
  background: #1bbbce;
  color: #fff;
  -webkit-box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 34px 0px rgba(0, 11, 40, 0.1);
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

/*====================================================*/
/*====================================================*/
/*=========== blog area css ===========*/
.blog_grid_item {
  cursor: pointer;
}

.blog_grid_item img {
  -webkit-transition: opacity 600ms linear;
  -o-transition: opacity 600ms linear;
  transition: opacity 600ms linear;
}

.blog_grid_item:hover .blog_img img {
  opacity: 0.70;
}

.blog_img {
  background: #000022;
}

.blog_img img {
  max-width: 100%;
}

.blog_content {
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.06);
  padding: 30px 47px 42px 50px;
  position: relative;
}

.blog_content .post_date {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  -webkit-box-shadow: 0px 3px 0px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 3px 0px 0px rgba(12, 0, 46, 0.06);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  right: 50px;
  top: -40px;
  background: #fff;
}

.blog_content .post_date h2 {
  font: 700 30px/20px "Poppins", sans-serif;
  margin-bottom: 0px;
  color: #5e2ced;
  padding-top: 5px;
}

.blog_content .post_date span {
  font-weight: 300;
  font-size: 14px;
  display: block;
  padding-top: 5px;
}

.blog_content p {
  font-weight: 400;
  font-size: 15px;
}

.blog_content h5:hover {
  color: #5e2ced;
}

.blog_content .learn_btn_two {
  font: 400 14px "Poppins", sans-serif;
  color: #282835;
}

.blog_content .learn_btn_two:before {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.blog_content .learn_btn_two i {
  font-size: 14px;
}

.blog_content .learn_btn_two:hover {
  color: #5e2ced;
}

.blog_content .learn_btn_two:hover:before {
  background: #5e2ced;
}

.entry_post_info {
  font: 400 15px/25px "Poppins", sans-serif;
  color: #677294;
  padding-bottom: 5px;
}

.entry_post_info a {
  color: #677294;
}

.entry_post_info a + a:before {
  content: "|";
  display: inline-block;
  margin: 0px 7px 0px 7px;
}

.blog_single .blog_content {
  padding-left: 0px;
  padding-right: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog_single .blockquote {
  color: #5e2ced;
  font-style: italic;
  border-left: 4px solid #5e2ced;
  padding-left: 35px;
}

.post_share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #d8dceb;
  padding-bottom: 10px;
}

.post_share a {
  font: 400 14px/26px "Poppins", sans-serif;
  color: #525c7c;
}

.post_share a i {
  padding-right: 12px;
}

.post_share a:hover {
  color: #5e2ced;
}

.post_share a + a {
  margin-left: 15px;
}

.post_share .flex {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
}

.post-nam {
  font: 400 15px/26px "Poppins", sans-serif;
  color: #282835;
}

.post_tag {
  padding-top: 20px;
}

.post_tag .post-nam {
  padding-right: 10px;
}

.post_tag a {
  font: 400 13px/26px "Poppins", sans-serif;
  color: #595b64;
  border: 1px solid #cfcfe6;
  border-radius: 3px;
  padding: 0px 11px;
  display: inline-block;
  margin-left: 10px;
}

.post_author .media-body {
  padding-left: 20px;
}

.post_author .media-body h6 {
  color: #677294;
}

.post_author .media-body p {
  color: #677294;
  margin-bottom: 0px;
}

.blog_post_item .blog_content {
  padding: 22px 22px 35px;
}

.blog_post_item .blog_content h5 {
  margin-bottom: 15px;
}

.comment-box .post_comment .post_author {
  margin-bottom: 30px;
}

.comment-box .post_comment .post_author .media-left {
  margin-right: 30px;
}

.comment-box .post_comment .post_author .replay {
  display: block;
  text-align: center;
  font-size: 20px;
  color: #525c7c;
  padding-top: 14px;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.comment-box .post_comment .post_author .replay:hover {
  color: #5e2ced;
}

.comment-box .post_comment .post_author .media-body {
  border-bottom: 1px solid #e8ebf4;
  padding-left: 0;
  padding-bottom: 25px;
}

.comment-box .post_comment .reply-comment {
  padding-left: 110px;
}

.comment-box .post_comment .reply-comment .post_author {
  margin-bottom: 0px;
}

/*=========== blog sidebar css ===========*/
.widget_title {
  margin-bottom: 35px;
}

.widget_title .border_bottom {
  width: 180px;
  height: 1px;
  background: #677294;
  display: block;
}

.widget_title_two {
  font-size: 20px;
  font-weight: 600;
  color: #282835;
  margin-bottom: 30px;
}

.blog-sidebar {
  padding-left: 40px;
}

.blog-sidebar .widget.widget_search .search-form {
  position: relative;
}

.blog-sidebar .widget.widget_search .search-form .form-control {
  font: 300 14px "Poppins", sans-serif;
  color: #677294;
  border-radius: 3px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(12, 0, 46, 0.08);
  box-shadow: 0px 2px 3px 0px rgba(12, 0, 46, 0.08);
  border-radius: 0px;
  border: 0px;
  padding-left: 30px;
  height: 55px;
  z-index: 0;
  border: 1px solid transparent;
}

.blog-sidebar .widget.widget_search .search-form .form-control.placeholder {
  color: #677294;
}

.blog-sidebar .widget.widget_search .search-form .form-control:-moz-placeholder {
  color: #677294;
}

.blog-sidebar .widget.widget_search .search-form .form-control::-moz-placeholder {
  color: #677294;
}

.blog-sidebar .widget.widget_search .search-form .form-control::-webkit-input-placeholder {
  color: #677294;
}

.blog-sidebar .widget.widget_search .search-form .form-control:focus {
  border-color: #ccc5fa;
  border-radius: 3px;
}

.blog-sidebar .widget.widget_search .search-form button {
  border: 0px;
  border-radius: 0px;
  font-size: 16px;
  color: #282835;
  background: transparent;
  position: absolute;
  right: 20px;
  height: 100%;
  padding: 0px;
  z-index: 1;
  cursor: pointer;
}

.blog-sidebar .widget.widget_search .search-form button:hover {
  color: #5e2ced;
}

.blog-sidebar .widget.widget_recent_post .post_item img {
  border-radius: 3px;
}

.blog-sidebar .widget.widget_recent_post .post_item .media-body {
  padding-left: 20px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.blog-sidebar .widget.widget_recent_post .post_item .media-body h3 {
  color: #282835;
}

.blog-sidebar .widget.widget_recent_post .post_item .media-body h3:hover {
  color: #5e2ced;
}

.blog-sidebar .widget.widget_recent_post .post_item .media-body .entry_post_info {
  padding-bottom: 0px;
}

.blog-sidebar .widget.widget_recent_post .post_item + .post_item {
  margin-top: 30px;
}

.blog-sidebar .widget.widget_categorie .widget_title {
  margin-bottom: 28px;
}

.blog-sidebar .widget.widget_categorie ul {
  margin-bottom: 0px;
}

.blog-sidebar .widget.widget_categorie ul li a {
  font: 400 16px/35px "Poppins", sans-serif;
  color: #282835;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.blog-sidebar .widget.widget_categorie ul li a span {
  border-bottom: 1px solid transparent;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.blog-sidebar .widget.widget_categorie ul li a em {
  float: right;
  font-style: normal;
}

.blog-sidebar .widget.widget_categorie ul li a:hover span {
  color: #5e2ced;
  border-color: #5e2ced;
}

.blog-sidebar .widget.widget_tag_cloud .post-tags {
  margin-top: -5px;
  margin-left: -5px;
  margin-right: -5px;
}

.blog-sidebar .widget.widget_tag_cloud .post-tags a {
  font: 400 13px/26px "Poppins", sans-serif;
  color: #595b64;
  border: 1px solid #cfcfe6;
  border-radius: 3px;
  padding: 6px 18px;
  float: left;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin: 5px;
}

.blog-sidebar .widget.widget_tag_cloud .post-tags a:hover {
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.blog-sidebar .widget.search_widget_two .search-form .form-control {
  padding: 0px 30px;
  background: #f3f4f9;
  border-radius: 4px;
  height: 60px;
  font-size: 15px;
  color: #677294;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  z-index: 0;
  border-color: transparent;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.blog-sidebar .widget.search_widget_two .search-form .form-control.placeholder {
  color: #677294;
}

.blog-sidebar .widget.search_widget_two .search-form .form-control:-moz-placeholder {
  color: #677294;
}

.blog-sidebar .widget.search_widget_two .search-form .form-control::-moz-placeholder {
  color: #677294;
}

.blog-sidebar .widget.search_widget_two .search-form .form-control::-webkit-input-placeholder {
  color: #677294;
}

.blog-sidebar .widget.search_widget_two .search-form .form-control:focus {
  border-color: #5e2ced;
  background: #fff;
}

.blog-sidebar .widget.search_widget_two .search-form button {
  position: absolute;
  background: transparent;
  padding: 0px;
  border: 0px;
  right: 30px;
  top: 51%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
  color: #282835;
  z-index: 0;
}

.blog-sidebar .widget.recent_post_widget_two .post_item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-sidebar .widget.recent_post_widget_two .post_item img {
  border-radius: 4px;
  padding-right: 25px;
}

.blog-sidebar .widget.recent_post_widget_two .post_item .media-body h3 {
  font: 500 16px/22px "Poppins", sans-serif;
  color: #222d39;
  margin-bottom: 0;
}

.blog-sidebar .widget.recent_post_widget_two .post_item .media-body h3:hover {
  color: #5e2ced;
}

.blog-sidebar .widget.recent_post_widget_two .post_item .media-body .entry_post_info {
  font-size: 14px;
  color: #85859c;
  font-weight: 400;
  text-transform: uppercase;
}

.blog-sidebar .widget.recent_post_widget_two .post_item:not(:last-child) {
  margin-bottom: 30px;
}

.blog-sidebar .widget.categorie_widget_two ul {
  margin-bottom: 0;
}

.blog-sidebar .widget.categorie_widget_two ul li a {
  font-size: 14px;
  line-height: 36px;
  color: #282835;
  text-transform: uppercase;
}

.blog-sidebar .widget.categorie_widget_two ul li a em {
  font-style: normal;
  color: #282835;
  padding-left: 10px;
}

.blog-sidebar .widget.categorie_widget_two ul li a:hover {
  color: #5e2ced;
}

.blog-sidebar .widget.tag_widget_two .post-tags {
  margin: -5px -4px;
}

.blog-sidebar .widget.tag_widget_two .post-tags a {
  padding: 6px 20px;
  font-size: 14px;
  color: #5a5a77;
  background: #f0f2f9;
  border-radius: 4px;
  margin: 5px 4px;
  display: inline-block;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.blog-sidebar .widget.tag_widget_two .post-tags a:hover {
  background: #5e2ced;
  color: #fff;
}

.blog-sidebar .widget.instagram_widget ul {
  margin: -7px;
}

.blog-sidebar .widget.instagram_widget ul li {
  display: inline-block;
  padding: 7px 6px;
}

.blog-sidebar .widget.instagram_widget ul li a {
  display: block;
  position: relative;
}

.blog-sidebar .widget.instagram_widget ul li a:before {
  content: "\e73d";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: 'themify';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 22px;
  background: rgba(94, 44, 237, 0.6);
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
  color: #fff;
}

.blog-sidebar .widget.instagram_widget ul li a img {
  max-width: 100%;
  border-radius: 4px;
}

.blog-sidebar .widget.instagram_widget ul li a:hover:before {
  opacity: 1;
}

/*========= blog_area_two css ===========*/
.blog_area_two .blog_list_item:not(:last-child) {
  margin-bottom: 70px;
}

.blog_area_two .shop_page_number {
  border-top: 1px solid #ebe8f1;
  padding-top: 35px;
  margin-top: 0px;
}

.blog_list_item_two {
  position: relative;
}

.blog_list_item_two .post_date {
  position: absolute;
  left: 20px;
  top: 20px;
  border-radius: 4px;
  background-color: #fbfbfd;
  -webkit-box-shadow: 0px 14px 30px 0px rgba(68, 28, 183, 0.25);
  box-shadow: 0px 14px 30px 0px rgba(68, 28, 183, 0.25);
  text-align: center;
  color: #5e2ced;
  padding: 13px 26px;
  z-index: 1;
}

.blog_list_item_two .post_date h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0;
}

.blog_list_item_two .post_date span {
  display: block;
  font-size: 15px;
  font-weight: 400;
}

.blog_list_item_two a img {
  border-radius: 6px;
}

.blog_list_item_two .video_post {
  position: relative;
  z-index: 0;
}

.blog_list_item_two .video_post:before {
  content: "";
  background: #282835;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.5;
  border-radius: 6px;
}

.blog_list_item_two .video_post img {
  border-radius: 6px;
}

.blog_list_item_two .video_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.blog_list_item_two .video_icon:after {
  display: none;
}

.blog_list_item_two .video_icon:before {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.blog_list_item_two .video_icon i {
  color: #794afd;
  text-shadow: 0px 6px 25px rgba(121, 74, 253, 0.9);
}

.blog_list_item_two .blog_content {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.blog_list_item_two .blog_content p {
  font-size: 16px;
  margin-bottom: 25px;
}

.blog_list_item_two .blog_content .post-info-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.blog_list_item_two .blog_content .post-info-bottom .post-info-comments {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #282835;
}

.blog_list_item_two .blog_content .post-info-bottom .post-info-comments i {
  color: #5e2ced;
  padding-right: 5px;
}

.blog_list_item_two .blog_content .learn_btn_two {
  text-transform: uppercase;
  font-size: 13px;
  margin-top: 0px;
  line-height: 20px;
  display: inline-block;
  font-weight: 500;
}

.blog_list_item_two .blog_content .post-info-comments {
  text-transform: uppercase;
  line-height: 20px;
  display: inline-block;
}

.blog_title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: #222d39;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
}

.blog_title:hover {
  color: #5e2ced;
}

.qutoe_post .blog_content {
  background-color: white;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(20, 3, 67, 0.08);
  box-shadow: 0px 10px 30px 0px rgba(20, 3, 67, 0.08);
  border-radius: 6px;
  padding: 28px 40px 42px 40px;
  position: relative;
  overflow: hidden;
  border-left: 6px solid #5e2ced;
  z-index: 0;
}

.qutoe_post .blog_content:after {
  content: "\f10d";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  right: 30px;
  bottom: 0px;
  color: #f2effd;
  font-size: 100px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  z-index: -1;
}

.qutoe_post .blog_content i {
  font-size: 30px;
  color: #5e2ced;
  padding-bottom: 12px;
  display: inline-block;
}

.qutoe_post .blog_content h6 {
  font-size: 20px;
  color: #525c7c;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 0;
}

.qutoe_post .blog_content .author_name {
  font-size: 14px;
  color: #848ca5;
  line-height: 20px;
  letter-spacing: 1px;
  margin-top: 22px;
  display: inline-block;
  text-transform: uppercase;
}

.qutoe_post.qutoe_post_two .blog_content:after {
  content: "\e02c";
  font-family: 'ElegantIcons';
  font-size: 150px;
  right: 0;
  bottom: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.qutoe_post.qutoe_post_two .blog_content .blog_title {
  font-weight: 500;
}

.blog_grid_info .blog_list_item {
  margin-bottom: 60px;
}

.blog_grid_info .blog_list_item .post_date {
  padding: 7px 19px;
}

.blog_grid_info .blog_list_item .post_date h2 {
  font-size: 24px;
}

.blog_grid_info .blog_list_item .blog_content {
  padding-top: 25px;
}

.blog_grid_info .blog_list_item .blog_content .blog_title {
  font-size: 20px;
  margin-bottom: 10px;
}

.blog_grid_info .blog_list_item.qutoe_post .blog_content {
  padding-bottom: 80px;
}

.blog_grid_info .blog_list_item.qutoe_post .blog_content:after {
  bottom: 0px;
  font-size: 90px;
  line-height: 90px;
}

.blog_grid_info .blog_list_item.qutoe_post_two .blog_content:after {
  bottom: auto;
  top: 80%;
}

.blog_single_info .blog_list_item_two .blog_content {
  padding-top: 50px;
}

.blog_single_info .blog_list_item_two .blog_content .qutoe_post {
  margin: 45px 0px;
}

.blog_single_info .blog_list_item_two .blog_content .post-info-bottom {
  padding-top: 20px;
}

.blog_single_info .blog_list_item_two .blog_content .post-info-bottom .social_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: uppercase;
  font-size: 13px;
  color: #424255;
  font-weight: 500;
  letter-spacing: 1px;
}

.blog_single_info .blog_list_item_two .blog_content .post-info-bottom .social_icon ul {
  display: inline-block;
}

.blog_single_info .blog_list_item_two .blog_content .post-info-bottom .social_icon ul li {
  display: inline-block;
  margin-left: 8px;
}

.blog_single_info .blog_list_item_two .blog_content .post-info-bottom .social_icon ul li a {
  font-size: 12px;
  color: #848ca5;
}

.blog_single_info .blog_list_item_two .blog_content .post-info-bottom .social_icon ul li a:hover {
  color: #5e2ced;
}

.blog_single_info .blog_titles {
  font: 600 20px "Poppins", sans-serif;
  color: #282835;
  margin-bottom: 30px;
}

.blog_single_info blockquote {
  background-color: white;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(20, 3, 67, 0.08);
  box-shadow: 0px 10px 30px 0px rgba(20, 3, 67, 0.08);
  border-radius: 6px;
  padding: 28px 40px 22px 40px;
  position: relative;
  overflow: hidden;
  border-left: 6px solid #5e2ced;
  z-index: 0;
  margin: 40px 0px 45px;
}

.blog_single_info blockquote:before {
  content: "\f10d";
  font-size: 30px;
  color: #5e2ced;
  padding-bottom: 12px;
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.blog_single_info blockquote:after {
  content: "\f10d";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  right: 30px;
  bottom: auto;
  top: 80%;
  color: #f2effd;
  font-size: 100px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  z-index: -1;
}

.blog_single_info blockquote i {
  font-size: 30px;
  color: #5e2ced;
  padding-bottom: 12px;
  display: inline-block;
}

.blog_single_info blockquote h6 {
  font-size: 20px;
  color: #525c7c;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 0;
}

.blog_single_info blockquote .author_name {
  font-size: 14px;
  color: #848ca5;
  line-height: 20px;
  letter-spacing: 1px;
  margin-top: 22px;
  display: inline-block;
  text-transform: uppercase;
}

.post_author_two {
  background: #f3f5fa;
  border-radius: 4px;
  padding: 35px 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.post_author_two .img_rounded {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-right: 30px;
}

.post_author_two .media-body .comment_info h3 {
  font: 500 18px/24px "Poppins", sans-serif;
  color: #282835;
}

.post_author_two .media-body .comment_info .comment-date {
  font: 400 14px "Poppins", sans-serif;
  color: #677294;
}

.post_author_two .media-body p {
  margin-bottom: 0;
  font-size: 16px;
  color: #525c7c;
}

.blog_related_post {
  margin-top: 100px;
}

.blog_related_post.blog_grid_info .blog_list_item .blog_content {
  padding-top: 25px;
}

.blog_related_post.blog_grid_info .blog_list_item .blog_content h5 {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
}

.blog_related_post.blog_grid_info .blog_list_item .blog_content p {
  font-size: 15px;
}

.comment_inner {
  margin-top: 40px;
}

.comment_inner .comment_box {
  margin-bottom: 0;
}

.comment_inner .comment_box .post_comment {
  background: #f7f8fb;
  padding: 30px 30px 30px;
}

.comment_inner .comment_box .post_comment .post_author_two {
  padding: 0px;
  background: transparent;
  -webkit-box-align: inherit;
  -ms-flex-align: inherit;
  align-items: inherit;
}

.comment_inner .comment_box .post_comment .post_author_two .comment_info {
  display: inline-block;
}

.comment_inner .comment_box .post_comment .post_author_two .comment_info h3 {
  margin-bottom: 0px;
}

.comment_inner .comment_box .post_comment .post_author_two .img_rounded {
  width: 70px;
  height: 70px;
}

.comment_inner .comment_box .post_comment .post_author_two .comment_reply {
  float: right;
  font: 500 13px/26px "Poppins", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #424255;
}

.comment_inner .comment_box .post_comment .post_author_two .comment_reply i {
  vertical-align: middle;
  font-size: 20px;
  padding-left: 5px;
}

.comment_inner .comment_box .post_comment .post_author_two .comment_reply:hover {
  color: #5e2ced;
}

.comment_inner .comment_box .post_comment .post_author_two p {
  padding-top: 12px;
}

.comment_inner .comment_box .post_comment .reply_comment {
  margin-left: 100px;
  border-top: 1px solid #e8ebf4;
  margin-top: 30px;
}

.comment_inner .comment_box .post_comment .reply_comment .post_author_two {
  padding-left: 0px;
  padding-right: 0;
  padding-top: 30px;
}

.comment_inner .comment_box .post_comment:not(:last-child) {
  margin-bottom: 30px;
}

.blog_comment_box {
  margin-top: 100px;
}

.blog_comment_box .get_quote_form .form-group .form-control {
  background: #f7f8fb;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog_comment_box .get_quote_form .form-group .form-control.placeholder {
  color: #9ca3b9;
}

.blog_comment_box .get_quote_form .form-group .form-control:-moz-placeholder {
  color: #9ca3b9;
}

.blog_comment_box .get_quote_form .form-group .form-control::-moz-placeholder {
  color: #9ca3b9;
}

.blog_comment_box .get_quote_form .form-group .form-control::-webkit-input-placeholder {
  color: #9ca3b9;
}

.blog_comment_box .get_quote_form .form-group .form-control:focus {
  border-color: #5e2ced;
  background: #fff;
}

.comments_widget ul {
  margin-bottom: 0;
}

.comments_widget ul li .comments_items .round_img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 30px;
  margin-top: 7px;
}

.comments_widget ul li .comments_items .media-body p {
  font-size: 15px;
  line-height: 26px;
  color: #282835;
  margin-bottom: 0px;
}

.comments_widget ul li .comments_items .media-body p:hover {
  color: #5e2ced;
}

.comments_widget ul li .comments_items .media-body span {
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  color: #848ca5;
  display: block;
  padding-top: 12px;
}

.comments_widget ul li:not(:last-child) {
  margin-bottom: 30px;
}

.widget_recent_comments #recentcomments {
  padding: 0px;
  list-style: none;
  margin-bottom: 0;
}

.widget_recent_comments #recentcomments .recentcomments {
  position: relative;
  padding-left: 90px;
  font-size: 15px;
  line-height: 26px;
  color: #282835;
  font-family: "Poppins", sans-serif;
  min-height: 66px;
}

.widget_recent_comments #recentcomments .recentcomments:before {
  content: "\76";
  font-family: eleganticons;
  border: 1px solid #5e2ced;
  text-align: center;
  line-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 20px;
  color: #5e2ced;
  -webkit-transition: background 0.3s linear, color 0.3s linear;
  -o-transition: background 0.3s linear, color 0.3s linear;
  transition: background 0.3s linear, color 0.3s linear;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 6px;
}

.widget_recent_comments #recentcomments .recentcomments .comment-author-link {
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  color: #848ca5;
}

.widget_recent_comments #recentcomments .recentcomments a {
  color: #282835;
}

.widget_recent_comments #recentcomments .recentcomments a:hover {
  color: #5e2ced;
}

.widget_recent_comments #recentcomments .recentcomments:hover:before {
  background: #5e2ced;
  color: #fff;
}

.widget_recent_comments #recentcomments .recentcomments:not(:last-child) {
  margin-bottom: 30px;
}

/*====================================================*/
/*====================================================*/
/*============= shop_grid_area css ==============*/
.shop_menu_left p {
  margin-bottom: 0px;
  font: 300 16px "Poppins", sans-serif;
  color: #282835;
}

.shop_menu_right h5 {
  font: 500 16px "Poppins", sans-serif;
  color: #282835;
  margin-bottom: 5px;
}

.shop_menu_right .selectpickers {
  border: 0px;
}

.shop_menu_right .selectpickers:after {
  width: 8px;
  height: 8px;
  top: 42%;
}

.shop_menu_right .selectpickers .current {
  font: 300 16px "Poppins", sans-serif;
  color: #677294;
}

.shop_menu_right .shop_grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.shop_menu_right .shop_grid a {
  font-size: 16px;
  color: #677294;
  margin-left: 12px;
}

.shop_menu_right .shop_grid .active a {
  color: #5e2ced;
}

.single_product_item {
  text-align: center;
  margin-top: 30px;
}

.single_product_item .product_img {
  position: relative;
}

.single_product_item .product_img .hover_content {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  text-align: center;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  overflow: hidden;
  opacity: 0;
}

.single_product_item .product_img .hover_content a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  background: #fff;
  margin: 0px 2px;
  color: #282835;
  font-size: 16px;
  display: inline-block;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.single_product_item .product_img .hover_content a:hover {
  background: #5e2ced;
  color: #fff;
}

.single_product_item .product_img:hover .hover_content {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.single_product_item .single_pr_details {
  padding: 28px 0px;
}

.single_product_item .single_pr_details h3 {
  color: #282835;
  margin-bottom: 5px;
}

.single_product_item .single_pr_details h3:hover {
  color: #5e2ced;
}

.single_product_item .single_pr_details .price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font: 300 14px/28px "Poppins", sans-serif;
  color: #677294;
}

.single_product_item .single_pr_details .price ins {
  font-weight: 500;
  color: #282835;
  text-decoration: none;
  padding-right: 12px;
}

.single_product_item .ratting a {
  font-size: 14px;
  color: #f3ad16;
}

.single_product_item .ratting a:before {
  content: "\e60a\e60a\e60a\e60a";
  font-family: 'themify';
}

.hr {
  width: 100%;
  height: 1px;
  background: #e7e7f6;
  margin-bottom: 40px;
}

.shop_page_number {
  text-align: center;
  margin-bottom: 0px;
}

.shop_page_number li {
  display: inline-block;
  margin: 0px 3px;
}

.shop_page_number li .page-numbers {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #e7e7f6;
  font-size: 16px;
  line-height: 39px;
  font-weight: 300;
  color: #677294;
  text-align: center;
  display: block;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.shop_page_number li .page-numbers:hover {
  border-color: #5e2ced;
  color: #5e2ced;
}

.shop_page_number li .page-numbers.current {
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

/*============= shop_grid_area css ==============*/
.shop_list_area .shop_page_number {
  margin-top: 70px;
}

.shop_list_item {
  padding-top: 50px;
}

.shop_list_item .single_product_item .single_pr_details {
  padding-bottom: 0px;
}

.shop_list_item .single_product_item .single_pr_details .price {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-size: 16px;
}

.shop_list_item .single_product_item .single_pr_details .s_list_title {
  display: inline-block;
}

.shop_list_item .single_product_item .single_pr_details .ratting {
  float: right;
}

.shop_list_item .single_product_item .single_pr_details .pr_button .cart_btn {
  font: 400 15px/30px "Poppins", sans-serif;
  color: #677294;
  border: 1px solid #e7e7f6;
  border-radius: 45px;
  background: #f9f9fc;
  padding: 9px 28px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
}

.shop_list_item .single_product_item .single_pr_details .pr_button .cart_btn:hover {
  color: #fff;
  background: #5e2ced;
  border-color: #5e2ced;
}

.shop_list_item .single_product_item .single_pr_details .pr_button a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #e7e7f6;
  background: #f9f9fc;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  color: #282835;
  margin-left: 4px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
}

.shop_list_item .single_product_item .single_pr_details .pr_button a:hover {
  color: #fff;
  background: #5e2ced;
  border-color: #5e2ced;
}

.product_details_area .product_slider {
  padding-right: 42px;
}

.product_details_area .product_slider .owl-thumbs {
  display: table;
  width: 102.6%;
  text-align: center;
  padding: 0;
  margin-top: 24px;
  margin-left: -7px;
  margin-right: -7px;
}

.product_details_area .product_slider .owl-thumbs .owl-thumb-item {
  display: table-cell;
  border: none;
  background: none;
  padding: 7px;
  width: calc(100% / 4);
}

.product_details_area .product_slider .owl-thumbs .owl-thumb-item img {
  max-width: 100%;
  border: 2px solid transparent;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.product_details_area .product_slider .owl-thumbs .owl-thumb-item.active img {
  border-color: #c8b6fc;
}

.pr_details .pr_title {
  color: #282835;
}

.pr_details .ratting {
  float: right;
  text-align: right;
}

.pr_details .ratting .star-rating {
  color: #5e2ced;
  line-height: 12px;
}

.pr_details .ratting .star-rating:before {
  content: "\e60a\e60a\e60a\e60a";
  font-family: 'themify';
  font-size: 12px;
}

.pr_details .ratting .woocommerce-review-link {
  font-size: 14px;
  font-weight: 300;
  color: #677294;
}

.pr_details .price {
  font: 300 20px "Poppins", sans-serif;
  color: #677294;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  padding-top: 8px;
}

.pr_details .price ins {
  text-decoration: none;
  font-weight: 600;
  color: #282835;
  padding-right: 18px;
}

.pr_details .stock {
  font: 400 16px "Poppins", sans-serif;
  color: #5e2ced;
  vertical-align: middle;
  padding-top: 20px;
  display: inline-block;
}

.pr_details .stock:before {
  content: "\e64c";
  font-family: 'themify';
  color: #5e2ced;
  padding-right: 10px;
}

.pr_details p {
  margin-bottom: 0px;
  padding: 20px 0px 40px;
}

.pr_details .product-qty {
  display: inline-block;
  position: relative;
}

.pr_details .product-qty .manual-adjust {
  width: 95px;
  border: 1px solid #e7e7f6;
  border-radius: 45px;
  background: #fff;
  text-align: center;
  height: 50px;
}

.pr_details .product-qty .ar_top, .pr_details .product-qty .ar_down {
  position: absolute;
  background: #fff;
  border: 0px;
  font-size: 12px;
  color: #677294;
  right: 5px;
  border-radius: 15px;
  padding: 0px 15px 0px 0px;
}

.pr_details .product-qty .ar_top:hover, .pr_details .product-qty .ar_down:hover {
  color: #5e2ced;
}

.pr_details .product-qty .ar_top {
  top: 3px;
}

.pr_details .product-qty .ar_down {
  bottom: 3px;
}

.pr_details .cart_button {
  display: inline-block;
}

.pr_details .cart_button .cart_btn {
  border-radius: 45px;
  font: 400 15px/48px "Poppins", sans-serif;
  color: #677294;
  border: 1px solid #e7e7f6;
  background: #f8f8fc;
  min-width: 140px;
  text-align: center;
  display: inline-block;
  margin: 0px 5px;
}

.pr_details .cart_button .wish_list {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #282835;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #e7e7f6;
}

.pr_details .cart_button .cart_btn, .pr_details .cart_button .wish_list {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.pr_details .cart_button .cart_btn:hover, .pr_details .cart_button .wish_list:hover {
  background: #5e2ced;
  color: #fff;
  border-color: #5e2ced;
}

.pr_details .product_meta {
  margin-bottom: 0;
}

.pr_details .product_meta li {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  color: #677294;
  margin-bottom: 10px;
}

.pr_details .product_meta li a {
  color: #677294;
}

.pr_details .product_meta li a:hover {
  color: #5e2ced;
}

.pr_details .product_meta li span {
  font-weight: 400;
  color: #282835;
  padding-right: 8px;
}

.pr_details .share-link label {
  margin-bottom: 0px;
  font: 400 15px "Poppins", sans-serif;
  color: #282835;
}

.pr_details .share-link .social-icon {
  margin-bottom: 0px;
  display: inline-block;
}

.pr_details .share-link .social-icon li {
  display: inline-block;
}

.pr_details .share-link .social-icon li a {
  font-size: 12px;
  color: #677294;
  padding-right: 10px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.pr_details .share-link .social-icon li a:hover {
  color: #5e2ced;
}

.pr_details_area {
  padding-top: 120px;
}

.product_info_details {
  border-bottom: 1px solid #e2e5ef;
}

.product_info_details .pr_tab {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 0px;
  margin: 0 auto 50px;
}

.product_info_details .pr_tab .nav-item {
  float: left;
  margin: 0px;
  border: 1px solid #e7e7f6;
  overflow: hidden;
  border-left: 0px;
}

.product_info_details .pr_tab .nav-item .nav-link {
  font: 300 16px/40px "Poppins", sans-serif;
  color: #677294;
  padding: 5px 25px;
  min-width: 148px;
  text-align: center;
  border: 0px;
  border-radius: 0px;
}

.product_info_details .pr_tab .nav-item .nav-link.active {
  background: #5e2ced;
  color: #fff;
}

.product_info_details .pr_tab .nav-item:first-child {
  border-bottom-left-radius: 45px;
  border-top-left-radius: 45px;
  border-left: 1px solid #e7e7f6;
}

.product_info_details .pr_tab .nav-item:last-child {
  border-bottom-right-radius: 45px;
  border-top-right-radius: 45px;
}

.product_info_details .aditional_info h6 {
  color: #282835;
}

.product_info_details .comment-box .comment-content {
  padding-left: 95px;
  position: relative;
}

.product_info_details .comment-box .comment-content .avatar {
  position: absolute;
  left: 0;
}

.product_info_details .comment-box .comment-content .avatar img {
  border-radius: 50%;
}

.product_info_details .comment-box .comment-content .comment-header {
  font: 300 14px "Poppins", sans-serif;
  display: inline-block;
}

.product_info_details .comment-box .comment-content .comment-header a {
  font-size: 15px;
  font-weight: 500;
  color: #3d3f45;
}

.product_info_details .comment-box .comment-content .comment-header a:after {
  content: "/";
  display: inline-block;
  margin: 0px 5px 0px 6px;
  font-weight: 400;
  color: #abb0bb;
}

.product_info_details .comment-box .comment-content .ratting a {
  font-size: 12px;
  color: #c9ccd1;
}

.product_info_details .comment-box .comment-content .ratting a:nth-child(1), .product_info_details .comment-box .comment-content .ratting a:nth-child(2), .product_info_details .comment-box .comment-content .ratting a:nth-child(3) {
  color: #5e2ced;
}

.car_get_quote_content {
  padding-bottom: 35px;
}

.car_get_quote_content h2 {
  color: #282835;
}

.car_get_quote_content .get_quote_form .form-group .form-control {
  height: 60px;
  border: 1px solid #ebebf7;
  border-radius: 4px;
  background: transparent;
  font: 300 14px "Poppins", sans-serif;
  color: #282835;
  padding-left: 25px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.car_get_quote_content .get_quote_form .form-group .form-control.placeholder {
  color: #a7adbe;
}

.car_get_quote_content .get_quote_form .form-group .form-control:-moz-placeholder {
  color: #a7adbe;
}

.car_get_quote_content .get_quote_form .form-group .form-control::-moz-placeholder {
  color: #a7adbe;
}

.car_get_quote_content .get_quote_form .form-group .form-control::-webkit-input-placeholder {
  color: #a7adbe;
}

.car_get_quote_content .get_quote_form .form-group .form-control:focus {
  border-color: #ddd1fe;
  background-color: white;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 21, 46, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 21, 46, 0.1);
}

.car_get_quote_content .get_quote_form .form-group textarea.form-control {
  height: 200px;
  padding-top: 20px;
}

.car_get_quote_content .ratting span {
  font-size: 14px;
  color: #282835;
}

.car_get_quote_content .ratting a {
  font-size: 12px;
  color: #c9ccd1;
}

.car_get_quote_content .ratting a:nth-child(4), .car_get_quote_content .ratting a:nth-child(2), .car_get_quote_content .ratting a:nth-child(3) {
  color: #5e2ced;
}

.car_get_quote_content .agency_banner_btn {
  float: right;
  border-radius: 45px;
  height: 46px;
  line-height: 45px;
  padding: 0px;
  min-width: 120px;
  text-align: center;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
}

/*============ shopping_cart_area css =============*/
.shopping_cart_area .cart_title {
  border-bottom: 1px solid #e7e7f6;
}

.shopping_cart_area h6 {
  font-size: 14px;
  color: #282835;
  line-height: 30px;
}

.shopping_cart_area .cart_table {
  padding-top: 30px;
}

.shopping_cart_area .cart_table tbody {
  width: 100%;
}

.shopping_cart_area .cart_table td {
  vertical-align: middle;
  font: 400 16px/28px "Poppins", sans-serif;
  color: #282835;
  border: 0px;
  padding-top: 0px;
  padding-bottom: 30px;
}

.shopping_cart_area .cart_table .product {
  padding-top: 0px;
}

.shopping_cart_area .cart_table .product .media .media-left {
  width: 100px;
  height: 100px;
}

.shopping_cart_area .cart_table .product .media .media-left img {
  max-width: 100%;
}

.shopping_cart_area .cart_table .product .media .media-body {
  padding-left: 30px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.shopping_cart_area .cart_table .product .media .media-body h5 {
  font: 500 16px/28px "Poppins", sans-serif;
  color: #282835;
}

.shopping_cart_area .cart_table .total {
  width: 175px;
}

.shopping_cart_area .cart_table .quantity {
  width: 175px;
}

.shopping_cart_area .cart_table .quantity p {
  color: #677294;
  margin-bottom: 0px;
}

.shopping_cart_area .cart_table .del-item {
  white-space: nowrap;
  vertical-align: middle;
  width: 140px;
}

.shopping_cart_area .cart_table .del-item a {
  color: #677294;
}

.shopping_cart_area .cart_table .del-item a:hover {
  color: #5e2ced;
}

.shopping_cart_area .cart_table .del-item a i {
  font-size: 22px;
}

.shopping_cart_area .cart_table .del-item a + a {
  position: relative;
  top: 3px;
}

.shopping_cart_area .cart_table .del-item .total {
  margin-right: 85px;
}

.shopping_cart_area .cart_table .product-qty {
  position: relative;
  display: inline-block;
}

.shopping_cart_area .cart_table .product-qty .ar_top, .shopping_cart_area .cart_table .product-qty .ar_down {
  position: absolute;
  background: #fff;
  border: 0px;
  font-size: 12px;
  color: #677294;
  right: 4px;
  border-radius: 15px;
  padding: 0px 15px 0px 0px;
  line-height: 12px;
  cursor: pointer;
}

.shopping_cart_area .cart_table .product-qty .ar_top:hover, .shopping_cart_area .cart_table .product-qty .ar_down:hover {
  color: #5e2ced;
}

.shopping_cart_area .cart_table .product-qty .ar_top {
  top: 8px;
}

.shopping_cart_area .cart_table .product-qty .ar_down {
  bottom: 8px;
}

.shopping_cart_area .cart_table .product-qty .manual-adjust {
  width: 95px;
  border: 1px solid #e7e7f6;
  height: 44px;
  border-radius: 45px;
  text-align: center;
  color: #677294;
}

.shopping_cart_area .cart_table .product-qty .manual-adjust.placeholder {
  color: #677294;
}

.shopping_cart_area .cart_table .product-qty .manual-adjust:-moz-placeholder {
  color: #677294;
}

.shopping_cart_area .cart_table .product-qty .manual-adjust::-moz-placeholder {
  color: #677294;
}

.shopping_cart_area .cart_table .product-qty .manual-adjust::-webkit-input-placeholder {
  color: #677294;
}

.shopping_cart_area .cart_btn {
  font: 400 14px "Poppins", sans-serif;
  color: #fff;
  border: 1px solid #5e2ced;
  margin: 0px;
  background: #5e2ced;
  padding: 15px 33px;
  border-radius: 4px;
}

.shopping_cart_area .cart_btn.cart_btn_two {
  border: 1px solid #e7e7f6;
  background: transparent;
  color: #5c6789;
  margin-left: 15px;
}

.shopping_cart_area .action_btn {
  width: 100%;
}

.shopping_cart_area h5 {
  color: #282835;
}

.shopping_cart_area .wislist_table .total, .shopping_cart_area .wislist_table .del-item {
  width: 178px;
}

.shopping_cart_area .wislist_table .quantity {
  width: 140px;
}

.shopping_cart_area .wislist_table .add_cart {
  font: 400 14px "Poppins", sans-serif;
  border: 1px solid #e7e7f6;
  background: transparent;
  color: #5c6789;
  margin-right: 20px;
  padding: 15px 33px;
  border-radius: 4px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.shopping_cart_area .wislist_table .add_cart:hover {
  background: #5e2ced;
  color: #fff !important;
  border-color: #5e2ced;
}

.cart_box {
  width: 370px;
  border: 1px solid #f4f4f9;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  padding: 30px 35px;
}

.cart_box .shop_table {
  width: 100%;
}

.cart_box .shop_table tbody tr {
  border-bottom: 1px solid #e7e7f6;
}

.cart_box .shop_table tbody tr th {
  width: 70%;
}

.cart_box .shop_table tbody tr td, .cart_box .shop_table tbody tr th {
  font: 400 16px/28px "Poppins", sans-serif;
  color: #282835;
  padding: 10px 0px;
}

.cart_box .shop_table tbody tr td {
  text-align: right;
}

.cart_box .shop_table tbody tr td .amount {
  color: #677294;
}

.cart_box .shop_table tbody tr:last-child {
  border: 0px;
}

.coupon {
  width: 370px;
  position: relative;
}

.coupon .input_text {
  width: 100%;
  border: 1px solid #f4f4f9;
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  font: 300 15px "Poppins", sans-serif;
  color: #9ca3b9;
  height: 55px;
  padding-left: 25px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.coupon .input_text:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.coupon .button {
  position: absolute;
  right: 4px;
  top: 50%;
  font: 400 14px/38px "Poppins", sans-serif;
  color: #282835;
  height: 48px;
  padding: 0px 25px;
  border: 0px;
  background: #eff2f7;
  border-radius: 3px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.coupon .button:hover {
  background: #5e2ced;
  color: #fff;
}

.checkout_button {
  font: 500 15px/30px "Poppins", sans-serif;
  color: #fff;
  background: #5e2ced;
  border-radius: 0px;
  display: block;
  text-align: center;
  padding: 10px 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #5e2ced;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.checkout_button:hover {
  background: transparent;
  color: #5e2ced;
}

/*================== check list css ======================*/
.return_customer {
  font: 400 15px/25px "Poppins", sans-serif;
  color: #3d3f45;
  padding-bottom: 20px;
}

.return_customer i {
  color: #5e2ced;
  padding-right: 8px;
  font-size: 14px;
}

.return_customer a {
  color: #5e2ced;
  font-weight: 300;
}

.return_customer a.collapsed {
  color: #9ca3b9;
}

.checkout_content {
  margin-bottom: -22px;
}

.checkout_content .checkout_title {
  margin-top: 63px;
  color: #282835;
}

.checkout_content input {
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  background: #fff;
  padding: 0px 30px;
  font: 300 14px/60px "Poppins", sans-serif;
  color: #969ca5;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 30px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.checkout_content input.placeholder {
  color: #9ca3b9;
}

.checkout_content input:-moz-placeholder {
  color: #9ca3b9;
}

.checkout_content input::-moz-placeholder {
  color: #9ca3b9;
}

.checkout_content input::-webkit-input-placeholder {
  color: #9ca3b9;
}

.checkout_content input:focus {
  -webkit-box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  border-color: #f2effc;
  outline: none;
}

.checkout_content label {
  font: 600 16px/50px "Poppins", sans-serif;
  display: block;
  color: #282835;
  margin-bottom: 20px;
}

.checkout_content label .required {
  font-size: 16px;
  color: #ff2525;
  text-decoration: none;
}

.checkout_content .selectpickers {
  width: 100%;
  border-radius: 0px;
  border: 0px;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 11, 40, 0.06);
  box-shadow: 0px 2px 3px 0px rgba(0, 11, 40, 0.06);
  font: 300 14px/60px "Poppins", sans-serif;
  background: #fff;
  height: 60px;
  color: #9ca3b9;
  padding-left: 30px;
  margin-bottom: 30px;
}

.checkout_content .selectpickers:after {
  right: 30px;
}

.checkout_content .selectpickers ul {
  width: 100%;
}

.checkout_content input[type="checkbox"] {
  position: relative;
  top: 2px;
  width: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.checkout_content input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  border: 1px solid #9ca3b9;
  top: 0;
  left: 0;
  border-radius: 2px;
  background-color: #fbfbfb;
}

.checkout_content input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 49%;
  left: 49%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #9ca3b9;
}

.checkout_content .l_text {
  display: inline-block;
  font: 300 15px/26px "Poppins", sans-serif;
  color: #9ca3b9;
  padding-left: 8px;
}

.checkout_content textarea {
  width: 100%;
  border: 0px;
  border-radius: 0px;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 11, 40, 0.06);
  box-shadow: 0px 2px 3px 0px rgba(0, 11, 40, 0.06);
  height: 170px;
  font: 300 14px/20px "Poppins", sans-serif;
  color: #b6b9be;
  padding-left: 30px;
  padding-top: 20px;
  margin-bottom: 30px;
}

.checkout_content textarea.placeholder {
  color: #9ca3b9;
}

.checkout_content textarea:-moz-placeholder {
  color: #9ca3b9;
}

.checkout_content textarea::-moz-placeholder {
  color: #9ca3b9;
}

.checkout_content textarea::-webkit-input-placeholder {
  color: #9ca3b9;
}

.checkout_content textarea:focus {
  outline: none;
}

.checkout_content .tab_content p {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0px;
  padding: 0px 0px 25px;
}

.checkout_content .tab_content .login_button {
  overflow: hidden;
  margin-bottom: 25px;
}

.checkout_content .tab_content .login_button label {
  display: inline-block;
  margin-bottom: 0px;
  vertical-align: middle;
}

.checkout_content .tab_content .login_button label input {
  margin-bottom: 0px;
}

.checkout_content .tab_content .login_btn {
  float: right;
  font: 400 16px/48px "Poppins", sans-serif;
  background: #5e2ced;
  border: 1px solid #5e2ced;
  color: #fff;
  border-radius: 4px;
  padding: 0px 38px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.checkout_content .tab_content .login_btn:hover {
  background: transparent;
  color: #5e2ced;
}

.checkout_content .tab_content .coupon_form {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.checkout_content .tab_content .coupon_form input {
  max-width: 380px;
  margin-bottom: 0px;
}

.checkout_content .tab_content .coupon_form .login_btn {
  line-height: 60px;
  margin-left: 20px;
}

.cart_total_box {
  margin-left: 50px;
  background: #fff;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 11, 40, 0.06);
  box-shadow: 0px 2px 3px 0px rgba(0, 11, 40, 0.06);
  padding: 50px;
}

.cart_total_box .checkout_title {
  border-bottom: 2px solid #e7e7f6;
  padding-bottom: 10px;
  color: #282835;
}

.woocommerce-checkout-review-order .shop_table {
  width: 100%;
  margin-bottom: 30px;
}

.woocommerce-checkout-review-order .shop_table tbody tr td {
  font: 400 15px/30px "Poppins", sans-serif;
  color: #677294;
}

.woocommerce-checkout-review-order .shop_table tbody tr td.price {
  color: #282835;
}

.woocommerce-checkout-review-order .shop_table tbody tr td.total {
  color: #5e2ced;
  font-size: 20px;
}

.woocommerce-checkout-review-order .shop_table tbody tr td + td {
  text-align: right;
}

.woocommerce-checkout-review-order .shop_table tbody tr.subtotal {
  border-top: 1px solid #dfe2f1;
}

.woocommerce-checkout-review-order .shop_table tbody tr.subtotal td {
  line-height: 56px;
  padding-bottom: 0px;
}

.woocommerce-checkout-review-order h6 {
  font-size: 15px;
  color: #282835;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin: 0px;
  line-height: 30px;
  margin-bottom: 6px;
}

.woocommerce-checkout-review-order .note {
  font-size: 15px;
  line-height: 26px;
  color: #677294;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  margin-bottom: 25px;
}

.woocommerce-checkout-review-order ul li.payment {
  padding-left: 25px;
  position: relative;
}

.woocommerce-checkout-review-order ul li.payment .radio-btn {
  position: absolute;
  left: 0;
}

.woocommerce-checkout-review-order ul li.payment span {
  float: none;
  font-size: 12px;
  color: #ff5f6d;
  padding-top: 15px;
  display: block;
}

.woocommerce-checkout-review-order ul li input[type="checkbox"] {
  position: relative;
  border: 0px;
  visibility: hidden;
  display: none;
}

.woocommerce-checkout-review-order ul li input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.woocommerce-checkout-review-order ul li input[type="checkbox"]:checked + label:before {
  border-color: #5e2ced;
}

.woocommerce-checkout-review-order ul li .radio-btn {
  line-height: 20px;
}

.woocommerce-checkout-review-order ul li .radio-btn label {
  color: #8f8f8f;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  padding-left: 20px;
}

.woocommerce-checkout-review-order ul li .radio-btn label:before {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  border: 1px solid #9ca3b9;
  top: 1px;
  left: 0;
  border-radius: 50%;
}

.woocommerce-checkout-review-order ul li .radio-btn label:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 4px;
  left: 3px;
  border-radius: 50%;
  background: #5e2ced;
  opacity: 0;
}

.woocommerce-checkout-review-order .condition {
  border-top: 1px solid #dfe2f1;
  padding-top: 25px;
  margin-top: 50px;
}

.woocommerce-checkout-review-order .condition p {
  font-size: 15px;
  font-weight: 300;
}

.woocommerce-checkout-review-order .condition input[type="checkbox"] {
  position: relative;
  border: 0px;
  visibility: hidden;
  display: none;
}

.woocommerce-checkout-review-order .condition input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.woocommerce-checkout-review-order .condition input[type="checkbox"]:checked + label:before {
  border-color: #5e2ced;
}

.woocommerce-checkout-review-order .condition .l_text {
  position: relative;
  padding-left: 25px;
  font-size: 16px;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
  color: #282835;
}

.woocommerce-checkout-review-order .condition .l_text span {
  color: #ff1e1e;
}

.woocommerce-checkout-review-order .condition .l_text:before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid #878a8e;
  top: 8px;
  left: 0;
  border-radius: 2px;
  background-color: white;
}

.woocommerce-checkout-review-order .condition .l_text:after {
  content: "";
  display: block;
  width: 5px;
  height: 7px;
  border: solid #5e2ced;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 8px;
  left: 3px;
  opacity: 0;
}

.woocommerce-checkout-review-order .button {
  width: 100%;
  background: #5e2ced;
  border: 1px solid #5e2ced;
  font: 500 16px/50px "Poppins", sans-serif;
  color: #fff;
  cursor: pointer;
  margin-top: 25px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.woocommerce-checkout-review-order .button:hover {
  background: transparent;
  color: #5e2ced;
}

.pr_sidebar .widget + .widget {
  margin-top: 60px;
}

.pr_sidebar .sp_widget_title {
  color: #282835;
  font-size: 20px;
  font-weight: 500;
  position: relative;
  margin-bottom: 30px;
}

.pr_sidebar .filter_slider_area {
  padding-top: 10px;
}

.pr_sidebar .filter_slider_area .ui-slider {
  height: 6px;
  background: #dbe2e9;
  border: 0px;
  border-radius: 0px;
}

.pr_sidebar .filter_slider_area .ui-slider .ui-slider-range {
  background: #9f7efd;
  border-radius: 0px;
}

.pr_sidebar .filter_slider_area .ui-slider .ui-slider-handle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #5e2ced;
  border: 0px;
  margin-left: 0;
  top: -7px;
}

.pr_sidebar .filter_slider_area .ui-slider .ui-slider-handle:focus {
  outline: none;
}

.pr_sidebar .filter_slider_area .filter_content {
  font-size: 16px;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  color: #282835;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
}

.pr_sidebar .filter_slider_area .filter_content #amount {
  border: 0px;
  font-size: 16px;
  display: inline-block;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 5px;
}

.pr_sidebar .widget_category ul {
  margin-bottom: 0;
}

.pr_sidebar .widget_category ul li a {
  font-size: 16px;
  color: #677294;
  display: inline-block;
  position: relative;
}

.pr_sidebar .widget_category ul li a:before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #5e2ced;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.pr_sidebar .widget_category ul li a:hover {
  color: #5e2ced;
}

.pr_sidebar .widget_category ul li a:hover:before {
  opacity: 1;
}

.pr_sidebar .widget_category ul li:not(:last-child) {
  margin-bottom: 15px;
}

.pr_sidebar .woocommerce-widget-layered-nav-list {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.pr_sidebar .woocommerce-widget-layered-nav-list li {
  position: relative;
  padding-left: 30px;
  color: #333;
}

.pr_sidebar .woocommerce-widget-layered-nav-list li::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: 1px solid #333333;
  left: 0;
  top: 50%;
  margin-top: -7.5px;
}

.pr_sidebar .woocommerce-widget-layered-nav-list li a {
  color: #333;
}

.pr_sidebar .woocommerce-widget-layered-nav-list li:not(:last-child) {
  margin-bottom: 12px;
}

.pr_sidebar .widget_size ul {
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
}

.pr_sidebar .widget_size ul li {
  font-size: 16px;
  color: #333333;
}

.pr_sidebar .widget_size ul li a {
  color: #333333;
}

.pr_sidebar .widget_color ul li:first-child:before {
  background: #1c1c1c;
  border-color: #1c1c1c;
}

.pr_sidebar .widget_color ul li:nth-child(2):before {
  background: #ff934c;
  border-color: #ff934c;
}

.pr_sidebar .widget_color ul li:nth-child(3):before {
  background: #fb53a7;
  border-color: #fb53a7;
}

.pr_sidebar .widget_color ul li:nth-child(4):before {
  background: #ff4949;
  border-color: #ff4949;
}

.pr_sidebar .widget_color ul li:nth-child(6):before {
  background: #ffeb50;
  border-color: #ffeb50;
}

.pr_sidebar .widget_product ul {
  margin-bottom: 0;
}

.pr_sidebar .widget_product ul li .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_sidebar .widget_product ul li .media img {
  padding-right: 30px;
}

.pr_sidebar .widget_product ul li .media .media-body h3 {
  font-size: 16px;
  color: #282835;
}

.pr_sidebar .widget_product ul li .media .media-body .rate {
  color: #677294;
  font-size: 16px;
}

.pr_sidebar .widget_product ul li:not(:last-child) {
  margin-bottom: 20px;
}

.pr_sidebar .widget_tag ul li {
  display: inline-block;
  margin-bottom: 6px;
}

.pr_sidebar .widget_tag ul li a {
  font-size: 16px;
  color: #677294;
  position: relative;
  display: block;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.pr_sidebar .widget_tag ul li a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #5e2ced;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.pr_sidebar .widget_tag ul li a:hover {
  color: #5e2ced;
}

.pr_sidebar .widget_tag ul li a:hover:before {
  opacity: 1;
}

/*====================================================*/
/*====================================================*/
.search_boxs {
  z-index: 20000;
  position: fixed;
  top: -105%;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}

.search_boxs:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: rgba(27, 29, 34, 0.95);
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 100%;
  -webkit-transform: scale(0.04), translateY(9999px);
  -ms-transform: scale(0.04), translateY(9999px);
  transform: scale(0.04), translateY(9999px);
  overflow: hidden;
}

.search_boxs .close_icon {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  opacity: 0;
}

.search_boxs .input-group {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  max-width: 800px;
  width: 20%;
  opacity: 0;
  -webkit-transition: all 900ms linear;
  -o-transition: all 900ms linear;
  transition: all 900ms linear;
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

.search_boxs .input-group input {
  border: none;
  height: 55px;
  padding: 0px 15px;
  font-size: 16px;
  padding-left: 0px;
  width: 100%;
  color: #fff;
  background: transparent;
  border-bottom: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 0px;
  outline: none;
}

.search_boxs .input-group input.placeholder {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.search_boxs .input-group input:-moz-placeholder {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.search_boxs .input-group input::-moz-placeholder {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.search_boxs .input-group input::-webkit-input-placeholder {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.search_boxs .input-group .input-group-append {
  margin-left: 0px;
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 4;
}

.search_boxs .input-group .input-group-append button {
  border: none;
  background: transparent;
  border-radius: 0px;
  height: 55px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 24px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: pointer;
}

.search_boxs .input-group .input-group-append button i {
  cursor: pointer;
}

input[type="text"] {
  -webkit-appearance: none;
  outline: none;
}

.open .search_boxs {
  top: 0px;
}

.open .search_boxs:before {
  -webkit-animation: menu-animation 0.8s ease-out forwards;
  animation: menu-animation 0.8s ease-out forwards;
  height: 100%;
}

.open .search_boxs .close_icon {
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
  opacity: 1;
}

.open .search_boxs .input-group {
  width: 100%;
  opacity: 1;
}

/* Css keyframes css
============================================================================================ */
@-webkit-keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    -o-transition: ease-out;
    transition: ease-out;
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }
  61% {
    -webkit-transform: scale(0.04);
    transform: scale(0.04);
  }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

@keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    -o-transition: ease-out;
    transition: ease-out;
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }
  61% {
    -webkit-transform: scale(0.04);
    transform: scale(0.04);
  }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

/* End Css keyframes css
============================================================================================ */
/*====================================================*/
/*====================================================*/
.ms-section.section_1 {
  background-image: -moz-linear-gradient(40deg, #6712a8 0%, #5f28fb 100%);
  background-image: -webkit-linear-gradient(40deg, #6712a8 0%, #5f28fb 100%);
  background-image: -ms-linear-gradient(40deg, #6712a8 0%, #5f28fb 100%);
}

.ms-section.section_2 {
  background-image: -moz-linear-gradient(40deg, #6712a8 0%, #5f28fb 100%);
  background-image: -webkit-linear-gradient(40deg, #6712a8 0%, #5f28fb 100%);
  background-image: -ms-linear-gradient(40deg, #6712a8 0%, #5f28fb 100%);
}

.ms-section.section_4 {
  background-image: -moz-linear-gradient(40deg, #6712a8 0%, #5f28fb 100%);
  background-image: -webkit-linear-gradient(40deg, #6712a8 0%, #5f28fb 100%);
  background-image: -ms-linear-gradient(40deg, #6712a8 0%, #5f28fb 100%);
}

.ms-section.section_6 {
  background-image: -webkit-linear-gradient(310deg, #6712a8 0%, #5f28fb 100%);
  background-image: -o-linear-gradient(310deg, #6712a8 0%, #5f28fb 100%);
  background-image: -webkit-linear-gradient(50deg, #6712a8 0%, #5f28fb 100%);
  background-image: -o-linear-gradient(50deg, #6712a8 0%, #5f28fb 100%);
  background-image: linear-gradient(40deg, #6712a8 0%, #5f28fb 100%);
}

.height {
  height: 100%;
}

.split_banner, .split_banner_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.square {
  width: 50px;
  height: 50px;
}

.square.one {
  top: 200px;
  left: 50px;
  -webkit-animation: spin2 2s infinite alternate;
  animation: spin2 2s infinite alternate;
}

.square.two {
  top: 220px;
  left: 70px;
  -webkit-animation: spin2 2.5s infinite alternate;
  animation: spin2 2.5s infinite alternate;
}

.square.three {
  height: 102px;
  width: 102px;
  top: auto;
  left: 20px;
  bottom: -10%;
  -webkit-animation: spin1 3s infinite alternate;
  animation: spin1 3s infinite alternate;
}

.square.four {
  bottom: 43%;
  left: 80px;
  -webkit-animation: spin2 2s infinite alternate;
  animation: spin2 2s infinite alternate;
}

.intro {
  width: 70%;
  margin-left: auto;
  margin-right: 100px;
  position: relative;
}

.split_slider_content {
  color: #fff;
  padding: 70px 0px 70px 120px;
  position: relative;
  z-index: 0;
}

.split_slider_content .br_shap {
  position: absolute;
  left: 0;
  top: 0;
  width: 330px;
  border: 10px solid #8429c9;
  height: 100%;
  z-index: -1;
}

.split_slider_content h2 {
  font-size: 50px;
  line-height: 66px;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
  z-index: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;
  color: #fff;
}

.split_slider_content h2:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0.8;
  position: absolute;
  z-index: 0;
  -webkit-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;
}

.split_slider_content p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 45px;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
  color: #fff;
}

.split_slider_content .btn_get {
  border: 1px solid #fff;
  border-radius: 3px;
  color: #fff;
  padding: 3px 33px;
  font-size: 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

.split_slider_content .btn_get:hover {
  background: #fff;
  color: #5f28fb;
}

.split_slider_content .content {
  overflow: hidden;
}

.spliet_slider_img .phone_one {
  position: absolute;
  top: -23%;
  z-index: 0;
  left: 111px;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: animateUpDown;
  animation-name: animateUpDown;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.spliet_slider_img .phone_two {
  position: relative;
  left: 40px;
  top: 112px;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: animateUpDown;
  animation-name: animateUpDown;
  -webkit-animation-duration: 3.3s;
  animation-duration: 3.3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.border_shap {
  position: absolute;
  width: 400px;
  height: 700px;
  background: rgba(255, 255, 255, 0.05);
  top: 0;
  left: 100px;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  opacity: 0;
  visibility: hidden;
}

.border_shap.two {
  left: 36%;
  top: -20%;
}

.square {
  position: absolute;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.05);
}

.active .border_shap {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition: 1.5s ease-in-out;
  -o-transition: 1.5s ease-in-out;
  transition: 1.5s ease-in-out;
}

.active .border_shap.two {
  -webkit-transition: -webkit-transform 2.5s linear;
  transition: -webkit-transform 2.5s linear;
  -o-transition: transform 2.5s linear;
  transition: transform 2.5s linear;
  transition: transform 2.5s linear, -webkit-transform 2.5s linear;
}

.active .split_slider_content h2 {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 1.5s linear;
  -o-transition: all 1.5s linear;
  transition: all 1.5s linear;
}

.active .split_slider_content h2:before {
  right: 0;
  left: auto;
  width: 0;
  -webkit-transition: all 2s linear;
  -o-transition: all 2s linear;
  transition: all 2s linear;
}

.active .split_slider_content p {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition: opacity 1s 1.5s linear , -webkit-transform 1s 1.5s linear;
  transition: opacity 1s 1.5s linear , -webkit-transform 1s 1.5s linear;
  -o-transition: transform 1s 1.5s linear , opacity 1s 1.5s linear;
  transition: transform 1s 1.5s linear , opacity 1s 1.5s linear;
  transition: transform 1s 1.5s linear , opacity 1s 1.5s linear , -webkit-transform 1s 1.5s linear;
}

.active .split_slider_content .btn_get {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: opacity 1s 1.7s linear,background 0.2s linear , -webkit-transform 1s 1.8s linear;
  transition: opacity 1s 1.7s linear,background 0.2s linear , -webkit-transform 1s 1.8s linear;
  -o-transition: transform 1s 1.8s linear , opacity 1s 1.7s linear,background 0.2s linear;
  transition: transform 1s 1.8s linear , opacity 1s 1.7s linear,background 0.2s linear;
  transition: transform 1s 1.8s linear , opacity 1s 1.7s linear,background 0.2s linear , -webkit-transform 1s 1.8s linear;
  opacity: 1;
}

.split_app_content h2 {
  margin-bottom: 38px;
}

.split_app_content p {
  font-size: 18px;
  line-height: 30px;
  color: #7e86a1;
  margin-bottom: 0;
}

.split_app_content .btn_three {
  font-size: 15px;
  padding: 15px 33px;
}

.split_app_content .s_app_btn {
  background: #5f28fb;
  border-color: #5f28fb;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.split_app_content .s_app_btn i {
  margin-right: 10px;
  font-size: 22px;
}

.split_app_content .s_app_btn + .s_app_btn {
  background: transparent;
  color: #5f28fb;
  margin-left: 16px;
}

.split_app_content .s_app_btn + .s_app_btn:hover {
  background: #5f28fb;
  color: #fff;
}

.split_app_content .s_app_btn:hover {
  background: transparent;
  color: #5f28fb;
}

.split_title {
  font-size: 40px;
  line-height: 50px;
  color: #051441;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.split_title span {
  font-weight: 400;
}

.web_skill_content {
  max-width: 540px;
}

.web_skill_content .split_title {
  margin-bottom: 45px;
}

.web_skill_content .skillbar-bg {
  width: 100%;
  height: 5px;
  background: #e7e9f0;
  position: relative;
}

.web_skill_content .skillbar-bg .custom-skillbar {
  background: #7a4cfa;
  height: 100%;
  position: absolute;
  left: 0;
  width: 0;
}

.web_skill_content .custom-skillbar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 500;
  color: #051441;
  padding-bottom: 8px;
}

.web_skill_content .skillbar-box {
  padding-left: 0px;
  list-style: none;
  margin-bottom: 0;
}

.web_skill_content .skillbar-box li {
  margin-bottom: 25px;
}

.web_skill_content .skillbar-box li:nth-child(1) .custom-skillbar-title .skill-bar-percent {
  color: #7a4cfa;
}

.web_skill_content .skillbar-box li:nth-child(1) .skillbar-bg .custom-skillbar {
  background: #7a4cfa;
}

.web_skill_content .skillbar-box li:nth-child(2) .custom-skillbar-title .skill-bar-percent {
  color: #00d8e6;
}

.web_skill_content .skillbar-box li:nth-child(2) .skillbar-bg .custom-skillbar {
  background: #00d8e6;
}

.web_skill_content .skillbar-box li:nth-child(3) .custom-skillbar-title .skill-bar-percent {
  color: #e6c300;
}

.web_skill_content .skillbar-box li:nth-child(3) .skillbar-bg .custom-skillbar {
  background: #e6c300;
}

.web_skill_content .btn_three {
  margin-top: 35px;
  background: transparent;
  color: #7a4cfa;
}

.web_skill_content .btn_three:hover {
  background: #7a4cfa;
  color: #fff;
}

.split_banner .contact_bg {
  position: absolute;
  top: 0;
  background: url("../img/new/home-split/contact.png");
  width: 100%;
  height: 100%;
  background-size: cover;
}

.split_banner .app_img {
  margin-left: 118px;
}

.split_content .contact_form_box {
  padding-top: 45px;
}

.split_content .contact_form_box .text_box input[type="text"], .split_content .contact_form_box .text_box textarea, .split_content .contact_form_box .text_box input[type="password"] {
  background: #f7f8fb;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.split_content .contact_form_box .text_box input[type="text"]:focus, .split_content .contact_form_box .text_box textarea:focus, .split_content .contact_form_box .text_box input[type="password"]:focus {
  -webkit-box-shadow: 0px 20px 20px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 20px 0px rgba(0, 11, 40, 0.1);
  background: #fff;
  border-color: rgba(95, 40, 251, 0.4);
}

.split_content .contact_form_box .text_box {
  margin-bottom: 20px;
}

#multiscroll-nav ul li a span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #e6e7ec;
  border: 0px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#multiscroll-nav ul li a.active span {
  background: #5f28fb;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#multiscroll-nav.white ul li a span {
  background: #8051f1;
}

#multiscroll-nav.white ul li a.active span {
  background: #fff;
}

/*====================================================*/
/*====================================================*/
/*============ hosting_menu css =============*/
.hosting_menu.menu_four .btn_get {
  background: transparent;
  border-color: #818198;
  color: #fff;
  line-height: 42px;
}

.hosting_menu.menu_four .btn_get:hover {
  background: #f8636b;
  border-color: #f8636b;
}

.hosting_menu.menu_four .w_menu > .nav-item > .nav-link:before {
  display: none;
}

.hosting_menu.menu_four .w_menu > .nav-item:hover > .nav-link, .hosting_menu.menu_four .w_menu > .nav-item.active > .nav-link {
  color: #f8636b;
}

.hosting_menu.menu_four .w_menu > .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link, .hosting_menu.menu_four .w_menu > .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link, .hosting_menu.menu_four .w_menu > .nav-item.submenu .dropdown-menu .nav-item.active > .nav-link {
  color: #f8636b;
}

.hosting_menu.menu_four .menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .text:hover {
  color: #f8636b;
}

.navbar_fixed .hosting_menu.menu_four .btn_get {
  background: #f8636b;
  border-color: #f8636b;
  color: #fff;
}

.navbar_fixed .hosting_menu.menu_four .btn_get:hover {
  background: #f8636b;
  border-color: #f8636b;
}

/*============ hosting_menu css =============*/
/*============ erp_menu css =============*/
.erp_menu .er_btn {
  margin-left: 0;
  margin-top: 0;
}

.erp_menu .menu > .nav-item > .nav-link:before {
  display: none;
}

.erp_menu .menu > .nav-item:hover > .nav-link, .erp_menu .menu > .nav-item.active > .nav-link {
  color: #4e78f4;
}

.erp_menu .menu > .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link, .erp_menu .menu > .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link, .erp_menu .menu > .nav-item.submenu .dropdown-menu .nav-item.active > .nav-link {
  color: #4e78f4;
}

.erp_menu .menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .text:hover {
  color: #4e78f4;
}

/*============ erp_menu css =============*/
/*================= hosting_banner_area css ==============*/
.hosting_banner_area {
  background: #020230;
  min-height: 650px;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.hosting_content h2 {
  font-size: 50px;
  line-height: 70px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
}

.hosting_content p {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 50px;
}

.hosting_content .hosting_btn {
  -webkit-box-shadow: 0px 10px 50px 0px rgba(248, 99, 107, 0.3);
  box-shadow: 0px 10px 50px 0px rgba(248, 99, 107, 0.3);
}

.hosting_content .hosting_btn:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.b_line li {
  position: absolute;
}

.b_line li:nth-child(1) {
  bottom: 200px;
  right: 500px;
}

.b_line li:nth-child(2) {
  top: 100px;
  left: 135px;
}

.b_line li:nth-child(3) {
  top: 85%;
  left: 100px;
}

.b_line li:nth-child(4) {
  bottom: 120px;
  right: 750px;
}

.b_line li:nth-child(7) {
  top: 50%;
  right: 200px;
}

.b_line li:nth-child(6) {
  top: 55%;
  left: 195px;
}

.b_line li:nth-child(5) {
  bottom: 150px;
  right: 895px;
}

.b_line li:nth-child(8) {
  top: 220px;
  right: 995px;
}

.hosting_btn {
  background: #f8636b;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  padding: 11px 34px;
  -webkit-box-shadow: 0px 10px 50px 0px rgba(248, 99, 107, 0.3);
  box-shadow: 0px 10px 50px 0px rgba(248, 99, 107, 0.3);
}

.hosting_btn:hover {
  background: #fe4c55;
}

/*================= hosting_banner_area css ==============*/
/*================= domain_search_area css ==============*/
.domain_search_area {
  background: #fffafa;
  position: relative;
  z-index: 1;
}

.map_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../img/hosting/map.png") no-repeat scroll center 0/cover;
  z-index: -1;
}

.domain_box_info {
  max-width: 1030px;
  margin: 0 auto;
  text-align: center;
}

.domain_box_info h3 {
  font-size: 30px;
  color: #2c2c51;
  font-weight: 600;
  margin-bottom: 40px;
}

.domain_form_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 8px;
  background-color: white;
  -webkit-box-shadow: 0px 50px 100px 0px rgba(64, 1, 4, 0.1);
  box-shadow: 0px 50px 100px 0px rgba(64, 1, 4, 0.1);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  border-left: 8px solid #f8636b;
}

.domain_form_inner input {
  width: 64%;
  border: 0px;
  border-radius: 0px;
  padding: 0px;
  font-size: 20px;
  font-weight: 300;
  padding-left: 40px;
}

.domain_form_inner input.placeholder {
  color: #9d9db0;
}

.domain_form_inner input:-moz-placeholder {
  color: #9d9db0;
}

.domain_form_inner input::-moz-placeholder {
  color: #9d9db0;
}

.domain_form_inner input::-webkit-input-placeholder {
  color: #9d9db0;
}

.domain_form_inner input:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.domain_form_inner .domain_select {
  width: 15%;
  padding-right: 20px;
}

.domain_form_inner .domain_select .selectpickers {
  border: 0px;
  width: 100%;
  padding: 0px;
  text-align: center !important;
  border-left: 1px solid #d8dcea;
  border-radius: 0px;
  font-size: 20px;
  color: #9d9db0;
  padding-left: 15px;
  font-weight: 300;
  background: transparent;
}

.domain_form_inner .domain_select .selectpickers:after {
  border-bottom: 1px solid #9d9db0;
  border-right: 1px solid #9d9db0;
  right: 35px;
  width: 6px;
  height: 6px;
}

.domain_form_inner .domain_select .selectpickers ul {
  width: 100%;
}

.domain_form_inner .domain_select .selectpickers ul li {
  color: #9d9db0;
}

.domain_form_inner .domain_select .selectpickers:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.domain_form_inner button {
  width: 21%;
  border: 0px;
  padding: 25px 32px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.domain_form_inner button:before {
  content: "\55";
  font-family: eleganticons;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
  margin-right: 10px;
}

.domain_price {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-top: 45px;
}

.domain_price li {
  font-size: 18px;
  font-weight: 500;
  color: #7b7b93;
}

.domain_price li + li {
  margin-left: 50px;
}

.domain_price li span {
  font-weight: 400;
  font-size: 16px;
  margin-left: 8px;
}

/*================= domain_search_area css ==============*/
.hosting_service_area {
  position: relative;
}

.hosting_title {
  max-width: 700px;
  margin: 0 auto 70px;
}

.hosting_title h2 {
  font-size: 40px;
  line-height: 55px;
  font-weight: 600;
  color: #2c2c51;
  margin-bottom: 15px;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

.hosting_title p {
  color: #7b7b93;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.hosting_title .w_color {
  color: #fff;
}

.hosting_service_item {
  padding: 60px 40px;
  border-top: 6px solid transparent;
  border-radius: 6px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}

.hosting_service_item .icon {
  width: 70px;
  height: 60px;
  line-height: 60px;
  border-radius: 6px;
  background: #fff7f7;
  text-align: center;
  margin-bottom: 37px;
}

.hosting_service_item p {
  color: #7b7b93;
  margin-bottom: 0;
  font-weight: 400;
}

.hosting_service_item:hover {
  border-color: #f6626a;
  background: #fff;
  -webkit-box-shadow: 0px 50px 100px 0px rgba(64, 1, 4, 0.1), 0px -6px 0px 0px rgba(248, 99, 107, 0.004);
  box-shadow: 0px 50px 100px 0px rgba(64, 1, 4, 0.1), 0px -6px 0px 0px rgba(248, 99, 107, 0.004);
}

.pattern_shap {
  position: absolute;
  width: 125px;
  height: 225px;
  left: 0;
  top: 200px;
}

.pos_service_info .hosting_service_item {
  padding: 45px 40px;
}

.pos_service_info .hosting_service_item h4:hover {
  color: #5e53fb;
}

.pos_service_info .hosting_service_item img {
  margin-bottom: 33px;
}

.pos_service_info .hosting_service_item:hover {
  border-color: #5e53fb;
  -webkit-box-shadow: 0px 50px 100px 0px rgba(5, 1, 64, 0.1), 0px -5px 0px 0px rgba(95, 84, 253, 0.004);
  box-shadow: 0px 50px 100px 0px rgba(5, 1, 64, 0.1), 0px -5px 0px 0px rgba(95, 84, 253, 0.004);
}

.h_head {
  font-size: 20px;
  font-weight: 600;
  color: #2c2c51;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

a .h_head:hover {
  color: #f6626a;
}

/*========== hosting_features_area css ========*/
.hosting_features_area {
  padding-bottom: 140px;
}

.h_features_content {
  padding-right: 80px;
}

.h_features_content .hosting_title {
  margin-bottom: 40px;
}

.h_features_content .hosting_title h2 {
  font-weight: 500;
}

.h_features_item {
  padding-right: 150px;
}

.h_features_item img {
  margin-right: 20px;
}

.h_features_item .h_head {
  margin-bottom: 10px;
}

.h_features_item p {
  margin-bottom: 0;
  font-weight: 400;
}

.h_features_item + .h_features_item {
  margin-top: 35px;
}

/*========== hosting_features_area css ========*/
/*========== h_action_area css ========*/
.h_action_area {
  background: #020230;
  padding: 80px 0px;
}

.h_action_content h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 54px;
  margin-bottom: 32px;
}

.h_action_content p {
  color: #b4b4d3;
  margin-bottom: 42px;
}

/*========== h_action_area css ========*/
/*========== h_blog_area css ========*/
.h_blog_item {
  background-color: white;
  -webkit-box-shadow: 0px 30px 50px 0px rgba(64, 1, 4, 0.06);
  box-shadow: 0px 30px 50px 0px rgba(64, 1, 4, 0.06);
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.h_blog_item img {
  width: 100%;
}

.h_blog_item .h_blog_content {
  padding: 25px 40px 30px;
}

.h_blog_item .h_blog_content h3 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  color: #2c2c51;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.h_blog_item .h_blog_content h3:hover {
  color: #f8636b;
}

.h_blog_item .h_blog_content .post_time {
  font-size: 14px;
  color: #7b7b93;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: inline-block;
}

.h_blog_item .h_blog_content .post_time i {
  color: #f8636b;
  margin-right: 5px;
}

.h_blog_item .h_blog_content .post-info-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 15px;
}

.h_blog_item .h_blog_content .post-info-bottom .learn_btn_two {
  margin-top: 0;
  font-size: 14px;
  line-height: 16px;
  color: #7b7b93;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
}

.h_blog_item .h_blog_content .post-info-bottom .learn_btn_two:before {
  content: "";
  height: 1px;
  background: #f8636b;
  position: absolute;
  bottom: 0px;
  width: 0;
  -webkit-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  transition: width 0.2s linear;
}

.h_blog_item .h_blog_content .post-info-bottom .learn_btn_two:hover {
  color: #f8636b;
}

.h_blog_item .h_blog_content .post-info-bottom .learn_btn_two:hover:before {
  width: 100%;
}

.h_blog_item .h_blog_content .post-info-bottom .learn_btn_two:hover i {
  padding-left: 5px;
}

.h_blog_item .h_blog_content .post-info-bottom .learn_btn_two i {
  vertical-align: inherit;
  padding-left: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.h_blog_item .h_blog_content .post-info-bottom .post-info-comments {
  color: #7b7b93;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.h_blog_item .h_blog_content .post-info-bottom .post-info-comments i {
  color: #f8636b;
  padding-right: 3px;
}

.h_blog_item:hover {
  -webkit-box-shadow: 0px 30px 50px 0px rgba(64, 1, 4, 0.08);
  box-shadow: 0px 30px 50px 0px rgba(64, 1, 4, 0.08);
}

.pos_blog_item {
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 30px 50px 0px rgba(5, 1, 64, 0.06);
  box-shadow: 0px 30px 50px 0px rgba(5, 1, 64, 0.06);
}

.pos_blog_item .h_blog_content {
  padding-left: 35px;
  padding-right: 35px;
}

.pos_blog_item .h_blog_content .post_time i {
  color: #5f54fd;
}

.pos_blog_item .h_blog_content h3 {
  font-size: 20px;
  line-height: 30px;
}

.pos_blog_item .h_blog_content h3:hover {
  color: #5f54fd;
}

.pos_blog_item .h_blog_content .post-info-bottom .learn_btn_two:before {
  background: #5f54fd;
}

.pos_blog_item .h_blog_content .post-info-bottom .learn_btn_two:hover {
  color: #5f54fd;
}

.pos_blog_item .h_blog_content .post-info-bottom .post-info-comments i {
  color: #5f54fd;
}

.pos_blog_item:hover {
  -webkit-box-shadow: 0px 30px 50px 0px rgba(5, 1, 64, 0.08);
  box-shadow: 0px 30px 50px 0px rgba(5, 1, 64, 0.08);
}

/*========== h_blog_area css ========*/
.h_action_promo_area {
  background: #f8636b;
  position: relative;
  padding: 130px 0px;
  z-index: 1;
}

.h_action_promo_area .overlay_bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  z-index: -1;
  opacity: 0.1;
}

.h_action_promo_area .hosting_btn {
  background: #fff;
  color: #f8636b;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(57, 35, 125, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(57, 35, 125, 0.1);
  border-radius: 2px;
  padding: 16px 34px;
  margin-top: 10px;
  text-align: center;
}

.h_action_promo_area .hosting_btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.h_promo_content h2 {
  margin-bottom: 0;
  margin-bottom: 10px;
  color: #fff;
  font-size: 40px;
  font-weight: 600;
}

.h_promo_content p {
  margin-bottom: 0;
  font-size: 20px;
  color: #fff;
  font-weight: 400;
}

/*=========== h_map_area ==========*/
.h_map_area {
  background: #fffafa;
  padding-top: 120px;
}

.h_map_area .hosting_title {
  margin-bottom: 0;
}

.h_map {
  background: url("../img/hosting/region_map.png") no-repeat scroll center top;
  height: 595px;
}

.h_map ul {
  height: 100%;
  margin-bottom: 0;
  position: relative;
}

.h_map ul li {
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.h_map ul li .place_name {
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
  color: #fff;
  border-radius: 45px;
  background: #f8636b;
  padding: 0px 12px;
  text-transform: uppercase;
  position: absolute;
  top: -100%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(214, 43, 51, 0.2);
  box-shadow: 0px 20px 30px 0px rgba(214, 43, 51, 0.2);
}

.h_map ul li .place_name:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: #f8636b transparent transparent transparent;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -5px;
}

.h_map ul li .round {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f8636b;
  position: relative;
}

.h_map ul li .round:before, .h_map ul li .round:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  background: #f8636b;
}

.h_map ul li .round:before {
  -webkit-transform: scale(2.6);
  -ms-transform: scale(2.6);
  transform: scale(2.6);
  opacity: 0.4;
}

.h_map ul li .round:after {
  -webkit-transform: scale(5);
  -ms-transform: scale(5);
  transform: scale(5);
  opacity: 0.2;
}

.h_map ul li .round .dot {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgba(247, 98, 106, 0.35);
  -webkit-animation: pulsate 3s infinite;
  animation: pulsate 3s infinite;
  -webkit-animation-delay: 1.5s;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation-delay: 1.5s;
  will-change: transform;
}

.h_map ul li:nth-child(1) {
  top: 80px;
  left: 100px;
}

.h_map ul li:nth-child(2) {
  top: 220px;
  left: 250px;
}

.h_map ul li:nth-child(3) {
  top: 180px;
  left: 50%;
}

.h_map ul li:nth-child(4) {
  top: 250px;
  left: 70%;
}

.h_map ul li:nth-child(5) {
  top: 100px;
  left: 80%;
}

.h_map ul li:nth-child(6) {
  top: 70%;
  left: 350px;
}

.h_map ul li:nth-child(7) {
  top: 60%;
  left: 54%;
}

.h_map ul li:nth-child(8) {
  top: 75%;
  left: 85%;
}

.h_footer_dark {
  background: #010e28;
  padding: 100px 0px 120px;
}

.h_footer_dark .company_widget p {
  font-size: 16px;
  font-weight: 400;
  margin-top: 32px;
  margin-bottom: 0;
}

.h_footer_dark .company_widget .f_social_icon {
  margin-top: 50px;
}

.h_footer_dark .company_widget .f_social_icon a {
  background: transparent;
  font-size: 14px;
  color: #7b7b93;
  border: 1px solid #2c3445;
}

.h_footer_dark .company_widget .f_social_icon a:hover {
  background: #f8636b;
  color: #fff;
  border-color: #f8636b;
}

.h_footer_dark .f_widget.about-widget .f_list li a, .h_footer_dark .f_widget.company_widget p {
  color: #7b7b93;
  font-weight: 400;
}

.h_footer_dark .f_widget.about-widget .f_list li {
  margin-bottom: 10px;
}

.h_footer_dark .f_widget.about-widget .f_list li a:before {
  background: #f8636b;
}

.h_footer_dark .f_widget.about-widget .f_list li a:hover {
  color: #f8636b;
}

.h_footer_dark_two .company_widget .f_social_icon a:hover {
  background: #4f79f6;
  border-color: #4f79f6;
}

.h_footer_dark_two .f_widget.about-widget .f_list li a:before {
  display: none;
}

.h_footer_dark_two .f_widget.about-widget .f_list li a:hover {
  color: #4f79f6;
}

.pos_footer_area {
  background: #413c85;
  padding-bottom: 0;
  padding-top: 120px;
  position: relative;
  z-index: 0;
}

.pos_footer_area .leaf_left, .pos_footer_area .leaf_right {
  position: absolute;
  bottom: 0;
}

.pos_footer_area .leaf_left {
  left: 0;
}

.pos_footer_area .leaf_right {
  right: 0;
}

.pos_footer_area .top_shap {
  position: absolute;
  bottom: 100%;
  height: 100%;
  left: 0;
  width: 100%;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: -1;
}

.pos_footer_area:before {
  content: "";
  width: 100%;
  height: 40px;
  background: #49458c;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pos_footer_area:after {
  content: "";
  width: 100%;
  height: 100%;
  background: url(../img/pos/wave_two.png) no-repeat scroll center bottom;
  position: absolute;
  left: 0;
  top: -100%;
  z-index: -1;
}

.pos_footer_area .f_widget.company_widget p {
  color: #bab5df;
}

.pos_footer_area .f_widget.company_widget .f_social_icon a {
  color: #bab5df;
  border-color: #6e679b;
}

.pos_footer_area .f_widget.company_widget .f_social_icon a:hover {
  background: #fff;
  border-color: #fff;
  color: #5f54fd;
}

.pos_footer_area .f_widget.about-widget .f_list li a {
  color: #bab5df;
}

.pos_footer_area .f_widget.about-widget .f_list li a:before {
  background: #fff;
}

.pos_footer_area .f_widget.about-widget .f_list li a:hover {
  color: #fff;
}

.pos_footer_bottom {
  padding-top: 80px;
  padding-bottom: 30px;
}

.pos_footer_bottom .pos_women {
  position: relative;
  bottom: -12px;
}

.wave_shap {
  width: 100%;
  position: absolute;
  height: 212px;
  bottom: 0;
  z-index: -1;
}

/*============== erp_banner_area_two css =============*/
.erp_banner_area_two {
  background: #f9f9fe;
  position: relative;
  z-index: 0;
}

.erp_banner_area_two + section {
  position: relative;
  z-index: 1;
}

.erp_banner_area_two .animation_img {
  margin-top: -380px;
}

.erp_banner_area_two .cloud_animation {
  margin-bottom: 0;
}

.erp_banner_area_two .cloud_animation li {
  position: absolute;
}

.erp_banner_area_two .cloud_animation li:nth-child(1) {
  top: 220px;
  left: 170px;
  -webkit-animation: jurk 4s 5s linear infinite alternate;
  animation: jurk 4s 5s linear infinite alternate;
}

.erp_banner_area_two .cloud_animation li:nth-child(2) {
  top: 420px;
  left: 370px;
  -webkit-animation: jurk 4s 2s linear infinite alternate;
  animation: jurk 4s 2s linear infinite alternate;
}

.erp_banner_area_two .cloud_animation li:nth-child(3) {
  left: 120px;
  top: 50%;
  -webkit-animation: jurk 3s 3s linear infinite alternate;
  animation: jurk 3s 3s linear infinite alternate;
}

.erp_banner_area_two .cloud_animation li:nth-child(6) {
  top: 220px;
  right: 170px;
  -webkit-animation: jurk 5s 3s linear infinite alternate;
  animation: jurk 5s 3s linear infinite alternate;
}

.erp_banner_area_two .cloud_animation li:nth-child(4) {
  top: 500px;
  right: 280px;
  -webkit-animation: jurk 4s 2s linear infinite alternate;
  animation: jurk 4s 2s linear infinite alternate;
}

.erp_banner_area_two .cloud_animation li:nth-child(5) {
  right: 120px;
  top: 380px;
  -webkit-animation: jurk 8s 2s linear infinite alternate;
  animation: jurk 8s 2s linear infinite alternate;
}

.erp_banner_area_two .erp_shap {
  content: '';
  display: block;
  position: absolute;
  top: calc(100% - 190px);
  background-color: white;
  min-height: 390px;
  width: 2000px;
  left: -77px;
  transform-origin: 0 0 0;
  transform: rotate(-6deg);
  z-index: -1;
}

.erp_banner_area_two .erp_shap_two {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.erp_banner_area_two .animation_img img {
  -webkit-box-shadow: 0px 40px 100px 0px rgba(1, 1, 64, 0.08);
  box-shadow: 0px 40px 100px 0px rgba(1, 1, 64, 0.08);
}

.erp_banner_area_two .section_container .intro_content h1 {
  font-size: 50px;
  line-height: 64px;
  color: #2c2c51;
  font-weight: 600;
}

.erp_banner_area_two .section_container .intro_content p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 40px;
}

.erp_banner_area_two .section_container .intro_content .er_btn {
  padding: 10px 37px;
  margin-top: 0;
}

@-webkit-keyframes cloud {
  0% {
    left: 14%;
    top: 200px;
    opacity: 0;
  }
  20% {
    left: 44%;
    top: 100px;
    opacity: 1;
  }
  40% {
    left: 65%;
    top: 50px;
    opacity: 1;
  }
  60% {
    left: 80%;
    top: 130px;
    opacity: 1;
  }
  80% {
    left: 90%;
    top: 180px;
    opacity: .5;
  }
  100% {
    left: 100%;
    top: 230px;
    opacity: 0;
  }
}

@keyframes cloud {
  0% {
    left: 14%;
    top: 200px;
    opacity: 0;
  }
  20% {
    left: 44%;
    top: 100px;
    opacity: 1;
  }
  40% {
    left: 65%;
    top: 50px;
    opacity: 1;
  }
  60% {
    left: 80%;
    top: 130px;
    opacity: 1;
  }
  80% {
    left: 90%;
    top: 180px;
    opacity: .5;
  }
  100% {
    left: 100%;
    top: 230px;
    opacity: 0;
  }
}

@-webkit-keyframes animateCloud {
  0% {
    left: -20%;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 1;
  }
}

@keyframes animateCloud {
  0% {
    left: -20%;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 1;
  }
}

@-webkit-keyframes jurk {
  0% {
    -webkit-transform: translate3d(-10px, -10px, 0);
    transform: translate3d(-10px, -10px, 0);
    opacity: 1;
  }
  50% {
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(30px, 0px, 0);
    transform: translate3d(30px, 0px, 0);
    opacity: 1;
  }
}

@keyframes jurk {
  0% {
    -webkit-transform: translate3d(-10px, -10px, 0);
    transform: translate3d(-10px, -10px, 0);
    opacity: 1;
  }
  50% {
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(30px, 0px, 0);
    transform: translate3d(30px, 0px, 0);
    opacity: 1;
  }
}

/*============== erp_banner_area_two css =============*/
/*============== erp_service_area css =============*/
.erp_service_area {
  padding-top: 120px;
}

.erp_service_area .row {
  margin-bottom: -100px;
}

.erp_title h2 {
  font-size: 34px;
}

.erp_title h2 span {
  color: #ff0000;
}

.erp_service_item {
  margin-bottom: 100px;
}

.erp_service_item img {
  margin-bottom: 35px;
}

.erp_service_item .h_head:hover {
  color: #4e78f4;
}

.erp_service_item p {
  font-weight: 400;
  margin-bottom: 0;
}

/*============== erp_service_area css =============*/
.erp_action_area {
  background: #4e78f4;
  padding: 58px 0px;
}

.erp_content h2 {
  color: #fff;
  font-size: 40px;
  line-height: 54px;
  letter-spacing: 0.25px;
  font-weight: 300;
}

.erp_content h2 strong {
  font-weight: 700;
}

.h_price_inner {
  max-width: 970px;
  margin: 0 auto;
  border: 2px solid #f7f0f0;
}

.hosting_tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.hosting_tab .nav-item {
  width: calc(100% / 3);
}

.hosting_tab .nav-item .nav-link {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #7b7b93;
  background: #faf7f8;
  border-radius: 0px;
  border: 0px;
  line-height: 64px;
  border-left: 2px solid #f7f0f0;
  border-bottom: 2px solid #f7f0f0;
}

.hosting_tab .nav-item .nav-link.active {
  background: #fff;
  border-bottom-color: #fff;
}

.hosting_tab .nav-item:first-child .nav-link {
  border-left: 0px;
}

.h_price_tab p {
  font-size: 16px;
  color: #7b7b93;
  padding: 50px;
  font-weight: 400;
  margin-bottom: 0;
}

.h_price_body .price_head {
  display: table;
  width: 100%;
  background: #faf7f8;
  height: 50px;
  padding-right: 50px;
  border-top: 1px solid #f7f0f0;
}

.h_price_body .price_head .p_head {
  width: calc(100% / 6);
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.h_price_body .price_head .p_head h5 {
  font-size: 14px;
  font-weight: 500;
  color: #2c2c51;
  margin-bottom: 0;
  text-transform: uppercase;
}

.h_price_body .h_p_list {
  display: table;
  width: 100%;
  padding-right: 50px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid #f7f0f0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
}

.h_price_body .h_p_list .h_price_item {
  width: calc(100% / 6);
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.h_price_body .h_p_list .h_price_item h5 {
  margin-bottom: 0;
  font-size: 16px;
  color: #7b7b93;
  font-weight: 400;
}

.h_price_body .h_p_list .h_price_item h5 span {
  font-weight: 600;
  display: block;
}

.h_price_body .h_p_list .h_price_item.memory h5 {
  font-weight: 600;
}

.h_price_body .h_p_list .h_price_item .h_price_btn {
  border: 1px solid #f8c0c3;
  font-size: 14px;
  font-weight: 500;
  color: #f8636b;
  border-radius: 4px;
  padding: 11px 20px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
}

.h_price_body .h_p_list .h_price_item .h_price_btn:hover {
  background: #f8636b;
  border-color: #f8636b;
  color: #fff;
}

.h_price_body .h_p_list:hover {
  background: #fdfafa;
}

/*=========== erp_features_area css =============*/
.erp_item_features + .erp_item_features {
  margin-top: 200px;
}

.erp_features_img_two {
  position: relative;
  padding-top: 50px;
}

.erp_features_img_two .img_icon {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  color: #fff;
  line-height: 112px;
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0px;
  right: -55px;
  z-index: 1;
}

.erp_features_img_two .img_icon .pluse_1, .erp_features_img_two .img_icon .pluse_2 {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(243, 186, 14, 0.102);
}

.erp_features_img_two .img_icon .pluse_1:before, .erp_features_img_two .img_icon .pluse_1:after, .erp_features_img_two .img_icon .pluse_2:before, .erp_features_img_two .img_icon .pluse_2:after {
  content: '';
  position: absolute;
  background: rgba(243, 186, 14, 0.13);
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
}

.erp_features_img_two .img_icon .pluse_1:before, .erp_features_img_two .img_icon .pluse_2:before {
  width: 80px;
  height: 80px;
}

.erp_features_img_two .img_icon .pluse_1:after, .erp_features_img_two .img_icon .pluse_2:after {
  width: 60px;
  height: 60px;
  background: #f3ba0e;
  margin-left: -30px;
  margin-top: -30px;
}

.erp_features_img_two .img_icon.red .pluse_1, .erp_features_img_two .img_icon.red .pluse_2 {
  background: rgba(249, 62, 94, 0.102);
}

.erp_features_img_two .img_icon.red .pluse_1:before, .erp_features_img_two .img_icon.red .pluse_1:after, .erp_features_img_two .img_icon.red .pluse_2:before, .erp_features_img_two .img_icon.red .pluse_2:after {
  background: rgba(249, 62, 94, 0.13);
}

.erp_features_img_two .img_icon.red .pluse_1:after, .erp_features_img_two .img_icon.red .pluse_2:after {
  background: #f93e5e;
}

.erp_features_img_two .img_icon.green .pluse_1, .erp_features_img_two .img_icon.green .pluse_2 {
  background: rgba(112, 215, 21, 0.102);
}

.erp_features_img_two .img_icon.green .pluse_1:before, .erp_features_img_two .img_icon.green .pluse_1:after, .erp_features_img_two .img_icon.green .pluse_2:before, .erp_features_img_two .img_icon.green .pluse_2:after {
  background: rgba(112, 215, 21, 0.13);
}

.erp_features_img_two .img_icon.green .pluse_1:after, .erp_features_img_two .img_icon.green .pluse_2:after {
  background: #70d715;
}

.erp_features_img_two .img_icon .pluse_1 {
  -webkit-animation: pulsates 3s infinite linear;
  animation: pulsates 3s infinite linear;
}

.erp_features_img_two .img_icon .pluse_2 {
  background: transparent !important;
}

.erp_features_img_two .img_icon .pluse_2:before {
  -webkit-animation: pulsates 3s infinite linear;
  animation: pulsates 3s infinite linear;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  margin-left: 0;
  margin-top: 0;
}

.erp_features_img_two img {
  -webkit-box-shadow: 0px 25px 100px 0px rgba(1, 1, 64, 0.1);
  box-shadow: 0px 25px 100px 0px rgba(1, 1, 64, 0.1);
}

@-webkit-keyframes pulsates {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.3);
    transform: translate(-50%, -50%) scale(0.3);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

@keyframes pulsates {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.3);
    transform: translate(-50%, -50%) scale(0.3);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

.erp_content_two {
  padding-left: 120px;
}

.erp_content_two .erp_title {
  margin-bottom: 40px;
}

.erp_content_two .erp_title h2 {
  line-height: 44px;
  margin-bottom: 20px;
}

.erp_content_two .erp_item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 28px;
}

.erp_content_two .erp_item .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f93e5e;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(172, 20, 46, 0.24);
  box-shadow: 0px 20px 30px 0px rgba(172, 20, 46, 0.24);
  text-align: center;
  font-size: 20px;
  line-height: 52px;
  color: #fff;
  margin-right: 20px;
}

.erp_content_two .erp_item .icon.green {
  background: #70d715;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(91, 171, 21, 0.24);
  box-shadow: 0px 20px 30px 0px rgba(91, 171, 21, 0.24);
}

.erp_content_two .erp_item .icon.blue {
  background: #3e89f9;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(21, 81, 171, 0.24);
  box-shadow: 0px 20px 30px 0px rgba(21, 81, 171, 0.24);
}

.erp_content_two .erp_item .icon.yellow {
  -webkit-box-shadow: 0px 20px 30px 0px rgba(171, 133, 21, 0.24);
  box-shadow: 0px 20px 30px 0px rgba(171, 133, 21, 0.24);
  background: #f3ba0e;
}

.erp_content_two .erp_item .icon.purple {
  -webkit-box-shadow: 0px 20px 30px 0px rgba(138, 21, 171, 0.24);
  box-shadow: 0px 20px 30px 0px rgba(138, 21, 171, 0.24);
  background: #d02cff;
}

.erp_content_two .erp_item .icon.green_two {
  -webkit-box-shadow: 0px 20px 30px 0px rgba(21, 168, 171, 0.24);
  box-shadow: 0px 20px 30px 0px rgba(21, 168, 171, 0.24);
  background: #17d2d4;
}

.erp_content_two .erp_item .media-body h5 {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c51;
}

.erp_content_two .erp_btn_learn {
  font-size: 16px;
  line-height: 20px;
  color: #4e78f4;
  font-weight: 500;
  display: inline-block;
  margin-top: 40px;
}

.erp_content_two .erp_btn_learn i {
  vertical-align: middle;
  padding-left: 8px;
}

.flex-row-reverse .erp_features_img_two .img_icon {
  left: -53px;
  right: auto;
}

.flex-row-reverse .erp_content_two {
  padding-right: 125px;
  padding-left: 0;
}

/*=========== erp_analytics_area css ============*/
.erp_analytics_area {
  background: url("../img/erp-home/background.png") no-repeat scroll center 0/cover;
  padding: 100px 0px 220px;
}

.erp_analytics_item {
  padding: 0px 50px;
}

.erp_analytics_item p {
  font-size: 22px;
  color: #2c2c51;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 25px;
}

.erp_testimonial_area {
  position: relative;
  z-index: 2;
}

.erp_testimonial_area .row {
  margin-right: -25px;
  margin-left: -25px;
  display: block;
}

.er_btn {
  border: 2px solid #c8d4fa;
  font-size: 16px;
  font-weight: 500;
  color: #4e78f4;
  font-family: "Poppins", sans-serif;
  border-radius: 45px;
  line-height: 26px;
  padding: 8px 27px;
  display: inline-block;
  margin-top: 60px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.er_btn:hover {
  background: #4e78f4;
  border-color: #4e78f4;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(45, 93, 237, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(45, 93, 237, 0.1);
  color: #fff;
}

.erp_testimonial_area .row{
  position: relative;
}

.erp_testimonial_area .slider_nav .prev, .erp_testimonial_area .slider_nav .next{
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 45px;
  color: #c1c1d4;
  transition: all 0.2s linear;
  cursor: pointer;
}

.erp_testimonial_area .slider_nav .prev:hover, .erp_testimonial_area .slider_nav .next:hover {
  color: #4e78f4;
}

.erp_testimonial_area .slider_nav .prev{
  left: -50px;
}

.erp_testimonial_area .slider_nav .next{
  right: -50px;
}

.erp_testimonial_item {
  padding: 10px 25px;
}

.erp_testimonial_item .content {
  background-color: white;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(1, 1, 64, 0.06);
  box-shadow: 0px 20px 40px 0px rgba(1, 1, 64, 0.06);
  padding: 50px;
  position: relative;
}

.erp_testimonial_item .content:before {
  content: "";
  width: 14px;
  height: 14px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  bottom: -8px;
  left: 70px;
}

.erp_testimonial_item .content p {
  font-weight: 400;
  font-size: 20px;
  color: #7b7b93;
  line-height: 36px;
}

.erp_testimonial_item .ratting {
  padding-top: 30px;
}

.erp_testimonial_item .ratting a {
  font-size: 15px;
  color: #f5c10d;
}

.erp_testimonial_item .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px;
  padding-left: 50px;
}

.erp_testimonial_item .media img {
  width: auto;
  border-radius: 50%;
  margin-right: 20px;
}

.erp_testimonial_item .media .media-body h5 {
  font-size: 18px;
  font-weight: 500;
  color: #2c2c51;
  margin-bottom: 3px;
}

.erp_testimonial_item .media .media-body h5 span {
  color: #7b7b93;
  font-weight: 400;
  font-size: 16px;
}

.erp_testimonial_item .media .media-body p {
  margin-bottom: 0;
  color: #7b7b93;
  font-weight: 400;
}

.erp_call_action_area {
  padding-bottom: 120px;
  padding-top: 50px;
  background: #fafafd;
  position: relative;
  z-index: 1;
}

.erp_call_action_area:before {
  content: "";
  position: absolute;
  background: url("../img/erp-home/shape.png") no-repeat scroll center bottom/cover;
  width: 100%;
  left: 0;
  height: 100%;
  z-index: -1;
}

.erp_call_action_area:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 10;
  top: -80%;
  background-color: #fafafd;
  min-height: 560px;
  width: 2000px;
  -webkit-transform: rotate(-6.8deg);
  -ms-transform: rotate(-6.8deg);
  transform: rotate(-6.8deg);
  left: -1.5%;
  z-index: -2;
}

.erp_action_content img {
  margin-bottom: 32px;
}

.erp_action_content h3 {
  font-size: 40px;
  font-weight: 600;
  color: #2c2c51;
  line-height: 44px;
  margin-bottom: 18px;
}

.erp_action_content p {
  font-size: 24px;
  color: #7b7b93;
  font-weight: 400;
}

.erp_action_content p span {
  font-weight: 700;
  color: #4e78f4;
}

.erp_action_content .er_btn {
  margin-top: 40px;
}

.er_btn_two {
  background: #4e78f4;
  border-color: #4e78f4;
  color: #fff;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(45, 93, 237, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(45, 93, 237, 0.1);
}

.er_btn_two:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #c8d4fa;
  background: transparent;
  color: #4e78f4;
}

.erp_customer_logo_area {
  padding-top: 120px;
}

.erp_customer_logo_area .er_btn {
  margin-top: 140px;
}

.animation_inner {
  height: 370px;
  position: relative;
}
.animation_inner > div{
  position: absolute;
}
.animation_inner > div .item {
  border-radius: 10px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(2, 45, 79, 0.1);
  box-shadow: 0px 2px 4px 0px rgba(2, 45, 79, 0.1);
  -webkit-transform: matrix(0.97029, 0.34192, -0.84486, 0.9425, 0, 0);
  -ms-transform: matrix(0.97029, 0.34192, -0.84486, 0.9425, 0, 0);
  transform: matrix(0.97029, 0.34192, -0.84486, 0.9425, 0, 0);
}

.animation_inner div .item img {
  border-radius: 10px;
}

.animation_inner > div:nth-child(1){
  top: 0;
  left: 35px;
}

.animation_inner > div:nth-child(2){
  top: 55px;
  left: 170px;
}

.animation_inner > div:nth-child(3){
  top: 130px;
  left: 270px;
}

.animation_inner > div:nth-child(4){
  top: 35px;
  left: 410px;
}

.animation_inner > div:nth-child(5){
  top: 109px;
  left: 495px;
}

.animation_inner > div:nth-child(6){
  top: 40px;
  left: 700px;
}

.animation_inner > div:nth-child(7){
  top: 0;
  left: 890px;
}

.animation_inner > div:nth-child(8){
  top: 40%;
  left: 35px;
}

.animation_inner > div:nth-child(9){
  top: 182px;
  left: 585px;
}

.animation_inner > div:nth-child(10){
  top: 130px;
  left: 780px;
}

.animation_inner > div:nth-child(11){
  top: 128px;
  left: 950px;
}

.animation_inner > div:nth-child(12){
  top: 85px;
  left: 95%;
}

.animation_inner > div:nth-child(13){
  bottom: 0;
  left: -35px;
}

.animation_inner > div:nth-child(14){
  bottom: 30px;
  left: 150px;
}

.animation_inner > div:nth-child(15){
  bottom: 20px;
  left: 310px;
}

.animation_inner > div:nth-child(16){
  bottom: -25px;
  left: 460px;
}

.animation_inner > div:nth-child(17){
  bottom: 35px;
  left: 690px;
}

.animation_inner > div:nth-child(18){
  bottom: -30px;
  left: 800px;
}

.animation_inner > div:nth-child(19){
  bottom: 45px;
  left: 940px;
}

.animation_inner > div:nth-child(20){
  bottom: 0px;
  right: 0;
}

/*============= pos_banner_area css ===========*/
.pos_banner_area {
  height: 100vh;
  min-height: 650px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.pos_slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.pos_slider:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #1a1a3a;
  opacity: 0.70;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.pos_slider .owl-stage-outer, .pos_slider .owl-stage, .pos_slider .owl-item {
  height: 100%;
}

.pos_slider .owl-stage-outer .pos_banner_item, .pos_slider .owl-stage .pos_banner_item, .pos_slider .owl-item .pos_banner_item {
  background-size: cover !important;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.pos_banner_text {
  max-width: 820px;
  margin: 0 auto;
}

.pos_banner_text h6 {
  font-size: 14px;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 4px;
  margin-bottom: 12px;
}

.pos_banner_text h6, .pos_banner_text h2 {
  color: #fff;
}

.pos_banner_text h2 {
  font-size: 50px;
  font-weight: 400;
  color: #fff;
}

.pos_banner_text h2 span {
  font-weight: 700;
}

.pos_banner_text .action_btn {
  margin-top: 40px;
}

.pos_banner_text .software_banner_btn {
  border-radius: 6px;
  background: #5f54fd;
  padding: 14px 42px;
  border: 1px solid #5f54fd;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.pos_banner_text .software_banner_btn:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: #fff;
  color: #fff;
}

.pos_banner_text .video_btn .icon {
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 50px;
  width: 50px;
  color: #fff;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.2);
  font-size: 14px;
  line-height: 48px;
  margin-right: 13px;
}

.pos_banner_text .video_btn span:before {
  display: none;
}

/*============= pos_banner_area css ===========*/
.ticket_area {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

.ticket_area .ticket_shap {
  content: "";
  width: 100%;
  height: 730px;
  position: absolute;
  right: 0;
  top: 0;
  background-position: right, right !important;
  background-repeat: no-repeat !important;
}

.ticket_area .pattern {
  position: absolute;
  right: 0;
  top: 0;
  width: 225px;
  height: 125px;
}

.ticket_area .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ticket_item {
  background: #fff;
  padding: 30px 50px 70px;
  -webkit-box-shadow: 0px 50px 100px 0px rgba(5, 1, 64, 0.1);
  box-shadow: 0px 50px 100px 0px rgba(5, 1, 64, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: inline-block;
  width: calc(84% / 3);
  position: relative;
}

.ticket_item:before {
  content: "";
  width: 100%;
  height: 10px;
  background: url("../img/pos/shape_bg.png") no-repeat scroll center bottom/cover;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.ticket_item:after {
  bottom: 40px;
  content: "";
  position: absolute;
  left: -3px;
  width: 100%;
  height: 1px;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, transparent), color-stop(50%, #c9c9d6));
  background-image: -webkit-linear-gradient(left, transparent 50%, #c9c9d6 50%);
  background-image: -o-linear-gradient(left, transparent 50%, #c9c9d6 50%);
  background-image: linear-gradient(to right, transparent 50%, #c9c9d6 50%);
  background-size: 10px 94%;
}

.ticket_item .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  background: #edfcec;
  margin-bottom: 25px;
}

.ticket_item .icon.pink {
  background: #feecfb;
}

.ticket_item .icon.yellow {
  background: #fefaec;
}

.ticket_item h2 {
  font-size: 44px;
  line-height: 35px;
  font-weight: 600;
  color: #2c2c51;
  text-transform: uppercase;
}

.ticket_item h2 span {
  font-weight: 500;
  color: #7b7b93;
  font-size: 18px;
  line-height: 30px;
  display: block;
  padding-top: 10px;
}

.ticket_item p {
  margin-bottom: 0;
  margin-top: 25px;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 4px;
  color: #aaaabb;
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 500;
}

.ticket_item + .ticket_item {
  margin-left: 8%;
}

.pos_developer_product_area {
  background: #fafafc;
}

.pos_developer_product_area .tab_img_info {
  z-index: 1;
}

.pos_developer_product_area .tab_img_info .tab_img {
  padding-bottom: 30px;
  overflow: visible;
  margin-left: -50px;
}

.pos_developer_product_area .tab_img_info .tab_img img {
  position: relative;
  z-index: 1;
  opacity: 0;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all 0.9s linear, opacity 0.2s linear;
  -o-transition: all 0.9s linear, opacity 0.2s linear;
  transition: all 0.9s linear, opacity 0.2s linear;
}

.pos_developer_product_area .tab_img_info .tab_img .square {
  height: 100%;
  width: 0px;
  right: 90px;
  top: 0;
  background: #f3f3f9;
  z-index: 0;
  opacity: 0;
  -webkit-transition: width 0.5s linear;
  -o-transition: width 0.5s linear;
  transition: width 0.5s linear;
}

.pos_developer_product_area .tab_img_info .tab_img .bg_circle {
  width: 220px;
  background: #f4cfe5;
  height: 220px;
  border-radius: 50%;
  position: absolute;
  bottom: 15px;
  left: 5px;
  z-index: 0;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0;
  -webkit-transition: all 0.7s linear;
  -o-transition: all 0.7s linear;
  transition: all 0.7s linear;
}

.pos_developer_product_area .tab_img_info .tab_img .bg_circle.green {
  background: #c8ede9;
}

.pos_developer_product_area .tab_img_info .tab_img .bg_circle.pink {
  background: #f93e5e;
}

.pos_developer_product_area .tab_img_info .tab_img .bg_circle.yellow {
  background: #f9edcf;
}

.pos_developer_product_area .tab_img_info .tab_img .pattern_shap {
  top: auto;
  bottom: -30px;
  left: 100px;
  z-index: -1;
}

.pos_developer_product_area .tab_img_info .tab_img.active img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.pos_developer_product_area .tab_img_info .tab_img.active .square {
  opacity: 1;
  width: 350px;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.pos_developer_product_area .tab_img_info .tab_img.active .bg_circle {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.pos_developer_product_area .tab_img_info .tab_img .tab_round {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 5px solid #f9edcf;
  position: absolute;
  top: 100px;
  left: -20px;
}

.pos_developer_product_area .tab_img_info .tab_img .tab_triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12.5px 0 12.5px 20px;
  border-color: transparent transparent transparent #c8ede9;
  position: absolute;
  top: -15px;
  left: 170px;
  border-radius: 4px;
}

.pos_developer_product_area .developer_product_content {
  padding-right: 0px;
}

.pos_developer_product_area .developer_product_content .develor_tab {
  padding-left: 0;
  margin-bottom: 50px;
}

.pos_developer_product_area .developer_product_content .develor_tab .nav-item .nav-link {
  color: #2c2c51;
}

.pos_developer_product_area .developer_product_content .develor_tab .nav-item .nav-link:before {
  background: #5f54fd;
}

.pos_developer_product_area .developer_product_content .develor_tab .nav-item .nav-link.active {
  color: #5f54fd;
}

.pos_developer_product_area .developer_product_content h4 {
  font-size: 30px;
  line-height: 40px;
  color: #2c2c51;
  font-weight: 600;
  margin-bottom: 32px;
}

.pos_developer_product_area .developer_product_content p {
  font-size: 16px;
  line-height: 28px;
  color: #7b7b93;
  margin-bottom: 0;
}

.pos_item + .pos_item {
  margin-top: 150px;
}

.flex-row-reverse .pos_features_content {
  padding-right: 70px;
}

.pos_features_content {
  padding-top: 50px;
}

.pos_features_content h2 {
  font-size: 34px;
  color: #2c2c51;
  line-height: 44px;
  font-weight: 500;
  margin-bottom: 50px;
}

.pos_features_content .h_features_item {
  padding-right: 0;
}

.pos_features_content .h_features_item i {
  font-size: 24px;
  color: #bb3efc;
  margin-right: 15px;
}

.pos_features_content .h_features_item i.orange {
  color: #fb702b;
}

.pos_features_content .h_features_item i.green {
  color: #0ed1ca;
}

.pos_features_content .h_features_item i.red {
  color: #f826c0;
}

.pos_features_content .h_features_item i.check {
  color: #1ad90b;
}

.pos_features_content .h_features_item .h_head {
  font-weight: 500;
}

.pos_features_content .h_features_item p {
  color: #7b7b93;
}

.pos_features_img {
  padding: 40px 0px 80px;
  position: relative;
  text-align: center;
}

.pos_features_img .shape_img {
  width: 470px;
  background: #e0faf4;
  border-radius: 6px;
  height: 100%;
  position: absolute;
  right: -50px;
  top: 0;
  z-index: -1;
}

.pos_features_img .shape_img.yellow {
  background: #fff7e5;
}

.pos_features_img .shape_img.blue {
  background: #e5f6ff;
}

.pos_features_img .shap_img {
  background: #99edd9;
  border-radius: 6px;
  height: 420px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pos_features_img .shap_img.yellow {
  background: #ffe3a9;
}

.pos_features_img .shap_img.blue {
  background: #a9e2ff;
}

.pos_features_img.img_left .shape_img {
  left: -50px;
  right: auto;
}

.pos_about_area {
  background: #413c85;
  padding-top: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pos_about_area + section {
  padding-top: 200px;
}

.pos_about_img {
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 82px;
  margin-bottom: -140px;
}

.pos_about_list {
  -webkit-column-count: 3;
  column-count: 3;
  margin-bottom: 65px;
}

.pos_about_list li {
  font-size: 16px;
  color: #d0cfe6;
  line-height: 28px;
  position: relative;
  padding-left: 40px;
}

.pos_about_list li:before {
  content: "\4e";
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 0;
  color: #1fc61a;
  font-size: 24px;
  font-family: eleganticons;
}

.pos_about_list li + li {
  margin-top: 40px;
}

.pos_subscribe .form-group {
  margin-bottom: 0;
}

.pos_subscribe .form-group input {
  width: 100%;
  border: 0px;
  height: 60px;
  border-radius: 5px;
  font-size: 16px;
  color: #2c2c51;
  padding-left: 30px;
}

.pos_subscribe .form-group input.placeholder {
  color: #bbbbce;
}

.pos_subscribe .form-group input:-moz-placeholder {
  color: #bbbbce;
}

.pos_subscribe .form-group input::-moz-placeholder {
  color: #bbbbce;
}

.pos_subscribe .form-group input::-webkit-input-placeholder {
  color: #bbbbce;
}

.pos_subscribe .btn_pos {
  background: #5f54fd;
  font-size: 16px;
  font-weight: 500;
  padding: 17px 37px;
  color: #fff;
}

.pos_subscribe .btn_pos:hover {
  background: #6b61fc;
  color: #fff;
}

.chat_area {
  padding: 60px 0px 150px;
  position: relative;
  z-index: 1;
}

.chat_content {
  padding-top: 40px;
}

.chat_content h2 {
  font-size: 34px;
  color: #2c2c51;
  font-weight: 500;
  line-height: 44px;
}

.chat_content .pos_btn {
  margin-top: 45px;
}

.chat_info {
  background: #fafaff;
  border-radius: 6px;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 77px 45px;
  border-left: 6px solid #5f54fd;
}

.chat_info img {
  margin-right: 46px;
}

.chat_info .date {
  font-size: 40px;
  font-weight: 700;
  color: #5f54fd;
  padding-bottom: 32px;
}

.chat_info .date span {
  font-weight: 400;
}

.chat_info h3 {
  font-size: 26px;
  line-height: 36px;
  color: #2c2c51;
  font-weight: 500;
}

.chat_info .pos_service_btn {
  margin-top: 32px;
}

.pos_service_btn {
  font-size: 16px;
  font-weight: 500;
  color: #5f54fd;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

.pos_service_btn:before {
  content: "";
  width: 0;
  height: 1px;
  background: #5f54fd;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: width 0.3s linear;
  -o-transition: width 0.3s linear;
  transition: width 0.3s linear;
}

.pos_service_btn i {
  vertical-align: middle;
  padding-left: 10px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.pos_service_btn:hover {
  color: #5f54fd;
}

.pos_service_btn:hover:before {
  width: 100%;
}

.pos_service_btn:hover i {
  padding-left: 15px;
}

.pos_btn {
  font-size: 16px;
  color: #5f54fd;
  font-weight: 500;
  padding: 9px 24px;
  border: 2px solid #aba6f9;
  border-radius: 4px;
  display: inline-block;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.pos_btn:hover {
  background: #5f54fd;
  color: #fff;
  border-color: #5f54fd;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(95, 84, 253, 0.15);
  box-shadow: 0px 10px 30px 0px rgba(95, 84, 253, 0.15);
}

.pos_action_area {
  position: relative;
  z-index: 3;
  margin-bottom: -30px;
}

.pos_action_area .pos_action_content {
  border-radius: 20px;
  background-color: white;
  -webkit-box-shadow: 0px 30px 80px 0px rgba(5, 1, 64, 0.1), 0px -4px 0px 0px rgba(95, 84, 253, 0.004);
  box-shadow: 0px 30px 80px 0px rgba(5, 1, 64, 0.1), 0px -4px 0px 0px rgba(95, 84, 253, 0.004);
  border-top: 4px solid #5d52f9;
  padding: 77px 20px;
}

.pos_action_area .pos_action_content h2 {
  font-size: 40px;
  color: #2c2c51;
  line-height: 44px;
  font-weight: 600;
  margin-bottom: 15px;
}

.pos_action_area .pos_action_content p {
  font-size: 24px;
  line-height: 35px;
  font-weight: 400;
  color: #7b7b93;
  margin-bottom: 52px;
}

.pos_action_area .pos_action_content .pos_btn {
  background: #5f54fd;
  color: #fff;
  border-color: #5f54fd;
  padding: 7px 33px;
}

.menu_pos .container {
  position: relative;
}

.menu_pos .container .search_cart {
  background: transparent;
}

.menu_pos .container .menu_toggle .hamburger span, .menu_pos .container .menu_toggle .hamburger-cross span {
  background: #fff;
}

/*============ support_home_area css ============*/
.support_home_area {
  background: #fafafe;
}

.support_home_area .banner_top .subcribes .form-control {
  border-radius: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #e1e1e1;
  height: 60px;
}

.support_home_area .banner_top .subcribes .form-control.placeholder {
  color: #999999;
}

.support_home_area .banner_top .subcribes .form-control:-moz-placeholder {
  color: #999999;
}

.support_home_area .banner_top .subcribes .form-control::-moz-placeholder {
  color: #999999;
}

.support_home_area .banner_top .subcribes .form-control::-webkit-input-placeholder {
  color: #999999;
}

.support_home_area .banner_top .subcribes .btn_submit {
  border-radius: 45px;
}

.support_home_img {
  margin-left: -70px;
}

.border_bottom {
  width: 100%;
  height: 1px;
  background: #e1e5e8;
}

.support_partner_logo_area {
  padding-top: 50px;
}

.support_partner_logo_area .logo_item {
  margin-bottom: 30px;
}

.support_help_area .support_item {
  text-align: center;
  width: 50%;
}

.support_help_area .support_item h4 {
  font-size: 24px;
  font-weight: 500;
  color: #051441;
  margin: 36px 0px 25px;
}

.support_help_area .support_item .software_banner_btn {
  background-color: #6754e2;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
}

.support_help_area .support_item .software_banner_btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}

.support_help_area .support_item + .support_item {
  border-left: 1px solid #e1e1e1;
}

.support_tab .nav-item .nav-link {
  border-radius: 10px;
}

.support_tab_content .tab-pane .tab_img {
  margin-right: -176px;
  max-width: inherit;
}

.support_tab_content .tab-pane .tab_img img {
  max-width: inherit;
}

.support_integration_area {
  background: #eaeeff;
  padding-top: 120px;
}

.integration_img {
  margin-left: -175px;
}

.s_integration_item {
  background: #fff;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 2px 7px 0px rgba(12, 0, 46, 0.1);
  border-radius: 10px;
  text-align: center;
  display: block;
  padding: 26px 0px;
  margin-bottom: 30px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 1;
  position: relative;
}

.s_integration_item h5 {
  font-size: 18px;
  color: #051441;
  margin-top: 15px;
}

.s_integration_item:hover {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}

/*============ support_home_area css ============*/
.support_price_area {
  background: #fafafe;
}

.support_price_area .price_content .price_item {
  border-radius: 10px;
  border: 3px solid #fff;
}

.support_price_area .price_content .price_item:hover {
  border-color: #6754e2;
}

.support_testimonial_info .testimonial_slider {
  padding: 0px;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px 30px;
  max-width: 890px;
}

.support_testimonial_info .testimonial_slider .author_img {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid #fff;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 2px 7px 0px rgba(12, 0, 46, 0.1);
}

.support_testimonial_info .testimonial_slider .owl-item {
  padding: 40px 30px 55px;
}

.support_testimonial_info .testimonial_slider .testimonial_item {
  -webkit-box-shadow: 0px 20px 40px 0px rgba(12, 0, 36, 0.06);
  box-shadow: 0px 20px 40px 0px rgba(12, 0, 36, 0.06);
  background: #fff;
  padding: 32px 50px 60px;
}

.support_testimonial_info .testimonial_slider .testimonial_item:before {
  content: "";
  background: url(../img/new-home/back_bg.png) no-repeat scroll center center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.support_testimonial_info .testimonial_slider .testimonial_item p {
  margin-bottom: 0;
}

.support_testimonial_info .testimonial_slider .testimonial_item .author_description {
  margin-bottom: 28px;
}

.support_subscribe_area {
  background: #f5f7ff;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.support_subscribe {
  max-width: 970px;
  margin: 0 auto;
  position: relative;
}

.support_subscribe .form-control {
  border: 1px solid #e1e1e1;
  padding-left: 50px;
  background-color: white;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  height: 100px;
  border-radius: 50px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
  color: #222d39;
  z-index: -1;
}

.support_subscribe .form-control.placeholder {
  color: #9ea4b7;
}

.support_subscribe .form-control:-moz-placeholder {
  color: #9ea4b7;
}

.support_subscribe .form-control::-moz-placeholder {
  color: #9ea4b7;
}

.support_subscribe .form-control::-webkit-input-placeholder {
  color: #9ea4b7;
}

.support_subscribe .btn-submit {
  border-radius: 50%;
  background: #6754e2;
  width: 70px;
  height: 70px;
  font-size: 20px;
  color: #fff;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}

.h_leaf {
  position: absolute;
  z-index: -1;
}

.h_leaf.one {
  left: 0;
  bottom: 0;
}

.h_leaf.two {
  right: 0;
  bottom: 0;
}

.support_menu.navbar .search_cart .search a.nav-link:before {
  display: none;
}

/*====================================================*/
/*====================================================*/
.banner_top .subcribes .btn_submit, .s_features_item.s_features_item_two .s_features_content .learn_btn:hover:before, .price_btn:hover {
  background: #6754e2;
}

.banner_top .subcribes .form-control:focus, .price_btn, .s_subcribes .form-control, .f_widget.about-widget .f_list li a:before {
  border-color: #6754e2;
}

.s_features_item.s_features_item_two .s_features_content .learn_btn:hover, .price_btn, .s_subcribes .btn-submit, .f_widget.about-widget .f_list li a:hover, .f_widget .widget-wrap p a:hover, .footer_bottom a, .menu > .nav-item:hover .nav-link, .menu > .nav-item.active .nav-link, .menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .text:hover, .menu > .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link, .menu > .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link, .menu > .nav-item.submenu .dropdown-menu .nav-item > .nav-link.active,.menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item.active .text{
  color: #6754e2;
}

.w_menu .nav-item:hover .nav-link, .w_menu .nav-item.active .nav-link {
  color: #fff;
}


.header_area.navbar_fixed .menu_two .nav_right_btn .login_btn.active, .header_area.navbar_fixed .menu_two .nav_right_btn .login_btn:hover {
  border-color: #00aff0;
}

.header_area.navbar_fixed .menu_two .nav_right_btn .login_btn.active:hover, .slider_btn:hover, .price_tab .hover_bg {
  background: #00aff0;
}

/*====================================================*/
/*====================================================*/
#pp-nav {
  right: 45px;
}

#pp-nav li {
  margin: 4px;
}

#pp-nav li a span {
  background: #decbfe;
  border: 0px;
  width: 6px;
  height: 6px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

#pp-nav li a.active span {
  background: #fff;
  -webkit-transform: scale(1.8);
  -ms-transform: scale(1.8);
  transform: scale(1.8);
}

.pagepiling .scroll-wrap {
  position: relative;
  min-height: 100%;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pagepiling .scroll-wrap .scrollable-content {
  width: 100%;
}

.p-section-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
}

.pp-scrollable.section {
  overflow-x: hidden;
}

.section-1 .pp_triangle {
  top: 150px;
  left: 52%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.section-1 .pp_snak {
  bottom: 50px;
  right: 100px;
  -webkit-animation: slideInnew3 4s both alternate infinite;
  animation: slideInnew3 4s both alternate infinite;
}

.section-1.active .section_one_img img {
  -webkit-animation: fadeInLeft 1s both 0.8s;
  animation: fadeInLeft 1s both 0.8s;
}

.section-1.active .section_one_img .dots {
  -webkit-animation: fadeInUp 1s both 1s;
  animation: fadeInUp 1s both 1s;
}

.pp_triangle {
  -webkit-animation: spinnerRotateone 6s both alternate infinite;
  animation: spinnerRotateone 6s both alternate infinite;
}

.p_absoulte {
  position: absolute;
}

.round_line {
  width: 600px;
  height: 600px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  opacity: 0.2;
  z-index: 0;
}

.round_line.one {
  top: -230px;
  right: -50px;
}

.round_line.two {
  top: -430px;
  right: -500px;
}

.round_line.three {
  bottom: -188px;
  left: -50px;
}

.round_line.four {
  bottom: -450px;
  left: -80px;
}

.round_line.two, .round_line.four {
  width: 800px;
  height: 800px;
}

.active .round {
  -webkit-animation: zoomIn 0.7s both 0.4s;
  animation: zoomIn 0.7s both 0.4s;
}

.active .round_line.two {
  -webkit-animation: fadeInDown2 1s both 1.4s;
  animation: fadeInDown2 1s both 1.4s;
}

.active .round_line.one {
  -webkit-animation: fadeInDown2 1s both 1s;
  animation: fadeInDown2 1s both 1s;
}

.active .round_line.three {
  -webkit-animation: fadeInUp2 1s both 1.4s;
  animation: fadeInUp2 1s both 1.4s;
}

.active .round_line.four {
  -webkit-animation: fadeInUp2 1s both 1s;
  animation: fadeInUp2 1s both 1s;
}

.active .pp_sec_title h3 {
  -webkit-animation: fadeInUp .9s both 0.6s;
  animation: fadeInUp .9s both 0.6s;
}

.active .pp_sec_title h2 {
  -webkit-animation: fadeInUp .9s both 0.8s;
  animation: fadeInUp .9s both 0.8s;
}

.pp_block {
  right: 0;
  top: 177px;
}

.section_one_img {
  margin-left: -180px;
  position: relative;
  z-index: 1;
}

.section_one_img img {
  -webkit-animation: fade .9s both;
  animation: fade .9s both;
}

.section_one_img .round {
  width: 550px;
  height: 550px;
  border-radius: 50%;
  background-image: -moz-linear-gradient(-140deg, #fae679 0%, #6ce987 100%);
  background-image: -webkit-linear-gradient(-140deg, #fae679 0%, #6ce987 100%);
  background-image: -ms-linear-gradient(-140deg, #fae679 0%, #6ce987 100%);
  -webkit-box-shadow: 0px 20px 60px 0px rgba(10, 5, 75, 0.06);
  box-shadow: 0px 20px 60px 0px rgba(10, 5, 75, 0.06);
  position: absolute;
  top: -60px;
  z-index: -2;
}

.section_one_img .dots {
  position: absolute;
  bottom: 0;
  right: 35px;
  z-index: -1;
}

.section_one-content {
  padding-right: 100px;
}

.section_one-content h2 {
  font-size: 50px;
  line-height: 60px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 40px;
}

.section_one-content h2 span {
  font-weight: 700;
}

.btn_scroll {
  font-size: 16px;
  color: #fff;
  border: 2px solid #c8c1fc;
  padding: 9px 32px;
  border-radius: 4px;
  display: inline-block;
}

.btn_scroll:hover {
  background: #5f54fd;
  border-color: #5f54fd;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(14, 6, 134, 0.2);
  box-shadow: 0px 20px 50px 0px rgba(14, 6, 134, 0.2);
  color: #fff;
}

/*=========== section-2 css ============*/
.section-2 .pp_block {
  left: 0;
  right: auto;
}

.section-2.active .pp_mackbook_img .one {
  -webkit-animation: slideInnew3 .9s both 1s;
  animation: slideInnew3 .9s both 1s;
}

.section-2.active .pp_mackbook_img .two {
  -webkit-animation: slideInnew3 .9s both 1.2s;
  animation: slideInnew3 .9s both 1.2s;
}

.section-2.active .pp_mackbook_img .three {
  -webkit-animation: slideInnew3 .9s both 1.4s;
  animation: slideInnew3 .9s both 1.4s;
}

.section-2.active .pp_mackbook_img .four {
  -webkit-animation: slideInnew3 .9s both 1.4s;
  animation: slideInnew3 .9s both 1.4s;
}

.section-2.active .pp_work_content h3 {
  -webkit-animation: fadeInUp .9s both 1.4s;
  animation: fadeInUp .9s both 1.4s;
}

.section-2.active .pp_work_content h2 {
  -webkit-animation: fadeInUp .9s both 1.6s;
  animation: fadeInUp .9s both 1.6s;
}

.section-2.active .pp_work_content p {
  -webkit-animation: fadeInUp .9s both 1.75s;
  animation: fadeInUp .9s both 1.75s;
}

.section-2.active .pp_work_content a {
  -webkit-animation: fadeInUp .9s both 1.95s;
  animation: fadeInUp .9s both 1.95s;
}

.pp_mackbook_img {
  height: 100%;
  position: relative;
}

.pp_mackbook_img .round {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background-image: -moz-linear-gradient(50deg, #fae679 0%, #f364eb 100%);
  background-image: -webkit-linear-gradient(50deg, #fae679 0%, #f364eb 100%);
  background-image: -ms-linear-gradient(50deg, #fae679 0%, #f364eb 100%);
  -webkit-box-shadow: 0px 20px 60px 0px rgba(10, 5, 75, 0.06);
  box-shadow: 0px 20px 60px 0px rgba(10, 5, 75, 0.06);
  position: absolute;
  top: -20px;
  right: -161px;
}

.pp_mackbook_img .one {
  top: -211px;
  left: 0px;
}

.pp_mackbook_img .three {
  top: -103px;
  right: -360px;
}

.pp_mackbook_img .two {
  top: 230px;
  left: -30px;
}

.pp_mackbook_img .four {
  top: 222px;
  left: -178px;
}

.pp_work_content .pp_sec_title {
  margin-bottom: 20px;
}

.pp_work_content h3, .pp_work_content h2 {
  -webkit-animation: fade .9s both;
  animation: fade .9s both;
}

.pp_work_content p {
  color: #fff;
  margin-bottom: 40px;
  -webkit-animation: fade .9s both;
  animation: fade .9s both;
}

.pp_work_content .btn_scroll {
  background: #5f54fd;
  border-color: #5f54fd;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(14, 6, 134, 0.2);
  box-shadow: 0px 20px 50px 0px rgba(14, 6, 134, 0.2);
}

.pp_work_content .btn_scroll:hover {
  background: #5f54fd;
  border-color: #5f54fd;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(14, 6, 134, 0.2);
  box-shadow: 0px 20px 50px 0px rgba(14, 6, 134, 0.2);
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}

/*=========== section-3 css ============*/
.section-3 .section_one_img {
  margin-left: 0;
  padding-top: 30px;
}

.section-3 .section_one_img .round {
  top: 45%;
  margin-top: -250px;
  background-image: -moz-linear-gradient(-120deg, #a679fa 0%, #ff6bb8 100%);
  background-image: -webkit-linear-gradient(-120deg, #a679fa 0%, #ff6bb8 100%);
  background-image: -ms-linear-gradient(-120deg, #a679fa 0%, #ff6bb8 100%);
  -webkit-box-shadow: 0px 20px 60px 0px rgba(10, 5, 75, 0.06);
  box-shadow: 0px 20px 60px 0px rgba(10, 5, 75, 0.06);
  width: 500px;
  height: 500px;
}

.section-3 .section_one_img .dots {
  right: -70px;
  bottom: 200px;
}

.section-3.active .pp_features_item {
  -webkit-animation: fadeInUp .9s both 0.9s;
  animation: fadeInUp .9s both 0.9s;
}

.section-3.active .section_one_img .round {
  -webkit-animation: zoomIn .9s both 1s;
  animation: zoomIn .9s both 1s;
}

.section-3.active .section_one_img img {
  -webkit-animation: fadeInUp .9s both 1.5s;
  animation: fadeInUp .9s both 1.5s;
}

.pp_sec_title h3, .pp_sec_title h2 {
  color: #fff;
}

.pp_sec_title h3 {
  font-weight: 300;
  font-size: 24px;
  margin-bottom: 5px;
}

.pp_features_info .row {
  margin-top: -70px;
}

.pp_features_item {
  padding-right: 80px;
  margin-top: 70px;
}

.pp_features_item .icon {
  width: 70px;
  height: 60px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #fff;
  margin-bottom: 38px;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(10, 5, 75, 0.12);
  box-shadow: 0px 20px 50px 0px rgba(10, 5, 75, 0.12);
}

.pp_features_item h4 {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 14px;
}

.pp_features_item p {
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0;
}

/*============ section-4 css===========*/
.section-4 .pp_block {
  right: auto;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.section-4 .pp_triangle {
  bottom: 50px;
  right: 80px;
  left: auto;
}

.section-4 .round_line.three {
  left: auto;
  right: 70px;
  bottom: -350px;
}

.section-4 .round_line.four {
  left: auto;
  right: -150px;
  bottom: -600px;
}

.section-4 .section_one_img .round {
  top: 50%;
  margin-top: -250px;
  width: 500px;
  height: 500px;
  left: -15px;
  background-image: -moz-linear-gradient(-120deg, #fae679 0%, #f364eb 100%);
  background-image: -webkit-linear-gradient(-120deg, #fae679 0%, #f364eb 100%);
  background-image: -ms-linear-gradient(-120deg, #fae679 0%, #f364eb 100%);
}

.section-4 .section_one_img .phon_img {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
}

.section-4.active .section_one_img img {
  -webkit-animation: fadeInUp .9s both 1s;
  animation: fadeInUp .9s both 1s;
}

.section-4.active .section_one_img .phon_img {
  -webkit-animation: fadeIn .9s both 1.5s;
  animation: fadeIn .9s both 1.5s;
}

.pp_testimonial_info .pp_testimonial_slider {
  margin-bottom: -35px;
}

.pp_testimonial_info .pp_testimonial_slider .item {
  margin-bottom: 35px;
}

.pp_testimonial_info .pp_testimonial_slider .item .media .img {
  border-radius: 50%;
  background-color: #efeaf1;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(10, 5, 75, 0.14);
  box-shadow: 0px 20px 30px 0px rgba(10, 5, 75, 0.14);
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin-right: 30px;
}

.pp_testimonial_info .pp_testimonial_slider .item .media .media-body h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 30px;
}

.pp_testimonial_info .pp_testimonial_slider .item .media .media-body .author_ratting h5 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.pp_testimonial_info .pp_testimonial_slider .item .media .media-body .author_ratting .rating i {
  font-size: 16px;
  color: #fcf925;
}

.pp_testimonial_info .pp_testimonial_slider .item:focus {
  outline: none;
}

.pp_testimonial_info .slider_nav {
  padding-left: 80px;
  padding-top: 40px;
}

.pp_testimonial_info .slider_nav i {
  font-size: 34px;
  color: #fff;
  opacity: 0.70;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  cursor: pointer;
}

.pp_testimonial_info .slider_nav i:hover {
  opacity: 1;
}

.t_left {
  left: 60px;
  bottom: 120px;
}

/*============ section-5 css===========*/
.section-5 .pp_block {
  left: 0;
  right: auto;
  top: 400px;
}

.pp_contact_info .pp_contact_item {
  padding-right: 100px;
  margin-bottom: 30px;
}

.pp_contact_info .pp_contact_item .icon {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 26px;
  color: #5f54fd;
  background: #fff;
  border-radius: 3px;
  display: block;
  margin-right: 40px;
}

.pp_contact_info .pp_contact_item .mmedia-body {
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  font-weight: 500;
}

.pp_contact_info .pp_contact_item .mmedia-body a {
  color: #fff;
  display: block;
}

.pp_contact_form .text_box {
  margin-bottom: 20px;
}

.pp_contact_form .text_box input, .pp_contact_form .text_box textarea {
  border: 1px solid rgba(255, 255, 255, 0.22);
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.12);
  color: #fff;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.pp_contact_form .text_box input.placeholder, .pp_contact_form .text_box textarea.placeholder {
  color: #fff;
}

.pp_contact_form .text_box input:-moz-placeholder, .pp_contact_form .text_box textarea:-moz-placeholder {
  color: #fff;
}

.pp_contact_form .text_box input::-moz-placeholder, .pp_contact_form .text_box textarea::-moz-placeholder {
  color: #fff;
}

.pp_contact_form .text_box input::-webkit-input-placeholder, .pp_contact_form .text_box textarea::-webkit-input-placeholder {
  color: #fff;
}

.pp_contact_form .text_box input:focus, .pp_contact_form .text_box textarea:focus {
  background: #fff;
  color: #a6a4bf;
}

.pp_contact_form .text_box input:focus.placeholder, .pp_contact_form .text_box textarea:focus.placeholder {
  color: #a6a4bf;
}

.pp_contact_form .text_box input:focus:-moz-placeholder, .pp_contact_form .text_box textarea:focus:-moz-placeholder {
  color: #a6a4bf;
}

.pp_contact_form .text_box input:focus::-moz-placeholder, .pp_contact_form .text_box textarea:focus::-moz-placeholder {
  color: #a6a4bf;
}

.pp_contact_form .text_box input:focus::-webkit-input-placeholder, .pp_contact_form .text_box textarea:focus::-webkit-input-placeholder {
  color: #a6a4bf;
}

.pp_contact_form .text_box textarea {
  height: 150px;
  padding-top: 0px;
}

.pp_contact_form .btn_scroll {
  background: transparent;
}

.pp_contact_form .btn_scroll:hover {
  background: #5f54fd;
  border-color: #5f54fd;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(14, 6, 134, 0.2);
  box-shadow: 0px 20px 50px 0px rgba(14, 6, 134, 0.2);
}

/*============ home_analytics_banner_area css ============*/
.home_analytics_banner_area {
  height: 100vh;
  min-height: 650px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -moz-linear-gradient(-50deg, #22186e 0%, #0e044b 100%);
  background-image: -webkit-linear-gradient(-50deg, #22186e 0%, #0e044b 100%);
  background-image: -ms-linear-gradient(-50deg, #22186e 0%, #0e044b 100%);
  overflow: hidden;
  position: relative;
}

.home_analytics_banner_area .elements_item {
  position: absolute;
}

.home_analytics_banner_area .elements_item:nth-child(1) {
  right: -30px;
  top: 165px;
}

.home_analytics_banner_area .elements_item:nth-child(2) {
  bottom: 250px;
  right: 100px;
}

.home_analytics_banner_area .elements_item:nth-child(3) {
  bottom: 66px;
  left: 280px;
}

.home_analytics_banner_area .elements_item:nth-child(4) {
  bottom: -80px;
  left: 100px;
}

.home_analytics_banner_area .elements_item:nth-child(5) {
  top: 250px;
  left: 180px;
}

.home_analytics_banner_area .elements_item:nth-child(6) {
  top: 180px;
  left: 150px;
}

.h_analytics_content h2 {
  color: #fff;
  font-size: 50px;
  line-height: 64px;
  font-weight: 600;
  margin-bottom: 20px;
}

.h_analytics_content p {
  font-size: 16px;
  color: #fff;
}

.h_analytics_content .er_btn_two {
  margin-top: 35px;
}

.h_analytics_content .er_btn_two:hover {
  color: #fff;
}

/*============ home_analytics_banner_area css ============*/
/*============ h_analytices_features_area css ============*/
.h_analytices_features_area {
  padding: 50px 0px 150px;
}

.h_analytices_features_area .er_btn {
  margin-top: 20px;
  border-color: #4e78f4;
}

.h_analytices_features_item {
  padding: 120px 0px;
}

.h_analytices_features_item .h_analytices_img {
  padding-top: 90px;
}

.h_analytices_features_item .h_analytices_img img {
  position: relative;
  z-index: 0;
}

.h_analytices_features_item .h_analytices_img .analytices_img_one, .h_analytices_features_item .h_analytices_img .analytices_img_two, .h_analytices_features_item .h_analytices_img .analytices_img_three {
  position: absolute;
}

.h_analytices_features_item .h_analytices_img .analytices_img_one {
  left: -30px;
  top: 0;
  z-index: 2;
}

.h_analytices_features_item .h_analytices_img .analytices_img_three {
  right: 0;
  bottom: 0;
  z-index: 4;
}

.h_analytices_features_item .h_analytices_img .analytices_img_two {
  left: -40px;
  bottom: 10px;
  -webkit-box-shadow: 0px 50px 80px 0px rgba(1, 1, 64, 0.1);
  box-shadow: 0px 50px 80px 0px rgba(1, 1, 64, 0.1);
  z-index: 0;
}

.h_analytices_features_item .h_analytices_img_two {
  padding-left: 70px;
  padding-bottom: 30px;
  padding-top: 70px;
}

.h_analytices_features_item .h_analytices_img_two .analytices_img_one, .h_analytices_features_item .h_analytices_img_two .analytices_img_two, .h_analytices_features_item .h_analytices_img_two .analytices_img_three {
  position: absolute;
  -webkit-box-shadow: 0px 50px 80px 0px rgba(1, 1, 64, 0.1);
  box-shadow: 0px 50px 80px 0px rgba(1, 1, 64, 0.1);
}

.h_analytices_features_item .h_analytices_img_two .analytices_img_one {
  bottom: 0;
  left: 20px;
}

.h_analytices_features_item .h_analytices_img_two .analytices_img_two {
  right: -120px;
  bottom: 100px;
}

.h_analytices_features_item .h_analytices_img_two .analytices_img_three {
  left: 60%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0;
}

.h_analytices_features_item .h_analytices_content h2 {
  font-size: 34px;
  font-weight: 500;
  color: #2c2c51;
  margin-bottom: 25px;
}

.h_analytices_features_item .h_analytices_content p {
  font-size: 16px;
  color: #7b7b93;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 40px;
}

.h_analytices_features_item .h_analytices_content ul {
  margin-bottom: 0;
  border-top: 1px solid #ececf6;
  padding-top: 12px;
}

.h_analytices_features_item .h_analytices_content ul li {
  font-size: 16px;
  line-height: 28px;
  color: #7b7b93;
  font-weight: 400;
  position: relative;
  padding-left: 20px;
  margin-top: 20px;
}

.h_analytices_features_item .h_analytices_content ul li span {
  font-weight: 500;
  color: #2c2c51;
}

.h_analytices_features_item .h_analytices_content ul li:before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #26da15;
  position: absolute;
  left: 0;
  top: 10px;
}

.h_analytices_features_item + .h_analytices_features_item {
  border-top: 1px solid #ececf6;
}

/*============ h_analytices_features_area css ============*/
/*============ h_action_area_three css ============*/
.h_action_area_three {
  background-color: #0e044b;
  padding: 100px 0px;
  position: relative;
  z-index: 1;
}

.h_action_area_three .shap_img {
  position: absolute;
  z-index: -1;
}

.h_action_area_three .shap_img.one {
  right: 0;
  top: 0;
}

.h_action_area_three .shap_img.two {
  left: 0;
  bottom: 0;
}

.h_action_area_three .h_action_content .video_icon {
  width: 70px;
  height: 70px;
  line-height: 74px;
  margin-bottom: 55px;
}

.h_action_area_three .h_action_content .video_icon:after {
  display: none;
}

.h_action_area_three .h_action_content .video_icon:before {
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
  background: rgba(255, 255, 255, 0.4);
}

.h_action_area_three .h_action_content .video_icon i {
  color: #4e78f4;
  text-shadow: none;
}

.h_action_area_three .h_action_content h2 {
  font-size: 34px;
  line-height: 44px;
  margin-bottom: 25px;
}

.h_action_area_three .h_action_content p {
  color: #a3a3b7;
}

.h_action_area_three .h_action_content .author h6 {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
}

.h_action_area_three .h_action_content .author p {
  color: #7b7b93;
  font-weight: 400;
  margin-bottom: 0;
}

/*============ h_action_area_three css ============*/
/*============ easy_setup_area css ============*/
.analytices_title span {
  display: block;
  font-weight: 300;
  color: #2c2c51;
}

.analytices_title p {
  font-weight: 400;
  color: #7b7b93;
  font-size: 16px;
  max-width: 570px;
  margin: 0 auto;
}

.setup_inner {
  max-width: 830px;
  margin: -30px auto 0px;
}

.setup_inner .setup_item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 0px;
  position: relative;
}

.setup_inner .setup_item.flex-row-reverse .setup_content {
  text-align: right;
  padding-right: 30px;
  padding-left: 0;
}

.setup_inner .setup_item .setup_img {
  text-align: center;
}

.setup_inner .setup_item .round {
  border-radius: 50%;
  background-color: white;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(1, 1, 64, 0.08);
  box-shadow: 0px 10px 30px 0px rgba(1, 1, 64, 0.08);
  width: 80px;
  height: 80px;
  text-align: center;
  margin: 0 auto;
  font-size: 30px;
  color: #4e78f4;
  font-weight: 500;
  line-height: 80px;
}

.setup_inner .setup_item .line {
  position: absolute;
  left: 50%;
  top: 0;
  width: 0;
  height: 100%;
  border-right: 2px dashed #94a2ce;
  opacity: 0.3;
  margin-left: -1px;
  z-index: -1;
}

.setup_inner .setup_item:first-child .line {
  top: 50%;
  height: 50%;
}

.setup_inner .setup_item:last-child .line {
  top: 0;
  height: 50%;
}

.setup_inner .setup_content {
  padding-left: 30px;
}

.setup_inner .setup_content h5 {
  font-size: 20px;
  font-weight: 600;
  color: #2c2c51;
  line-height: 28px;
  margin-bottom: 15px;
}

.setup_inner .setup_content p {
  font-size: 16px;
  color: #7b7b93;
  font-weight: 400;
  margin-bottom: 0;
}

.analytices_price_item {
  border: 1px solid #f0f0f6;
  border-radius: 5px;
  padding: 40px;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.analytices_price_item .p_head {
  text-align: center;
  border-bottom: 1px solid #ececf6;
  padding-bottom: 30px;
}

.analytices_price_item .p_head h5 {
  font-size: 20px;
  font-weight: 500;
  color: #2c2c51;
}

.analytices_price_item .p_head .rate {
  font-size: 34px;
  font-weight: 600;
  color: #4e78f4;
  padding-bottom: 13px;
  padding-top: 22px;
}

.analytices_price_item .p_head h6 {
  font-size: 16px;
  color: #7b7b93;
  font-weight: 400;
}

.analytices_price_item .p_head .tag {
  color: #2cc91d;
}

.analytices_price_item .p_head .tag i {
  margin-right: 8px;
  vertical-align: middle;
}

.analytices_price_item .p_body {
  padding-top: 20px;
  margin-bottom: 0;
}

.analytices_price_item .p_body li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  color: #7b7b93;
  font-size: 400;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 12px 0px;
}

.analytices_price_item .p_body li i {
  font-size: 14px;
}

.analytices_price_item .er_btn {
  margin-top: 40px;
  display: block;
  text-align: center;
}

.analytices_price_item.active, .analytices_price_item:hover {
  -webkit-box-shadow: 0px 40px 80px 0px rgba(1, 1, 64, 0.08);
  box-shadow: 0px 40px 80px 0px rgba(1, 1, 64, 0.08);
  border-color: #fff;
}

.analytices_price_item.active .p_body li, .analytices_price_item:hover .p_body li {
  color: #2c2c51;
}

.analytices_price_item.active .p_body li .ti-check, .analytices_price_item:hover .p_body li .ti-check {
  color: #26da15;
}

.analytices_price_item.active .p_body li .ti-close, .analytices_price_item:hover .p_body li .ti-close {
  color: #fc314e;
}

/*============ easy_setup_area css ============*/
/*============ analytices_customers_logo_area css ============*/
.analytices_customers_logo_area {
  padding: 200px 0px 120px;
}

.analytices_logo {
  display: block;
  text-align: center;
  padding: 25px;
  border-radius: 6px;
  margin-top: 20px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.analytices_logo:hover {
  background-color: white;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(1, 1, 64, 0.08);
  box-shadow: 0px 20px 50px 0px rgba(1, 1, 64, 0.08);
}

.analytices_logo_content {
  padding-right: 80px;
}

.analytices_logo_content .analytices_title {
  margin-bottom: 0;
}

.analytices_logo_content .er_btn {
  margin-top: 40px;
}

/*============ analytices_customers_logo_area css ============*/
/*============ analytices_list_area css ============*/
.analytices_list_area {
  position: relative;
  padding-top: 100px;
  padding-bottom: 150px;
  z-index: 2;
}

.analytices_list_area .macbook_a {
  position: absolute;
  right: 0;
  bottom: 0;
}

.analytices_list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  padding-top: 40px;
}

.analytices_list li {
  font-size: 16px;
  line-height: 20px;
  color: #7b7b93;
  position: relative;
  padding-left: 30px;
  margin-top: 20px;
}

.analytices_list li:before {
  content: "\e64c";
  display: inline-block;
  font-family: 'themify';
  position: absolute;
  left: 0;
  top: 0px;
  font-size: 14px;
  color: #26da15;
}

.analytices_action_area_two {
  padding-top: 120px;
}

.analytices_action_area_two:after {
  background: url("../img/home-software/down_bg.png") no-repeat scroll;
  top: -70%;
}

/*============ analytices_list_area css ============*/
/*====================================================*/
/*====================================================*/
/*============== footer_area css ==============*/
.f_bg {
  background: #eff2f9;
  -webkit-box-shadow: 0px -1px 0px 0px rgba(231, 236, 246, 0.004);
  box-shadow: 0px -1px 0px 0px rgba(231, 236, 246, 0.004);
}

.footer_top {
  border-bottom: 1px solid #e0e3ef;
  padding: 115px 0px 100px;
}

.f_widget .widget-wrap {
  margin-top: 35px;
}

.f_widget .widget-wrap p span {
  color: #051441;
}

.f_widget .widget-wrap p a {
  color: #677294;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.f_widget.company_widget .mchimp-errmessage, .f_widget.company_widget .mchimp-sucmessage {
  position: absolute;
}

.f_widget.about-widget .f_list {
  margin-bottom: 0px;
}

.f_widget.about-widget .f_list li {
  margin-bottom: 15px;
}

.f_widget.about-widget .f_list li a {
  font: 300 16px/20px "Poppins", sans-serif;
  color: #677294;
  position: relative;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  display: inline-block;
}

.f_widget.about-widget .f_list li a:before {
  content: "";
  width: 0;
  height: 1px;
  background: #6754e2;
  right: 0;
  left: auto;
  bottom: 0;
  position: absolute;
  -webkit-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  transition: width 0.2s linear;
}

.f_widget.about-widget .f_list li a:hover:before {
  width: 100%;
  right: auto;
  left: 0;
}

.f_widget.about-widget .f_list li:last-child {
  margin-bottom: 0px;
}

.f_subscribe {
  position: relative;
  margin-top: 40px;
}

.f_subscribe .form-control {
  font: 300 14px "Poppins", sans-serif;
  color: #333;
  border: 1px solid #e2e7f3;
  border-radius: 3px;
  background: #e9ecf3;
  padding-left: 25px;
  height: 54px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.f_subscribe .form-control.placeholder {
  color: #9ba2b5;
}

.f_subscribe .form-control:-moz-placeholder {
  color: #9ba2b5;
}

.f_subscribe .form-control::-moz-placeholder {
  color: #9ba2b5;
}

.f_subscribe .form-control::-webkit-input-placeholder {
  color: #9ba2b5;
}

.f_subscribe .form-control:focus {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
}

.f_subscribe button {
  position: absolute;
  right: 25px;
  background: transparent;
  padding: 0;
  color: #222d39;
  font-size: 16px;
  top: 52%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.footer_bottom {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f88a6;
  padding: 27px 0px;
}

.footer_bottom p a {
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.footer_bottom .f_menu {
  margin-bottom: 0px;
}

.footer_bottom .f_menu li {
  display: inline-block;
  position: relative;
}

.footer_bottom .f_menu li a {
  color: #7f88a6;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.footer_bottom .f_menu li a:hover {
  color: #6754e2;
}

.footer_bottom .f_menu li + li {
  margin-left: 16px;
}

.footer_bottom .f_menu li + li:before {
  content: "";
  width: 1px;
  height: 12px;
  background: #b1b7ca;
  display: inline-block;
  margin-right: 18px;
  vertical-align: middle;
}

.footer_bottom a:hover {
  color: #6754e2;
}

.f_social_icon_two a {
  font-size: 14px;
  color: #969db4;
  margin: 0px 8px;
}

/*================ footer area two css ==============*/
.footer_area_two {
  background: #fbfbfd;
}

.footer_area_two .footer_top_two {
  border-bottom: 1px solid #e9ecf3;
  padding: 120px 0px 70px;
}

.footer_area_two .footer_top_two .f_widget.about-widget .f_list li a:before {
  background: #00aff0;
}

.footer_area_two .footer_top_two .f_widget.about-widget .f_list li a:hover {
  color: #00aff0;
}

.footer_area_two .footer_top_two .f_widget .widget-wrap p a:hover {
  color: #00aff0;
}

.footer_area_two .footer_bottom {
  position: relative;
}

.footer_area_two .footer_bottom p a {
  color: #00aff0;
}

.footer_area_two .footer_bottom .f_menu li a:hover {
  color: #00aff0;
}

.footer_area_two .footer_bottom .f_menu li + li:before {
  background: #7f88a6;
}

.f_social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 45px;
  color: #858da8;
  display: inline-block;
  background: #ebeef5;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.f_social_icon a:hover {
  background: #00aff0;
  color: #fff;
}

.f_social_icon a + a {
  margin-left: 10px;
}

.pagescroll_btn {
  width: 44px;
  height: 44px;
  border: 1px solid #1d1d42;
  font-size: 16px;
  color: #00aff0;
  text-align: center;
  line-height: 44px;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -22px;
  background: #121233;
}

.footer_three {
  background: #1b1e29;
}

.footer_three .footer_top {
  border-color: #252130;
  color: #b2b6c4;
}

.footer_three .footer_top p {
  color: #b2b6c4;
}

.footer_three .footer_top .f-title {
  color: #fff;
}

.footer_three .footer_top .f_widget.about-widget .f_list li a {
  color: #b2b6c4;
}

.footer_three .footer_top .f_widget.about-widget .f_list li a:before {
  background: #a8a9ab;
}

.footer_three .footer_top .f_widget.about-widget .f_list li a:hover {
  color: #fff;
}

.footer_three .footer_top .social_icon {
  margin-top: 42px;
}

.footer_three .footer_top .social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  color: #8a8e9b;
  font-size: 14px;
  line-height: 45px;
  background: #282b38;
  text-align: center;
  display: inline-block;
  margin-right: 12px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.footer_three .footer_top .social_icon a:hover {
  background: #7444fd;
  color: #fff;
}

.footer_three .footer_bottom {
  color: #b2b6c4;
}

.footer_three .footer_bottom .f_menu li a {
  color: #b2b6c4;
}

.footer_three .footer_bottom .f_menu li a:hover {
  color: #fff;
}

/*=============== footer_area_four css =============*/
.footer_area_four .footer_top {
  border-bottom: 1px solid #e9ecf3;
  padding: 120px 0px 70px;
}

.footer_area_four .footer_top .f_widget.about-widget .f_list li a:hover {
  color: #5e2ced;
}

.footer_area_four .footer_top .f_widget.about-widget .f_list li a:hover:before {
  background: #5e2ced;
}

.footer_area_four .footer_top .f_widget .widget-wrap p a:hover {
  color: #5e2ced;
}

.footer_area_four .footer_bottom p a {
  color: #5e2ced;
}

.footer_area_four .footer_bottom a:hover {
  color: #5e2ced;
}

/*======= footer_area_five css ========*/
.footer_area_five .footer_top .f_widget.about-widget .f_list li a:hover {
  color: #3d64f4;
}

.footer_area_five .footer_top .f_widget.about-widget .f_list li a:hover:before {
  background: #3d64f4;
}

.footer_area_five .footer_top .f_widget .widget-wrap p a:hover {
  color: #3d64f4;
}

.footer_area_five .footer_bottom p a {
  color: #3d64f4;
}

.footer_area_five .footer_bottom a:hover {
  color: #3d64f4;
}

.payment_footer_area.footer_area_six {
  background-image: linear-gradient(0deg, #1c143b 0%, #111339 100%);
  border-top: 2px solid #192161;
  padding-top: 120px;
}

.payment_footer_area .footer_top_six .company_widget a {
  color: #3d64f4;
}

.payment_footer_area .footer_top_six .f_widget.about-widget .f_list li a:before {
  background: #fff;
}

.payment_footer_area .footer_top_six .f_widget.about-widget .f_list li a:hover {
  color: #fff;
}

.payment_footer_area .footer_top_six .social-widget {
  margin-right: -15px;
}

.payment_footer_area .footer_top_six .social-widget .f_social_icon {
  padding-top: 4px;
}

.payment_footer_area .footer_top_six .social-widget .f_social_icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #232453;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.payment_footer_area .footer_top_six .social-widget .f_social_icon a + a {
  margin-left: 2px;
}

.payment_footer_area .footer_top_six .social-widget .f_social_icon a:hover {
  background: #3d57f4;
}

/*============ footer_seven css==============*/
.footer_seven {
  border-top: 1px solid #d9e1fb;
}

.footer_seven .f_social_icon {
  padding-top: 14px;
}

.footer_seven .f_social_icon a {
  width: auto;
  height: auto;
  font-size: 14px;
  color: #969db4;
  background: transparent;
}

.footer_seven .f_social_icon a:hover {
  color: #4069eb;
}

.footer_seven .f_social_icon a + a {
  margin-left: 16px;
}

.footer_seven .f_widget .widget-wrap p a:hover {
  color: #4069eb;
}

.footer_seven .f_widget.about-widget .f_list li {
  margin-bottom: 13px;
}

.footer_seven .f_widget.about-widget .f_list li a {
  font-size: 14px;
}

.footer_seven .f_widget.about-widget .f_list li a:before {
  background: #4069eb;
}

.footer_seven .f_widget.about-widget .f_list li a:hover {
  color: #4069eb;
}

.footer_seven .footer_bottom {
  padding: 20px 0px;
}

.footer_seven .footer_bottom a:hover {
  color: #4069eb;
}

.footer_seven .footer_bottom p a {
  color: #4069eb;
}

/*============= error_footer css ===========*/
.error_footer {
  background: #eff2f9;
  padding: 40px 0px;
}

.error_footer a:hover {
  color: #5e2ced;
}

.error_footer .f_menu li a {
  position: relative;
}

.error_footer .f_menu li a:before {
  content: "";
  width: 0;
  height: 1px;
  background: #5e2ced;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.error_footer .f_menu li a:hover {
  color: #5e2ced;
}

.error_footer .f_menu li a:hover:before {
  width: 100%;
}

.error_footer .f_menu li + li:before {
  display: none;
}

/*============== footer_dark_one css =============*/
.footer_dark_one {
  background: #15133a;
}

.footer_dark_one .footer_top {
  border-color: #1f1d48;
}

.footer_dark_one .f_widget.about-widget .f_list li a, .footer_dark_one .f_widget .widget-wrap p a, .footer_dark_one .f_social_icon_two a {
  color: #9ca5c1;
}

.dark_f_bottom a {
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.dark_f_bottom a:hover {
  color: #4069eb;
}

.dark_f_bottom p {
  color: #9ca5c1;
}

.dark_f_bottom p a {
  color: #4069eb;
}

.dark_f_bottom .f_menu li a {
  color: #9ca5c1;
}

.dark_f_bottom .f_menu li a:hover {
  color: #4069eb;
}

.dark_f_bottom .f_menu li + li:before {
  background: #5e6482;
}

.dark_widget .f-title {
  color: #fff;
}

.dark_widget.company_widget .f_social_icon_two {
  margin-top: 25px;
}

.dark_widget.company_widget .f_social_icon_two a:first-child {
  margin-left: 0px;
}

.dark_widget.company_widget .f_social_icon_two a:hover {
  color: #4069eb;
}

.dark_widget .widget-wrap p span {
  color: #fff;
}

.dark_widget .widget-wrap p a:hover {
  color: #4069eb;
}

.dark_widget.about-widget .f_list li a:before {
  background: #4069eb;
}

.dark_widget.about-widget .f_list li a:hover {
  color: #4069eb;
}

.dark_widget .f_subscribe .form-control {
  background: transparent;
  border-color: #202430;
}

.dark_widget .f_subscribe button {
  color: #fff;
}

/*============ footer_dark_two css ===========*/
.footer_dark_two {
  background: #13112d;
}

.footer_dark_two .footer_top {
  border-color: #1f1d48;
}

.footer_dark_two .dark_widget.company_widget .f_social_icon_two a:hover {
  color: #6754e2;
}

.footer_dark_two .dark_widget .widget-wrap p a:hover {
  color: #6754e2;
}

.footer_dark_two .dark_widget.about-widget .f_list li a:before {
  background: #6754e2;
}

.footer_dark_two .dark_widget.about-widget .f_list li a:hover {
  color: #6754e2;
}

.footer_dark_two .dark_f_bottom a {
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.footer_dark_two .dark_f_bottom a:hover {
  color: #6754e2;
}

.footer_dark_two .dark_f_bottom p a {
  color: #6754e2;
}

.footer_dark_two .dark_f_bottom .f_menu li a:hover {
  color: #6754e2;
}

.footer_dark_three {
  background: #1b1e29;
}

.footer_dark_three .footer_top {
  border-color: #212532;
}

.footer_dark_three .dark_widget .widget-wrap p a {
  color: #6d738c;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.footer_dark_three .dark_widget .widget-wrap p a:hover {
  color: #5e2ced;
}

.footer_dark_three .dark_widget.about-widget .f_list li a {
  color: #6d738c;
}

.footer_dark_three .dark_widget.about-widget .f_list li a:before {
  background: #5e2ced;
}

.footer_dark_three .dark_widget.about-widget .f_list li a:hover {
  color: #5e2ced;
}

.footer_dark_three .dark_f_bottom a {
  color: #6d738c;
}

.footer_dark_three .dark_f_bottom a:hover {
  color: #5e2ced;
}

.footer_dark_three .dark_f_bottom p {
  color: #6d738c;
}

.footer_dark_three .dark_f_bottom p a {
  color: #5e2ced;
}

.footer_dark_three .dark_f_bottom .f_menu li a {
  color: #6d738c;
}

.footer_dark_three .dark_f_bottom .f_menu li a:hover {
  color: #5e2ced;
}

.footer_dark_four {
  background: #16143a;
}

.footer_dark_five {
  background: #121233;
}

.footer_dark_five .footer_top_two {
  border-color: #1d1d42;
}

.footer_dark_five .f_widget .widget-wrap p span {
  color: #fff;
}

.footer_dark_five .f_widget .f_social_icon a {
  background: #1c1c42;
}

.footer_dark_five .f_widget .f_social_icon a:hover {
  background: #00aff0;
}

/*=========== footer_nine_area css ============*/
.footer_nine_area {
  position: relative;
}

.footer_nine_area .footer_shap {
  position: absolute;
  background: url("../img/home9/footer.png") no-repeat scroll center;
  left: 0;
  width: 100%;
  background-size: cover;
  top: 0px;
  height: 100%;
}

.footer_nine_top {
  padding-bottom: 170px;
  position: relative;
  padding-top: 150px;
}

.footer_nine_top .f_widget .f-title {
  color: #051441;
  text-transform: uppercase;
}

.footer_nine_top .f_widget .f-title:after {
  content: "";
  width: 100px;
  height: 1px;
  display: block;
  background: #ebeefa;
  margin-top: 15px;
}

.footer_nine_top .f_widget.about-widget .f_list li a:before {
  background: #0479f7;
}

.footer_nine_top .f_widget.about-widget .f_list li a:hover {
  color: #0479f7;
}

.footer_nine_top .company_widget .f_social_icon_two a:first-child {
  margin-left: 0;
}

.footer_nine_top .company_widget .f_social_icon_two a:hover {
  color: #0479f7;
}

.footer_nine_bottom {
  background: #051441;
  padding: 27px 0px;
}

.footer_nine_bottom p {
  font: 300 14px "Poppins", sans-serif;
  color: #7f88a6;
}

.footer_nine_bottom p a {
  color: #fff;
}

.flag_selector {
  float: right;
  background-color: transparent !important;
  border: 0px !important;
  border-radius: 0px;
  padding: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font: 400 14px "Poppins", sans-serif;
  color: #7f88a6;
}

.flag_selector .dropdown-toggle {
  background-color: transparent !important;
  border: 0px !important;
  border-radius: 0px;
  padding: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font: 400 14px "Poppins", sans-serif;
  color: #7f88a6;
}

.footer_nine_bottom .flag_selector .dropdown-toggle:after {
  display: none;
}

.footer_nine_bottom .flag_selector .dropdown-toggle:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.footer_nine_bottom .flag_selector .text {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.footer_nine_bottom .flag_selector .text .flag-icon {
  margin-left: 0px;
  margin-right: 20px;
  float: none;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.footer_nine_bottom .flag_selector .flag-icon {
  position: relative;
  width: 39px;
  line-height: 1em;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  height: 22px;
  margin-left: 27px;
  float: right;
}

.footer_nine_bottom .flag_selector .flag-icon:before {
  content: "";
  width: 1px;
  height: 100%;
  position: relative;
  display: inline-block;
  background: #18295c;
  left: -15px;
}

.footer_nine_bottom .flag_selector .flag-icon.flag-icon-us {
  background-image: url(../img/home9/flag.png);
}

.footer_nine_bottom .flag_selector .flag-icon.flag-icon-mx {
  background-image: url(../img/home9/flag.png);
}

.footer_nine_bottom .flag_selector .dropdown-menu {
  padding: 0px;
  border-radius: 0px;
  border: 0px;
}

.footer_nine_bottom .flag_selector .dropdown-menu .flag-icon:before {
  display: none;
}

.footer_nine_bottom .flag_selector .dropdown-menu li .dropdown-item {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.footer_nine_bottom .flag_selector .dropdown-menu li .dropdown-item:hover {
  background: #21d4fd;
  color: #fff;
}

/*===========footer_dark_ten css ===========*/
.footer_dark_ten .f_widget .widget-wrap p a:hover {
  color: #23b1fe;
}

.footer_dark_ten .f_widget.about-widget .f_list li a:before {
  background: #23b1fe;
}

.footer_dark_ten .f_widget.about-widget .f_list li a:hover {
  color: #23b1fe;
}

.footer_dark_ten .dark_f_bottom .f_menu li a:hover, .footer_dark_ten .dark_f_bottom a {
  color: #23b1fe;
}

.payment_footer_area {
  background: #07112d;
  border: 0px;
  padding: 220px 0px 100px;
}

.payment_footer_area .f_widget.company_widget p {
  color: #7f88a6;
}

.payment_footer_area .f_widget.company_widget a {
  color: #5f51fb;
}

.payment_footer_area .f_widget.about-widget .f_list li a {
  color: #959eb8;
}

.payment_footer_area .social-widget .f_social_icon a {
  background: #182240;
}

.payment_footer_area .social-widget .f_social_icon a:hover {
  background: #5f51fb;
}

.payment_footer_area_two {
  padding: 100px 0px;
}

/*============ new_footer_area css ===========*/
.new_footer_area {
  background: #fbfbfd;
}

.new_footer_area .footer_bottom {
  padding-top: 5px;
  padding-bottom: 50px;
}

.new_footer_area .footer_bottom p {
  font-size: 16px;
  color: #6a7695;
  line-height: 28px;
  margin-bottom: 0;
}

.new_footer_area .footer_bottom p i {
  color: #fd2f51;
}

.new_footer_top {
  padding: 120px 0px 270px;
  position: relative;
}

.new_footer_top .f-title {
  margin-bottom: 30px;
  color: #263b5e;
}

.new_footer_top .company_widget p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #6a7695;
  margin-bottom: 20px;
}

.new_footer_top .company_widget .f_subscribe_two .form-control {
  border: 1px solid #e2e2eb;
  border-radius: 4px;
  height: 55px;
  background: #fff;
  font-size: 15px;
  font-weight: 300;
  line-height: 55px;
  padding-left: 30px;
}

.new_footer_top .company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px;
}

.new_footer_top .f_widget.about-widget .f_list li {
  margin-bottom: 11px;
}

.new_footer_top .f_widget.about-widget .f_list li a {
  color: #6a7695;
}

.new_footer_top .f_widget.about-widget .f_list li a:before {
  display: none;
}

.new_footer_top .f_widget.about-widget .f_list li a:hover {
  color: #5e2ced;
}

.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #e2e2eb;
  font-size: 12px;
}

.new_footer_top .f_social_icon a:hover {
  background: #5e2ced;
  border-color: #5e2ced;
}

.new_footer_top .f_social_icon a + a {
  margin-left: 4px;
}

.new_footer_top .footer_bg {
  position: absolute;
  bottom: 0;
  background: url("../img/seo/footer_bg.png") no-repeat scroll center 0;
  width: 100%;
  height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
  background: url("../img/seo/car.png") no-repeat center center;
  width: 70px;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 30%;
  -webkit-animation: myfirst 22s  linear infinite;
  animation: myfirst 22s  linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
  background: url("../img/seo/bike.png") no-repeat center center;
  width: 50px;
  height: 52px;
  bottom: 0;
  left: 38%;
  position: absolute;
  -webkit-animation: myfirst 30s  linear infinite;
  animation: myfirst 30s  linear infinite;
}

@-moz-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

/*===== new css =====*/
.CircularProgressbar{
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
}
.CircularProgressbar .CircularProgressbar-text{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.sec_title p{
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
}
.breadcrumb_area_three .breadcrumb_shap{
  width: 100%;
  height: 100%;
  background-size: cover;
}

/*=================== h_security_banner_area css =============*/
.h_security_banner_area {
  height: 100vh;
  min-height: 650px;
  background-image: linear-gradient(60deg, #0063c1 0%, #0e79de 100%);
  align-items: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 1;
}

.h_security_banner_area .s_round {
  position: absolute;
  border-radius: 50%;
  top: -100px;
  background: rgba(255, 255, 255, 0.05);
  z-index: -1;
}

.h_security_banner_area .s_round.one {
  width: 280px;
  height: 280px;
  left: -8%;
}

.h_security_banner_area .s_round.two {
  width: 500px;
  height: 500px;
  left: -430px;
}

.h_security_banner_area .s_shap {
  right: 0;
  bottom: 0;
}

.security_banner_content h2 {
  font-size: 50px;
  line-height: 64px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 18px;
}

.security_banner_content p {
  font-size: 20px;
}

.security_banner_content .app_btn {
  background-color: #fba820;
  -webkit-box-shadow: 0px 10px 50px 0px rgba(10, 92, 80, 0.2);
  box-shadow: 0px 10px 50px 0px rgba(10, 92, 80, 0.2);
  font-size: 16px;
  padding: 10px 39px;
}

.security_banner_content .app_btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}

.security_banner_content .w_btn {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-left: 30px;
}

.security_banner_content .w_btn img {
  margin-right: 10px;
  vertical-align: sub;
}

.security_title h2 {
  font-size: 34px;
  line-height: 44px;
}

.security_title h2 span {
  display: block;
  font-weight: 300;
}

/*================== h_security_area css ===========*/
/*================== h_security_area css ===========*/
.h_security_area {
  padding: 100px 0px;
}

.h_security_area .row {
  margin-bottom: -45px;
}

.h_security_item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 45px 0px;
}

.h_security_item img {
  margin-right: 40px;
}

.h_security_item .media-body h4 {
  font-size: 20px;
  font-weight: 600;
  color: #2c2c51;
  line-height: 28px;
  margin-bottom: 18px;
}

.h_security_item .media-body p {
  margin-bottom: 0;
  color: #7b7b93;
}

/*=================== h_security_area css =============*/
/*=================== security_features_area css =============*/
.security_features_area {
  padding: 50px 0px;
  position: relative;
  z-index: 1;
}

.security_features_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 1030px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.security_features_inner .s_features_item .item {
  width: 300px;
  text-align: center;
  border-radius: 30px;
  background-color: white;
  -webkit-box-shadow: 0px 50px 100px 0px rgba(1, 1, 64, 0.06);
  box-shadow: 0px 50px 100px 0px rgba(1, 1, 64, 0.06);
  padding: 83px 0px;
  position: relative;
}

.security_features_inner .s_features_item .item h6 {
  font-size: 24px;
  color: #2c2c51;
  font-weight: 500;
  margin-bottom: 0;
  padding-top: 26px;
}

.security_features_inner .s_features_item:nth-child(3n - 1) {
  margin-top: 75px;
}

.security_features_inner .s_features_item:nth-child(3n + 1) {
  margin-top: 150px;
}

/*=================== security_features_area css =============*/
/*=================== h_security_about_area css =============*/
.h_security_about_area {
  background: #fafbfc;
  position: relative;
  z-index: 0;
}

.h_security_about_area:before {
  content: "";
  width: 150%;
  height: 460px;
  background: #fafbfc;
  position: absolute;
  z-index: -5;
  -webkit-transform: rotate(-10.9deg);
  -ms-transform: rotate(-10.9deg);
  transform: rotate(-10.9deg);
  top: -215px;
  right: -171px;
  z-index: -1;
}

.h_security_about_area .h_analytices_content ul {
  border-top: 0px;
  padding-top: 0;
}

.h_analytices_features_item.flex-row-reverse .h_security_img:before {
  left: auto;
  right: 20px;
  background: #edb4cb;
}

.h_analytices_features_item.flex-row-reverse .h_security_img img {
  -webkit-box-shadow: 15px 25.981px 60px 0px rgba(1, 1, 64, 0.14);
  box-shadow: 15px 25.981px 60px 0px rgba(1, 1, 64, 0.14);
}

.h_security_img {
  position: relative;
  z-index: 0;
}

.h_security_img:before {
  content: "";
  position: absolute;
  width: 70px;
  top: 60px;
  bottom: 60px;
  left: 20px;
  background: #b1ceeb;
  z-index: -1;
}

.h_security_img img {
  -webkit-box-shadow: -15px 25.981px 60px 0px rgba(1, 1, 64, 0.14);
  box-shadow: -15px 25.981px 60px 0px rgba(1, 1, 64, 0.14);
}

/*=================== h_security_about_area css =============*/

/*============== priceing_area_four css ============*/
.pricing_area_four .sec_title {
  margin-bottom: 50px;
}

.pricing_area_four .sec_title h2 {
  margin-bottom: 0px;
}

.pricing_area_four .sec_title p {
  font-style: italic;
}

.price_info_two {
  -webkit-box-shadow: 0px 4px 5px 0px rgba(12, 0, 46, 0.05);
  box-shadow: 0px 4px 5px 0px rgba(12, 0, 46, 0.05);
}

.price_info_two .price_head {
  display: table;
  width: 100%;
}

.price_info_two .price_head .p_head {
  width: calc(100% / 4);
  text-align: center;
  color: #fff;
  vertical-align: middle;
  display: table-cell;
  padding: 31px 0px;
}

.price_info_two .price_head .p_head h4 {
  color: #222d39;
  font-size: 20px;
}

.price_info_two .price_head .p_head h4, .price_info_two .price_head .p_head h5 {
  margin-bottom: 0px;
}

.price_info_two .price_head .p_head p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.price_info_two .price_head .p_head:nth-child(2) {
  background: #0ed1b3;
}

.price_info_two .price_head .p_head:nth-child(3) {
  background: #6c84ee;
}

.price_info_two .price_head .p_head:nth-child(4) {
  background: #ee6cda;
}

.price_info_two .price_head .p_head h5 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.price_info_two .price_item {
  width: calc(100% / 4);
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  cursor: pointer;
  padding: 15px 0px;
}

.price_info_two .price_item:nth-child(odd) {
  background: #f8f8fa;
}

.price_info_two .price_item:first-child {
  text-align: left;
  padding-left: 30px;
}

.price_info_two .price_item h5 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #222d39;
}

.price_info_two .price_item h5 i {
  font-size: 20px;
}

.price_info_two .price_item .pr_title {
  position: relative;
  display: inline-block;
  padding-left: 30px;
}

.price_info_two .price_item .pr_title:before {
  content: "\70";
  position: absolute;
  left: 0;
  top: 0;
  font-family: eleganticons;
  color: #afb5c7;
  -webkit-transition: color 0s linear;
  -o-transition: color 0s linear;
  transition: color 0s linear;
}

.price_info_two .price_item .pr_title:hover:before {
  color: #5e2ced;
}

.price_info_two .price_item .check {
  color: #0ed1b3;
}

.price_info_two .price_item .cros {
  color: #afb5c7;
}

.price_info_two .pr_list {
  display: table;
  width: 100%;
}

.price_info_two .price_btn {
  border-radius: 45px;
  color: #677294;
  border-color: #dfe3ed;
  padding: 0px 32px;
  line-height: 46px;
  margin: 40px 0px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.price_info_two .price_btn:hover {
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.tooltip .tooltip-inner {
  background-color: #fff !important;
  opacity: 1;
  color: #111 !important;
  opacity: 1;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.14);
  box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.14);
  font-size: "Poppins", sans-serif;
  font-size: 16px;
  color: #677294;
  line-height: 26px;
  width: 100%;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #fff;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #fff;
}

.tooltip.show {
  opacity: 1;
}

.price_info_three .price_head .p_head:nth-child(2) {
  background: #fba820;
}

.price_info_three .price_head .p_head:nth-child(3) {
  background: #e63779;
}

.price_info_three .price_head .p_head:nth-child(4) {
  background: #0e79de;
}

.price_info_three .price_item h5 {
  color: #2c2c51;
}

.price_info_three .price_item .pr_title:hover:before {
  color: #26da15;
}

.price_info_three .price_item .check {
  color: #0e79de;
}

.price_info_three .price_item .cros {
  color: #fc3f48;
}

.price_info_three .price_btn:hover {
  background: #0e79de;
  border-color: #0e79de;
}

/*============ analytices_list_area css ============*/
.analytices_list_area {
  position: relative;
  padding-top: 100px;
  padding-bottom: 150px;
  z-index: 2;
}

.analytices_list_area:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 560px;
  z-index: -1;
  left: 0;
  background: url("../img/home-software/down_bg.png") no-repeat scroll;
  bottom: 0;
  background-size: cover;
}

.analytices_list_area .macbook_a {
  position: absolute;
  right: 0;
  bottom: 0;
}

.analytices_list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  padding-top: 40px;
}

.analytices_list li {
  font-size: 16px;
  line-height: 20px;
  color: #7b7b93;
  position: relative;
  padding-left: 30px;
  margin-top: 20px;
}

.analytices_list li:before {
  content: "\e64c";
  display: inline-block;
  font-family: 'themify';
  position: absolute;
  left: 0;
  top: 0px;
  font-size: 14px;
  color: #4e78f4;
}

.analytices_action_area_two {
  padding-top: 120px;
}

.analytices_action_area_two:after {
  display: none;
}

.security_list_area {
  padding-top: 180px;
}

.security_list_area .macbook_a {
  bottom: 110px;
}

.security_list_area .lock {
  position: absolute;
  right: 41%;
  bottom: 100px;
  z-index: -1;
}

.security_list_area .analytices_title {
  margin-bottom: 0;
}

.security_list_area .analytices_list {
  padding-top: 25px;
}

/*============ analytices_list_area css ============*/
/*=================== security_customers_logo_area css =============*/
.security_customers_logo_area {
  padding: 100px 0px;
}

.security_customers_logo_area .hosting_title {
  margin-bottom: 50px;
}

.security_inner {
  padding: 0px 100px 20px;
  position: relative;
  border-bottom: 1px solid #ececf6;
}

.security_inner .security_leaf_left,
.security_inner .security_leaf_right {
  top: 50%;
  margin-top: -100px;
}

.security_inner .security_leaf_left {
  left: 0;
}

.security_inner .security_leaf_right {
  right: 0;
}

.security_app_btn {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  color: #7b7b93;
}

.security_app_btn i {
  color: #0e79de;
  font-size: 45px;
  vertical-align: middle;
  padding-right: 20px;
}

.security_app_btn span {
  color: #0e79de;
}

.security_app_btn + .security_app_btn {
  margin-left: 35px;
}

/*=================== security_customers_logo_area css =============*/
.security_integration_area {
  padding: 30px 0px 120px;
}

.security_integration_area .s_integration_item {
  -webkit-box-shadow: 0px 3px 8px 0px rgba(12, 0, 46, 0.08);
  box-shadow: 0px 3px 8px 0px rgba(12, 0, 46, 0.08);
}

.security_integration_area .s_integration_item:hover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
}

/*======== security_action_area css =========*/
.security_action_area {
  background: #fafafd;
  padding: 130px 0px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.security_action_area .img_bg {
  right: 0;
  top: -22px;
  z-index: -1;
}

.security_action_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.security_action_inner .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.security_action_inner .media img {
  margin-right: 70px;
}

.security_action_inner .media h3 {
  font-size: 34px;
  font-weight: 600;
  color: #2c2c51;
  margin-bottom: 0;
}

.security_action_inner .media h3 span {
  display: block;
  color: #0e79de;
  font-weight: 300;
}

.security_action_inner .er_btn_two {
  margin-top: 0;
  background: #0e79de;
  border: 0px;
  padding: 12px 57px;
  -webkit-box-shadow: 0px 10px 50px 0px rgba(10, 52, 92, 0.2);
  box-shadow: 0px 10px 50px 0px rgba(10, 52, 92, 0.2);
}

.security_action_inner .er_btn_two:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}

.security_footer_area:after {
  display: none;
}
.security_btn {
  margin-top: 0;
  font-size: 14px;
  border: 2px solid #87bcef;
  padding: 0px 39px;
  border-radius: 45px;
  box-shadow: none;
  color: #fff;
}

.security_btn:hover {
  background: #fff;
  color: #0e79de;
  border-color: #0e79de;
}
.navbar_fixed .security_btn {
  color: #0e79de;
}

.navbar_fixed .security_btn:hover {
  background: #0e79de;
  color: #fff;
}

.styles_overlay__CLSq-{
  background: rgba(0, 11, 40, 0.9) !important;
  z-index: 999999;
}
.styles_overlay__CLSq- div div > div{
  background: rgba(0, 11, 40, 0.9) !important;
}

/*=========== tracking_banner_area css =============*/
.tracking_banner_area {
  background: #efeec9;
  padding-top: 175px;
}

.tracking_banner_content h3 {
  font-size: 48px;
  color: #051441;
  font-weight: 400;
  line-height: 60px;
}

.tracking_banner_content p {
  color: #666666;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 50px;
}

.tracking_software_logo {
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 60px;
  padding-bottom: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tracking_software_logo img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.25;
}

/*============ tracking_activity_area css ==============*/
.tracking_activity_area {
  padding-top: 115px;
  background: #f6f6f6;
}

.tracking_item img {
  margin-right: 10px;
}

.tracking_item .media-body h3 {
  font-weight: 600;
  margin-bottom: 10px;
}

.tracking_item .media-body h3:hover {
  color: #6754e2;
}

.tracking_item .media-body p {
  margin-bottom: 0;
}

/*=========== tracking_product_area css ==============*/
.tracking_product_area {
  background: #f6f6f6;
}

.tracking_pr_item {
  padding: 60px;
  background: #fe8585;
}
.tracking_pr_two{
  background: #9260d9;
}
.tracking_pr_three{
  background: #fe8585;
}

.tracking_pr_item .tracking_bg {
  text-align: center;
}

.tracking_pr_item .tracking_content {
  padding: 45px 55px;
  border: 1px solid #ffc2c2;
}

.tracking_pr_item .tracking_content h3 {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  color: #fff;
  margin-bottom: 18px;
}

.tracking_pr_item .tracking_content p {
  color: #fff;
  margin-bottom: 32px;
}

.tracking_pr_item .tracking_content .seo_btn {
  border-radius: 45px;
  background: #fff;
  border: 1px solid #ffffff;
  color: #000000;
  padding: 7px 44px;
}

.tracking_pr_item .tracking_content .seo_btn:hover {
  -webkit-box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.1);
}

.tracking_pr_item .tracking_content .seo_btn_one {
  border: 1px solid #9472cd;
  color: #8964c7;
  background: #eee9f7;
}

.tracking_pr_item .tracking_content .seo_btn_one:hover {
  background: #8964c7;
}

.tracking_pr_item + .tracking_pr_item {
  margin-top: 120px;
}

.tracking_pr_two .tracking_content {
  border-color: #fff;
}

/*=========== tracking_price_area css ==============*/
.tracking_price_area {
  background: #f8ad5d;
}

.tracking_price_inner {
  max-width: 760px;
  margin: 0 auto;
}

.tracking_price_inner h2 {
  color: #fff;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 0;
}

.tracking_price_inner .track_price {
  padding-right: 70px;
}

.tracking_price_inner .track_price h3 {
  font-size: 120px;
  color: #fff;
  font-weight: 700;
  line-height: 90px;
  margin-bottom: 10px;
}

.tracking_price_inner .track_price h6 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  text-align: right;
  margin-bottom: 0;
}

.tracking_price_inner .track_body {
  padding: 80px 0px;
}

.tracking_price_inner .track_text {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  padding-left: 72px;
}

.tracking_price_inner .track_text h4 {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 18px;
}

.tracking_price_inner .track_text ul {
  margin-bottom: 0;
}

.tracking_price_inner .track_text ul li {
  font-size: 18px;
  color: #fff;
}

.tracking_price_inner .track_text ul li i {
  font-size: 16px;
  margin-right: 7px;
}

.tracking_price_inner .software_banner_btn {
  font-size: 16px;
  background-color: #6754e2;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.tracking_price_inner .software_banner_btn:hover {
  -webkit-box-shadow: 0px 17px 35px 0px rgba(103, 84, 226, 0.27);
  box-shadow: 0px 17px 35px 0px rgba(103, 84, 226, 0.27);
  color: #fff;
}

.tracking_price_inner h6 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 20px;
}

.tracking_getting_area {
  text-align: center;
  background: url("../img/home-tracking/cta_pattern_bg.jpg") no-repeat scroll center 0/cover;
}

.tracking_getting_area h2 {
  font-weight: 700;
  font-size: 36px;
  color: #222;
}

.tracking_getting_area .software_banner_btn {
  background-color: #6754e2;
  font-size: 16px;
  -webkit-box-shadow: 0px 17px 35px 0px rgba(103, 84, 226, 0.27);
  box-shadow: 0px 17px 35px 0px rgba(103, 84, 226, 0.27);
  padding: 17px 60px;
  margin-top: 10px;
}

.tracking_getting_area .software_banner_btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}

/*============== event_banner_area css ===========*/
.event_banner_area {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(-120deg, #6e1822 0%, #0e044b 100%);
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.event_banner_area .parallax-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 105%;
  z-index: -1;
  opacity: 0.2;
}

.event_banner_content {
  position: relative;
}

.event_banner_content .round {
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.05;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -300px;
  margin-left: -300px;
  z-index: -1;
}

.event_banner_content h6 {
  font-size: 20px;
  font-weight: 500;
  color: #fcb747;
  margin-bottom: 18px;
}

.event_banner_content h2 {
  font-size: 80px;
  line-height: 94px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 45px;
}

.event_banner_content h2 span {
  font-weight: 300;
}

.event_banner_content .event_btn_two {
  margin-left: 15px;
}

.event_btn {
  font-size: 16px;
  padding: 11px 51px;
  border-radius: 45px;
  background: #fd475d;
  border: 2px solid #fd475d;
  color: #fff;
}

.event_btn i {
  margin-right: 8px;
  font-size: 26px;
  vertical-align: middle;
}

.event_btn:hover {
  -webkit-box-shadow: 0px 10px 50px 0px rgba(195, 33, 52, 0.3);
  box-shadow: 0px 10px 50px 0px rgba(195, 33, 52, 0.3);
}

.event_btn_two {
  padding: 11px 33px;
  border-color: #fba820;
  color: #fba820;
  background: transparent;
}

.event_btn_two:hover {
  background: #fd475d;
  color: #fff;
  border-color: #fd475d;
}

.event_counter_area {
  background: #fdfaf6;
  padding: 75px 0px;
}

.event_text {
  padding-right: 100px;
}

.event_text h3 {
  font-size: 34px;
  line-height: 44px;
  font-weight: 500;
  color: #2c2c51;
  margin-bottom: 0;
}

.event_text h3 span {
  font-weight: 700;
  color: #fd475d;
}

/* .event_counter .box > div {
  margin: 0px 18px;
  width: calc(25% - 36px);
} */

.event_counter .box{
  width: 165px;
  height: 165px;
  border:8px solid #fd475d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.event_counter .box p {
  font-size: 40px;
  font-weight: 600;
  line-height: 30px;
  color: #fd475d;
  margin-bottom: 5px;
}

.event_counter .box span {
  font-size: 16px;
  font-weight: 400;
  color: #fd475d;
}
.event_counter .box.two{
  border-color:  #2d8dfa;
}
.event_counter .box.two p,
.event_counter .box.two span {
  color: #2d8dfa;
}


.event_counter .box.three{
  border-color:  #9449fb;
}
.event_counter .box.three p,
.event_counter .box.three span {
  color: #9449fb;
}

.event_counter .box.four{
  border-color:  #4ad425;
}
.event_counter .box.four p,
.event_counter .box.four span {
  color: #4ad425;
}

/*========== event_about_area css =======*/
.event_about_area {
  padding: 150px 0px 50px;
}

.event_about_content {
  padding-right: 120px;
}

.event_about_content .event_about_item {
  padding-top: 40px;
}

.event_about_content h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  color: #2c2c51;
  margin-bottom: 25px;
}

.event_about_content p {
  font-size: 16px;
  line-height: 28px;
  color: #7b7b93;
}

.event_about_content h6 {
  color: #2c2c51;
  font-size: 18px;
  font-weight: 500;
  padding-top: 25px;
  margin-bottom: 12px;
}

.event_about_img {
  position: relative;
}

.event_about_img .about_bg {
  height: 330px;
  border-radius: 10px;
  background-image: linear-gradient(140deg, #fd475d 0%, #0e044b 100%);
  position: absolute;
  left: 90px;
  right: -50px;
  bottom: -50px;
  opacity: 0.10;
}

.event_about_img img {
  border-radius: 10px;
}

/*========== event_team_area css =========*/
.event_team_area .slick-dots{
  margin-top: 55px;
  position: relative;
  bottom: 0;
}

.event_team_area .slick-dots li button {
  border: 0px;
  background: #f2dcdf;
}

.event_team_area .slick-dots li.slick-active button{
  background: #fd475d;
}

.event_team_slider .item {
  text-align: center;
  cursor: pointer;
  padding: 0px 15px;
}

.event_team_slider .item .e_team_img {
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
  border-radius: 6px;
  background-image: linear-gradient(60deg, #fd475d 0%, #0e044b 100%);
  overflow: hidden;
}

.event_team_slider .item .e_team_img:before, .event_team_slider .item .e_team_img:after {
  content: "";
  width: 100px;
  height: 121px;
  background: #fff;
  opacity: 0.6;
  position: absolute;
  bottom: -105px;
  right: -104px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 1;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.event_team_slider .item .e_team_img:after {
  width: 172px;
  height: 117px;
  -webkit-transform: rotate(-27deg);
  -ms-transform: rotate(-27deg);
  transform: rotate(-27deg);
  bottom: -120px;
}

.event_team_slider .item .e_team_img img {
  -webkit-transition: all 0.3s, opacity 0.4s linear;
  -o-transition: all 0.3s, opacity 0.4s linear;
  transition: all 0.3s, opacity 0.4s linear;
  width: 100%;
}

.event_team_slider .item .e_team_img ul {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 30px;
  margin-bottom: 0;
  overflow: hidden;
}

.event_team_slider .item .e_team_img ul li {
  overflow: hidden;
}

.event_team_slider .item .e_team_img ul li a {
  width: 50px;
  height: 50px;
  display: inline-block;
  border: 2px solid #fff;
  font-size: 16px;
  line-height: 50px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.event_team_slider .item .e_team_img ul li a:hover {
  background: #fff;
  color: #fd475d;
}

.event_team_slider .item .e_team_img ul li:nth-child(1) a {
  -webkit-transition: all 0.3s, background 0.2s, color 0.2s linear;
  -o-transition: all 0.3s, background 0.2s, color 0.2s linear;
  transition: all 0.3s, background 0.2s, color 0.2s linear;
}

.event_team_slider .item .e_team_img ul li:nth-child(2) a {
  transition: all 0.4s, background 0.2s, color 0.2s linear;
}

.event_team_slider .item .e_team_img ul li:nth-child(3) a {
  -webkit-transition: all 0.5s, background 0.2s, color 0.2s linear;
  -o-transition: all 0.5s, background 0.2s, color 0.2s linear;
  transition: all 0.5s, background 0.2s, color 0.2s linear;
}

.event_team_slider .item .e_team_img ul li + li {
  margin-top: 10px;
}

.event_team_slider .item h4 {
  font-size: 20px;
  font-weight: 500;
  color: #2c2c51;
  margin-bottom: 2px;
}

.event_team_slider .item h4:hover {
  color: #fd475d;
}

.event_team_slider .item p {
  color: #7b7b93;
  margin-bottom: 0;
}

.event_team_slider .item:hover .e_team_img:before {
  bottom: -55px;
  right: -44px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.event_team_slider .item:hover .e_team_img:after {
  bottom: -85px;
  right: -44px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.event_team_slider .item:hover img {
  opacity: 0.2;
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
}

.event_team_slider .item:hover ul li a {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

/*============== event_features_area css ============*/
.event_features_area {
  background: #fdfaf6;
  padding-top: 100px;
}

.event_features_inner {
  margin-bottom: -120px;
  position: relative;
  z-index: 1;
}

.event_features_item {
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 40px 60px 0px rgba(92, 10, 20, 0.08);
  box-shadow: 0px 40px 60px 0px rgba(92, 10, 20, 0.08);
  height: 100%;
  padding: 40px;
}

.event_features_item img {
  margin-bottom: 35px;
}

.event_features_item h5 {
  font-weight: 500;
  font-size: 20px;
  color: #2c2c51;
  margin-bottom: 18px;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  display: block;
}

.event_features_item h5:hover {
  color: #fd475d;
}

.event_features_item p {
  color: #7b7b93;
}

.event_features_item .e_features_btn {
  font-size: 16px;
  font-weight: 500;
  color: #2c2c51;
  display: inline-block;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  margin-top: 20px;
}

.event_features_item .e_features_btn:hover {
  color: #fd475d;
}

.event_promotion_area {
  padding-top: 266px;
  padding-bottom: 120px;
  position: relative;
  z-index: 0;
  background: #fff;
}

.event_promotion_info {
  margin-top: -60px;
}

.event_promotion_info p {
  padding-left: 30px;
}

.e_promo_text {
  margin-left: 70px;
  border-radius: 6px;
  padding: 60px;
  background-color: white;
  -webkit-box-shadow: 0px 40px 60px 0px rgba(92, 10, 20, 0.08);
  box-shadow: 0px 40px 60px 0px rgba(92, 10, 20, 0.08);
}

.e_promo_text .promo_tag {
  padding: 1px 10px;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  background: #fd475d;
}

.e_promo_text h3 {
  font-size: 34px;
  font-weight: 600;
  line-height: 44px;
  color: #2c2c51;
  margin: 30px 0px 40px;
}

.e_promo_text .date {
  color: #fba820;
  margin-left: 30px;
}

.e_promo_text .event_btn {
  padding: 7px 37px;
}

.event_schedule_area {
  background: #fdfaf6;
}

.event_schedule_inner {
  max-width: 970px;
  margin: 0 auto;
}

.event_schedule_inner .event_tab {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 20px;
  border: 0px;
}

.event_schedule_inner .event_tab .nav-item {
  margin: 0;
}

.event_schedule_inner .event_tab .nav-item .nav-link {
  text-align: center;
  border: 2px solid #fd475d;
  border-radius: 45px;
  padding: 6px 58px 8px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.event_schedule_inner .event_tab .nav-item .nav-link h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  color: #fd475d;
}

.event_schedule_inner .event_tab .nav-item .nav-link h5 span {
  font-weight: 400;
  font-size: 14px;
  display: block;
  padding-top: 5px;
}

.event_schedule_inner .event_tab .nav-item .nav-link.active, .event_schedule_inner .event_tab .nav-item .nav-link:hover {
  background: #fd475d;
  -webkit-box-shadow: 0px 10px 50px 0px rgba(195, 33, 52, 0.3);
  box-shadow: 0px 10px 50px 0px rgba(195, 33, 52, 0.3);
}

.event_schedule_inner .event_tab .nav-item .nav-link.active h5, .event_schedule_inner .event_tab .nav-item .nav-link:hover h5 {
  color: #fff;
}

.event_schedule_inner .event_tab .nav-item + .nav-item {
  margin-left: 20px;
}

.event_tab_content {
  max-width: 970px;
  margin: 0 auto;
}

.event_tab_content .media {
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 30px 60px 0px rgba(92, 10, 20, 0.08);
  box-shadow: 0px 30px 60px 0px rgba(92, 10, 20, 0.08);
  padding: 40px;
  margin-top: 20px;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.event_tab_content .media .media-left {
  margin-right: 70px;
}

.event_tab_content .media .media-left img {
  border-radius: 50%;
  margin-bottom: 12px;
}

.event_tab_content .media .media-left a {
  display: block;
  font-size: 16px;
  line-height: 20px;
  color: #2c2c51;
  margin-top: 6px;
}

.event_tab_content .media .media-left a i {
  color: #fd475d;
  padding-right: 8px;
}

.event_tab_content .media .media-body {
  border-left: 1px solid #f1ece5;
  padding-left: 70px;
}

.event_tab_content .media .media-body .h_head {
  font-weight: 500;
  margin-bottom: 3px;
}

.event_tab_content .media .media-body span {
  color: #7b7b93;
}

.event_tab_content .media .media-body span a {
  color: #fba820;
}

.event_tab_content .media .media-body p {
  color: #7b7b93;
  margin-bottom: 0;
  padding-top: 12px;
}

.event_tab_content .show .media {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

/*================ event_price_area css ============== */
.event_price_item {
  padding: 46px 0px 36px;
  border: 0px;
  border-radius: 0px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 40px 0px rgba(64, 1, 9, 0.06);
  box-shadow: 0px 10px 40px 0px rgba(64, 1, 9, 0.06);
  position: relative;
  margin-bottom: 30px;
}

.event_price_item:before {
  content: "";
  background: url("../img/home-event/price_shap.png") no-repeat scroll center bottom;
  position: absolute;
  bottom: -22px;
  left: 0;
  height: 22px;
  width: 100%;
  background-size: cover;
}

.event_price_item .p_head {
  border: 0px;
}

.event_price_item .p_head h5 {
  margin-bottom: 0;
}

.event_price_item .p_head .rate, .event_price_item .p_head .tag {
  color: #fba820;
}

.event_price_item .p_body {
  padding: 0px 40px 30px;
  border-bottom: 0.5px dashed #cfcfdc;
}

.event_price_item .p_body li {
  color: #7b7b93;
}

.event_price_item .p_body li .ti-check {
  color: #26da15;
}

.event_price_item .p_body li .ti-close {
  color: #fc314e;
}

.event_price_item .event_btn {
  width: 100%;
}

.event_price_item .event_btn:hover {
  -webkit-box-shadow: 0px 10px 40px 0px rgba(195, 33, 52, 0.3);
  box-shadow: 0px 10px 40px 0px rgba(195, 33, 52, 0.3);
}

.event_price_item .text-center {
  padding: 40px 40px 0px;
}

.event_price_item.active {
  padding-top: 20px;
  -webkit-box-shadow: 0px 50px 100px 0px rgba(64, 1, 9, 0.1);
  box-shadow: 0px 50px 100px 0px rgba(64, 1, 9, 0.1);
}

.event_price_item.active .p_head .rate {
  color: #fd475d;
}

.event_price_item.active .p_body li {
  color: #7b7b93;
}

.event_price_item.active .event_btn {
  -webkit-box-shadow: 0px 10px 40px 0px rgba(195, 33, 52, 0.3);
  box-shadow: 0px 10px 40px 0px rgba(195, 33, 52, 0.3);
}

.event_price_item:hover {
  -webkit-box-shadow: 0px 50px 100px 0px rgba(64, 1, 9, 0.1);
  box-shadow: 0px 50px 100px 0px rgba(64, 1, 9, 0.1);
}

/*============ event_fact_area  css =================*/
.event_fact_area {
  background: #fdfaf6;
}

.event_fact_area .seo_fact_info:before {
  background: url("../img/home-event/dot.png") no-repeat center center;
}

.event_fact_area .seo_fact_info .seo_fact_item {
  margin-top: 0;
}

.event_gallery .portfolio_item .portfolio_img:hover .hover_content {
  background-image: linear-gradient(-120deg, #fd475d 0%, #0e044b 100%);
  opacity: 0.8;
}

.event_sponser_inner .event_sponser_item {
  border-top: 1px solid #f0ebec;
  padding: 40px 0px;
}

.event_sponser_inner .event_sponser_item .sponser_title {
  margin-bottom: 0;
  padding-top: 25px;
}

.event_sponser_inner .event_sponser_item .analytices_logo {
  margin-top: 0;
}

.event_sponser_inner .event_sponser_item .event_btn:hover {
  -webkit-box-shadow: 0px 10px 50px 0px rgba(195, 33, 52, 0.3);
  box-shadow: 0px 10px 50px 0px rgba(195, 33, 52, 0.3);
}

.event_sponser_inner .event_sponser_item .event_btn.event_btn_two {
  margin-left: 20px;
}

.event_sponser_inner .event_sponser_item.last-child {
  padding-top: 60px;
  padding-bottom: 0;
}

.event_footer_area {
  background: #212146;
}

.event_footer_area .instagram_widget ul li a:before {
  background: rgba(253, 71, 93, 0.5);
}

/*============= event_location_area css =========*/
.event_location_area {
  position: relative;
  z-index: 1;
}

.event_location_area .map iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0px;
  z-index: 1;
}

.event_location {
  max-width: 570px;
  z-index: 2;
  position: relative;
  background-color: rgba(255, 255, 255, 0.922);
  padding: 150px 100px;
}

.event_location h6 {
  font-size: 20px;
  font-weight: 600;
  color: #2c2c51;
}

.event_location p {
  font-size: 16px;
  color: #7b7b93;
  margin-bottom: 0;
}

.event_location .contact_info_item {
  border: 0px;
}

.event_location .f_social_icon_two {
  padding-top: 30px;
}

.event_location .f_social_icon_two a {
  color: #7b7b93;
}

.event_location .f_social_icon_two a:hover {
  color: #fd475d;
}


/*============= chat_banner_area css ============*/
.chat_banner_area {
  height: 100vh;
  min-height: 700px;
  background: #fafafe;
  position: relative;
  padding: 150px 0px 120px;
  overflow: hidden;
}
.chat_banner_area .cloud {
  top: 190px;
  left: 0px;
  width: 100%;
}
.chat_banner_area .left {
  left: 0;
  bottom: 30px;
}
.chat_banner_area .right {
  right: 0;
  bottom: 30px;
}

.chat_banner_content {
  padding-top: 128px;
}
.chat_banner_content .c_tag {
  border-radius: 4px;
  background: #e2f4e8;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  color: #0ec123;
  padding: 4px 15px;
  margin-bottom: 25px;
}
.chat_banner_content .c_tag img {
  margin-right: 6px;
}
.chat_banner_content h2 {
  font-size: 48px;
  line-height: 72px;
  font-weight: 400;
  color: #051441;
  margin-bottom: 30px;
}
.chat_banner_content .chat_btn {
  width: 100%;
}
.chat_banner_content span {
  font-size: 14px;
  color: #999999;
}

.chat_img {
  position: relative;
}
.chat_img .round {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  opacity: 0.7;
}
.chat_img .round.one {
  background: #deeef1;
  left: 0;
  bottom: 120px;
}
.chat_img .round.two {
  background: #f5eae3;
  right: -20px;
  top: 120px;
}
.chat_img .round.three {
  background: #f9f5e8;
  right: -50px;
  top: 70px;
  width: 60px;
  height: 60px;
}
.chat_img .p_one {
  right: -250px;
  bottom: 300px;
  animation: fadeInUp3 2.5s 1s both alternate infinite;
  z-index: 1;
}
.chat_img .p_two {
  left: -140px;
  bottom: 300px;
  z-index: 1;
  animation: fadeInUp3 2.5s 3s both alternate infinite;
}
.chat_img .p_three {
  right: -70px;
  bottom: 180px;
  z-index: 1;
  animation: fadeInUp3 1.5s 5s both alternate infinite;
}

/*=========== chat_core_features_area css ============*/
.chat_title {
  margin-bottom: 75px;
}
.chat_title h2 {
  color: #222;
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
}
.chat_title h2 span {
  font-weight: 700;
}
.chat_title p {
  max-width: 730px;
  margin: 0px auto;
  color: #677294;
}

.chat_features_item {
  text-align: center;
  padding: 0px 30px;
}
.chat_features_item .round {
  height: 270px;
  line-height: 324px;
  position: relative;
}
.chat_features_item .round .top_img {
  top: -6px;
  right: 8px;
  transition: all 0.3s linear;
}
.chat_features_item .round_circle {
  background-image: -moz-linear-gradient(-140deg, #f483a3 0%, #c670fe 100%);
  background-image: -webkit-linear-gradient(-140deg, #f483a3 0%, #c670fe 100%);
  background-image: linear-gradient(-140deg, #f483a3 0%, #c670fe 100%);
  width: 220px;
  height: 220px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  left: 58%;
  transform: translateX(-50%);
  position: absolute;
  z-index: -1;
  top: 0;
}
.chat_features_item .round_circle.two {
  background-image: -moz-linear-gradient(-140deg, #83f3f4 0%, #70b6fe 100%);
  background-image: -webkit-linear-gradient(-140deg, #83f3f4 0%, #70b6fe 100%);
  background-image: linear-gradient(-140deg, #83f3f4 0%, #70b6fe 100%);
}
.chat_features_item .round_circle.three {
  background-image: -moz-linear-gradient(-140deg, #f0f483 0%, #fec070 100%);
  background-image: -webkit-linear-gradient(-140deg, #f0f483 0%, #fec070 100%);
  background-image: linear-gradient(-140deg, #f0f483 0%, #fec070 100%);
}
.chat_features_item h4 {
  font-size: 18px;
  font-weight: 700;
  color: #222;
  margin-bottom: 18px;
  position: relative;
  z-index: 1;
}
.chat_features_item p {
  color: #666666;
  line-height: 24px;
}
.chat_features_item:hover .round .top_img {
  transform: rotate(90deg);
}

/*============= chat_features_area css ===========*/
.chat_features_area {
  padding: 120px 0px;
}

.chat_features_img {
  background-image: -moz-linear-gradient(-140deg, #83e0f4 0%, #7094fe 100%);
  background-image: -webkit-linear-gradient(-140deg, #83e0f4 0%, #7094fe 100%);
  background-image: -ms-linear-gradient(-140deg, #83e0f4 0%, #7094fe 100%);
  background-image: linear-gradient(-140deg, #83e0f4 0%, #7094fe 100%);
  width: 100%;
  height: 475px;
  position: relative;
  padding-left: 60px;
}
.chat_features_img .chat_features_img_bg_one {
  position: absolute;
  left: 0;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
}
.chat_features_img .chat_one, .chat_features_img .chat_two {
  box-shadow: 0px 5px 20px 0px rgba(12, 0, 46, 0.15);
}
.chat_features_img .chat_one {
  top: 0px;
  position: relative;
}
.chat_features_img .chat_two {
  left: 46%;
  bottom: -35px;
}
.chat_features_img .dot_bg {
  left: -30px;
  bottom: 40px;
}
.chat_features_content img {
  margin-bottom: 28px;
}
.chat_features_content h2 {
  font-size: 36px;
  font-weight: 400;
  color: #222;
  margin-bottom: 16px;
}
.chat_features_content h2 span {
  font-weight: 700;
}
.chat_features_content p {
  line-height: 30px;
  color: #666666;
}
.chat_features_content a {
  color: #666666;
  font-size: 14px;
  font-weight: 700;
}
.chat_features_content a:hover {
  color: #6754e2;
}

.chat_features_img_two {
  background-image: -moz-linear-gradient(-140deg, #f7b05c 0%, #f08260 100%);
  background-image: -webkit-linear-gradient(-140deg, #f7b05c 0%, #f08260 100%);
  background-image: -ms-linear-gradient(-140deg, #f7b05c 0%, #f08260 100%);
  background-image: linear-gradient(-140deg, #f7b05c 0%, #f08260 100%);
  margin-left: 30px;
}
.chat_features_img_two .chat_two {
  bottom: 50px;
  left: -30px;
}
.chat_features_img_two .chat_one {
  top: -30px;
}

.chat_features_img_three {
  background-image: -moz-linear-gradient(-140deg, #5cf77d 0%, #70c7fe 100%);
  background-image: -webkit-linear-gradient(-140deg, #5cf77d 0%, #70c7fe 100%);
  background-image: -ms-linear-gradient(-140deg, #5cf77d 0%, #70c7fe 100%);
  background-image: linear-gradient(-140deg, #5cf77d 0%, #70c7fe 100%);
}
.chat_features_img_three .chat_two {
  left: 60px;
  bottom: 50px;
}

.chat_integration_area {
  padding-bottom: 120px;
}
.chat_integration_area .border-bottom {
  margin-bottom: 110px;
}

/*============= chat_features_area css ===========*/
/*============= chat_clients_area css ===========*/
.chat_clients_feedback {
  background-image: -moz-linear-gradient(-140deg, #5ce3f7 -100%, #70a5fe 100%);
  background-image: -webkit-linear-gradient(-140deg, #5ce3f7 -100%, #70a5fe 100%);
  background-image: linear-gradient(-140deg, #5ce3f7 -100%, #70a5fe 100%);
  display: flex;
  align-items: flex-end;
}
.chat_clients_feedback.feedback_two {
  background-image: -moz-linear-gradient(-140deg, #b473fe 0%, #ad65ff 100%);
  background-image: -webkit-linear-gradient(-140deg, #b473fe 0%, #ad65ff 100%);
  background-image: linear-gradient(-140deg, #b473fe 0%, #ad65ff 100%);
}
.chat_clients_feedback.feedback_two .clients_feedback_item .feedback_body {
  padding-right: 0;
}

.clients_feedback_item {
  display: flex;
  align-items: flex-start;
  align-items: center;
  padding-top: 40px;
}
.clients_feedback_item .feedback_body {
  padding-left: 100px;
  padding-right: 100px;
  position: relative;
  flex: 1;
}
.clients_feedback_item .feedback_body p {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 25px;
}
.clients_feedback_item .feedback_body .ratting {
  padding-bottom: 10px;
}
.clients_feedback_item .feedback_body .ratting i {
  font-size: 16px;
  color: #ffc859;
}
.clients_feedback_item .feedback_body .f_name {
  font-size: 30px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
}
.clients_feedback_item .feedback_body a {
  font-size: 14px;
  color: #fff;
}

.chat_get_started_area {
  background: url("../img/home-chat/get_started_bg.png") no-repeat scroll center bottom;
  height: 712px;
  padding-top: 120px;
}

.chat_get_started_content h2 {
  font-weight: 700;
  color: #222;
  font-size: 36px;
}
.chat_get_started_content p {
  font-size: 18px;
  line-height: 30px;
  max-width: 550px;
  margin: 0 auto 40px;
}

.chat_btn {
  background: #6754e2;
  color: #fff;
  padding: 9px 95px;
  border-radius: 4px;
}
.chat_btn:hover {
  box-shadow: 0px 10px 40px 0px rgba(103, 84, 226, 0.3);
}

.h_footer_track {
  padding-bottom: 100px;
}
.h_footer_track:before, .h_footer_track:after {
  display: none;
}

/*============= chat_clients_area css ===========*/


/*====================================================*/


/*====================================================*/

/*# sourceMappingURL=style.css.map */
